import "./ChatCommentInputBox.css";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class ChatCommentInputBox extends React.Component {
  state = {
    chatInput: "",
    messageSent: false,
    sendingProgress: 0,
    chatInputPlaceholderVisible: true,
    sendButton: true,
  };

  componentDidMount = () => {
    document.getElementsByClassName("progressBar")[0].style.display = "none";
  };

  startProgressBar() {
    let n = 0;
    const intervalID = setInterval(() => {
      this.setState({ sendingProgress: this.state.sendingProgress + 1 });
      if (n === 100) {
        clearInterval(intervalID);
        this.setState({ sendingProgress: 0 });
      }
      n += 1;
    }, 10);
  }

  writeMessageAndAnimateSendingOperation = () => {
    document.getElementsByClassName("progressBar")[0].style.display = "block";
    this.startProgressBar();

    document.getElementsByClassName("chatInput")[0].style.color = "rgba(0,0,0,0)";

    setTimeout(() => {
      this.props.writeComment(this.state.chatInput);
      this.setState({ messageSent: true, sendButton: false });
      document.getElementsByClassName("chatInput")[0].style.color = "#30d469";
    }, 1000);
    setTimeout(() => {
      this.setState({ messageSent: false, chatInput: "", sendButton: true });
      document.getElementsByClassName("chatInput")[0].style.color = "#656565";
      document.getElementsByClassName("progressBar")[0].style.display = "none";
    }, 3000);
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="chatFieldWrapper"
          onKeyDown={e => {
            if (e.key === "Enter") {
              this.writeMessageAndAnimateSendingOperation();
            }
          }}
        >
          <input
            placeholder={this.state.chatInputPlaceholderVisible && "Type your message"}
            value={this.state.messageSent ? "Message sent" : this.state.chatInput}
            onChange={e => this.setState({ chatInput: e.target.value })}
            className="chatInput"
          />

          <div className="chatMessageIcon">
            {/* <FontAwesomeIcon name="comments" className="fas fa-comments fa-2x" /> */}
            <FontAwesomeIcon icon={["fa", "comments"]} size="2x" />
          </div>
          {this.state.sendButton ? (
            <div
              className="chatSendMessageButton"
              onClick={() => this.writeMessageAndAnimateSendingOperation()}
            >
              {/* <FontAwesomeIcon name="telegram" className="fab fa-telegram fa-2x" /> */}
              <FontAwesomeIcon icon={["fab", "telegram"]} size="2x" />
            </div>
          ) : (
            <div className="chatSendMessageButton" style={{ color: "#30d469" }}>
              {/* <FontAwesomeIcon name="check" className="fab fa-check fa-2x" /> */}
              <FontAwesomeIcon icon={["fa", "check"]} size="2x" />
            </div>
          )}

          <div className="progressBar">
            <div className="progress" style={{ width: `${this.state.sendingProgress}%` }} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ChatCommentInputBox.propTypes = {
  writeComment: PropTypes.func,
};

export default ChatCommentInputBox;
