import "./ActiveFansBox.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import ActiveFansList from "./ActiveFansList";
import "../../core/css/round-box.css";
import BoxBlock from "../../core/BoxBlock";
import { Constants } from "../../core/constants";
import Scheduler from "../../core/Scheduler";

class ActiveFansBox extends Component {
  render = () => (
    <Scheduler interval={10000} isRunning={this.props.isRunning} action={this.props.action}>
      <BoxBlock
        title="Most Engaged"
        boxType={Constants.BOX_TYPES.ACTIVE_FANS}
        expandVertical
        color="yellow"
        addedClassName="ActiveFans"
        isEmpty={this.props.fans === null || this.props.fans.length === 0}
        emptyStateMessage="Most Engaged viewers will appear here"
      >
        <ActiveFansList fans={this.props.fans} />
      </BoxBlock>
    </Scheduler>
  );
}

ActiveFansBox.propTypes = {
  fans: PropTypes.array,
  interval: PropTypes.number,
  isRunning: PropTypes.bool,
  action: PropTypes.func,
};

export default ActiveFansBox;
