import React, { Component } from "react";
import { Parallax, Background } from "react-parallax";
import "./LandingPage.css";
import "./mediaStyle.css";
import PreviewBox from "./components/PreviewBox";
import Carousel from "./Slider/Carousel";
import FooterNavigationBox from "./components/FooterNavigationBox";
// import workPeople from "../../assets/images/group-12.svg";
import workPeople from "../../assets/images/loola-social-promo-image-v2.svg";
import BGR from "../../assets/images/BG.png";
import stephanie from "../../assets/images/Stephanie.png";
// import stephanie from "../../assets/images/stephanie-2x.png";
import olivia from "../../assets/images/Olivia.png";
// import olivia from "../../assets/images/olivia-2x.png";
import rocky from "../../assets/images/Rocky.png";
// import rocky from "../../assets/images/rocky-2x.png";
import anesha from "../../assets/images/Anesha.png";
// import anesha from "../../assets/images/anesha-2x.png";
import { StudioConnectPagePath } from "../../PageConstants";
import LandingPageFooter from "./LandingPageFooter";
import LoolaPublicPageHeader from "./components/LoolaPublicPageHeader";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

const slides = [
  {
    id: 0,
    header: "“I’m totally geeking out on Loola right now.”",
    body: "Stephanie Liu",
    content: "@heystephanieliu",
    footer: "Founder and Host of Lights, Camera, Live®",
    img: stephanie,
  },
  {
    id: 1,
    header: "“Loola is a must-have for anyone who want to truly engage with their audience.”",
    body: "Olivia Thai",
    content: "@olivia.thai",
    footer: "Musician & Social Media Influencer",
    img: olivia,
  },
  {
    id: 2,
    header:
      "“Loola helps me connect with more people at the same time, and the costumer support is amazing.”",
    body: "Rocky G",
    content: "@rockygmusic",
    footer: "DJ & Producer",
    img: rocky,
  },
  {
    id: 3,
    header:
      "“Being able to broadcast my content on multiple platforms at the same time is a huge timesaver for me.”",
    body: "Anesha Collins",
    content: "@unashamedimaging",
    footer: "Video-Marketing Strategist & Content Creator",
    img: anesha,
  },
];

class LandingPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.LANDING_PAGE);
  }

  play = () => {
    this.YouTube.innerHTML = `<iframe 
                src="https://www.youtube.com/embed/tzIt9AmixxE?autoplay=1"
                rel="0" 
                allow="autoplay"
                frameborder="0"  
                encrypted-media   
                allowfullscreen />`;
  };

  handleGetStartedClick = () => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_GET_STARTED_BUTTON_CLICKED);
  };

  render() {
    return (
      <div className="LandingPage">
        <div className="top-section">
          <LoolaPublicPageHeader textColor="black" logoColor="white" />

          <div className="getStarted">
            <div className="startedBox">
              <p className="title">
                Get The Most
                <br />
                Out of Your Live Content!
              </p>
              <div className="responseBox">
                <p className="description">
                  Finally, you can go live on multiple social networks all at once and in one place.
                </p>
                <div className="btn-box">
                  <a href={StudioConnectPagePath}>
                    <button onClick={this.handleGetStartedClick} className="btnStart">
                      Let’s Get Started
                    </button>
                  </a>
                  <span className="free">Try for Free!</span>
                </div>
              </div>
            </div>
            <img className="workPeople" src={workPeople} alt="" />
          </div>
        </div>

        <Parallax className="parallax" strength={500}>
          <Background className="custom-bg">
            <img src={BGR} alt="fill murray" />
            <div className="parallaxBox">
              <div>
                <p className="slogan-top">
                  <span>Loola</span> is a new, innovative way of live streaming:
                </p>
                <p className="slogan-bottom">A home for creators, influencers and storytellers.</p>
              </div>
            </div>
          </Background>
        </Parallax>

        <div className="containerVideoBox">
          <div
            id="videoBox"
            ref={ref => {
              this.YouTube = ref;
            }}
          >
            <div className="videoClickButton" onClick={this.play} />
          </div>
        </div>

        <PreviewBox />

        <div className="line-background">
          <p>let’s get started</p>
          <a href={StudioConnectPagePath}>
            <button>Connect your channels and start streaming</button>
          </a>
        </div>

        <Carousel slides={slides} />
        <FooterNavigationBox />
        <LandingPageFooter />
      </div>
    );
  }
}

export default LandingPage;
