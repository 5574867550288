import "./MainVideoRoomPage.css";

import React, { Component } from "react";
import StreamDestinationBoxContainer from "../Platforms/StreamDestinationBoxContainer";

import LiveVideoContainer from "../LiveVideo/LiveVideoContainer";
import ChatViewerBoxContainer from "../common/ChatMessages/ChatViewerBoxContainer";

export default class MainVideoRoomPage extends Component {
  render() {
    return (
      <div className="MainVideoRoomPage MobileVideoRoomView">
        <div className="MainVideoRoomPage__videoRailContainer">
          <StreamDestinationBoxContainer isMobile />
          <LiveVideoContainer />
          <ChatViewerBoxContainer overlay isMobile />
        </div>
      </div>
    );
  }
}
