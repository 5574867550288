import React, { Component } from "react";
import moment from "moment";
import cx from "classnames";
import "./MyBroadcastsBox.css";
import PropTypes from "prop-types";
import VideoLink from "./VideoLink";
import DeleteLink from "./DeleteLink";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class BroadcastHistoryLiveEventEntry extends Component {
  renderVideoLinks(videoLink) {
    if (videoLink === undefined || videoLink === null) return null;

    return (
      <div className="myBroadcastsList-item-download">
        {videoLink.landscape && <VideoLink filePath={videoLink.landscape} type="landscape" />}
        {videoLink.portrait && <VideoLink filePath={videoLink.portrait} type="portrait" />}
      </div>
    );
  }

  chooseCurrentBroadcast(roomId) {
    this.props.chooseCurrentBroadcast(roomId);
    // track
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.BROADCASTS_HISTORY_SELECTED, {
      room: roomId,
    });
  }

  renderVideoPreviewImage(element) {
    if (element.videoPreviewImage == null) return <div className="circle" />;
    return <img src={element.videoPreviewImage} alt={element.title} />;
  }

  render() {
    const greyStyle = {
      backgroundColor: "#cecece",
      color: "#cecece",
      opacity: "0.46",
      borderRadius: "3.5px",
      marginBottom: "6px",
      whiteSpace: "pre",
      // lineHeight: "1.6",
    };

    const element = this.props;
    const broadcastTitle = element.title ? element.title : "[No Name]";

    return (
      <div
        className={cx("myBroadcastsList-item", {
          "myBroadcastsList__item--selected": element.isSelected,
        })}
        onClick={() => {
          this.chooseCurrentBroadcast(element.roomId);
        }}
      >
        {this.renderVideoLinks(element.videoLink)}

        <div className="myBroadcastsList-item-image">{this.renderVideoPreviewImage(element)}</div>

        <div className="myBroadcastsList-item-description">
          {broadcastTitle == null ? (
            <div style={greyStyle}>{broadcastTitle}</div>
          ) : (
            <div className="myBroadcastsList-item-broadcastName">{broadcastTitle}</div>
          )}

          <div className="myBroadcastsList-item-statistics">
            {element.numberOfViews == null ? (
              <div style={greyStyle}>
                Streamed: {moment(element.startTime).format("D/M/YYYY, hh:mm:ss")}
              </div>
            ) : (
              <div>Streamed: {moment(element.startTime).format("D/M/YYYY, hh:mm:ss")}</div>
            )}
            <DeleteLink
              onClick={e => {
                e.stopPropagation();
                this.props.deleteBroadcast(element.roomId);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

BroadcastHistoryLiveEventEntry.propTypes = {
  roomId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  startTime: PropTypes.object,
  numberOfViews: PropTypes.number,
  numberOfChannels: PropTypes.number,
  lengthInSeconds: PropTypes.number,
  isSelected: PropTypes.bool,
  fetchBroadcastById: PropTypes.func,
  videoPreviewImage: PropTypes.string,
  videoLink: PropTypes.string,
  deleteBroadcast: PropTypes.func,
};

export default BroadcastHistoryLiveEventEntry;
