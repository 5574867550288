export const loadState = () => {
  let serializedState;
  try {
    serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    console.log("hint: error loading state", serializedState);
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    // IGNORE ERRORS
    console.log("hint: error storing state", state);
  }
};
