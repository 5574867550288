import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import "./NotificationModal.css";

const NotificationModal = ({
  title,
  afterClose,
  hideModal,
  actionText = "OK",
  width = "100%",
  height = "auto",
}) => {
  const onClose = () => {
    hideModal();

    if (afterClose) {
      afterClose();
    }
  };

  return (
    <Modal title={title} onClose={onClose} height={height} width={width}>
      <div className="notification-modal__wrapper">
        <button className="notification-modal__action Button Button--save" onClick={onClose}>
          {actionText}
        </button>
      </div>
    </Modal>
  );
};

NotificationModal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default NotificationModal;
