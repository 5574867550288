import React from "react";
import imgGroup1 from "../../../assets/images/group-13.png";
import imgGroup2 from "../../../assets/images/group-14.png";
import imgGroup3 from "../../../assets/images/group-16.png";
import imgGroup4 from "../../../assets/images/group-17.png";
import AOS from "aos";
import "aos/dist/aos.css";

const itemPreview = [
  {
    img: imgGroup1,
    titleText: "OMG, you’re everywhere!",
    descriptionText:
      "Go live on multiple social networks at once, multiply your views and control all of the comments.",
  },
  {
    img: imgGroup2,
    titleText: "Easy as pie.",
    descriptionText:
      "No technical hassle, no downloads, no coding. Just login and start streaming.",
  },
  {
    img: imgGroup3,
    titleText: "Grow with each stream.",
    descriptionText: "Review your analytics to see how your live streams are performing.",
  },
  {
    img: imgGroup4,
    titleText: "Make it your own.",
    descriptionText: "Add your branding, add super cool effects and make your streams outstanding.",
  },
];

class PreviewBox extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
  }

  render() {
    return (
      <div id="preview-container-box">
        {itemPreview.map((item, i) => (
          <div
            data-aos={i % 2 !== 0 ? "fade-left" : "fade-right"}
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
            className={`preview-box ${i % 2 !== 0 ? "rightFloat" : ""}`}
            key={i}
          >
            <img src={item.img} alt="" />
            <div className="preview-container">
              <p className="box-title">{item.titleText}</p>
              <p className="box-hint">{item.descriptionText}</p>
              <span className="box-line" />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default PreviewBox;
