import "./actions-styles.css";
import React, { Component } from "react";

export class BackgroundIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Background</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Background" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icon" transform="translate(14.000000, 17.000000)">
            <g id="Background">
              <g id="Group-4">
                <g id="Page-1">
                  <polygon
                    id="Fill-1"
                    fill="#F2680A"
                    opacity="0.343919837"
                    points="0.5471 16.4623 5.2421 11.2983 9.6541 16.4623 18.6681 6.8853 25.2411 13.4583 25.2411 20.6853 0.5471 20.6853"
                  />
                  <path
                    d="M12.8934,6.6968 C12.8934,8.2528 11.6334,9.5138 10.0774,9.5138 C8.5204,9.5138 7.2604,8.2528 7.2604,6.6968 C7.2604,5.1408 8.5204,3.8798 10.0774,3.8798 C11.6334,3.8798 12.8934,5.1408 12.8934,6.6968 Z"
                    id="Fill-3"
                    stroke="#F2680A"
                    fillOpacity="0.4296875"
                    fill="#F2680A"
                  />
                  <path
                    d="M2.5,20.687 L23.193,20.687 C24.2975695,20.687 25.193,19.7915695 25.193,18.687 L25.193,2.5 C25.193,1.3954305 24.2975695,0.5 23.193,0.5 L2.5,0.5 C1.3954305,0.5 0.5,1.3954305 0.5,2.5 L0.5,18.687 C0.5,19.7915695 1.3954305,20.687 2.5,20.687 Z"
                    id="Stroke-5"
                    stroke="#F2680A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.5002,16.4623 L5.1942,11.2983 L9.6072,16.4623 L18.6212,6.8853 L25.1932,13.4583 L25.1932,18.6853 C25.1932,19.7898695 24.2977695,20.6853 23.1932,20.6853 L2.5002,20.6853 C1.3956305,20.6853 0.5002,19.7898695 0.5002,18.6853 L0.5002,16.4623 Z"
                    id="Stroke-7"
                    stroke="#F2680A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
