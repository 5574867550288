import { Constants } from "../../core/constants";
import { PollsQuestionsModel } from "../../models/PollsQuestionsModel";

const initialState = {
  allQuestions: [],
  selectedQuestionId: null, // this one is a string identifier -- either null for not choosing or the id itself.
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.POLLS_GET_QUESTIONS:
      if (
        action.payload == null ||
        action.payload.questions == null ||
        action.payload.questions.length === 0
      )
        return state;

      const pollsQuestionsModel = new PollsQuestionsModel(action.payload.questions);
      const newState = Object.assign({}, state, {
        allQuestions: pollsQuestionsModel.questions,
      });
      return newState;
    case Constants.ACTION_TYPES.POLLS_SHOW_QUESTION:
      if (!action.payload.ok) return state;

      const newStateAfterSelection = Object.assign({}, state, {
        selectedQuestionId: action.payload.selectedQuestionId,
      });
      return newStateAfterSelection;
    case Constants.ACTION_TYPES.POLLS_HIDE_QUESTION:
      if (!action.payload.ok) return state;

      const newStateAfterHiding = Object.assign({}, state, {
        selectedQuestionId: null,
      });
      return newStateAfterHiding;
    default:
      return state;
  }
}
