import { Constants } from "../core/constants";
import { BroadcastHistoryLiveEventDetails } from "../models/BroadcastHistoryLiveEventDetails";

const initialState = {
  selectedBroadcastRoomId: null,
  selectedBroadcast: {
    empty: true,
    broadcastInfo: null,
    liveEventPlatformsTotals: null,
    liveEventPlatformCounters: null,
    igTvShareStatus: null,
  },
  allBroadcasts: [],
};

const broadcastHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_ALL:
      return Object.assign({}, state, {
        allBroadcasts: action.payload.broadcastHistoryList,
      });
    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_BY_ROOM:
      const clientModel = new BroadcastHistoryLiveEventDetails(
        action.payload.broadcastHistoryLiveEventDetails
      );
      return Object.assign({}, state, {
        selectedBroadcast: clientModel,
        selectedBroadcastRoomId: clientModel.roomId,
      });
    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_CURRENT_BROADCAST:
      return Object.assign({}, state, {
        currentBroadcast: JSON.parse(JSON.stringify(action.payload)),
      });

    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM:
      return Object.assign({}, state, {
        ...state,
        selectedBroadcast: {
          ...state.selectedBroadcast,
          broadcastInfo: {
            ...state.selectedBroadcast.broadcastInfo,
            comments: action.payload,
          },
        },
      });

    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_SHARE_ON_IGTV:
      return Object.assign({}, state, {
        ...state,
        error: action.error,
        payload: action.payload,
        selectedBroadcast: {
          ...state.selectedBroadcast,
          IGTVShareStatus: !action.error,
        },
      });
    case Constants.ACTION_TYPES.BROADCASTS_HISTORY_DELETE_ROOM:
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      if (action.payload) {
        const broadcasts = state.allBroadcasts.filter(
          broadcast => broadcast.roomId !== action.payload
        );
        const isSelectedDeleted =
          (state.selectedBroadcast && state.selectedBroadcast.roomId) === action.payload;

        return Object.assign({}, state, {
          ...state,
          selectedBroadcast: isSelectedDeleted
            ? initialState.selectedBroadcast
            : state.selectedBroadcast,
          allBroadcasts: broadcasts,
        });
      }

      break;
    default:
      return state;
  }
};

export default broadcastHistoryReducer;
