import { SHOW_MODAL, HIDE_MODAL } from "./ActionTypes";

export const showModal = (type, props) => {
  return {
    type: SHOW_MODAL,
    payload: {
      type,
      props,
    },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
