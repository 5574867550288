const isPromise = val => {
  if (val === undefined || val == null) {
    return false;
  }
  return val && typeof val.then === "function";
};

const downloadData = (data, fileName, dataFormat) => {
  const element = document.createElement("a");
  element.setAttribute("href", dataFormat(data));
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
export { isPromise, downloadData };
