import CookieUtil from "./cookie-util";

const clearAuthState = () => {
  // must call reset to both segment and mixpanel (segment doesn't to that for us)
  // in order to clear the cookie and not mistakenly attribute anonymous events to the user that was just logged off
  if (window.analytics !== undefined) {
    window.analytics.reset();
  }

  try {
    if (window.mixpanel !== undefined) {
      window.mixpanel.reset();
    }
  } catch (error) {
    console.warn("Failed to clear mixpanel data", error);
  }

  try {
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.error("Failed to remove item from storage!", error);
  }

  try {
    CookieUtil.clearAllCookies();
  } catch (error) {
    console.error("Failed to clear all cookies!", error);
  }
};

export { clearAuthState };
