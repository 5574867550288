import React, { Component } from "react";
import cx from "classnames";
import "./SystemMessageError.css";
import SystemMessageHelpLink from "./SystemMessageHelpLink";

class SystemMessageError extends Component {
  state = {
    timerId: null,
  };

  componentDidMount() {
    this.sheduleMessageDestroying();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // if there is new another one error message, cancel destroyng preveous
    if (nextProps.messageText !== this.props.messageText) {
      clearTimeout(this.state.timerId);
      this.sheduleMessageDestroying();
    }
  }

  sheduleMessageDestroying = () => {
    const timerId = setTimeout(() => {
      this.props.hideSystemMessage();
    }, 10000);

    this.setState({ timerId });
  };

  destroyMessage = () => {
    this.props.hideSystemMessage();
    clearInterval(this.state.timerId);

    this.setState({
      timerId: null,
    });
  };

  render() {
    const { messageText, helpLink } = this.props;

    return (
      <div className="SystemMessage--floating SystemMessage-wrap">
        {/* key attr for rerender component in order to reset css animation (opacity fading) */}
        <div className={cx("SystemMessage", `SystemMessage--error`)} key={messageText}>
          <div className="SystemMessage--error-text">
            {messageText}
            <SystemMessageHelpLink helpLink={helpLink} />
          </div>
          <i className="SystemMessage--error-button" onClick={this.destroyMessage}>
            +
          </i>
        </div>
      </div>
    );
  }
}

export default SystemMessageError;
