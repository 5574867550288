import React, { Component } from "react";
import PropTypes from "prop-types";
import { Constants } from "../../../core/constants";

import "./LiveChatIcon.css";

export class LiveChatIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg className={`LiveChatIcon--${this.props.size}`} viewBox="0 0 36 36" version="1.1">
        <defs>
          <polygon id="path-1" points="0 0 16.1800003 0 16.1800003 16.1800003 0 16.1800003" />
        </defs>
        <g id="chat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Page-1" transform="translate(9.000000, 10.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M2.27217598,12.5430194 C2.3981927,12.6582488 2.43817877,12.8401899 2.36911191,12.9966592 C2.28308127,13.2404603 2.18008683,13.4781967 2.06134031,13.7086554 C1.7438751,14.2362846 1.33916755,14.7081185 0.865393148,15.1011113 C0.648498982,15.2891171 0.443721809,15.4892523 0.251061629,15.7015169 C0.072941839,15.9101427 0.105657719,16.0083909 0.380713448,16.0726768 C1.06290013,16.2206555 1.77053249,16.2158038 2.45029576,16.0569085 C3.73833207,15.7730804 4.98759399,15.3339959 6.16900076,14.7457197 C6.30349937,14.6765821 6.45738518,14.6535362 6.60521249,14.6814338 C7.75875017,14.8767173 8.94015694,14.8427549 10.0815776,14.5856115 C12.2008396,14.1319717 14.0414108,12.8256346 15.1719262,10.9746872 C16.0431379,9.59436067 16.3666616,7.93627078 16.0758538,6.3291244 C15.772929,4.41752991 14.6969401,2.71456121 13.1023439,1.61927579 C11.6798089,0.568869173 9.95919601,0.00242588133 8.1913268,0 C7.77086716,0.032749398 7.35040753,0.032749398 6.92994789,0.0970352533 C5.136633,0.311725751 3.46448804,1.11347953 2.17524004,2.37978959 C0.923554711,3.55876792 0.156549087,5.16348842 0.0256855683,6.87858652 C-0.118506642,8.51484348 0.347997568,10.1462487 1.33432076,11.4586505 C1.62634028,11.8310233 1.94986397,12.203396 2.27217598,12.5430194"
              id="Fill-1"
              fill="#0BADFB"
              mask="url(#mask-2)"
            />
          </g>
        </g>
      </svg>
    );
  }
}

LiveChatIcon.propTypes = {
  size: PropTypes.oneOf(Object.values(Constants.ICON_SIZES)).isRequired,
};
