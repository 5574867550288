class UserSettingsLiveLimitsModel {
  constructor() {
    this.objectModel = "userSettingsLiveLimitsModel";
    this.liveLimits = {
      timeLeftInSeconds: 0,
      exceededLimit: false,
      hasLiveLimit: false,
    };
  }

  static createEmptyModel() {
    return new UserSettingsLiveLimitsModel();
  }

  static createFromServerModel(serverModel) {
    const userSettingsLiveLimitModel = new UserSettingsLiveLimitsModel();
    if (serverModel == null) return userSettingsLiveLimitModel;

    // else (we got data from server, let's map it ...
    const timeLeftInMinutes = serverModel.limitDefined - serverModel.totalTimeStreamedInMinutes;
    const timeLeftInSeconds = timeLeftInMinutes > 0 ? timeLeftInMinutes * 60 : 0;
    const { exceededLimit, hasLiveLimit } = serverModel;

    const liveLimits = {
      timeLeftInSeconds,
      exceededLimit,
      hasLiveLimit,
    };

    userSettingsLiveLimitModel.liveLimits = liveLimits;
    return userSettingsLiveLimitModel;
  }

  isInTrialPeriod() {
    return this.liveLimits && this.liveLimits.hasLiveLimit;
  }

  getTrialTimeLeftInSeconds() {
    return this.liveLimits.timeLeftInSeconds;
  }

  isTrialExceededLimit() {
    return this.liveLimits && this.liveLimits.exceededLimit;
  }
}

export { UserSettingsLiveLimitsModel };
