import "./PrivacyPage.css";

import React, { Component } from "react";

import LoolaPublicPageHeader from "../LandingPage/components/LoolaPublicPageHeader";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class PrivacyPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.PRIVACY_PAGE);
  }

  render() {
    return (
      <div className="PrivacyPage">
        <LoolaPublicPageHeader textColor="black" logoColor="black" />
        <div className="privacyText">
          <p className="privacyText--header">Privacy Policy</p>

          <p className="fontStrong textUnderline">General</p>

          <p className="privacyText">
            This Privacy Policy (the"<span className="fontStrong">Privacy Policy</span>") applies to
            the Loola, content streaming platform operated by Loola Communication Ltd ("Loola"),
            consisting of the web site available at the URL{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://loola.tv">
              https://loola.tv
            </a>
            , and all related services, software applications and networks that allow for the
            authorized streaming and distribution of content over the internet (the"
            <span className="fontStrong">Services</span>").
          </p>

          <p className="privacyText">
            Loola values the privacy of the users and others who visit and use the Services and
            wants you to be familiar with how we collect, use and disclose information from and
            about you.
          </p>

          <p className="privacyText">
            By agreeing to this Privacy Policy or by accessing or using the Services in any way, you
            expressly consent to the information handling practices described hereunder.
          </p>

          <p className="privacyText">
            Capitalized terms not defined herein shall have the meaning ascribed to such terms in
            our Terms of Service at:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://loola.tv/terms">
              https://loola.tv/terms
            </a>{" "}
            (the "<span className="fontStrong">Terms</span>"), of which this Privacy Policy
            constitutes an integral part of.
          </p>

          <p className="fontStrong textUnderline">Information Collected by Loola</p>
          <p className="textUnderline">Information provided by you</p>
          <p className="privacyText">
            As part of the registration to the Services and/or the use of the Services, you may be
            required to provide us with certain information, which might include personal
            identifiable information such as your name, email address, your telephone number and
            other similar information, credit card number and billing information, as well as the
            credentials to your accounts in the Third Party Platforms in or to which you would like
            to broadcast Your User Content through the Services.
          </p>
          <p className="textUnderline">Information collected automatically</p>
          <p className="privacyText">
            When you access and use the Services and/or receive or reply to any notices from Loola,
            we may automatically record and collect certain information from your systems by using
            cookies and other tracking tools. This information may include an IP Address (Internet
            Protocol address), a unique user ID, device type, device identifiers, browser types and
            language, GeoIP and other location identifiers, referring and exit pages, platform type,
            version of software installed, system type, the content and pages that you access on the
            Services, the number of clicks, the amount of time spent on pages, the dates and times
            that you visit the Services, and other similar information. In addition, Loola may
            compile statistical information related to the performance of the Services and/or the
            use of the Services, and perform Analysis as further described below.
          </p>
          <p className="privacyText">
            In addition, Loola collects and stores (in Loola's servers, third party's external
            servers or cloud services on which the Services operates) Your User Content as
            broadcasted by you through the Services, along with its social activity (i.e chat
            comments, likes, gifs, shares and other statistics).
          </p>
          <p className="textUnderline">Information from Other Sources</p>
          <p className="privacyText">
            Loola may obtain information, including personal identifiable information, from third
            parties and sources other than the Loola, such as advertisers. Loola also may obtain
            information, including personal identifiable information, from third parties such as the
            Third Party Platforms for which you have explicitly connected to the Services (for
            example, information from YouTube using YouTube API Services, Twitch, Twitter, TikTok,
            or any other platform the Services connects with). When you access the Services, you are
            authorizing Loola to collect, store and use such information and content in accordance
            with this Privacy Policy. Loola may also obtain information from third parties such as
            Third Party Platforms regarding your use of such services, including information about
            your use of the content you choose to broadcast through the Services. Please refer to
            privacy policy of the Third Party Platforms you use with the Services (for example, to
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.google.com/policies/privacy"
            >
              {" "}
              Google Privacy Policy for YouTube
            </a>
            ). If we combine or associate information from other sources with personal identifiable
            information that we collect through the Services, we will treat the combined information
            as personal identifiable information in accordance with this Privacy Policy.
          </p>

          <p className="fontStrong textUnderline">How Loola Uses the Collected Information</p>
          <p className="privacyText">
            Loola uses the information collected by it as detailed above, including personal
            identifiable information and Your User Content ("User Information"), to operate and
            maintain the Services, to enable you to use the Services and its entire functionalities
            and features (including without limitation its personalized features) and to process any
            flagging activity or correspondence you send to us. The foregoing includes, without
            derogating from its generality, the accessing on your behalf to on your behalf to the
            Third Party Platforms, performing actions on your behalf to or on your accounts on such
            Third Party Platforms and enabling you with access to your history activity in the
            Services.
          </p>
          <p className="privacyText">
            In addition, Loola uses User Information for the purpose of improving the Services,
            enhancing users' experience and creating new features. Loola uses User Information in
            order to track and analyze users' preferences, trends, activities, communications and
            use of the Services, to monitor aggregate metrics such as total number of visitors and
            users of the Services and to track your entries, submissions or other activities in
            connection with the Services (collectively,"Analysis"). Loola may use and/or make
            publicly available the results and any work product of such Analysis (including reports
            and charts).
          </p>
          <p className="privacyText">
            Loola may share User Information with third parties service providers, for the sole
            purposes of providing and improving the Services, performing maintenance, security
            purposes, backup and recovery of such information and/or the Services, performing
            Analysis, and storing such information on third party's external servers or cloud
            services on which the Services operates.
          </p>
          <p className="privacyText">
            In addition, Loola may disclose User Information if Loola believes in good faith that
            such disclosure is necessary to comply with applicable laws, including under a response
            to a court order, judicial or other requirement by an authorized body. Loola may also
            disclose User Information in order to protect itself, investigate and defend itself
            against third-party allegations or in order to maintain the security or integrity of the
            Services.
          </p>
          <p className="privacyText">
            Loola does not use your email address or other personally identifiable information to
            send commercial or marketing messages without your consent. However, Loola may use your
            email address for non- marketing or administrative purposes (such as notifying you of
            major Service changes or for customer service purposes).
          </p>
          <p className="privacyText">
            Except as set forth herein, Loola does not use or share with third parties any User
            Information. Specifically, Loola will not share your stored videos with any 3rd party,
            without your prior permission, except for its storage provider or if required by law /
            court order.
          </p>
          <br />
          <p className="privacyText">
            By providing your Personal Identifiable Information you consent to transferring your
            Personal Identifiable Information to third-party service providers outside of the
            European Economic Area (“EEA”). In any event, Loola will only transfer Personal
            Identifiable Information to third parties which provide appropriate safeguards and
            provided that your rights and effective legal remedies for your Personal Identifiable
            Information are available.
          </p>
          <p className="fontStrong textUnderline">User Information Security</p>
          <p className="privacyText">
            Loola takes reasonably appropriate security measures to protect the security of User
            Information, in accordance with any applicable law. However, Loola cannot guarantee that
            data loss or breach and/or that any unauthorized access and/or hacking will not occur
            with respect to the User Information.
          </p>
          <p className="privacyText">
            In the event that any Personal Identifiable Information which was provided to Loola is
            compromised as a result of a breach of security, Loola will take reasonable steps, in
            accordance with any applicable law, to investigate the situation and where appropriate,
            notify those individuals whose Personal Identifiable Information may have been
            compromised and take other steps, in accordance with any applicable laws and
            regulations.
          </p>
          <p className="privacyText">
            You will not disclose your user login and password to your account under the Services
            and will be fully responsible to maintain the secrecy of such information. You agree to
            immediately inform Loola upon the event of any unauthorized use of such account.
          </p>
          <p className="fontStrong textUnderline">Your Choices</p>
          <p className="privacyText">
            You are not obligated to provide Personal Identifiable Information, however, you
            acknowledge that if you do not provide such information, Loola may not be able to
            provide you with any or all of the Services.
          </p>
          <p className="privacyText">
            Subject to the provisions of any applicable law (including any exclusions), you are
            entitled for the following rights (i) the right to know what Personal Identifiable
            Information Loola holds about you, where Loola processes it and the right to access such
            information; (ii) the right to have any inaccurate Personal Identifiable Information
            Loola holds about you rectified and the right to have any incomplete details completed;
            (iii) the right to have your Personal Identifiable Information erased without delay
            unless Loola has to comply with a legal obligation; (iv) the right to restrict or object
            to the processing of your Personal Identifiable Information If you contest its accuracy,
            claim that it was processed unlawfully or in the event Loola no longer requires the
            Personal Identifiable Information. Where processing has been restricted on this basis,
            Loola may continue to store your Personal Identifiable Information; (v) the right to
            withdraw your consent to the processing of your Personal Identifiable Information. Such
            right will not affect the lawfulness of processing based on consent before its
            withdrawal; (vi) the right to receive your Personal Identifiable Information from Loola
            in an accessible and readable format; (vii) the right to object to the processing of
            your Personal Identifiable Information for direct marketing purposes; (viii) the right
            to file a complaint with an information protection supervisory authority.
          </p>
          <p className="privacyText">
            If you wish to exercise any of your foregoing rights or you have any questions regarding
            this Privacy Policy, please contact Loola at{" "}
            <a target="_self" href="mailto:support@loola.tv">
              support@loola.tv
            </a>
            . Loola will address your application in good faith and in accordance with the
            applicable law.
          </p>
          <p className="fontStrong textUnderline">Amendments</p>
          <p className="privacyText">
            Loola is entitled to amend this Privacy Policy at any time. Upon such amendments Loola
            will inform you by publishing a notice through the Services or otherwise communicating
            with you. Such amendments shall apply as to your use of the Services after you have
            accepted such amendments, and in any event upon the lapse of 7 (seven) days as of such
            notice (event if have not accepted such amendments). If you do not wish to be bound by
            such amendments, then your only recourse is to discontinue the use of the Services.
          </p>
        </div>
        <LandingPageFooter />
      </div>
    );
  }
}

export default PrivacyPage;
