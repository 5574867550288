import React, { Component } from "react";

class MirrorFlipCamIcon extends Component {
  render() {
    return (
      <svg
        onClick={this.props.onClick}
        transform={this.props.rotated ? "rotate(180)" : ""}
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
        version="1.1"
      >
        <title>Camera_Flip</title>
        <desc>Created with Sketch.</desc>
        <g id="Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-227.000000, -49.000000)">
            <g id="Camera_Flip" transform="translate(227.000000, 49.000000)">
              <g
                id="flip"
                transform="translate(12.500000, 14.062500)"
                fill="#FFFFFF"
                fillRule="nonzero"
              >
                <polygon
                  id="Shape"
                  points="0 5.88779297 0 18.6637695 9.79350586 16.7682617 9.79350586 3.99228516"
                />
                <polygon
                  id="Shape"
                  points="15.206543 3.99228516 15.206543 16.7682617 25 18.6637695 25 5.88779297"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="0.0171875"
                  width="1.46484375"
                  height="2.19335938"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="4.10292969"
                  width="1.46484375"
                  height="2.19335938"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="8.18837891"
                  width="1.46484375"
                  height="2.19335938"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="12.2743164"
                  width="1.46484375"
                  height="2.19335938"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="16.3597656"
                  width="1.46484375"
                  height="2.19335938"
                />
                <rect
                  id="Rectangle-path"
                  x="11.7675781"
                  y="20.4457031"
                  width="1.46484375"
                  height="2.19335938"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default MirrorFlipCamIcon;
