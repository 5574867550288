const Constants = {
  DEFAULT_SERVER_APPLICATIVE_ERROR_MESSAGE: "Internal Server Error",
  DEFAULT_500_SERVER_ERROR_MESSAGE: "Network or Server error",

  TABS: {
    web: "web",
    rtmp: "rtmp",
  },

  API_STATUS_CODES: {
    OK: "200",
    UNAUTHORIZED: "401",
    SERVER_ERROR: "500",
  },

  ACTION_TYPES: {
    UNAUTHORIZED: "UNAUTHORIZED",
    SESSION_STORE_GEOLOCATION: "SESSION_STORE_GEOLOCATION",
    PLATFORM_GET_PLATFORMS: "PLATFORM_GET_PLATFORMS",
    PLATFORM_IS_FETCHING_PLATFORM_TOGGLE: "PLATFORM_IS_FETCHING_PLATFORM_TOGGLE",
    PLATFORM_TOGGLE: "PLATFORM_TOGGLE",
    PLATFORM_CONFIGURE: "PLATFORM_CONFIGURE",
    PLATFORM_DISCONNECT: "PLATFORM_DISCONNECT",
    PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS: "PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS",
    PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS: "PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS",
    RESET_PLATFORM_ERROR: "RESET_PLATFORM_ERROR",
    LIVE_GET_ROOM_CONFIGURATION: "LIVE_GET_ROOM_CONFIGURATION",
    LIVE_GO_LIVE: "LIVE_GO_LIVE",
    LIVE_END_LIVE: "LIVE_END_LIVE",
    LIVE_START_BROADCAST: "LIVE_START_BROADCAST",
    LIVE_HEARTBEAT_BROADCAST: "LIVE_HEARTBEAT_BROADCAST",
    LIVE_SAVE_BROADCAST_CONFIG: "LIVE_SAVE_BROADCAST_CONFIG",
    LIVE_CHANGE_SCREEN_SHARE_STATUS: "LIVE_CHANGE_SCREEN_SHARE_STATUS",
    RTMP_CAMERA_TOGGLE: "RTMP_CAMERA_TOGGLE",
    RTMP_LOAD: "RTMP_LOAD",
    RTMP_HEARTBEAT: "RTMP_HEARTBEAT",
    LIVE_TAB_SWITCH: "LIVE_TAB_SWITCH",
    LIVE_END_BROADCAST: "LIVE_END_BROADCAST",
    LIVE_GET_STATS: "LIVE_GET_STATS",
    LIVE_GET_COMMENTS: "LIVE_GET_COMMENTS",
    LIVE_WRITE_COMMENT: "LIVE_WRITE_COMMENT",
    LIVE_GET_ACTIVE_FANS: "LIVE_GET_ACTIVE_FANS",
    POLLS_GET_QUESTIONS: "POLLS_GET_QUESTIONS",
    POLLS_SHOW_QUESTION: "POLLS_SHOW_QUESTION",
    POLLS_HIDE_QUESTION: "POLLS_HIDE_QUESTION",
    POST_LIVE_GET_SUMMARY: "POST_LIVE_GET_SUMMARY",
    FACEBOOK_SET_LOGIN_STATE: "FACEBOOK_SET_LOGIN_STATE",
    FACEBOOK_SET_ACCOUNT: "FACEBOOK_SET_ACCOUNT",
    FACEBOOK_SET_INSTAGRAM_TOKEN: "FACEBOOK_SET_INSTAGRAM_TOKEN",
    FACEBOOK_SET_INSTAGRAM_COOKIE: "FACEBOOK_SET_INSTAGRAM_COOKIE",
    FACEBOOK_CLEAR_INSTAGRAM_TOKEN: "FACEBOOK_CLEAR_INSTAGRAM_TOKEN",
    FACEBOOK_STORE_INSTAGRAM_TOKEN: "FACEBOOK_STORE_INSTAGRAM_TOKEN",
    BROADCASTS_HISTORY_GET_ALL: "BROADCASTS_HISTORY_GET_ALL",
    BROADCASTS_HISTORY_GET_BY_ROOM: "BROADCASTS_HISTORY_GET_BY_ROOM",
    BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM: "BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM",
    BROADCASTS_HISTORY_CURRENT_BROADCAST: "BROADCASTS_HISTORY_CURRENT_BROADCAST",
    BROADCASTS_HISTORY_SHARE_ON_IGTV: "BROADCASTS_HISTORY_SHARE_ON_IGTV",
    BROADCASTS_HISTORY_DELETE_ROOM: "BROADCASTS_HISTORY_DELETE_ROOM",
    SYSTEM_MESSAGE_SHOW: "SYSTEM_MESSAGE_SHOW",
    SYSTEM_MESSAGE_HIDE: "SYSTEM_MESSAGE_HIDE",
    STARTING_ACTION: "STARTING_ACTION",
    IDLE_ACTION: "IDLE_ACTION",
    USER_SIGN_IN_OR_REGISTER: "USER_SIGN_IN_OR_REGISTER",
    USER_SIGN_OUT: "USER_SIGN_OUT",
    USER_SETTINGS: "USER_SETTINGS",
    USER_SETTINGS_SAVE_VIDEO_LAYOUT: "USER_SETTINGS_SAVE_VIDEO_LAYOUT",
    USER_SETTINGS_GET_LIVE_LIMIT: "USER_SETTINGS_GET_LIVE_LIMIT",
    ROOM_LOCAL_FEED_ERROR: "ROOM_LOCAL_FEED_ERROR",
  },

  SPINNER_TYPES: {
    DEFAULT: "default",
    ACCORDION: "accordion",
  },

  SPINNER_SIZES: {
    XS: "xs",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
  },

  ICON_SIZES: {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
  },

  BOX_TYPES: {
    CHAT: "chat",
    ACTIVE_FANS: "active_fans",
    STREAM_TO: "stream_to",
    ACTION_BAR: "action_bar",
    MY_BROADCASTS: "my_broadcasts",
    OTHER: "other",
  },
};

export { Constants };
