import React, { Component } from "react";
import { connect } from "react-redux";
import LiveVideo from "./LiveVideo";
import PropTypes from "prop-types";
import { sendAction } from "../core/send-action";
import { bindActionCreators } from "redux";
import { Constants } from "../core/constants";
import { showSystemMessage, hideSystemMessage } from "../core/system-message-actions";
import Scheduler from "../core/Scheduler";
import { closeBroadcastSummary } from "../Analytics/broadcast-summary-actions";
import config from "../Config";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import BroadcastingState from "../models/BroadcastingState";
import { RoomConfigurationModel } from "../models/RoomConfigurationModel";

class LiveVideoContainer extends Component {
  constructor(props) {
    super(props);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  // Warning before leaving the page (back button, or outgoinglink) on cases where we are live-streaming
  handleBeforeUnload(event) {
    if (this.props.goLive) {
      console.log("show alert message before unloading the video page");
      event.returnValue =
        "Are you sure you want to leave this page? Changes you made may not be saved.";
      return "Are you sure you want to leave this page? Changes you made may not be saved.";
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  isValidRoomConfiguration(roomConfiguration) {
    if (roomConfiguration === null) return false;

    if (Object.keys(roomConfiguration).length === 0 && roomConfiguration.constructor === Object)
      return false;

    // so we have roomConfiguration
    return true;
  }
  UNSAFE_componentWillMount() {
    // Loading Room Configuration

    const { roomConfiguration } = this.props;
    if (!RoomConfigurationModel.isValidRoomConfiguration(roomConfiguration)) {
      this.props.sendAction(Constants.ACTION_TYPES.LIVE_GET_ROOM_CONFIGURATION);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.roomConfiguration !== this.props.roomConfiguration) {
      console.log("LIVE VIDEO CONTAINER - BALAGAN");
    }
    if (nextProps.hasError !== this.props.hasError) {
      if (nextProps.hasError) {
        window.analytics.track(LoolaAnalyticsConstants.EVENTS.BROADCAST_FAILED, {
          when: this.props.liveStatus,
          reason: nextProps.error ? nextProps.error.code : nextProps.errorMessage,
          isRTMP: this.props.broadcastConfig.activeTab === Constants.TABS.rtmp,
        });

        if (nextProps.error && nextProps.error.code >= 2110 && nextProps.error.code <= 2116) {
          this.props.showSystemMessage(
            "error",
            nextProps.errorMessage,
            `${config.helpurl}/new-users`
          );
        } else if (nextProps.error && nextProps.error.code === 2005) {
          this.props.showSystemMessage(
            "error",
            `Failed to 'Go Live'. Please verify your accounts and make sure they all enabled for live streaming`,
            `${config.helpurl}/new-users`
          );
        } else {
          this.props.showSystemMessage("error", nextProps.error.message);
        }
      } else {
        this.props.hideSystemMessage();
      }
    }
  }

  render() {
    const {
      roomConfiguration,
      configuredPlatformsModel,
      goLive,
      liveStatus,
      totalViewers,
      totalLikes,
      isBroadcastSummaryOpened,
      rtmpConfiguration,
      broadcastConfig,
    } = this.props;
    console.log("room configuration", roomConfiguration);

    if (!this.isValidRoomConfiguration(roomConfiguration)) {
      return null;
    }

    return (
      <Scheduler
        interval={15000}
        isRunning={this.props.goLive}
        action={() => this.props.sendAction(Constants.ACTION_TYPES.LIVE_HEARTBEAT_BROADCAST)}
      >
        <LiveVideo
          isLive={goLive}
          liveStatus={liveStatus}
          roomConfiguration={roomConfiguration}
          rtmpConfiguration={rtmpConfiguration}
          sampleRTMP={() => this.props.sendAction(Constants.ACTION_TYPES.RTMP_HEARTBEAT)}
          onTabSwitch={tab => this.props.sendAction(Constants.ACTION_TYPES.LIVE_TAB_SWITCH, tab)}
          configuredPlatformsModel={configuredPlatformsModel}
          totalViewers={totalViewers}
          totalLikes={totalLikes}
          isBroadcastSummaryOpened={isBroadcastSummaryOpened}
          closeBroadcastSummary={this.props.closeBroadcastSummary}
          broadcastConfig={broadcastConfig}
        />
      </Scheduler>
    );
  }
}

LiveVideoContainer.propTypes = {
  roomConfiguration: PropTypes.object,
  goLive: PropTypes.bool,
  liveStatus: PropTypes.oneOf(Object.values(BroadcastingState)).isRequired,
  hasError: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    goLive,
    liveStatus,
    hasError,
    error,
    errorMessage,
    roomConfiguration,
    rtmpConfiguration,
    broadcastConfig,
  } = state.liveVideoData;
  const { totalViewers, totalLikes } = state.liveStatsData;
  const { configuredPlatformsModel } = state.platformsData;
  const { isOpened: isBroadcastSummaryOpened } = state.postLiveBroadcastSummaryData;

  return {
    goLive,
    liveStatus,
    hasError,
    error,
    errorMessage,
    roomConfiguration,
    rtmpConfiguration,
    configuredPlatformsModel,
    totalViewers,
    totalLikes,
    isBroadcastSummaryOpened,
    broadcastConfig,
  };
}

function matchDispatchToProps(dispatch) {
  console.log("on configuration data -- in DISPATCH to props");
  return bindActionCreators(
    {
      showSystemMessage,
      hideSystemMessage,
      sendAction,
      closeBroadcastSummary,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(LiveVideoContainer);
