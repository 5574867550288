import React from "react";
import { connect } from "react-redux";

import Notification from "./NotificationModalContainer";
import Confirmation from "./ConfirmationModalContainer";
import Configuration from "../Platforms/ConfigurationModalContainer";
import PlatformSettings from "../Platforms/PlatformSettings/PlatformSettingsModalContainer";

import {
  MODAL_TYPE_NOTIFICATION,
  MODAL_TYPE_CONFIRMATION,
  MODAL_TYPE_CONFIGURATION,
  MODAL_TYPE_PLATFORM_SETTINGS,
} from "./ModalTypes";

const MODAL_COMPONENTS = {
  [MODAL_TYPE_NOTIFICATION]: Notification,
  [MODAL_TYPE_CONFIRMATION]: Confirmation,
  [MODAL_TYPE_CONFIGURATION]: Configuration,
  [MODAL_TYPE_PLATFORM_SETTINGS]: PlatformSettings,
};

const ModalRoot = ({ type, props }) => {
  if (!type) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type];
  return <ModalComponent {...props} />;
};

function mapStateToProps(state) {
  console.log("Modal Root", state.modalData);
  return state.modalData;
}

export default connect(mapStateToProps)(ModalRoot);
