import "./TermsPage.css";

import React, { Component } from "react";

import LoolaPublicPageHeader from "../LandingPage/components/LoolaPublicPageHeader";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class TermsPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.TERMS_PAGE);
  }

  render() {
    return (
      <div className="TermsPage">
        <LoolaPublicPageHeader textColor="black" logoColor="black" />
        <div className="termsText">
          <p className="termsText--header">Terms of Use</p>

          <p className="fontStrong textUnderline">General</p>
          <p className="termsText">
            Welcome to the Loola. content streaming platform operated by Loola Communication Ltd ("
            <span className="fontStrong">Loola</span>"), consisting of the web site available at the
            URL{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://loola.tv">
              https://loola.tv
            </a>
            , and all related services, software applications and products that allow for the
            authorized streaming and distribution of content over the internet (the "
            <span className="fontStrong">Services</span>").
          </p>
          <p className="termsText">
            Please read these terms of use ("<span className="fontStrong">Terms</span>") carefully.
            By agreeing to these Terms or by registering for, accessing, browsing, downloading from
            or using the Services, you acknowledge that you have read, understood, and agree to be
            bound by these Terms. If you do not agree to these Terms, please do not use the Services
            in any way.
          </p>
          <p className="termsText">
            If you are entering these Terms on behalf of a corporation or any other legal entity,
            you hereby represent that you have the authority to bind such entity with these Terms.
            If you are entering into these Terms as an individual, you hereby confirm that you are
            more than 18 years of age. If you are under such age, you confirm that you possess legal
            parental or guardian consent to obligate to these Terms. As used herein, "you" and
            "your" refer to you or the entity on behalf of which you are acting, as applicable.
          </p>
          <p className="fontStrong textUnderline">Right to Use the Services; Prohibited Use</p>
          <p className="termsText">
            Subject to these Terms, Loola is granting you the right to access and use the Services
            for your personal use.
          </p>
          <p className="termsText">
            You shall not: (a) access or use all or any part of the Services in order to build or
            create a product or service which is similar to, or which competes with, the Services;
            (b) use the Services in any manner that damages, disables, overburdens, or impairs the
            Services, or Loola's systems or servers, or the cloud or other platform on which the
            Services operates; (c) use the Services in any manner that is unlawful or prohibited or
            in violation of these Terms and/or any applicable law or regulation.
          </p>
          <p className="fontStrong textUnderline">Third Party Platforms</p>
          <p className="termsText">
            As the Services allows you for the broadcasting, distributing, posting and/or publishing
            Your User Content (as defined below) to certain third-party platforms you explicitly
            connect (the "<span className="fontStrong">Third Party Platform/s</span>"), and
            connecting your media / social network accounts in such Third Party Platform/s to the
            Service, you hereby acknowledge that by doing so you are bound be by the agreements,
            terms and policies of such Third Party Platforms, and you agree and undertake to comply
            in full at all times with such agreements, terms and policies. Loola shall not be
            responsible or liable for any breach by you of the agreements, terms and policies of any
            Third Party Platforms.
          </p>
          <p className="termsText">
            In order for you to use the Services, you acknowledge and agree for the Services to
            access on your behalf to your accounts on such Third Party Platforms, and to perform
            actions on your behalf (such as, but not limited to, "connecting", "subscribing",
            "posting" or "sharing"), to or on your accounts on such Third Party Platforms.
          </p>
          <p className="termsText">
            In addition, you agree to the YouTube{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/t/terms">
              terms of use.
            </a>
          </p>
          <p className="fontStrong textUnderline">Registration to the Services</p>
          <p className="termsText">
            You are required to sign up to the Services in order to use the Services.
          </p>
          <p className="termsText">
            The sign-up process may require you to provide certain information regarding, but not
            limited to, your name, email address, your telephone number, credit card number and
            billing information, and other information. Loola reserves the right to terminate the
            account or refuse any or all use of the Services by you if registration information
            submitted seems or proves to be false, inaccurate or incomplete. The sign-up process and
            use of the Services also require you to provide us with the credentials to your accounts
            in the Third Party Platforms in or to which you would like to broadcast, distribute,
            post and/or publish Your User Content (as defined below) through the Services.
          </p>
          <p className="termsText">
            Loola will keep the foregoing information confidential in accordance with its Privacy
            Policy.
          </p>
          <p className="termsText">
            Registration of more than one account per individual or organization is not permitted
            without prior written approval of Loola, and automatic generation of multiplied accounts
            by individuals or organizations is restricted.
          </p>
          <p className="fontStrong textUnderline">Privacy Policy</p>
          <p className="termsText">
            Please review Loola's Privacy Policy at:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://loola.tv/privacy">
              https://loola.tv/privacy
            </a>{" "}
            ("<span className="fontStrong">Privacy Policy</span>") for information relating to how
            we collect, use, and disclose your personal information. The Privacy Policy constitutes
            an integral part of these Terms, and by accepting these Terms you agree to the Privacy
            Policy.
          </p>
          <p className="fontStrong textUnderline">User Content</p>
          <p className="termsText">
            You confirm that you shall own all rights, title and interest (or have the full
            applicable rights of use) in and to all the content you stream through the Services
            (including on or to any Third Party Platforms) ("
            <span className="fontStrong">Your User Content</span>"), and shall have the exclusive
            responsibility and liability for Your User Content, including without limitation, for
            the legality, reliability, authenticity, integrity, accuracy, content, completeness,
            availability and quality of Your User Content.
          </p>
          <p className="termsText">
            You will not stream content that: (i) is unlawful, harmful, threatening, violent,
            defamatory, offensive, obscene, infringing, harassing or racially or ethnically
            offensive; and/or (ii) facilitates or relates to illegal activity; and/or (iii) depicts
            sexually content such as pornography materials; and/or (iv) promotes or relates to
            unlawful violence; and/or (v) is discriminatory based on race, gender, colour, religious
            belief, sexual orientation, disability, or any other illegal activity; (vi) causes or
            may cause damage or injury to any person or property; (vii) contains commercial ads or
            marketing materials; and/or (viii) is not Your User Content or content that you don’t
            own or have the rights with respect thereto.
          </p>
          <p className="termsText">
            You shall remain exclusively responsible for all acts or omissions in connection with
            Your User Content. Loola does not control or monitor, and is not responsible in any way
            for any of Your User Content, including without limitation with respect to the legality,
            reliability, authenticity, integrity, accuracy, content, completeness, availability and
            quality thereof, and reserves the right, to its discretion, to remove any content from
            Your User Content, which deems to be in violation with these Terms, and take any action
            it deems appropriate, including disabling, suspending or terminating any or all use of
            the Services
          </p>
          <p className="termsText">
            Without derogating from anything herein, you confirm and obligate that you have and will
            legally acquire/d and/or own all right, title and interest, in and to all of Your User
            Content and that you have and will have the right to use, distribute, provide Loola, and
            transmit through the Services, Your User Content. You hereby give your consent to Loola,
            and grant it with a worldwide, royalty-free, and non- exclusive license, to scan and
            collect Your User Content, and store Your User Content in Loola's servers, third party's
            external servers or cloud services on which the Services operates, to use Your User
            Content in order to provide and allow you to use the Services and in any other manner
            detailed herein.
          </p>
          <p className="fontStrong textUnderline">Charges and Payments</p>
          <p className="termsText">
            The Services (or certain features thereof) are subject to your payment of applicable
            fees. Unless otherwise is explicitly agreed between you and Loola, the fees for the
            Services shall be as stated in Loola's then current price list appears in its website.
            Loola reserve its right to charge in the future, for certain features of the Services
            which are currently available for free, upon prior written notice to its users.
          </p>
          <p className="fontStrong textUnderline">Third Party Providers</p>
          <p className="termsText">
            You acknowledge that Loola uses third party cloud infrastructure services in order to
            provide the Services, and you agree that Loola is not and shall not be responsible for
            the availability, performance or security of any such external third party services or
            resources, and it shall not be held liable for any loss or damage (including loss of
            data and/or loss of profits), which may be incurred by you, as a result of the lack of
            availability of, the interruptions or errors in the performance of, and any other
            problem in those external third party services or resources, or as a result of the lack
            of availability of, the interruptions or errors in the performance of, and any other
            problem in the Services provided through such external third party services or
            resources.
          </p>
          <p className="fontStrong textUnderline">Proprietary Rights</p>
          <p className="termsText">
            Loola owns and retains at all times all title, rights and/or interest, including any
            intellectual property rights of any kind, in and to the Services (and any part thereof),
            including any enhancements, modifications, upgrades, updates, analyses, derivatives, and
            work products thereof or therefrom.
          </p>
          <p className="fontStrong textUnderline">No Warranties; Disclaimers</p>
          <p className="termsText">
            YOU HEREBY CONSENT AND AKWNOLEDGE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE
            RISK. LOOLA DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
            SERVICES AND YOUR USE THEREOF, TO THE FULLEST EXTENT PERMITTED BY LAW. THE SERVICES ARE
            PROVIDED AS-IS AND LOOLA PROVIDES NO REPRESENTATIONS AND/OR WARRANTIES IN CONNECTION
            WITH THE SERVICES, INCLUDING AS TO ACCURACY OR COMPLETENESS, MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS. Without derogating
            from the above Loola disclaims and shall have no liability for and assumes no liability
            or responsibility whatsoever for (1) any and all damages or loss of any kind; (2) any
            bugs, viruses, trojan horses, malware or the like which may be transmitted to or through
            the services or through the actions of any third party; (3) any mistakes, or
            inaccuracies of content; (4) any interruption of the services; (5) any unauthorized
            access to or use of servers used by Loola in connection with the services including
            third party servers (6) any unauthorized access to any and all Personal Identifiable
            Information; (7) any and all of your user content; (8) any products or service
            advertised or offered by a third party including through the services or any hyperlinked
            website or featured in any banner or other advertising, and any transaction between you
            and third-party providers of products or services, all including in connection with
            Third Party Platforms.
          </p>
          <p className="fontStrong textUnderline">Limitations of Liability</p>
          <p className="termsText">
            WITHOUT DEROGATING FROM THE FOREGOING, IN NO EVENT WILL LOOLA BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR
            RELATING TO THE SERVICES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS
            AND/OR BUSINESS, CUSTOMERS AND/OR DATA OR ANY OTHER PROPERTY, EVEN IF IT WAS ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p className="termsText">
            TO THE FULL EXTENT PERMITTED BY LAW, AND NOTWITHSTANDING ANYTHING HEREIN TO THE
            CONTRARY, IN NO EVENT SHALL LOOLA’S LIABILITY UNDER THESE TERMS AND WITH RESPECT TO THE
            SERVICES EXCEED AMOUNTS ACTUALLY PAID BY YOU TO LOOLA HEREUNDER DURING A PERIOD OF 12
            MONTHS PRIOR TO ANY LIABILITY EVENT (IF ANY). AS SUCH, IF YOU HAVE NOT MADE ANY PAYMENTS
            HEREUNDER, LOOLA SHALL NOT HAVE ANY LIABILITY UNDER THESE TERMS AND WITH RESPECT TO THE
            SERVICES. YOUR SOLE REMEDY UNDER THESE TERMS WILL BE TO TERMINATE YOUR USE OF THE
            SERVICES.
          </p>
          <p className="termsText">
            THE FOREGOING LIMITATION OF LIABILITY WILL APPLY WHETHER SUCH LIABILITY ARISES IN
            CONTRACT, TORT (INCLUDING NEGLIGENCE), UNDER STATUTE OR OTHERWISE EVEN IF LOOLA WAS
            ADVISED OF SAME.
          </p>
          <p className="fontStrong textUnderline">Termination or Suspension of Services</p>
          <p className="termsText">
            Loola is entitled, at its sole discretion, to change, terminate or cease the operation
            of the Services (or any part thereof). In addition, Loola is entitled in its sole
            discretion, without prior notice, to terminate or suspend your right to use the Services
            and/or to prevent your access to and use of the Services in the event of any violation
            and/or breach and/or infringement by you of these Terms.
          </p>
          <p className="fontStrong textUnderline">Modification of these Terms</p>
          <p className="termsText">
            Loola is entitled to amend these Terms at any time. Upon such amendments Loola will
            inform you by publishing a notice through the Services or otherwise communicating with
            you. Such amendments shall apply as to your use of the Services after you have accepted
            such amendments, and in any event upon the lapse of 7 (seven) days as of such notice
            (event if you have not accepted such amendments). If you do not wish to be bound by such
            amendments, please discontinue the use of the Services.
          </p>
          <p className="fontStrong textUnderline">Entire Agreement</p>
          <p className="termsText">
            These Terms, together with the Privacy Policy and any other legal notices published by
            Loola, constitute the entire agreement between you and Loola in connection with the
            Services.
          </p>
          <p className="fontStrong textUnderline">Assignment</p>
          <p className="termsText">
            You shall not, without the prior written consent of Loola, assign, transfer, or
            sub-contract these Terms and/or any of its rights or obligations hereunder, and any
            unauthorized assignment shall be null and void. Loola may, at any time, assign, transfer
            or sub-contract any of its rights or obligations under these Terms.
          </p>
          <p className="fontStrong textUnderline">Governing Law</p>
          <p className="termsText">
            These Terms shall be governed by the laws of the State of Israel. It is agreed that any
            disputes pursuant to these Terms shall be exclusively referred to courts of Tel Aviv,
            Israel.
          </p>
        </div>
        <LandingPageFooter />
      </div>
    );
  }
}

export default TermsPage;
