import config from "../../../Config";

// environment, token, expiry
const setFacebookAuthToken = (accessToken, expiresIn) => {
  const platformType = "facebook";
  console.log("inside (facebook) set auth token", platformType, accessToken, expiresIn);
  return fetch(
    `${config.apiurl}/generic-oauth/set-fb-auth-code?platformType=${platformType}&accessToken=${accessToken}&expiresIn=${expiresIn}`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }
  )
    .then(data => data.json())
    .then(response => console.log("post set-fb-auth-code", response));
};

const setAuthToken = (platformType, code, countryCode, authVerifier) => {
  console.log("inside set auth token", platformType, code, countryCode, authVerifier);
  let url = `${config.apiurl}/generic-oauth/set-auth-code?code=${code}&platformType=${platformType}`;
  if (countryCode) url = `${url}&countryCode=${countryCode}`;
  if (authVerifier) url = `${url}&authVerifier=${authVerifier}`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(data => data.json())
    .then(response => {
      console.log("post set-auth-code", response);
      return response;
    });
};

const configure = (platformType, code) => {
  const requestParams = {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  };

  requestParams.body = JSON.stringify({
    platformType,
    oauthCode: code,
  });

  const url = `authenticate-platform/configure`;
  return fetch(`${config.apiurl}/${url}`, requestParams)
    .then(data => data.json())
    .then(response => console.log("post configure", response));
};

export { setAuthToken, setFacebookAuthToken, configure };
