import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import DownloadIcon from "../assets/icons/actions/DownloadIcon";
import "./DownloadButton.css";

class DownloadButton extends React.Component {
  render() {
    return (
      <Button
        onClick={this.handleClick.bind(this)}
        additionalClassName="Button--download"
        data-tip={this.props.toolTipText}
        disabled={this.props.disabled}
      >
        {this.props.actionText}
        <DownloadIcon additionalClassName="Button--download__Icon" size="small" />
      </Button>
    );
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}

DownloadButton.propTypes = {
  onClick: PropTypes.func,
  actionText: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DownloadButton;
