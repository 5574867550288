import React, { Component } from "react";

class BroadcastSummaryHeader extends Component {
  renderTime(time) {
    if (!time) {
      return "";
    }

    const timeUnits = ["sec", "min", "h"].reverse();
    const timeValues = time.split(":");

    return timeValues.map((item, i) => `${item}${timeUnits[i]}`).join(" ");
  }

  render() {
    const { duration } = this.props;

    return (
      <div className="BroadcastSummaryHeader">
        <div className="BroadcastSummaryHeader__title">Broadcast Summary</div>
        <div className="BroadcastSummaryHeader__duration">{this.renderTime(duration)}</div>
      </div>
    );
  }
}

export default BroadcastSummaryHeader;
