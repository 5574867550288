import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./BoxBlock.css";
import { LiveChatIcon } from "../assets/icons/actions/LiveChatIcon";
import { Constants } from "./constants";
import { StreamToIcon } from "../assets/icons/actions/StreamToIcon";
import { MostActiveFansIcon } from "../assets/icons/actions/MostActiveFansIcon";
import { LiveChatEmptyStateIcon } from "../assets/icons/actions/LiveChatEmptyStateIcon";
import { MostActiveFansEmptyStateIcon } from "../assets/icons/actions/MostActiveFansEmptyStateIcon";

class BoxBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: true,
    };
  }

  changeVisibility = () => {
    this.setState(prevState => {
      return { isOpened: !prevState.isOpened };
    });
  };

  showVisibilityButton() {
    if (this.props.canCollapse) {
      return (
        <span className="BoxBlock__visibilityButton" onClick={this.changeVisibility}>
          {this.state.isOpened ? (
            <i className="BoxBlock__visibilityButton--up" />
          ) : (
            <i className="BoxBlock__visibilityButton--down" />
          )}
        </span>
      );
    }
  }
  chooseColor() {
    switch (this.props.boxType) {
      case Constants.BOX_TYPES.CHAT:
        return "blue";
      case Constants.BOX_TYPES.ACTIVE_FANS:
        return "yellow";
      case Constants.BOX_TYPES.STREAM_TO:
        return "green";
      case Constants.BOX_TYPES.MY_BROADCASTS:
        return "green";
      case Constants.BOX_TYPES.ACTION_BAR:
        return "redpink";
      default:
        return "black";
    }
  }
  renderTitleIcon() {
    switch (this.props.boxType) {
      case Constants.BOX_TYPES.CHAT:
        return <LiveChatIcon size={Constants.ICON_SIZES.LARGE} />;
      case Constants.BOX_TYPES.ACTIVE_FANS:
        return <MostActiveFansIcon />;
      case Constants.BOX_TYPES.STREAM_TO:
      case Constants.BOX_TYPES.MY_BROADCASTS:
        return <StreamToIcon />;
      default:
        return null;
    }
  }

  renderTitle() {
    if (this.props.hideTitle) return null;

    return (
      <div className="BoxBlock__titleSection">
        <div
          className={cx("BoxBlock__title", `BoxBlock__title--${this.chooseColor()}`)}
          style={this.props.titleHider ? { display: "none" } : null}
        >
          <h4>
            {this.props.title} {this.showVisibilityButton()}
          </h4>
        </div>
        <div
          className={cx("BoxBlock__iconWrapper", `BoxBlock__iconWrapper--${this.chooseColor()}`)}
        >
          {this.renderTitleIcon()}
        </div>
      </div>
    );
  }

  renderEmptyStateIcon() {
    switch (this.props.boxType) {
      case Constants.BOX_TYPES.CHAT:
        return <LiveChatEmptyStateIcon />;
      case Constants.BOX_TYPES.ACTIVE_FANS:
        return <MostActiveFansEmptyStateIcon />;
      default:
        return null;
    }
  }

  renderContentBase() {
    if (this.props.isEmpty) {
      return (
        <div className="BoxBlock__contentBase BoxBlock__contentBase--empty">
          {this.renderEmptyStateIcon()}
          <div className="BoxBlock__emptyContentMessage">{this.props.emptyStateMessage}</div>
        </div>
      );
    }

    return <div className="BoxBlock__contentBase">{this.props.children}</div>;
  }

  render() {
    const { addedClassName } = this.props;

    return this.props.borderHide === false ? null : (
      <div
        className={cx(
          "BoxBlock",
          { BoxBlock__withScrolling: this.props.enableScrolling },
          { BoxBlock__withLimitedWidth: this.props.limitWidth },
          { BoxBlock__expandVertical: this.props.expandVertical },
          `BoxBlock__border--${this.chooseColor()}`,
          addedClassName ? `BoxBlock--${addedClassName}` : ""
        )}
      >
        {this.renderTitle()}
        {this.state.isOpened && (
          <div className="BoxBlock__contentBase-wrap">{this.renderContentBase()}</div>
        )}
      </div>
    );
  }
}

BoxBlock.propTypes = {
  canCollapse: PropTypes.bool,
  enableScrolling: PropTypes.bool,
  limitWidth: PropTypes.bool,
  expandVertical: PropTypes.bool,
  hideTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  boxType: PropTypes.oneOf(Object.values(Constants.BOX_TYPES)).isRequired,
  isEmpty: PropTypes.bool,
  emptyStateMessage: PropTypes.string,
  children: PropTypes.node,
};

export default BoxBlock;
