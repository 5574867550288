import { Constants } from "../core/constants";

const RUNNING_STATES = {
  RUNNING: "RUNNING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  IDLE: "IDLE", // This is not running and not currently done
};

const createRunningStateMethods = status => {
  return {
    status,
    isDone: () => status === RUNNING_STATES.ERROR || status === RUNNING_STATES.SUCCESS,
    isError: () => status === RUNNING_STATES.ERROR,
    isSuccess: () => status === RUNNING_STATES.SUCCESS,
    isRunning: () => status === RUNNING_STATES.RUNNING,
  };
};

const handler = {
  get(target, name) {
    // eslint-disable-next-line no-prototype-builtins
    return target.hasOwnProperty(name)
      ? target[name]
      : createRunningStateMethods(RUNNING_STATES.IDLE);
  },
};

export default function(state = {}, action) {
  // Removing previously done actions (So the isDone method will only return true once)
  const targetState = Object.assign({}, state, {});
  // eslint-disable-next-line no-restricted-syntax
  for (const key in state) {
    if (targetState[key].isDone()) {
      delete targetState[key];
    }
  }

  switch (action.type) {
    case Constants.ACTION_TYPES.STARTING_ACTION:
      targetState[action.payload] = createRunningStateMethods(RUNNING_STATES.RUNNING);
      break;
    case Constants.ACTION_TYPES.IDLE_ACTION:
      targetState[action.payload] = createRunningStateMethods(RUNNING_STATES.IDLE);
      break;
    // case Constants.ACTION_TYPES.USER_LOGOUT:
    case Constants.ACTION_TYPES.UNAUTHORIZED:
      // Returns an "empty" state (with the proxy, so all the action types will return the "idle"
      return new Proxy({}, handler);
    default:
      if (action.error) {
        targetState[action.type] = createRunningStateMethods(RUNNING_STATES.ERROR);
      } else {
        targetState[action.type] = createRunningStateMethods(RUNNING_STATES.SUCCESS);
      }
  }

  // The proxy is used when we try to look for an action that doesn't exist in this reducer's state. in this case we return a "idle" version, which is not running and not done
  // Assuming the state is {'type1': val1, 'type2': val2}, state.get('type1') will return val1 but state.get('newType') will return the default value.
  return new Proxy(targetState, handler);
}
