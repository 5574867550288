// export const RootPagePath = "/";
export const StudioConnectPagePath = "/connect";
export const MainVideoRoomPagePath = "/main-video-room";
export const ConfigurePlatformsPagePath = "/configure-platforms";
export const InstagramAuthPagePath = "/auth/instagram";
export const TwitchAuthPagePath = "/auth/twitch";
export const TrovoAuthPagePath = "/auth/trovo";
export const PeriscopeAuthPagePath = "/auth/periscope";
export const TwitterAuthPagePath = "/auth/twitter";
export const YoutubeAuthPagePath = "/auth/youtube";
export const FacebookAuthPagePath = "/auth/facebook";
export const GoogleAuthPagePath = "/auth";
export const TestModalPagePath = "/test-modal";
export const AnalyticsPagePath = "/analytics";
export const AboutPagePath = "/about-us";
export const BroadcastHistoryPagePath = "/broadcast-history";
export const OverlaysPagePath = "/overlays";
export const PricingPagePath = "/pricing";
export const BillingPagePath = "/billing";
export const PublicLandingPagePath = "/";
export const SubscriptionCompletedPagePath = "/thank-you";
export const PrivacyPagePath = "/privacy";
export const TermsPagePath = "/terms";
export const FacebookTestPath = "/facebook-test";
export const SampleVideoPlayerPagePath = "/sample-video-player";
