import { Constants } from "../core/constants";

const initialState = {
  isVisible: false,
  messageText: "",
  messageType: null,
  helpLink: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.SYSTEM_MESSAGE_SHOW:
      console.log("showing error message", action.payload);
      return Object.assign({}, state, {
        isVisible: true,
        messageText: action.payload.messageText,
        messageType: action.payload.messageType,
        helpLink: action.payload.helpLink,
      });

    case Constants.ACTION_TYPES.SYSTEM_MESSAGE_HIDE:
      return initialState;

    case Constants.ACTION_TYPES.ROOM_LOCAL_FEED_ERROR:
    case Constants.ACTION_TYPES.ROOM_ICE_ERROR:
      console.log("WEBRTC error: mapped on System Message Reducer", action);
      return state;

    default:
      return state;
  }
}
