/* eslint-disable no-bitwise */
import "./ChatMessageList.css";

import React from "react";
import ChatMessage from "./ChatMessage";

export default class ChatMessageList extends React.PureComponent {
  scrollToBottom = () => {
    const messagesContainer = document.querySelector(".ChatMessageOverlay");
    if (this.props.overlay && messagesContainer) {
      const shouldScroll =
        messagesContainer.scrollTop + messagesContainer.clientHeight ===
        messagesContainer.scrollHeight;

      console.log("scroll-to-bottom: in overlay");
      if (!shouldScroll) {
        console.log("scroll-to-bottom: should scroll");
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
        console.log("scroll-to-bottom: scrolled");
      }
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  getHash = input => {
    let hash = 0;
    const len = input.length;
    for (let i = 0; i < len; i += 1) {
      hash = (hash << 5) - hash + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return hash;
  };

  renderChatMessages() {
    const messagesArr = this.props.messages;
    const chatMessages = [];
    for (let i = messagesArr.length - 1; i >= 0; i -= 1) {
      const chatEntry = messagesArr[i];
      // const keyName = `${chatEntry.timestamp}_${chatEntry.userName}_${chatEntry.type}_${
      //   chatEntry.message
      // }`;
      // const key = this.getHash(keyName);

      const key = chatEntry.guid;
      // console.log("BEFORE RENDER MESSAGE:", keyName, key);
      chatMessages.push(
        <ChatMessage
          userName={chatEntry.chatUserName}
          userImage={chatEntry.userImage}
          userChannel={chatEntry.userChannel}
          message={chatEntry.message}
          giftImage={chatEntry.giftImage}
          type={chatEntry.type}
          timestamp={chatEntry.timestamp}
          key={key}
        />
      );
    }
    return <div>{chatMessages}</div>;
  }

  render() {
    console.log("RENDER CHAT: LIST");
    const chatMessageListClassName = this.props.overlay ? "ChatMessageOverlay" : "ChatMessageList";

    return <div className={chatMessageListClassName}>{this.renderChatMessages()}</div>;
  }
}
