import "./LiveVideo.css";
import "./LiveVideoServerGridOverlay.css";

import React, { Component } from "react";
import PropTypes from "prop-types";

class LiveVideoServerGridOverlay extends Component {
  renderInstagramFrame() {
    if (!this.props.showInstagram) return null;

    return (
      <div className="LiveVideoServerGridOverlay__instagramLayer">
        <span>instagram live 9:16 ratio</span>
      </div>
    );
  }

  renderWatermarkLayer() {
    if (!this.props.showWatermark) return null;

    return (
      <div className="LiveVideoServerGridOverlay__watermarkLayer">
        <img
          alt="watermark"
          src="https://app.loola.tv/static-files/loola/loola_live_platform-71.png"
        />
      </div>
    );
  }

  render() {
    if (window.isMobile) return null;

    // currently this is going to be rendered only on PC (/web) - as on mobile we are trying to be very lean and clean
    return [
      <div key="item1" className="LiveVideoServerGridOverlay">
        {this.renderInstagramFrame()}
      </div>,
      <div key="item2" className="LiveVideoServerGridOverlay">
        {this.renderWatermarkLayer()}
      </div>,
    ];
  }
}

LiveVideoServerGridOverlay.propTypes = {
  isLive: PropTypes.bool,
  showInstagram: PropTypes.bool,
  showWatermark: PropTypes.bool,
};

export default LiveVideoServerGridOverlay;
