import { connect } from "react-redux";
import { moveToSignInOrRegisterPage } from "../common/Authentication/action-sign-in";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { openBroadcastSummaryModal } from "../Analytics/broadcast-summary-actions";
import LoolaAppHeader from "./LoolaAppHeader";
import { Constants } from "../core/constants";
import { sendAction } from "../core/send-action";

function mapStateToProps(state) {
  const hasErrorWhileLive = state.runningActions[
    Constants.ACTION_TYPES.LIVE_START_BROADCAST
  ].isError();

  const isRunningGetPlatforms = state.runningActions[
    Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS
  ].isRunning();

  const hasAtLeastOnePlatformAuthenticated = state.platformsData.configuredPlatformsModel.hasAtLeastOneAuthenticated();
  const isReadyForLive = !isRunningGetPlatforms;

  const isRTMP = state.liveVideoData.broadcastConfig.activeTab === Constants.TABS.rtmp;
  const { hasValidRTMPStream } = state.liveVideoData.rtmpConfiguration;

  console.log(
    "LIVE BUTTON - container preparation",
    state.runningActions[Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS],
    isRunningGetPlatforms,
    hasAtLeastOnePlatformAuthenticated,
    isReadyForLive
  );
  const isLiveStartedAndNotFailed = state.liveVideoData.goLive && !hasErrorWhileLive;

  // RoomModel --> user (authenticated \ sign-in), platforms (authenticated \ non), broadcast (no \live), errors
  return {
    // Pre Live \ Broadcast State Data
    currentUser: state.authenticationData.currentUser,
    hasError: state.authenticationData.hasError,
    isSuccessUserSignOut: state.runningActions[Constants.ACTION_TYPES.USER_SIGN_OUT].isSuccess(),
    isLoading: state.authenticationData.isLoading || false,
    isSuccessfulSignIn: state.runningActions[
      Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER
    ].isSuccess(),
    isReadyForLive,
    hasAtLeastOnePlatformAuthenticated,
    isRTMP,
    hasValidRTMPStream,

    // During Live \ Broadcast State Data
    liveBroadcastFailed: hasErrorWhileLive,
    isLive: isLiveStartedAndNotFailed,
    liveStatus: state.liveVideoData.liveStatus,
    isLiveBroadcastSessionEnded:
      state.runningActions[Constants.ACTION_TYPES.LIVE_END_BROADCAST].isSuccess() ||
      state.runningActions[Constants.ACTION_TYPES.LIVE_END_BROADCAST].isDone(),
    hasBroadcastSummaryDetails:
      state.runningActions[Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY].isSuccess() ||
      state.runningActions[Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY].isDone(),

    userSettingsLiveLimits: state.userSettingsLiveLimitsData.userSettingsLiveLimits,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      moveToSignInOrRegisterPage,
      openBroadcastSummaryModal,
      sendAction,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoolaAppHeader));
