import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../Modals/Modal";
import SinglePlatformConfigurationModalContainer from "./SinglePlatformConfigurationModalContainer";

class ConfigurationModal extends Component {
  render() {
    return (
      <Modal onClose={this.props.onClose} width="360px">
        <SinglePlatformConfigurationModalContainer {...this.props} />
      </Modal>
    );
  }
}

ConfigurationModal.propTypes = {
  onClose: PropTypes.func,
};

export default ConfigurationModal;
