import "./actions-styles.css";
import React, { Component } from "react";

export class PollsIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Contests</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Contests" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Page-1" transform="translate(16.000000, 16.000000)">
            <path
              d="M13.724465,8.69187146 L20.1290019,8.69187146 C20.8865671,8.69187146 21.5014026,9.30579206 21.5014026,10.0642722 L21.5014026,19.2136106 C21.5014026,19.9720907 20.8865671,20.5860113 20.1290019,20.5860113 L13.724465,20.5860113 C12.9659849,20.5860113 12.3520643,19.9720907 12.3520643,19.2136106 L12.3520643,10.0642722 C12.3520643,9.30579206 12.9659849,8.69187146 13.724465,8.69187146 Z"
              id="Stroke-1"
              stroke="#FEC400"
            />
            <path
              d="M1.83032514,0.457466919 L8.234862,0.457466919 C8.99242722,0.457466919 9.60726276,1.07138752 9.60726276,1.82986767 L9.60726276,19.2136106 C9.60726276,19.9720907 8.99242722,20.5860113 8.234862,20.5860113 L1.83032514,20.5860113 C1.07184499,20.5860113 0.457924386,19.9720907 0.457924386,19.2136106 L0.457924386,1.82986767 C0.457924386,1.07138752 1.07184499,0.457466919 1.83032514,0.457466919 Z"
              id="Stroke-3"
              stroke="#FEC400"
            />
            <path
              d="M1.83032514,0.457466919 L8.234862,0.457466919 C8.99242722,0.457466919 9.60726276,1.07138752 9.60726276,1.82986767 L9.60726276,19.2136106 C9.60726276,19.9720907 8.99242722,20.5860113 8.234862,20.5860113 L1.83032514,20.5860113 C1.07184499,20.5860113 0.457924386,19.9720907 0.457924386,19.2136106 L0.457924386,1.82986767 C0.457924386,1.07138752 1.07184499,0.457466919 1.83032514,0.457466919"
              id="Fill-5"
              fill="#FEC400"
              opacity="0.246263587"
            />
          </g>
        </g>
      </svg>
    );
  }
}
