import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Constants } from "../../core/constants";
import { sendAction } from "../../core/send-action";
import { VideoLayoutSettingsTab } from "./VideoLayoutSettingsTab";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class VideoLayoutSettingsTabContainer extends React.Component {
  saveLayoutToUserSettings = videoLayout => {
    window.analytics.track(
      LoolaAnalyticsConstants.EVENTS.LIVE_ACTION_BAR_SAVE_VIDEO_LAYOUT,
      videoLayout
    );
    this.props.sendAction(Constants.ACTION_TYPES.USER_SETTINGS_SAVE_VIDEO_LAYOUT, videoLayout);
  };

  handleLoadUserSettings = () => {
    this.props.sendAction(Constants.ACTION_TYPES.USER_SETTINGS);
  };

  // componentWillMount() {
  //   if(this.props.userSettings === null || this.props.userSettings === undefined)
  //     this.props.sendAction(Constants.ACTION_TYPES.USER_SETTINGS);
  // };

  isUserSettingsHasValidCanvas(userSettings) {
    return userSettings !== null && userSettings.videoCanvas !== null;
  }

  render() {
    const { userSettings } = this.props;
    // console.log(userSettings)

    const broadcastTitle = userSettings !== null ? userSettings.broadcastTitle : "";
    const headline = this.isUserSettingsHasValidCanvas(userSettings)
      ? userSettings.videoCanvas.headline
      : "";
    const backgroundColor = this.isUserSettingsHasValidCanvas(userSettings)
      ? userSettings.videoCanvas.backgroundColor
      : "";
    const headlineColor = this.isUserSettingsHasValidCanvas(userSettings)
      ? userSettings.videoCanvas.headlineColor
      : "";

    return (
      <VideoLayoutSettingsTab
        canEdit
        broadcastTitle={broadcastTitle}
        headline={headline}
        backgroundColor={backgroundColor}
        headlineColor={headlineColor}
        loadUserSettings={this.handleLoadUserSettings}
        saveLayoutToUserSettings={this.saveLayoutToUserSettings}
        isLoading={this.props.runningActions[
          Constants.ACTION_TYPES.USER_SETTINGS_SAVE_VIDEO_LAYOUT
        ].isRunning()}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoaded: state.userSettingsData.isLoaded,
    runningActions: state.runningActions,
    userSettings: state.userSettingsData.userSettings,
    isBroadcasting: state.liveVideoData.isBroadcasting,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(VideoLayoutSettingsTabContainer);
