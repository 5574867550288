import "./GoogleButton.css";

import React from "react";
import PropTypes from "prop-types";
// import { GoogleConnectIcon } from "../assets/icons/actions/GoogleConnectIcon";
import Button from "./Button";

class GoogleButton extends React.Component {
  render() {
    return (
      <Button onClick={this.handleClick.bind(this)} additionalClassName="GoogleButton__Button">
        {/* <GoogleConnectIcon name="google" className="GoogleButton__GoogleConnectIcon" size={20} /> */}
        <span className="GoogleButton__Text">Sign in with Google</span>
      </Button>
    );
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}

GoogleButton.propTypes = {
  onClick: PropTypes.func,
};

export default GoogleButton;
