import { Constants } from "../core/constants";
import { PlatformStatuses } from "../core/server_enums";
import { PlatformModel, ConfiguredPlatformsModel } from "../models/PlatformModel";

const arrayOfNonConfiguredPlatforms = [
  new PlatformModel({ platformType: "youtube", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "instagram", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "tiktok", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "twitch", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "twitter", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "trovo", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "periscope", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "facebookrtmp", status: PlatformStatuses.notConfigured }),
  new PlatformModel({ platformType: "customrtmp", status: PlatformStatuses.notConfigured }),

  // new PlatformModel({ platformType: "liveme", status: PlatformStatuses.notConfigured }),
  // new PlatformModel({ platformType: "uplive", status: PlatformStatuses.notConfigured }),
  // new PlatformModel({ platformType: "seventeen", status: PlatformStatuses.notConfigured }),
];

const initialStateOfNonConfiguredPlatforms = new ConfiguredPlatformsModel(
  arrayOfNonConfiguredPlatforms
);

const initialState = {
  platforms: arrayOfNonConfiguredPlatforms,
  configuredPlatformsModel: initialStateOfNonConfiguredPlatforms,
  error: null,
  errorMessage: "",
  requireTwoFactorAuthCode: null,
  requireCaptchaVerification: null,
  requireMobileDeviceValidation: null,
  platformTypeBeingFetched: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS:
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      const platforms = action.payload.map(platform => new PlatformModel(platform));
      const configuredPlatformsModel = new ConfiguredPlatformsModel(platforms);
      return Object.assign({}, state, {
        platforms,
        configuredPlatformsModel,
        error: null,
        errorMessage: "",
      });

    case Constants.ACTION_TYPES.PLATFORM_IS_FETCHING_PLATFORM_TOGGLE:
      return Object.assign({}, state, {
        platformTypeBeingFetched: action.payload.platformType,
        error: null,
        errorMessage: "",
      });

    case Constants.ACTION_TYPES.PLATFORM_TOGGLE:
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
          platformTypeBeingFetched: null,
        });
      }

      const updatedPlatforms = state.platforms.slice(0);
      const i = updatedPlatforms.findIndex(p => p.platformType === action.payload.platformType);
      updatedPlatforms[i] = new PlatformModel(action.payload);
      const updatedConfiguredPlatformsModel = new ConfiguredPlatformsModel(updatedPlatforms);

      return Object.assign({}, state, {
        platformTypeBeingFetched: null,
        platforms: updatedPlatforms,
        configuredPlatformsModel: updatedConfiguredPlatformsModel,
        error: null,
        errorMessage: "",
      });

    case Constants.ACTION_TYPES.PLATFORM_CONFIGURE:
    case Constants.ACTION_TYPES.PLATFORM_DISCONNECT:
      if (action.error) {
        console.log("configure or disconnect from platform (error)", action.error);
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
          requireTwoFactorAuthCode:
            action.error.rawError.status === 2006
              ? action.error.rawError.twoFactorIdentifier
              : null,
          requireCaptchaVerification:
            action.error.rawError.status === 2007 ? action.error.rawError.captchaImage : null,
          requireMobileDeviceValidation:
            action.error.rawError.status === 2041 || action.error.rawError.status === 2042
              ? action.error.rawError
              : null,
        });
      }

      const newPlatforms = state.platforms.slice(0); // clone
      const foundPlatformIndex = newPlatforms.findIndex(
        p => p.platformType === action.payload.platformType
      );
      newPlatforms[foundPlatformIndex] = new PlatformModel(action.payload);
      const newConfiguredPlatformsModel = new ConfiguredPlatformsModel(newPlatforms);

      return Object.assign({}, state, {
        platforms: newPlatforms,
        configuredPlatformsModel: newConfiguredPlatformsModel,
        error: null,
        errorMessage: "",
        requireTwoFactorAuthCode: null,
        requireCaptchaVerification: null,
        requireMobileDeviceValidation: null,
      });

    case Constants.ACTION_TYPES.RESET_PLATFORM_ERROR: {
      return {
        ...state,
        error: null,
        errorMessage: "",
        requireTwoFactorAuthCode: null,
        requireCaptchaVerification: null,
        requireMobileDeviceValidation: null,
      };
    }

    default:
      return state;
  }
}
