const config = require("./config.global");

config.env = "dev";
config.hostname = "dev.loola.tv";
config.helpurl = "https://support.loola.tv";

// use the one below, if you want to connect your local env to the dev-env
config.apiurl = `https://dev.loola.tv/api`;
// config.apiurl = `http://localhost:9099/api`;

config.janusapi = `wss://ws-dev.loola.tv/janus-ws`;
config.overlaysAppUrl = `https://overlays-dev.loola.tv`;

config.credentials = "include";

config.loolaExtensionId = "anmadfkgdplheaiilenncnkobcncnbmc";
// config.loolaExtensionId = "cegddajdfabllncgnkadccjdkffbnolk";

config.instagramUrl = "https://www.instagram.com/accounts/login/";

config.facebookAppId = "497118077478095";
config.facebookUrl =
  "https://www.facebook.com/v3.2/dialog/oauth?client_id=497118077478095&auth_type=rerequest&scope=user_link,email,publish_video,manage_pages,publish_pages,publish_to_groups,groups_access_member_info,pages_show_list&redirect_uri=https://local.loola.tv:9099/auth/facebook";

config.twitchUrl =
  "https://id.twitch.tv/oauth2/authorize?client_id=kwls83109odp0zbcr0nyjtz6ogq748&redirect_uri=https://local.loola.tv:9099/auth/twitch&response_type=code&scope=channel:read:editors+channel:read:stream_key+user:read:email+channel:manage:broadcast+channel:manage:videos+channel:read:subscriptions+chat:read+chat:edit+bits:read+user:read:subscriptions&force_verify=true";

config.trovoUrl =
  "https://open.trovo.live/page/login.html?client_id=34c634194181982ce408d0101863e2cf&response_type=code&scope=channel_details_self+channel_update_self+user_details_self+send_to_my_channel+manage_messages+chat_send_self+chat_connect&redirect_uri=https%3A%2F%2Flocal.loola.tv:9099%2Fauth%2Ftrovo&state=loolatv";
config.periscopeUrl =
  "https://www.pscp.tv/oauth?client_id=ukFb2HWDiVi_eIed44qAvJKLhzEblGHzMolI_aO4ywA_QifeVc&redirect_uri=https://local.loola.tv:9099/auth/periscope&scope=chat";

config.youtubeUrl =
  "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=profile%20email%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&prompt=consent%20select_account&response_type=code&client_id=586692893620-rc0kgtkmrqmovgie6h8nu59u4us042gt.apps.googleusercontent.com&redirect_uri=https://local.loola.tv:9099/auth/youtube";
// config.googleOAuthClientID = '586692893620-kp6qu5gerc9o9gjb0s8lcaa9ordtt411.apps.googleusercontent.com'; //DEV
// config.googleOAuthSecret = 'KssNO4whyDwSrm-GJ7lWrmRB'; //DEV

config.googleOAuthClientID =
  "586692893620-rc0kgtkmrqmovgie6h8nu59u4us042gt.apps.googleusercontent.com"; // BETA
config.googleOAuthSecret = "UmHgRUILsPuxL2YU_jqMLJRh"; // BETA

config.turnserver = "turn.loola.tv:80";
config.janususer = "janususer";
config.januspass = "januspwd";

// config.mongo.db = 'example_test';

module.exports = config;
