import { Constants } from "./constants";
import _ from "lodash";
import { store } from "../store/application-store";

import { authActions } from "../common/Authentication/auth-actions";
import { platformsActions } from "../Platforms/platforms-actions";
import { platformSettingsActions } from "../Platforms/PlatformSettings/platform-settings-actions";
import { goLiveActions } from "../LiveVideo/go-live-action";
import { postLiveBroadcastActions } from "../LiveVideo/post-live-action";
import { liveStreamActions } from "../LiveVideo/live-stream-action";
import { activeFansActions } from "../common/ActiveFans/active-fans-actions";
import { chatActions } from "../common/ChatMessages/chat-actions";
import { userSettingsActions } from "../LiveVideoEditor/EditorTabs/user-settings-actions";
import { userSettingsLiveLimitsActions } from "../common/UserSettingsLiveLimits/user-settings-live-limits-actions";
import { pollsSettingsActions } from "../LiveVideoEditor/EditorTabs/polls-settings-actions";
import { facebookActions } from "../Facebook/facebook-actions";
import { broadcastHistoryActions } from "../BroadcastHistorySelection/broadcast-history-actions";

const allActionsCreators = [
  authActions,
  platformsActions,
  platformSettingsActions,
  goLiveActions,
  postLiveBroadcastActions,
  liveStreamActions,
  activeFansActions,
  chatActions,
  userSettingsActions,
  userSettingsLiveLimitsActions,
  pollsSettingsActions,
  facebookActions,
  broadcastHistoryActions,
];

const sendAction = (type, payload = {}) => {
  // -------------------------------------------------
  // validate no multiple implementations of the same action type
  // -------------------------------------------------
  if (allActionsCreators.length > 1) {
    const allActionTypesImplementationKeys = allActionsCreators.map(actionCreator =>
      _.keys(actionCreator)
    );
    const duplicateActionTypesImplementations = _.intersection(...allActionTypesImplementationKeys);
    if (duplicateActionTypesImplementations.length > 0) {
      throw new Error(`Multiple implementations of action type: ${type}`);
    }
  }
  // -------------------------------------------------

  const combinedActionCreators = Object.assign({}, ...allActionsCreators);

  const startingAction = { type: Constants.ACTION_TYPES.STARTING_ACTION, payload: type };

  if (type !== Constants.ACTION_TYPES.STARTING_ACTION) {
    store.dispatch(startingAction);
  }

  if (type === Constants.ACTION_TYPES.STARTING_ACTION) {
    return startingAction;

    // eslint-disable-next-line no-prototype-builtins
  } else if (combinedActionCreators.hasOwnProperty(type)) {
    return combinedActionCreators[type](payload);
  }
  throw new Error(`Fired a wrong action type: ${type}.`);
};

export { sendAction };
