import "../../core/css/round-box.css";

import React from "react";
import PropTypes from "prop-types";
import { TabContent } from "./TabContent";

class TabNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemIndex: -1,
    };
  }

  handleSelection = actionText => {
    if (actionText === null) {
      this.setState(prev => {
        const newState = Object.assign({}, prev);
        newState.selectedItemIndex = -1;
        return newState;
      });
    } else {
      const selectedItemIndex = this.props.panes.findIndex(item => item.text === actionText);
      this.setState(prev => {
        const newState = Object.assign({}, prev);
        newState.selectedItemIndex = selectedItemIndex;
        return newState;
      });
    }
  };

  renderHeaderItems() {
    const PaneItemComponent = this.props.renderNavigationItem; // convert function into component
    return this.props.panes.map(item => (
      <PaneItemComponent key={item.id} {...item} onTabPaneSelected={this.handleSelection} />
    ));
  }

  render() {
    if (this.state.selectedItemIndex > -1) {
      const currentAction = this.props.panes[this.state.selectedItemIndex];
      return (
        <TabContent
          showBackButton
          tabTitle={currentAction.tabTitle}
          onBackEvent={this.handleSelection}
        >
          {currentAction.render()}
        </TabContent>
      );
    }

    return <TabContent>{this.renderHeaderItems()}</TabContent>;
  }
}

// panes - is holding the items with icon and the specific pane look and feel
// the renderNavigationItem - is being used so you can set specific behavior for your tab-navigation-header per item
TabNavigation.propTypes = {
  panes: PropTypes.array.isRequired,
  renderNavigationItem: PropTypes.func,
};

export { TabNavigation };
