import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SinglePlatformSettingsPage from "./SinglePlatformSettingsPage";
import { sendAction } from "../../core/send-action";
import { Constants } from "../../core/constants";

function mapStateToProps(state) {
  if (state.modalData === null || state.modalData.platformType === null) return state.modalData;

  const { accounts } = state.platformSettingsData;
  const { selectedAccountId } = state.platformSettingsData;
  let selectedAccountType = null;
  if (selectedAccountId != null) {
    const selectedAccount = accounts.find(account => account.id === selectedAccountId);
    selectedAccountType = selectedAccount == null ? "" : selectedAccount.type;
  }

  return {
    platformType: "facebook",
    platformAccounts: accounts,
    selectedAccountId,
    selectedAccountType,
    isPageLoading: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS
    ].isRunning(),
    isSuccessPlatformUpdateSettings: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS
    ].isSuccess(),
    isErrorPlatformUpdateSettings: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS
    ].isError(),
    isRunningPlatformUpdateSettings: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS
    ].isRunning(),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePlatformSettingsPage);
