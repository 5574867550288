import "./LiveButton.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../core/Button";
import { Constants } from "../core/constants";
import BroadcastingState from "../models/BroadcastingState";
import { Spinner } from "../core/Spinner";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

class LiveButton extends Component {
  handleEndBroadcast = () => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.BROADCAST_COMPLETED, {
      isRTMP: this.props.isRTMP,
    });
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_END_LIVE);
  };

  handleGoLiveClick = () => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.GO_LIVE_CLICKED, {
      isRTMP: this.props.isRTMP,
    });
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_GO_LIVE);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("LIVE BUTTON", nextProps);
    console.log("LIVE BUTTON", nextProps.isLiveBroadcastSessionEnded);
    console.log("LIVE BUTTON", nextProps.hasBroadcastSummaryDetails);
    console.log("LIVE BUTTON", this.props.liveBroadcastFailed);

    if (this.props.isLive && this.props.liveStatus !== nextProps.liveStatus) {
      if (nextProps.liveStatus === BroadcastingState.broadcasting) {
        window.analytics.track(LoolaAnalyticsConstants.EVENTS.BROADCAST_STARTED, {
          isRTMP: this.props.isRTMP,
        });
      }
    }

    if (!this.props.liveBroadcastFailed) {
      if (nextProps.isLiveBroadcastSessionEnded) {
        this.props.sendAction(Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY);
      }

      if (nextProps.hasBroadcastSummaryDetails) {
        this.props.openBroadcastSummaryModal();
      }
    }
  }

  render() {
    const { isReadyForLive, isLive, liveStatus } = this.props;
    console.log("LIVE BUTTON -- (in render) is ready", isReadyForLive);
    if (!isReadyForLive) {
      return (
        <div className="LiveButton__buttonContent">
          <span>Getting Ready</span>
          <Spinner
            isVisible
            type={Constants.SPINNER_TYPES.DEFAULT}
            size={Constants.SPINNER_SIZES.SMALL}
          />
        </div>
      );
    }

    if (isLive && liveStatus === BroadcastingState.initiatingBroadcast) {
      return (
        <div className="LiveButton__buttonContent">
          <span>Starting ...</span>
        </div>
      );
    }

    if (isLive && liveStatus === BroadcastingState.broadcasting) {
      return (
        <Button onClick={this.handleEndBroadcast} theme="goOffline">
          <div className="LiveButton__buttonContent">
            <span className="LiveButton__filler" />
            <span className="LiveButton__text">End</span>
            <FontAwesomeIcon icon="circle" name="circle" className="LiveButton__liveIndicator" />
          </div>
        </Button>
      );
    }

    if (liveStatus === BroadcastingState.stoppingBroadcast) {
      return (
        <div className="LiveButton__buttonContent">
          <span>Ending Live</span>
          <Spinner
            isVisible
            type={Constants.SPINNER_TYPES.DEFAULT}
            size={Constants.SPINNER_SIZES.SMALL}
          />
        </div>
      );
    }

    const shouldDisableButtonDueToPlatform = !this.props.hasAtLeastOnePlatformAuthenticated;
    const shouldDisableButtonDueToRTMP = this.props.isRTMP && !this.props.hasValidRTMPStream;
    const shouldDisableButton = shouldDisableButtonDueToPlatform || shouldDisableButtonDueToRTMP;

    return (
      <div>
        <Button onClick={this.handleGoLiveClick} disabled={shouldDisableButton} theme="goLive">
          Go Live
        </Button>
      </div>
    );
  }
}

LiveButton.propTypes = {
  hasAtLeastOnePlatformAuthenticated: PropTypes.bool.isRequired,
  isRTMP: PropTypes.bool.isRequired,
  hasValidRTMPStream: PropTypes.bool.isRequired,
  isReadyForLive: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
  liveStatus: PropTypes.oneOf(Object.values(BroadcastingState)).isRequired,
  hasBroadcastSummaryDetails: PropTypes.bool,
  isLiveBroadcastSessionEnded: PropTypes.bool,
  sendAction: PropTypes.func,
  openBroadcastSummaryModal: PropTypes.func,
  broadcastingToPlatforms: PropTypes.array,
};

export default LiveButton;
