import "./actions-styles.css";
import React, { Component } from "react";

export class LayoutIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Layout</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Page-1" transform="translate(15.000000, 16.000000)">
            <path
              d="M10.04377,21.23132 L1.55067,21.23132 C1.03037,21.26322 0.58267,20.86722 0.55187,20.34582 C0.54967,20.30842 0.54967,20.27102 0.55187,20.23472 L0.55187,1.55012 C0.51997,1.02982 0.91707,0.58322 1.43737,0.55132 C1.47477,0.54912 1.51327,0.54912 1.55067,0.55132 L10.04267,0.55132 C10.56297,0.52052 11.00957,0.91652 11.04147,1.43682 C11.04367,1.47532 11.04367,1.51272 11.04147,1.55012 L11.04147,20.33482 C10.99307,20.84742 10.55967,21.23792 10.04377,21.23132"
              id="Fill-1"
              fill="#2BB6F9"
              opacity="0.236696105"
            />
            <path
              d="M21.63348,21.23242 L14.23928,21.23242 C13.71898,21.26432 13.27128,20.86832 13.24048,20.34692 C13.23828,20.30952 13.23828,20.27212 13.24048,20.23472 L13.24048,12.83942 C13.20858,12.31912 13.60568,11.87252 14.12598,11.84062 C14.16338,11.83842 14.20188,11.83842 14.23928,11.84062 L21.63348,11.84062 C22.15378,11.80982 22.60038,12.20582 22.63228,12.72722 C22.63448,12.76462 22.63448,12.80202 22.63228,12.83942 L22.63228,20.23472 C22.66308,20.75392 22.26708,21.20162 21.74678,21.23242 C21.70828,21.23572 21.67088,21.23572 21.63348,21.23242"
              id="Fill-3"
              fill="#2BB6F9"
              opacity="0.236696105"
            />
            <path
              d="M10.04377,21.23132 L1.55067,21.23132 C1.03037,21.26322 0.58267,20.86722 0.55187,20.34582 C0.54967,20.30842 0.54967,20.27102 0.55187,20.23472 L0.55187,1.55012 C0.51997,1.02982 0.91707,0.58322 1.43737,0.55132 C1.47477,0.54912 1.51327,0.54912 1.55067,0.55132 L10.04267,0.55132 C10.56297,0.52052 11.00957,0.91652 11.04147,1.43682 C11.04367,1.47532 11.04367,1.51272 11.04147,1.55012 L11.04147,20.33482 C10.99307,20.84742 10.55967,21.23792 10.04377,21.23132 Z"
              id="Stroke-5"
              stroke="#2BB6F9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.63348,9.94345 L14.23928,9.94345 C13.71898,9.97535 13.27128,9.57825 13.24048,9.05795 C13.23828,9.02055 13.23828,8.98205 13.24048,8.94465 L13.24048,1.55045 C13.20858,1.03015 13.60568,0.58355 14.12598,0.55165 C14.16338,0.54945 14.20188,0.54945 14.23928,0.55165 L21.63348,0.55165 C22.15378,0.52085 22.60038,0.91685 22.63228,1.43715 C22.63448,1.47455 22.63448,1.51305 22.63228,1.55045 L22.63228,8.94355 C22.66418,9.46385 22.26818,9.91155 21.74788,9.94345 C21.70938,9.94565 21.67198,9.94565 21.63348,9.94345 Z"
              id="Stroke-7"
              stroke="#2BB6F9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.63348,21.23242 L14.23928,21.23242 C13.71898,21.26432 13.27128,20.86832 13.24048,20.34692 C13.23828,20.30952 13.23828,20.27212 13.24048,20.23472 L13.24048,12.83942 C13.20858,12.31912 13.60568,11.87252 14.12598,11.84062 C14.16338,11.83842 14.20188,11.83842 14.23928,11.84062 L21.63348,11.84062 C22.15378,11.80982 22.60038,12.20582 22.63228,12.72722 C22.63448,12.76462 22.63448,12.80202 22.63228,12.83942 L22.63228,20.23472 C22.66308,20.75392 22.26708,21.20162 21.74678,21.23242 C21.70828,21.23572 21.67088,21.23572 21.63348,21.23242 Z"
              id="Stroke-9"
              stroke="#2BB6F9"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}
