/* eslint-disable eqeqeq */
import config from "../Config";

class ChromeExtensionUtil {
  static sendMessage = (typeValue, onSuccess) => {
    const extensionId = config.loolaExtensionId;
    console.log(`ChromeExtensionUtil: ${typeValue} - started`);
    if (ChromeExtensionUtil.isValidChromeRuntime()) {
      window.chrome.runtime.sendMessage(extensionId, { type: typeValue }, reply => {
        onSuccess(reply);
        console.log(`ChromeExtensionUtil: ${typeValue} - after success`);
      });
    }
  };

  static checkExtensionIsInstalled = onSuccess => {
    const extensionId = config.loolaExtensionId;
    let hasExtension = false;

    if (ChromeExtensionUtil.isValidChromeRuntime()) {
      window.chrome.runtime.sendMessage(extensionId, { type: "GET_VERSION" }, reply => {
        if (reply) {
          hasExtension = true;
        }

        onSuccess(hasExtension);
      });
    }
  };

  static isValidChromeRuntime() {
    // It turns out that chrome.runtime.getManifest() returns undefined when the
    // runtime has been reloaded.
    // Note: If this detection method ever fails, try to send a message using
    // chrome.runtime.sendMessage. It will throw an error upon failure.
    // return window.chrome && window.chrome.runtime && !!window.chrome.runtime.getManifest();
    return window.chrome && window.chrome.runtime;
  }
}

export default ChromeExtensionUtil;
