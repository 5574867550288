import React, { Component } from "react";

export class MostActiveFansIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
        <defs>
          <polygon id="path-1" points="0 0 17.1818182 0 17.1818182 16.3636364 0 16.3636364" />
        </defs>
        <g id="Most-Active-Fans" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Page-1" transform="translate(9.000000, 10.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
              d="M9.06219261,0.352970015 L10.8496215,5.87410012 L16.6959498,5.87410012 C16.9874065,5.8980159 17.2040645,6.15083993 17.1799914,6.44039177 C17.1679548,6.58730303 17.094016,6.72311053 16.9762297,6.81364887 L12.2656366,10.1832123 L14.0556448,15.7051966 C14.1502177,15.9580206 14.0221144,16.238177 13.7676271,16.3321318 C13.6111519,16.3910672 13.4349023,16.3662972 13.3016404,16.2663634 L8.59104733,12.8950917 L3.88045428,16.2646551 C3.66293647,16.4260867 3.35514448,16.3825258 3.19265095,16.1664296 C3.09291946,16.0340386 3.06798659,15.8597951 3.12644988,15.7034883 L4.9173178,10.1806499 L0.20586499,6.81364887 C-0.0254088792,6.63598874 -0.0683965873,6.30629254 0.110432278,6.0773843 C0.201566219,5.95951364 0.339126885,5.88605801 0.488724109,5.87410012 L6.32989388,5.87410012 L8.11990205,0.352970015 C8.1938409,0.0950211765 8.46552322,-0.0553066234 8.72516898,0.0190031413 C8.88852227,0.0651264436 9.01576588,0.191538457 9.06219261,0.352970015"
              id="Fill-1"
              fill="#FDC201"
              mask="url(#mask-2)"
            />
          </g>
        </g>
      </svg>
    );
  }
}
