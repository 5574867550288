import "./LoolaCountryAndJSDetectionMessage.css";

import React, { Component } from "react";
import { LoolaPermanentMessage } from "./LoolaPermanentMessage";
import PropTypes from "prop-types";

class LoolaCountryAndJSDetectionMessage extends Component {
  render() {
    return (
      <LoolaPermanentMessage handleLinkClicked={this.props.onDismissClicked}>
        <div>
          <p>
            It looks like you are running pop-up blocker, ad-blocker or any other tool that is
            blocking JavaScript.
          </p>
          <p>Loola uses JavaScript technology which has limited support in your current browser.</p>
        </div>
      </LoolaPermanentMessage>
    );
  }
}

LoolaCountryAndJSDetectionMessage.propTypes = {
  onDismissClicked: PropTypes.func,
};

export default LoolaCountryAndJSDetectionMessage;
