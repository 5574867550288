import React from "react";
import PropTypes from "prop-types";
import Button from "../core/Button";
import "./BroadcastSummaryCommands.css";

import UpgradeVersionContainer from "../common/UpgradeVersion/UpgradeVersionContainer";
import { UpgradeVersionComponentType } from "../common/UpgradeVersion/UpgradeVersionComponentType";

class BroadcastSummaryCommands extends React.Component {
  renderButtonsForPayingUsers() {
    const { onDownloadVideo, onDetailedStat } = this.props;
    return (
      <React.Fragment>
        <div className="BroadcastSummaryCommands__buttonPlaceHolder">
          <Button theme="save" onClick={onDownloadVideo}>
            Download Video
          </Button>
        </div>
        <div className="BroadcastSummaryCommands__buttonPlaceHolder">
          <Button theme="save" onClick={onDetailedStat}>
            Detailed Statistic
          </Button>
        </div>
      </React.Fragment>
    );
  }

  renderButtonsForFreeUsers() {
    const { onUpgrade } = this.props;
    return (
      <div className="BroadcastSummaryCommands__buttonPlaceHolder">
        <UpgradeVersionContainer
          componentType={UpgradeVersionComponentType.button}
          onButtonClicked={onUpgrade}
        />
      </div>
    );
  }

  render() {
    const { onBroadcastAgain, isUserPaying } = this.props;
    return (
      <div className="BroadcastSummaryCommands">
        <div className="BroadcastSummaryCommands__buttonPlaceHolder">
          <Button theme="save" onClick={onBroadcastAgain}>
            Close
          </Button>
        </div>
        {isUserPaying ? this.renderButtonsForPayingUsers() : this.renderButtonsForFreeUsers()}
      </div>
    );
  }
}

BroadcastSummaryCommands.propTypes = {
  isUserPaying: PropTypes.bool.isRequired,
  onBroadcastAgain: PropTypes.func.isRequired,
  onDownloadVideo: PropTypes.func.isRequired,
  onDetailedStat: PropTypes.func.isRequired,
  onUpgrade: PropTypes.func.isRequired,
};

export default BroadcastSummaryCommands;
