import config from "../../Config";
import { LoolaApi } from "../../core/loola-api";
import { MainVideoRoomPagePath } from "../../PageConstants";
import { Constants } from "../../core/constants";

export const getUserGeoIP = () => {
  // This is from --> freegeoip.net
  // const ipStackAccessKey = `2e7ea9f2ad646846945020607c7419ab`;
  // const geoIPUrl = `http://api.ipstack.com/check?access_key=${ipStackAccessKey}&format=1`;

  const geoIPUrl = `https://ipapi.co/json/?key=2ydeb9X33EroePG7iU0yh9iEPlDSYLO7NOyVis1OozDfmq7A7x`;

  return dispatch => {
    fetch(geoIPUrl, {
      method: "GET",
    })
      .then(data => data.json())
      .then(response => {
        console.log("LOCATION: MY IP:", response.ip);
        console.log("LOCATION: MY Country Code:", response.country);
        dispatch(storeUserGeoLocation(response));
      })
      .catch(err => {
        console.log("Can't log in and Can't fetch location", err);
      });
  };
};

export const storeUserGeoLocation = locationEntity => {
  const payloadResponse = {
    geoIP: locationEntity.ip,
    geoLocation: locationEntity.country,
  };

  return {
    type: Constants.ACTION_TYPES.SESSION_STORE_GEOLOCATION,
    payload: payloadResponse,
  };
};

export function getGoogleAuthenticatedUser() {
  return dispatch => {
    dispatch(signInIsLoading(true));

    getUserInfo()
      .then(response => {
        if (response.status === 500 || response.status === 401) {
          console.log(`getUserInfo failed with status code ${response.status}`);
          dispatch(signInFailed(true));
        } else {
          response
            .json()
            .then(jsonResponse =>
              dispatch(
                googleUserIsAuthenticated(
                  jsonResponse.email,
                  jsonResponse.name,
                  jsonResponse.profileImage
                )
              )
            );
        }
      })
      .catch(err => {
        console.log("inside-error--catch", err);
        dispatch(signInFailed(true));
      });
  };
}

function getUserInfo() {
  return fetch(`${config.apiurl}/google-auth/user-info`, {
    method: "GET",
    credentials: LoolaApi.apiCredentials(),
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  });
}

export function signInIsLoading(isLoading) {
  return {
    type: "SIGN_IN_IS_LOADING",
    isLoading,
  };
}

export function signInFailed(hasError) {
  return {
    type: "SIGN_IN_FAILED",
    hasError,
  };
}

export function MoveToMainVideoRoomPage(history) {
  console.log("MOVE TO MAIN - IN");
  return dispatch => {
    dispatch({
      type: "MOVE_TO_POST_LOG_IN",
    });
    history.push(MainVideoRoomPagePath);
  };
}

export function moveToSignInOrRegisterPage(history) {
  return dispatch => {
    dispatch({
      type: "MOVE_TO_POST_LOG_IN",
    });
    history.push("/");
  };
}

export function googleUserIsAuthenticated(userEmail, name, profileImage) {
  return {
    type: "GOOGLE_USER_IS_AUTHENTICATED",
    payload: {
      email: userEmail,
      name,
      profileImage,
    },
  };
}
