import { connect } from "react-redux";
import ConfigurationModal from "./ConfigurationModal";
import { hideModal } from "../Modals/action-modal";
import { platformsActions } from "./platforms-actions";

const resetPlatformError = platformsActions.RESET_PLATFORM_ERROR;

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => dispatch(hideModal()),
    resetPlatformError: () => dispatch(resetPlatformError()),
  };
}

export default connect(null, mapDispatchToProps)(ConfigurationModal);
