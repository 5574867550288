import React, { Component } from "react";

class UserCamIcon extends Component {
  render() {
    const { enabled, onClick } = this.props;

    return (
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="49"
        viewBox="0 0 50 49"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M37.464 19.775V29.95c0 .787-.869 1.278-1.564.887l-4.325-2.445v.697c0 2.094-1.733 3.792-3.87 3.792H16.37c-2.137 0-3.87-1.698-3.87-3.792v-8.442c0-2.095 1.733-3.793 3.87-3.793h11.34c2.137 0 3.87 1.698 3.87 3.793v.696l4.325-2.445c.69-.4 1.56.095 1.56.877z"
          />
          {!enabled && <path fill="#FFF" d="M11.78 7.34l25.565 31.572-2.38 1.928L9.399 9.268z" />}
        </g>
      </svg>
    );
  }
}

export default UserCamIcon;
