import { API_CALL } from "../../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../../core/loola-api";
import { Constants } from "../../core/constants";

const userSettingsActions = {
  [Constants.ACTION_TYPES.USER_SETTINGS]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.USER_SETTINGS,
        payload: LoolaApi.getUserSettings(),
      },
    };
  },

  [Constants.ACTION_TYPES.USER_SETTINGS_SAVE_VIDEO_LAYOUT]: videoLayoutSettings => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.USER_SETTINGS_SAVE_VIDEO_LAYOUT,
        payload: LoolaApi.saveVideoLayoutToUserSettings(videoLayoutSettings),
      },
    };
  },
};

export { userSettingsActions };
