import "./actions-styles.css";
import React, { Component } from "react";

export class GiftIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Giveaways</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Giveaways" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Page-1" transform="translate(15.000000, 16.000000)">
            <g
              id="Group-5"
              transform="translate(1.000000, 10.303200)"
              fill="#E04DCF"
              opacity="0.433140851"
            >
              <path
                d="M8.1468,0.4829 L0.8648,0.4829 L0.8648,9.7679 C0.9198,10.2179 1.3238,10.5409 1.7748,10.4959 L8.1468,10.4959 L8.1468,0.4829 Z"
                id="Fill-1"
              />
              <path
                d="M19.8899,0.4829 L12.6079,0.4829 L12.6079,10.4049 L18.9799,10.4049 C19.4209,10.4179 19.8059,10.1089 19.8899,9.6769 L19.8899,1.1199 L19.8899,0.4829 Z"
                id="Fill-3"
              />
            </g>
            <path
              d="M5.6878,4.5048 L16.9758,4.5048 L17.2488,4.2318 C17.5878,3.6298 17.5528,2.8868 17.1578,2.3198 C16.5208,1.1368 15.7018,0.4998 14.8828,0.4998 C13.8828,0.4998 12.7888,1.3188 11.7828,2.9578 C11.6918,3.1398 11.5098,3.4128 11.4188,3.5948 C11.3278,3.4128 11.1458,3.1398 11.0548,2.9578 C9.9668,1.3188 8.9658,0.4998 7.9638,0.4998 C7.0538,0.4998 6.3248,1.1368 5.6878,2.3208 C5.1878,2.8008 5.1108,3.5728 5.5058,4.1418 C5.5088,4.2838 5.5758,4.4178 5.6878,4.5048"
              id="Fill-6"
              fill="#E04DCF"
              opacity="0.431102808"
            />
            <path
              d="M21.346,4.5048 L13.608,4.5048 L13.608,10.8768 L9.057,10.8768 L9.057,4.5048 L1.41,4.5048 C0.908,4.5058 0.501,4.9128 0.5,5.4148 L0.5,9.9668 C0.501,10.4688 0.908,10.8758 1.41,10.8768 L21.346,10.8768 C21.848,10.8758 22.255,10.4688 22.256,9.9668 L22.256,5.4148 C22.255,4.9128 21.848,4.5058 21.346,4.5048"
              id="Fill-8"
              fill="#E04DCF"
              opacity="0.432801178"
            />
            <path
              d="M5.6878,4.5048 L16.9758,4.5048 L17.2488,4.2318 C17.5878,3.6298 17.5528,2.8868 17.1578,2.3198 C16.5208,1.1368 15.7018,0.4998 14.8828,0.4998 C13.8828,0.4998 12.7888,1.3188 11.7828,2.9578 C11.6918,3.1398 11.5098,3.4128 11.4188,3.5948 C11.3278,3.4128 11.1458,3.1398 11.0548,2.9578 C9.9668,1.3188 8.9658,0.4998 7.9638,0.4998 C7.0538,0.4998 6.3248,1.1368 5.6878,2.3208 C5.1878,2.8008 5.1108,3.5728 5.5058,4.1418 C5.5088,4.2838 5.5758,4.4178 5.6878,4.5048 Z"
              id="Stroke-10"
              stroke="#E04DCF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.8899,10.7861 L1.8649,10.7861 L1.8649,20.0711 C1.9199,20.5211 2.3239,20.8441 2.7749,20.7991 L19.9749,20.7991 C20.4159,20.8121 20.8009,20.5031 20.8849,20.0711 L20.8849,11.5141 L20.8899,10.7861 Z"
              id="Stroke-12"
              stroke="#E04DCF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.2561,9.8759 C22.2551,10.3779 21.8481,10.7849 21.3461,10.7859 L1.4101,10.7859 C0.9081,10.7849 0.5011,10.3779 0.5001,9.8759 L0.5001,5.4149 C0.5011,4.9129 0.9081,4.5059 1.4101,4.5049 L21.3461,4.5049 C21.8481,4.5059 22.2551,4.9129 22.2561,5.4149 L22.2561,9.8759 Z"
              id="Stroke-14"
              stroke="#E04DCF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <polygon
              id="Stroke-16"
              stroke="#E04DCF"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="9.148 20.7082 13.7 20.7082 13.7 10.7862 9.148 10.7862"
            />
            <polygon
              id="Stroke-18"
              stroke="#E04DCF"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="9.148 10.8772 13.7 10.8772 13.7 4.5052 9.148 4.5052"
            />
          </g>
        </g>
      </svg>
    );
  }
}
