import "./actions-styles.css";
import React, { Component } from "react";

export class MusicPlayerIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Music</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g id="Music" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" transform="translate(16.000000, 16.000000)">
            <g id="Page-1">
              <path
                d="M17.6135143,13.4404286 C19.4468476,13.1837619 21.109419,14.2292857 21.3252286,15.7755714 C21.5420857,17.3229048 20.2325619,18.7864286 18.398181,19.0430952 C16.5648476,19.2997619 14.9022762,18.2531905 14.6864667,16.7058571 C14.4696095,15.1595714 15.780181,13.6970952 17.6135143,13.4404286"
                id="Fill-1"
                fill="#FE1A69"
                opacity="0.441632699"
              />
              <path
                d="M7.18750476,18.290381 C7.40331429,19.8377143 6.09379048,21.3001905 4.25940952,21.5568571 C2.42607619,21.8135238 0.764552381,20.768 0.547695238,19.2206667 C0.330838095,17.674381 1.64140952,16.2108571 3.47474286,15.9541905 C5.30807619,15.6975238 6.97064762,16.7440952 7.18750476,18.290381 Z"
                id="Fill-3"
                fill="#FE1A69"
                opacity="0.46"
              />
              <polygon
                id="Fill-5"
                fill="#FE1A69"
                opacity="0.460314764"
                points="21.3650381 4.50455238 21.3650381 0.5236 7.22218095 2.93312381 7.22218095 7.0188381"
              />
              <polygon
                id="Stroke-7"
                stroke="#FE1A69"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="21.3650381 4.50455238 21.3650381 0.5236 7.22218095 2.93312381 7.22218095 7.0188381"
              />
              <polyline
                id="Stroke-9"
                stroke="#FE1A69"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="7.22218095 18.3337524 7.22218095 7.01946667 21.3650381 4.50413333 21.3650381 15.8194667"
              />
              <path
                d="M3.47474286,15.9541905 C5.30807619,15.6975238 6.97064762,16.7440952 7.18750476,18.290381 C7.40331429,19.8377143 6.09379048,21.3001905 4.25940952,21.5568571 C2.42607619,21.8135238 0.764552381,20.768 0.547695238,19.2206667 C0.330838095,17.674381 1.64140952,16.2108571 3.47474286,15.9541905 Z"
                id="Stroke-11"
                stroke="#FE1A69"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.6135143,13.4404286 C19.4468476,13.1837619 21.109419,14.2292857 21.3252286,15.7755714 C21.5420857,17.3229048 20.2325619,18.7864286 18.398181,19.0430952 C16.5648476,19.2997619 14.9022762,18.2531905 14.6864667,16.7058571 C14.4696095,15.1595714 15.780181,13.6970952 17.6135143,13.4404286 Z"
                id="Stroke-13"
                stroke="#FE1A69"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
