import React from "react";
import PropTypes from "prop-types";
import SummaryCounter from "./SummaryCounter";
import { EyeIcon } from "../assets/icons/actions/EyeIcon";
import { LikeIcon } from "../assets/icons/actions/LikeIcon";
import { ManUserIcon } from "../assets/icons/actions/ManUserIcon";
import { Constants } from "../core/constants";
import "./BroadcastSummaryCounters.css";

class BroadcastSummaryCounters extends React.Component {
  render() {
    const { views, viewers, followers, likes } = this.props;

    return (
      <section className="BroadcastSummaryCounters BroadcastSummary__inner-rounded-box">
        <SummaryCounter
          title="Total Views:"
          value={views.toString()}
          addedClassName="summary-counters"
        >
          <EyeIcon size={Constants.ICON_SIZES.MEDIUM} color="black" />
        </SummaryCounter>
        <SummaryCounter
          title="Max Concurrent Viewers:"
          value={viewers.toString()}
          addedClassName="summary-counters"
        >
          <ManUserIcon size={Constants.ICON_SIZES.MEDIUM} color="black" />
        </SummaryCounter>
        <SummaryCounter
          title="New Followers:"
          value={followers.toString()}
          addedClassName="summary-counters"
        >
          <ManUserIcon size={Constants.ICON_SIZES.MEDIUM} color="green" />
        </SummaryCounter>
        <SummaryCounter title="Likes:" value={likes.toString()} addedClassName="summary-counters">
          <LikeIcon size={Constants.ICON_SIZES.MEDIUM} color="red" />
        </SummaryCounter>
      </section>
    );
  }
}

BroadcastSummaryCounters.propTypes = {
  views: PropTypes.number.isRequired,
  viewers: PropTypes.number.isRequired,
  followers: PropTypes.number.isRequired,
  likes: PropTypes.number.isRequired,
};

export default BroadcastSummaryCounters;
