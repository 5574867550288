import React, { Component } from "react";
import logoFooter from "../../assets/icons/loola-logo-symbol.svg";
import facebookIcons from "../../assets/images/facebook-icons.svg";
import linkedInIcons from "../../assets/images/linkedIn-icons.svg";
import twitterIcons from "../../assets/images/twitter-icons.svg";
import instagramIcons from "../../assets/icons/channels/instagram-black-white.svg";
import { PrivacyPagePath, TermsPagePath } from "../../PageConstants";
import { NavHashLink as NavLink } from "react-router-hash-link";
import "./LandingPageFooter.css";

class LandingPageFooter extends Component {
  renderSocialLink(socialName, socialIcon, socialLink) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={socialLink}>
        <img src={socialIcon} alt={socialName} style={{ width: "21px", height: "21px" }} />
      </a>
    );
  }

  render() {
    return (
      <footer className="LandingPageFooter">
        <img className="logoFooter" src={logoFooter} alt="" />
        <div className="iconsFooter">
          {this.renderSocialLink("facebook", facebookIcons, "https://www.facebook.com/loola.tv/")}
          {this.renderSocialLink("twitter", twitterIcons, "https://twitter.com/LoolaTv")}
          {this.renderSocialLink(
            "instagram",
            instagramIcons,
            "https://www.instagram.com/loola.tv/"
          )}
          {this.renderSocialLink(
            "linkedin",
            linkedInIcons,
            "https://www.linkedin.com/company/loola-tv/"
          )}
        </div>
        <div className="textFooter">
          <p className="textFooterSection">
            © {new Date().getFullYear()} Loola All rights reserved.
          </p>
          <p className="textFooterSection">
            <NavLink
              target="_self"
              rel="noopener noreferrer"
              to={TermsPagePath}
              activeStyle={{
                color: "#f62682",
              }}
            >
              Terms
            </NavLink>
          </p>
          <p className="textFooterSection">
            <NavLink
              target="_self"
              rel="noopener noreferrer"
              to={PrivacyPagePath}
              activeStyle={{
                color: "#f62682",
              }}
            >
              Privacy
            </NavLink>
          </p>
        </div>
      </footer>
    );
  }
}

export default LandingPageFooter;
