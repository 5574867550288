export const YearlyPricingPlans = [
  {
    planName: "",
    planDescription: "Base",
    pricePerMonth: "Free",
    main3: "FREE FOREVER",
    text1: "Multicasting",
    text2: "SD Resolution (480p @ 30 FPS)",
    text3: "3 hours per month",
    text4: "Max session time 20 min",
    text5: "Recording last 10 videos",
    buttonType: "free",
    buttonText: "Try Now",
    link: "https://www.loola.tv/connect",
  },
  {
    planName: "premium_annual",
    planDescription: "Premium",
    pricePerMonth: "$19",
    main3: "BILLED AS $228/year",
    text1: "Everything in Base",
    text2: "HD Resolution (720p @ 60 FPS)",
    text3: "Unlimited streaming / recording",
    text4: "Max session time 4 hours",
    text5: "Loola watermark removed",
    buttonType: "subscribe",
    buttonText: "Upgrade Now",
  },
  {
    planName: "professional_annual",
    planDescription: "Pro",
    pricePerMonth: "$39",
    main3: "BILLED AS $468/year",
    text1: "Everything in Premium",
    text2: "Full HD (1080p @ 60 FPS)",
    text3: "Custom Destinations (RTMP)",
    text4: "Max session time 12 hours",
    text5: "Email+Chat Support",
    buttonType: "subscribe",
    buttonText: "Upgrade Now",
  },
];

export const MonthlyPricingPlans = [
  {
    planName: "",
    planDescription: "Base",
    pricePerMonth: "Free",
    main3: "FREE FOREVER",
    text1: "Multicasting",
    text2: "SD Resolution (480p @ 30 FPS)",
    text3: "3 hours per month",
    text4: "Max session time 20 min",
    text5: "Recording last 10 videos",
    buttonType: "free",
    buttonText: "Try Now",
    link: "https://www.loola.tv/connect",
  },
  {
    planName: "premium",
    planDescription: "Premium",
    pricePerMonth: "$24",
    main3: "BILLED MONTHLY",
    text1: "Everything in Base",
    text2: "HD Resolution (720p @ 60 FPS)",
    text3: "Unlimited streaming / recording",
    text4: "Max session time 4 hours",
    text5: "Loola watermark removed",
    buttonType: "subscribe",
    buttonText: "Upgrade Now",
  },
  {
    planName: "professional",
    planDescription: "Pro",
    pricePerMonth: "$49",
    main3: "BILLED MONTHLY",
    text1: "Everything in Premium",
    text2: "Full HD (1080p @ 60 FPS)",
    text3: "Custom Destinations (RTMP)",
    text4: "Max session time 12 hours",
    text5: "Email+Chat Support",
    buttonType: "subscribe",
    buttonText: "Upgrade Now",
  },
];
