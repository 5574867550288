import "./UserBox.css";

import React from "react";
import PropTypes from "prop-types";

import GenericUserImage from "../assets/icons/profile/user.svg";
import PlatformIcon from "../common/PlatformIcon";
import AllChannelTypes from "../common/channelTypes";
import { Constants } from "../core/constants";
import cx from "classnames";

class UserBox extends React.PureComponent {
  render() {
    console.log("UserBox render called");

    if (this.props.showImageOnly && this.props.showNameAndChannelOnly) {
      throw new Error("can't set both attributes to true");
    }

    if (this.props.showImageOnly) {
      return <div className="UserBox">{this.renderUserImage()}</div>;
    } else if (this.props.showNameAndChannelOnly) {
      return (
        <div className="UserBox">
          {this.renderChannel()}
          <span
            className={cx(
              "UserBox__userName",
              `UserBox__userName--${this.props.textColor}`,
              `UserBox__userName--${this.props.textWeight}`
            )}
          >
            {this.props.name}
          </span>
        </div>
      );
    }

    // show regular user-box (with both image and name)
    return (
      <div className="UserBox">
        {this.renderUserImage()}
        {this.renderChannel()}
        <span
          className={cx(
            "UserBox__userName",
            `UserBox__userName--${this.props.textColor}`,
            `UserBox__userName--${this.props.textWeight}`
          )}
        >
          {this.props.name}
        </span>
      </div>
    );
  }

  renderUserImage() {
    if (this.props.imageSrc)
      return (
        <img
          className={cx("UserBox__imageStyle", `UserBox__imageSize--${this.props.imageSize}`)}
          src={this.props.imageSrc}
          alt={this.props.name}
        />
      );
    // no image
    return (
      <img
        className={cx("UserBox__imageStyle", `UserBox__imageSize--${this.props.imageSize}`)}
        src={GenericUserImage}
        alt={this.props.name}
      />
    );
  }
  renderChannel() {
    if (this.props.channel) return <PlatformIcon channel={this.props.channel} />;
    return "";
  }
}

UserBox.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  channel: PropTypes.oneOf(AllChannelTypes.channelTypesNames),
  showImageOnly: PropTypes.bool,
  showNameAndChannelOnly: PropTypes.bool,
  imageSize: PropTypes.oneOf(Object.values(Constants.ICON_SIZES)),
  textWeight: PropTypes.oneOf(["normal", "bold"]),
  textColor: PropTypes.oneOf(["white", "grey"]),
};

UserBox.defaultProps = {
  imageSize: Constants.ICON_SIZES.MEDIUM,
  textWeight: "normal",
  textColor: "grey",
};

export default UserBox;
