import React from "react";

const Title = ({ the = true, entityName, addedClassName, imgSrc }) => (
  <h2 className={`about__title ${addedClassName && `about__title--${addedClassName}`}`}>
    {the && "The "}
    <span className="about__title-entity">
      {entityName}
      <img src={imgSrc} alt="title" className="about__title-logo" />
    </span>
  </h2>
);

export default Title;
