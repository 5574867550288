import React, { Component } from "react";
import PropTypes from "prop-types";
import "./EyeIcon.css";
import { Constants } from "../../../core/constants";

export class EyeIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { size, color = "#fff" } = this.props;

    return (
      // <svg x="0px" y="0px" viewBox="0 0 54.308 54.308" className={`Eye--${size}`}>
      //   <g fill={color || "currentColor"}>
      //     <path
      //       d="M53.583,25.902c-5.448-9.413-15.575-15.26-26.429-15.26S6.173,16.489,0.725,25.902L0,27.154
      //       l0.725,1.252c5.447,9.413,15.574,15.26,26.429,15.26s20.981-5.847,26.429-15.26l0.725-1.252L53.583,25.902z M5.826,27.154
      //       c2.187-3.319,5.102-6.031,8.452-7.993c-1.198,2.126-1.889,4.574-1.889,7.183c0,3.778,1.446,7.215,3.797,9.821
      //       C12.031,34.179,8.419,31.089,5.826,27.154z M37.843,36.302c2.426-2.621,3.922-6.114,3.922-9.958c0-0.999-0.102-1.974-0.293-2.917
      //       c-0.432,1.268-1.207,2.377-2.222,3.208c-0.762,0.624-1.658,1.086-2.635,1.351c-0.594,0.161-1.216,0.255-1.86,0.255
      //       c-3.922,0-7.101-3.179-7.101-7.101c0-1.605,0.539-3.08,1.436-4.269c0.289-0.383,0.615-0.735,0.974-1.052
      //       c1.55,0.18,3.069,0.503,4.543,0.956c1.81,0.557,3.547,1.314,5.184,2.249c0.687,0.393,1.36,0.811,2.008,1.266
      //       c2.608,1.829,4.888,4.145,6.681,6.865C45.83,31.178,42.117,34.323,37.843,36.302z"
      //     />
      //   </g>
      // </svg>
      <svg width="9" height="6" viewBox="0 0 9 6" className={`Eye--${size}`}>
        <path
          fill={color}
          fillRule="nonzero"
          d="M4.5 0C2.455 0 .695 1.24 0 3c.695 1.76 2.455 3 4.5 3S8.305 4.76 9 3c-.695-1.76-2.455-3-4.5-3zm0 5c-1.145 0-2.045-.88-2.045-2s.9-2 2.045-2 2.045.88 2.045 2-.9 2-2.045 2zm0-3.2c-.695 0-1.227.52-1.227 1.2 0 .68.532 1.2 1.227 1.2.695 0 1.227-.52 1.227-1.2 0-.68-.532-1.2-1.227-1.2z"
          opacity="1"
        />
      </svg>
    );
  }
}

EyeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.values(Constants.ICON_SIZES)),
};
