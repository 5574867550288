const emptyStreamHealthData = {
  bitRate: 0,
  frameRate: 0,
  height: 0,
  width: 0,
};

class RTMPSettingsModel {
  constructor() {
    this.hasValidRTMPStream = false;
    this.serverUrl = "";
    this.rtmpServerUrl = "";
    this.hlsFilePath = "";
    this.streamKey = "";
    this.isValidResolution = true;
    this.streamHealthData = emptyStreamHealthData;
    this.resolutionConstraints = null;
  }

  static map(serverModel) {
    return {
      hasValidRTMPStream: serverModel.hasRTMPValidSession || false,
      serverUrl: serverModel.serverUrl,
      rtmpServerUrl: serverModel.rtmpServerUrl,
      hlsFilePath: serverModel.hlsFilePath,
      streamKey: serverModel.streamKey,
      isValidResolution: serverModel.internals?.isValidResolution,
      streamHealthData: serverModel.internals?.streamHealthData || emptyStreamHealthData,
      resolutionConstraints: serverModel.internals?.resolutionConstraints || null,
    };
  }
}

export { RTMPSettingsModel };
