import "./ActiveFan.css";

import React, { Component } from "react";

import PropTypes from "prop-types";
import AllChannelTypes from "../channelTypes";
import PlatformIcon from "../PlatformIcon";

export default class ActiveFan extends Component {
  renderNumberOfFollowers() {
    if (this.props.giftPoints && this.props.giftPoints > 1) {
      console.log("render number of gift points: ", this.props.giftPoints);
      return (
        <span className="ActiveFan__fanActivity ActiveFan__followersIcon">
          &nbsp;
          <span>{this.props.giftPoints}</span>
          &nbsp;gift points
        </span>
      );
    }

    if (this.props.comments && this.props.comments > 1) {
      console.log("render number of gift points: ", this.props.comments);
      return (
        <span className="ActiveFan__fanActivity ActiveFan__followersIcon">
          &nbsp;
          <span>{this.props.comments}</span>
          &nbsp;comments
        </span>
      );
    }

    if (this.props.followers && this.props.followers > 0) {
      console.log("render number of followers: ", this.props.followers);
      return (
        <span className="ActiveFan__fanActivity ActiveFan__followersIcon">
          &nbsp;
          <span>{this.props.followers}</span>
          &nbsp;followers
        </span>
      );
    }
    // if no returning till now...
    return null;
  }

  render() {
    return (
      <div className="ActiveFan">
        <a href={this.props.fanPageUrl} target="_blank" rel="noopener noreferrer">
          <img src={this.props.img} className="ActiveFan__fanImage" alt={this.props.name} />
        </a>
        <div className="ActiveFan__fanData">
          <div className="ActiveFan__fanName">
            <a href={this.props.fanPageUrl} target="_blank" rel="noopener noreferrer">
              {this.props.name}
            </a>
            <PlatformIcon channel={this.props.platform} />
          </div>
          {this.renderNumberOfFollowers()}
        </div>
      </div>
    );
  }
}

ActiveFan.propTypes = {
  platform: PropTypes.oneOf(AllChannelTypes.channelTypesNames),
  name: PropTypes.string,
  img: PropTypes.string,
  fanPageUrl: PropTypes.string,
  followers: PropTypes.number,
  giftPoints: PropTypes.number,
  comments: PropTypes.number,
};
