import React from "react";
import PropTypes from "prop-types";
import "./BroadcastSummaryHints.css";
import UpgradeVersionContainer from "../common/UpgradeVersion/UpgradeVersionContainer";
import { UpgradeVersionComponentType } from "../common/UpgradeVersion/UpgradeVersionComponentType";

class BroadcastSummaryHints extends React.Component {
  render() {
    const { isUserPaying } = this.props;
    if (isUserPaying) return null;

    return (
      <div className="BroadcastSummaryHints BroadcastSummary__inner-rounded-box">
        <div className="BroadcastSummaryHints__hint">
          Live ended too early? Upgrade to get longer live sessions{" "}
          <UpgradeVersionContainer componentType={UpgradeVersionComponentType.link} />
        </div>
      </div>
    );
  }
}

BroadcastSummaryHints.propTypes = {
  isUserPaying: PropTypes.bool.isRequired,
};

export default BroadcastSummaryHints;
