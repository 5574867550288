import React from "react";
import PropTypes from "prop-types";
import PrettyCounter from "../core/PrettyCounter";
import "./PlatformCounter.css";

const PlatformCounter = props => {
  const { icon, views, totalFollowers, newFollowers } = props;

  return (
    <div className="PlatformCounter">
      <div className="PlatformCounter__icon-wrap">
        <img src={icon} className="PlatformCounter__icon" alt="" />
      </div>
      <div className="PlatformCounter__values">
        <div className="PlatformCounter__value">
          <PrettyCounter value={views} />
        </div>
        <div className="PlatformCounter__value">
          <span>
            <PrettyCounter value={totalFollowers} />
          </span>{" "}
          {newFollowers && (
            <span>
              (<PrettyCounter value={newFollowers} /> new)
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

PlatformCounter.propTypes = {
  icon: PropTypes.string,
  views: PropTypes.string,
  totalFollowers: PropTypes.string,
  newFollowers: PropTypes.string,
};

export default PlatformCounter;
