import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

class ColorPickerButton extends Component {
  render() {
    const { currentColor } = this.props;
    return (
      <button
        className={cx("ColorPickerButton", `ColorBoxItem__color--${currentColor}`)}
        onClick={this.props.toggleTooltipVisible}
      />
    );
  }
}

ColorPickerButton.propTypes = {
  toggleTooltipVisible: PropTypes.func.isRequired,
  currentColor: PropTypes.string.isRequired,
};

export default ColorPickerButton;
