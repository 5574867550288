import React, { Component } from "react";
import PropTypes from "prop-types";
import { LiveActionBarItem } from "../LiveVideoEditor/LiveActionBarItem";
import "./VideoJsWrapper.css";

export default class VideoJsWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isFlashLoaded: false,
    };
  }
  UNSAFE_componentWillUpdate() {
    return false;
  }

  scriptLoaded = () => {
    this.setState({ isLoaded: true });
  };
  scriptFlashLoaded = () => {
    this.setState({ isFlashLoaded: true });
  };

  loadCSS = (cssStylePath, scriptLoadedEvent) => {
    const cssLinkElement = document.createElement("link");

    cssLinkElement.rel = "stylesheet";
    cssLinkElement.type = "text/css";
    cssLinkElement.href = cssStylePath;
    cssLinkElement.onload = () => scriptLoadedEvent();

    document.body.appendChild(cssLinkElement);
  };

  loadJS = (filePath, scriptLoadedEvent) => {
    const script = document.createElement("script");

    script.src = filePath;

    script.type = "text/javascript";
    script.async = false;
    script.onload = () => scriptLoadedEvent();

    document.body.appendChild(script);
  };

  // <link href="https://vjs.zencdn.net/7.8.4/video-js.css" rel="stylesheet">
  // <script src='https://vjs.zencdn.net/7.8.4/video.js'></script>
  // <script src='https://cdn.jsdelivr.net/npm/videojs-flash@2/dist/videojs-flash.min.js'></script>
  loadFlashIfNeeded = () => {
    if (this.props.isHLS) return;
    // else (should load flash)
    this.loadJS(
      "https://cdn.jsdelivr.net/npm/videojs-flash@2/dist/videojs-flash.min.js",
      this.scriptFlashLoaded
    );
  };

  loadVideoJs = () => {
    this.loadJS("https://vjs.zencdn.net/7.8.4/video.js", this.scriptLoaded);
  };

  // loadVideoJsCSS = () => {
  //   this.loadCSS("https://vjs.zencdn.net/7.8.4/video-js.css", this.scriptLoaded);
  // };

  componentDidMount() {
    this.loadVideoJs();
    this.loadFlashIfNeeded();
    // this.loadVideoJsCSS();
  }

  // <video-js autoplay muted id='my-video'
  //           className='video-js vjs-default-skin vjs-big-play-centered vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v7 vjs-user-active'
  //           controls width='640' height='480' data-setup='{"techOrder":["html5"], "fluid": true}'>
  renderHLSViewer = () => (
    <div id="VideoJsWrapper" className="VideoJsWrapper">
      <video
        id="VideoJsWrapper"
        className="VideoJsWrapper-size video-js vjs-default-skin vjs-big-play-centered vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v7 vjs-user-active"
        controls
        muted
        autoPlay
        preload="auto"
        data-setup='{"techOrder":["html5"]}'
      >
        <source src={this.props.src} type="application/x-mpegURL" />
      </video>
    </div>
  );

  renderFlashViewer = () => (
    <div id="VideoJsWrapper" className="VideoJsWrapper">
      <video
        id="VideoJsWrapper"
        className="VideoJsWrapper-size video-js vjs-default-skin vjs-big-play-centered"
        poster="https://app.loola.tv/static-files/loola/adobe-flash-plugin.png"
        controls
        autoPlay
        preload="auto"
        data-setup='{"techOrder":["flash"]}'
      >
        <source src={this.props.src} type="rtmp/mp4" />
      </video>
    </div>
  );

  render() {
    if (!this.state.isLoaded) return "Still Loading...";
    if (!this.props.isHLS && !this.state.isFlashLoaded) return "Still Loading Flash...";

    if (this.props.isHLS) return this.renderHLSViewer();

    // else RTMP URL (not HLS)
    return this.renderFlashViewer();
  }
}

VideoJsWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  isHLS: PropTypes.bool.isRequired,
};

export { LiveActionBarItem };
