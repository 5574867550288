import React, { Component } from "react";
import Soul from "./Soul";
import Mind from "./Mind";
import Heart from "./Heart";
import soulImgSrc from "../../assets/icons_new/about/soul-title.svg";
import mindImgSrc from "../../assets/icons_new/about/mind-title.svg";
import heartImgSrc from "../../assets/icons_new/about/heart-title.svg";
import soulCloudRight from "../../assets/icons_new/about/soul-cloud-right.svg";
import soulCloudMiddle from "../../assets/icons_new/about/soul-cloud-middle.svg";
import soulCloudLeft from "../../assets/icons_new/about/soul-cloud-left.svg";
import "./AboutPage.css";
import LoolaPublicPageHeader from "../../PublicWebsite/LandingPage/components/LoolaPublicPageHeader";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class AboutPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.ABOUT_US_PAGE);
  }

  render() {
    return (
      <div className="aboutPage">
        <LoolaPublicPageHeader textColor="white" logoColor="white" />
        {/* SOUL BLOCK */}
        <Soul
          title={{
            entityName: "Soul",
            addedClassName: "soul",
            imgSrc: soulImgSrc,
          }}
          text={{
            content: (
              <React.Fragment>
                Loola is a new, innovative way of live streaming - A home for creators, influencers
                and storytellers. For people who want to make their live content better, more
                engaging and to reach their entire audience across all channels from one spot. While
                live content is exponentially growing, it creates up-to-date, fresh content with new
                possibilities. This is our passion, this is Loola.
                <img src={soulCloudLeft} alt="decorator" className="about__soul-text-cloud left" />
                <img
                  src={soulCloudMiddle}
                  alt="decorator"
                  className="about__soul-text-cloud middle"
                />
                <img
                  src={soulCloudRight}
                  alt="decorator"
                  className="about__soul-text-cloud right"
                />
              </React.Fragment>
            ),
          }}
        />

        {/*  MIND BLOCK */}
        <Mind
          title={{
            entityName: "Mind",
            addedClassName: "mind",
            imgSrc: mindImgSrc,
          }}
          text={{
            content: (
              <React.Fragment>
                We believe live streaming should be easy to use, simple and without technical
                hassle.{" "}
                <span className="about__mind-link">
                  We believe that every creator should be able to stream anytime to anywhere, from
                  any device, focusing on their content and not the platforms.
                </span>{" "}
                <p>
                  With some of the best minds in the high-tech social field combined with passionate
                  creators who want to change the way they engage with their audiences, We built
                  Loola where creators can control all of their livestreams with tools never seen
                  before.
                </p>
              </React.Fragment>
            ),
          }}
        />

        {/* HEART BLOCK */}
        <Heart
          show={false}
          title={{
            entityName: "Heart",
            addedClassName: "heart",
            imgSrc: heartImgSrc,
          }}
        />
      </div>
    );
  }
}

export default AboutPage;
