import { Constants } from "../core/constants";

export const showSystemMessage = (messageType, messageText, helpLink) => {
  return {
    type: Constants.ACTION_TYPES.SYSTEM_MESSAGE_SHOW,
    payload: { messageType, messageText, helpLink },
  };
};

export const hideSystemMessage = () => {
  return {
    type: Constants.ACTION_TYPES.SYSTEM_MESSAGE_HIDE,
    payload: {},
  };
};
