class PollsQuestionsModel {
  constructor(serverQuestions = []) {
    this.questions = this.toJsonObject(serverQuestions);
  }

  toJsonObject(serverQuestions) {
    const obj = serverQuestions.map(item => {
      return {
        questionId: item.questionId,
        question: item.questionText,
        nickName: item.username,
        avatar: item.profileImage,
      };
    });
    return obj;
  }
}

export { PollsQuestionsModel };
