// import { PlatformStatuses } from "../core/server_enums";

class BroadcastSummaryCountersModel {
  constructor(views, viewers, newFollowers, likes, duration = "00:00:00") {
    this.views = views || 0;
    this.viewers = viewers || 0;
    const tempNewFollowers = newFollowers || 0;
    this.newFollowers = Math.max(0, tempNewFollowers);
    this.likes = likes || 0;
    this.duration = duration;
  }
}

class PlatformCountersModel {
  constructor(serverModel) {
    this.platformType = serverModel.platformType;
    this.views = serverModel.views || 0;
    this.totalFollowers = serverModel.followers || 0;
    this.newFollowers = Math.max(0, serverModel.newFollowers);
  }
}

class SummaryPageModel {
  constructor(broadcastSummaryCounters, platformsCounters) {
    this.broadcastSummaryCounters = broadcastSummaryCounters;
    this.platformsCounters = platformsCounters;

    // Find Maximum in array
    this.dailyAchievement = this.calcDailyAchievement(broadcastSummaryCounters);
  }

  calcDailyAchievement = broadcastSummaryCounters => `${broadcastSummaryCounters.likes} likes`;
}

export { SummaryPageModel, PlatformCountersModel, BroadcastSummaryCountersModel };
