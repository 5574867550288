import React, { Component } from "react";

class CarouselIndicator extends Component {
  render() {
    return (
      <div>
        <div
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.goToSlide}
          // onMouseMove= {this.props.onClickMause}
          onMouseDown={this.props.goToSlide}
        />
      </div>
    );
  }
}

export default CarouselIndicator;
