import React, { Component } from "react";

export class MostActiveFansEmptyStateIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="92px" height="92px" viewBox="0 0 92 92" version="1.1">
        <defs />
        <g id="activefansempty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle id="Oval-2" fill="#F8F6F6" cx="46" cy="46" r="46" />
          <path
            d="M48.2068848,23.9059564 L52.7842159,38.076857 L67.7557656,38.076857 C68.5021414,38.1382408 69.0569695,38.7871558 68.9953219,39.5303389 C68.9644981,39.9074111 68.775152,40.2559837 68.4735194,40.4883654 L56.4104133,49.1369117 L60.9943496,63.3100045 C61.2365364,63.9589195 60.9084833,64.6779875 60.2567806,64.9191384 C59.8560715,65.0704057 59.4047233,65.0068296 59.06346,64.7503328 L47.000354,56.097402 L34.937248,64.7459482 C34.3802183,65.1602892 33.5920102,65.0484829 33.1758892,64.493836 C32.9204922,64.1540325 32.8566429,63.7068073 33.0063584,63.30562 L37.5924964,49.1303348 L25.5271887,40.4883654 C24.9349318,40.0323711 24.8248468,39.1861509 25.2828001,38.5986197 C25.5161802,38.296085 25.8684519,38.1075489 26.2515475,38.076857 L41.209887,38.076857 L45.7938232,23.9059564 C45.9831693,23.2438877 46.678906,22.8580463 47.343819,23.0487747 C47.7621417,23.1671579 48.0879931,23.4916154 48.2068848,23.9059564"
            id="Fill-1"
            fill="#EBEBEB"
          />
        </g>
      </svg>
    );
  }
}
