import "./StreamDestinationItem.css";

import React from "react";
import PropTypes from "prop-types";
import AllChannelTypes from "../common/channelTypes";
import { PlatformStatuses } from "../core/server_enums";
import { MODAL_TYPE_CONFIGURATION } from "../Modals/ModalTypes";
import { Spinner } from "../core/Spinner";
import { Constants } from "../core/constants";

class StreamDestinationItem extends React.Component {
  constructor(props) {
    super(props);

    this.toggleChannel = this.toggleChannel.bind(this);
  }

  showConfigurationPage = () => {
    this.props.showModal(MODAL_TYPE_CONFIGURATION, {
      title: "Set Credentials for",
      platformType: this.props.platformType,
    });
  };

  toggleToNextState() {
    if (this.props.platformStatus === PlatformStatuses.NOT_CONFIGURED) {
      this.showConfigurationPage();
      return;
    }

    const shouldGoOn = this.props.platformStatus === PlatformStatuses.CONFIGURED;

    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_IS_FETCHING_PLATFORM_TOGGLE, {
      platformType: this.props.platformType,
    });

    this.props.togglePlatform({
      platformType: this.props.platformType,
      shouldGoOn,
    });
  }

  toggleChannel() {
    if (!this.props.canToggle) {
      return;
    }

    this.toggleToNextState();
  }

  renderMessage() {
    let textRepresentNumberOfFans = "";
    // only when live and authenticated channel ...
    if (this.props.isLive && this.props.platformStatus === PlatformStatuses.AUTHENTICATED) {
      textRepresentNumberOfFans = this.props.numberOfFans ? this.props.numberOfFans : 0;

      console.log(
        "Stream Destination Item - number of Fans - inside IF",
        this.props.platformType,
        this.props.numberOfFans,
        textRepresentNumberOfFans
      );
    }

    return <p className="actionText">{textRepresentNumberOfFans}</p>;
  }

  render() {
    const channelLogo = AllChannelTypes.getChannelImageLogo(this.props.platformType);
    const channelName = AllChannelTypes.getChannelName(this.props.platformType);

    const isNotConfigured = this.props.platformStatus === PlatformStatuses.NOT_CONFIGURED;
    const isNotAuthenticated = this.props.platformStatus !== PlatformStatuses.AUTHENTICATED;
    const imageStyleOff = isNotAuthenticated ? "StreamDestinationItem__channelOff" : "";
    const tooltip = isNotConfigured
      ? channelName
      : `${channelName} - ${this.props.platformUserName}`;

    return (
      <div className="StreamDestinationItem__actionContainer" onClick={this.toggleChannel}>
        <div className="StreamDestinationItem__imageContainer">
          <img
            className={`StreamDestinationItem__imageStyle ${imageStyleOff}`}
            title={tooltip}
            src={channelLogo}
            alt={channelName}
          />
        </div>
        {this.renderMessage()}
        <Spinner isVisible={this.props.showSpinner} type="accordion" size="xs" />
      </div>
    );
  }
}

StreamDestinationItem.propTypes = {
  isLive: PropTypes.bool.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  canToggle: PropTypes.bool.isRequired,
  togglePlatform: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  platformType: PropTypes.string.isRequired,
  platformStatus: PropTypes.oneOf([
    PlatformStatuses.NOT_CONFIGURED,
    PlatformStatuses.CONFIGURED,
    PlatformStatuses.AUTHENTICATED,
  ]).isRequired,
  platformUserName: PropTypes.string,
  numberOfFans: PropTypes.number,
};

export default StreamDestinationItem;
