import { Constants } from "../../core/constants";
import { LoolaApi } from "../../core/loola-api";
import { API_CALL } from "../../redux-middlewares/loola-api-handler";

const chatActions = {
  [Constants.ACTION_TYPES.LIVE_GET_COMMENTS]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_GET_COMMENTS,
        payload: LoolaApi.getLiveComments(),
      },
    };
  },

  [Constants.ACTION_TYPES.LIVE_WRITE_COMMENT]: comment => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_WRITE_COMMENT,
        payload: LoolaApi.writeLiveComment(comment),
      },
    };
  },
};

export { chatActions };
