import React, { Component } from "react";
import PropTypes from "prop-types";
import { LiveActionBarItem } from "../LiveVideoEditor/LiveActionBarItem";
import Scheduler from "../core/Scheduler";
import { Constants } from "../core/constants";
import VideoHLSWrapper from "./VideoHLSWrapper";

import "./RTMPVideoPlayer.css";
import { CopyToClipboardComponent } from "./CopyToClipboardComponent";
import { IntercomHelpPages } from "../core/intercom-help-pages";
import InvalidResolutionErrorBox from "../LiveVideo/InvalidResolutionErrorBox";

export default class RTMPVideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streamEnded: false,
    };
  }

  handleStreamEnd = () => {
    this.setState({ streamEnded: true });
  };

  UNSAFE_componentWillMount() {
    this.props.sampleRTMP();
  }

  componentDidUpdate() {
    if (this.props.hasValidRTMPStream && this.state.streamEnded) {
      this.setState({ streamEnded: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(this.props.hasValidRTMPStream && this.state.streamEnded && !nextState.streamEnded);
  }

  renderStepsToConnect() {
    return (
      <ol type="1">
        <li>Start your encoding software (preview will start automatically)</li>
        <li>Click Go Live </li>
      </ol>
    );
  }

  renderConnectHintView() {
    return (
      <div className="RTMPVideoPlayer">
        <div className="RTMPVideoPlayer__Title">How to stream using RTMP:</div>
        <div className="RTMPVideoPlayer__Content">{this.renderStepsToConnect()}</div>
        <CopyToClipboardComponent text={this.props.rtmpUrl} title="RTMP Server:" />
        <CopyToClipboardComponent text={this.props.streamKey} title="Stream Key:" />
        <div className="RTMPVideoPlayer__Note">
          <div>
            Note: Broadcast will be ended automatically if the stream is interrupted for more than
            30 seconds.
          </div>
          <div className="RTMPVideoPlayer__Note--black">
            To ensure optimal video delivery, please see our{" "}
            <a
              href={IntercomHelpPages.rtmpEncoderSettings}
              target="_blank"
              rel="noopener noreferrer"
            >
              recommended encoder settings.
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log("RTMP Video Player - render log....", this.props);
    console.log(this.props, "IS LIVE 222");

    if (!this.props.hasValidRTMPStream || this.state.streamEnded) {
      // run scheduler each 10 secones and for the next 5 minutes
      return (
        <Scheduler
          interval={10000}
          isRunning
          action={() => this.props.sampleRTMP()}
          // sessionTimeout={300000}
          // onSessionTimeout={() => this.props.stopSampleRTMP()}
        >
          {this.renderConnectHintView()}
        </Scheduler>
      );
    }

    if (
      this.props.hasValidRTMPStream &&
      !this.props.isValidResolution &&
      this.props.streamHealthData
    ) {
      return (
        <InvalidResolutionErrorBox
          streamHealthData={this.props.streamHealthData}
          resolutionConstraints={this.props.resolutionConstraints}
        />
      );
    }

    // videoSource = `https://app.loola.tv/hlspush/2gA7zX-Z1c9Cdj.m3u8`;
    const videoSource = this.props.hlsUrl;

    return (
      <Scheduler
        interval={5000}
        isRunning={this.props.isLive}
        action={() => {
          this.props.sendAction(Constants.ACTION_TYPES.LIVE_HEARTBEAT_BROADCAST);
        }}
      >
        <VideoHLSWrapper onStreamEnd={this.handleStreamEnd} src={videoSource} />
      </Scheduler>
    );
  }
}

RTMPVideoPlayer.propTypes = {
  isLive: PropTypes.bool.isRequired,
  rtmpUrl: PropTypes.string.isRequired,
  hlsUrl: PropTypes.string.isRequired,
  streamKey: PropTypes.string.isRequired,
  hasValidRTMPStream: PropTypes.bool.isRequired,
  isValidResolution: PropTypes.bool.isRequired,
  streamHealthData: PropTypes.object,
  resolutionConstraints: PropTypes.object,
  sampleRTMP: PropTypes.func,
};

export { LiveActionBarItem };
