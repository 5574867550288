import React, { Component } from "react";
import "./TextField.css";
import cx from "classnames";
import PropTypes from "prop-types";

class TextField extends Component {
  state = {
    // this params response for position of input label;
    // if false -> label seems like placeholder, if true, it seems like legend(html)
    isPlaceholderFloated: false,
  };

  UNSAFE_componentWillMount = () => {
    const { value = "" } = this.props;

    if (value.length > 0) {
      this.setState({
        isPlaceholderFloated: true,
      });
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.value?.length > 0) {
      this.setState({
        isPlaceholderFloated: true,
      });
    }
  };

  inputChangeHandler = e => {
    if (this.props.onChange && typeof this.props.onChange === "function") {
      this.props.onChange(e);
    }
  };

  inputFocusHandler = () => {
    this.setState(() => {
      return {
        isPlaceholderFloated: true,
      };
    });
  };

  inputBlurFocus = e => {
    if (e.target.value.length === 0) {
      this.setState(() => {
        return {
          isPlaceholderFloated: false,
        };
      });
    }
  };

  render() {
    const {
      value,
      label,
      type,
      wrapAddedClassName,
      inputAddedClassName,
      shouldShowError,
      errorMessage,
    } = this.props;
    const spanFloatedClass = this.state.isPlaceholderFloated === true ? "floated" : "";
    let errorText = null;

    if (shouldShowError) {
      errorText = errorMessage;
    }

    return (
      <div
        className={cx(
          "TextField__inputFormItem",
          wrapAddedClassName ? `TextField__inputFormItem--${wrapAddedClassName}` : "",
          shouldShowError ? "TextField__inputFormItem--error" : ""
        )}
      >
        <span className={cx("TextField__inputFormInputLabel", spanFloatedClass)}>{label}</span>
        <input
          className={cx("TextField__inputFormInput", inputAddedClassName)}
          type={type}
          value={value}
          onChange={this.inputChangeHandler}
          onFocus={this.inputFocusHandler}
          onBlur={this.inputBlurFocus}
        />
        {shouldShowError && (
          <label className="TextField__inputFormInput-errorLabel">{errorText}</label>
        )}
      </div>
    );
  }
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  wrapAddedClassName: PropTypes.string,
  inputAddedClassName: PropTypes.string,
  userNameTitle: PropTypes.string,
  onChange: PropTypes.func,
  shouldShowError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default TextField;
