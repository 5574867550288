import React from "react";
import PropTypes from "prop-types";
import BroadcastSummaryCounters from "./BroadcastSummaryCounters";
import DailyAchievement from "./DailyAchievement";
import PlatformsCountersList from "./PlatformsCountersList";
import BroadcastSummaryCommands from "./BroadcastSummaryCommands";
import BroadcastSummaryHints from "./BroadcastSummaryHints";
import BroadcastSummaryHeader from "./BroadcastSummaryHeader";
import BroadcastSummaryClose from "./BroadcastSummaryClose";
import "./BroadcastSummaryVideoOverlay.css";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

class BroadcastSummaryVideoOverlay extends React.Component {
  transformDurationStringToDurationInSeconds(durationString) {
    if (durationString === null || durationString === undefined) return 0;
    const durationParts = durationString.split(":");
    if (durationParts.length !== 3) return 0;

    const hours = 60 * 60 * parseInt(durationParts[0], 10);
    const minutes = 60 * parseInt(durationParts[1], 10);
    const seconds = parseInt(durationParts[2], 10);

    return hours + minutes + seconds;
  }

  UNSAFE_componentWillMount() {
    const listOfPlatforms = this.props.platformsCounters.map(
      platformEntry => platformEntry.platformType
    );

    const durationInSeconds = this.transformDurationStringToDurationInSeconds(
      this.props.summaryCounters.duration
    );

    window.analytics.track(LoolaAnalyticsConstants.EVENTS.BROADCAST_SUMMARY_VIEWED, {
      numberOfPlatforms: listOfPlatforms.length,
      isRTMP: this.props.liveMetadata.isRTMP,
      platforms: listOfPlatforms,
      duration: this.props.summaryCounters.duration,
      durationInSeconds,
      newFollowers: this.props.summaryCounters.followers,
      totalLikes: this.props.summaryCounters.likes,
      totalViews: this.props.summaryCounters.views,
      maxConcurrentViewers: this.props.summaryCounters.viewers,
    });
  }

  render() {
    const {
      commands,
      userMetadata,
      dailyAchievement,
      summaryCounters,
      platformsCounters,
    } = this.props;

    return (
      <div title="Broadcast Summary" className="LiveVideoBroadcastSummary">
        <BroadcastSummaryClose closeBroadcastSummary={commands.closeBroadcastSummary} />
        <BroadcastSummaryHeader duration={summaryCounters.duration} />
        {/*  first div is just a wrap for grouping inside blocks */}
        <div>
          <div className="BroadcastSummaryModal__middle">
            <BroadcastSummaryCounters
              views={summaryCounters.views}
              viewers={summaryCounters.viewers}
              followers={summaryCounters.followers}
              likes={summaryCounters.likes}
            />

            <div className="BroadcastSummaryModal__dailyAchievement  BroadcastSummary__inner-rounded-box">
              <DailyAchievement value={dailyAchievement.value} />
            </div>
          </div>

          <PlatformsCountersList platforms={platformsCounters} />
          <BroadcastSummaryHints isUserPaying={userMetadata.isUserPaying} />
        </div>

        <footer className="BroadcastSummaryModal__broadcastSummaryCommands">
          <BroadcastSummaryCommands
            onDownloadVideo={commands.onDownloadVideo}
            onDetailedStat={commands.onDetailedStat}
            onBroadcastAgain={commands.onBroadcastAgain}
            onUpgrade={commands.onUpgrade}
            isUserPaying={userMetadata.isUserPaying}
          />
        </footer>
      </div>
    );
  }
}

BroadcastSummaryVideoOverlay.propTypes = {
  liveMetadata: PropTypes.shape({
    isRTMP: PropTypes.bool,
  }),
  userMetadata: PropTypes.shape({
    isUserPaying: PropTypes.bool,
  }),
  summaryCounters: PropTypes.shape({
    views: PropTypes.number,
    viewers: PropTypes.number,
    followers: PropTypes.number,
    likes: PropTypes.number,
    duration: PropTypes.string,
  }),
  dailyAchievement: PropTypes.shape({
    value: PropTypes.string,
  }),
  platformsCounters: PropTypes.array,
  commands: PropTypes.shape({
    onDownloadVideo: PropTypes.func,
    onDetailedStat: PropTypes.func,
    onBroadcastAgain: PropTypes.func,
    onUpgrade: PropTypes.func,
  }),
};

export default BroadcastSummaryVideoOverlay;
