import "./LoolaPermanentMessage.css";

import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

class LoolaPermanentMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
    };
  }

  handleDismiss = () => {
    this.setState({ clicked: true });
    this.props.handleLinkClicked();
  };

  render() {
    if (this.state.clicked) return null;

    return (
      <div className={cx("LoolaPermanentMessage", this.props.extraClassName)}>
        <div className="LoolaPermanentMessage__contentSection">
          <div className="LoolaPermanentMessage__messageSection">{this.props.children}</div>
          <div className="LoolaPermanentMessage__actionSection">
            <button id="dismiss-button" onClick={this.handleDismiss}>
              Dismiss
            </button>
          </div>
        </div>
      </div>
    );
  }
}

LoolaPermanentMessage.propTypes = {
  children: PropTypes.node.isRequired,
  extraClassName: PropTypes.string,
  handleLinkClicked: PropTypes.func,
};

export { LoolaPermanentMessage };
