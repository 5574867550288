import { Constants } from "../core/constants";
import { saveBroadcastConfigToStorage } from "../store/broadcastLocalStorage";
import { getFeed } from "../VideoRoom/LocalUser/localUserSelector";

export const saveBroadcastConfig = broadcastConfig => {
  saveBroadcastConfigToStorage(broadcastConfig);
  return {
    type: Constants.ACTION_TYPES.LIVE_SAVE_BROADCAST_CONFIG,
    payload: broadcastConfig,
  };
};

export const muteAudio = () => (dispatch, getState) => {
  const localVideoRoom = getFeed(getState().videoRoom.feeds)?.plugin;
  if (!localVideoRoom) return;
  const isMuteSuccess = localVideoRoom.muteAudio();
  if (isMuteSuccess) {
    dispatch(
      saveBroadcastConfig({
        isAudioMuted: true,
      })
    );
  }
};

export const unmuteAudio = () => (dispatch, getState) => {
  const localVideoRoom = getFeed(getState().videoRoom.feeds)?.plugin;
  if (!localVideoRoom) return;
  const isUnmuteSuccess = localVideoRoom.unmuteAudio();
  if (isUnmuteSuccess) {
    dispatch(
      saveBroadcastConfig({
        isAudioMuted: false,
      })
    );
  }
};

export const muteVideo = () => (dispatch, getState) => {
  const localVideoRoom = getFeed(getState().videoRoom.feeds)?.plugin;
  if (!localVideoRoom) return;
  const isMuteSuccess = localVideoRoom.muteVideo();
  if (isMuteSuccess) {
    dispatch(
      saveBroadcastConfig({
        isVideoMuted: true,
      })
    );
  }
};

export const unmuteVideo = () => (dispatch, getState) => {
  const localVideoRoom = getFeed(getState().videoRoom.feeds)?.plugin;
  if (!localVideoRoom) return;
  const isUnmuteSuccess = localVideoRoom.unmuteVideo();
  if (isUnmuteSuccess) {
    dispatch(
      saveBroadcastConfig({
        isVideoMuted: false,
      })
    );
  }
};

export const flipVideo = () => (dispatch, getState) => {
  const { isVideoFlipped } = getState().liveVideoData.broadcastConfig;

  dispatch(
    saveBroadcastConfig({
      isVideoFlipped: !isVideoFlipped,
    })
  );
};

export const startScreenShare = () => {
  return {
    type: Constants.ACTION_TYPES.LIVE_CHANGE_SCREEN_SHARE_STATUS,
    payload: { status: true },
  };
};

export const stopScreenShare = () => {
  return {
    type: Constants.ACTION_TYPES.LIVE_CHANGE_SCREEN_SHARE_STATUS,
    payload: { status: false },
  };
};
