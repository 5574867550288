import { bindActionCreators } from "redux";
import { sendAction } from "../../core/send-action";
import React from "react";
import { connect } from "react-redux";

import { UpgradeVersionComponentType } from "./UpgradeVersionComponentType";
import UpgradeVersionButton from "./UpgradeVersionButton";
import UpgradeVersionIndicator from "./UpgradeVersionIndicator";
import UpgradeVersionLink from "./UpgradeVersionLink";
import PropTypes from "prop-types";

class UpgradeVersionContainer extends React.Component {
  renderButton() {
    const { userSettingsLiveLimits } = this.props;
    if (userSettingsLiveLimits === null) return;

    // else...
    return (
      <UpgradeVersionButton
        isInTrialPeriod={userSettingsLiveLimits.isInTrialPeriod()}
        trialTimeLeftInSeconds={userSettingsLiveLimits.getTrialTimeLeftInSeconds()}
        trialExceededLimit={userSettingsLiveLimits.isTrialExceededLimit()}
        isLive={this.props.isLive}
        onButtonClicked={this.props.onButtonClicked}
      />
    );
  }

  renderLink() {
    const { userSettingsLiveLimits } = this.props;
    if (userSettingsLiveLimits === null) return;

    // else
    return (
      <UpgradeVersionLink
        isInTrialPeriod={userSettingsLiveLimits.isInTrialPeriod()}
        trialTimeLeftInSeconds={userSettingsLiveLimits.getTrialTimeLeftInSeconds()}
        trialExceededLimit={userSettingsLiveLimits.isTrialExceededLimit()}
        isLive={this.props.isLive}
        {...this.props}
      />
    );
  }

  renderUpgradeVersionIndicator() {
    const { currentUser, userSettingsLiveLimits } = this.props;
    if (currentUser === null) return null;
    if (userSettingsLiveLimits === null) return null;

    // else
    return (
      <UpgradeVersionIndicator
        isInTrialPeriod={userSettingsLiveLimits.isInTrialPeriod()}
        trialTimeLeftInSeconds={userSettingsLiveLimits.getTrialTimeLeftInSeconds()}
        trialExceededLimit={userSettingsLiveLimits.isTrialExceededLimit()}
        isLive={this.props.isLive}
        {...this.props}
      />
    );
  }

  render() {
    const { componentType } = this.props;

    if (componentType === UpgradeVersionComponentType.button) {
      return this.renderButton();
    }
    if (componentType === UpgradeVersionComponentType.link) {
      return this.renderLink();
    }
    if (componentType === UpgradeVersionComponentType.indicator) {
      return this.renderUpgradeVersionIndicator();
    }

    // else
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.authenticationData.currentUser,
    userSettingsLiveLimits: state.userSettingsLiveLimitsData.userSettingsLiveLimits,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

UpgradeVersionContainer.propTypes = {
  componentType: PropTypes.oneOf(Object.values(UpgradeVersionComponentType)).isRequired,
  onButtonClicked: PropTypes.func,
  isLive: PropTypes.bool,
};

export default connect(mapStateToProps, matchDispatchToProps)(UpgradeVersionContainer);
