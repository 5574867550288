class CurrentUserModel {
  constructor(serverModel = {}) {
    this.objectModel = "currentUserModel";
    this.name = serverModel.name || "";
    this.email = serverModel.email || "";
    this.rtmpKey = serverModel.rtmpKey || "";
    this.registrationDate = serverModel.registrationDate || "";
    this.profileImage = serverModel.profileImage || "";
    this.location = serverModel.location || "";
    this.accounts = serverModel.accounts || null;
    // this.isPaying = serverModel.subscriptionPlan !== undefined && serverModel.subscriptionPlan !== null && serverModel.subscriptionPlan !== "free";
    this.subscriptionPlan = serverModel.subscriptionPlan || "";
  }

  isUser() {
    // empty name or empty email -- proves that something is broken with this user, so we better know it.
    const isInvalidUser = this.name === "" || this.email === "";
    return !isInvalidUser;
  }

  isPaying() {
    return (
      this.subscriptionPlan !== undefined &&
      this.subscriptionPlan !== null &&
      this.subscriptionPlan !== "free"
    );
  }

  isLiveInUS() {
    return this.location === "US";
  }
}

export { CurrentUserModel };
