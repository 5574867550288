import config from "../Config";

class LoolaApi {
  static baseUrl() {
    return config.apiurl;
  }

  static apiCredentials() {
    return config.credentials;
  }

  static requestHeaders() {
    const headers = { "Content-Type": "application/json" };

    // let authToken = getCurrentUserAuthToken();
    // if (authToken) {
    //   headers[Consts.HTTP_API_HEADERS.AUTHORIZATION] = authToken;
    // }
    return headers;
  }

  static _fetchIt(method, url, data = null) {
    const requestParams = {
      method,
      credentials: this.apiCredentials(),
      headers: this.requestHeaders(),
    };

    if (method === "post" || method === "put") {
      requestParams.body = JSON.stringify(data);
    }

    return fetch(`${config.apiurl}/${url}`, requestParams);
  }

  static post(url, data = {}) {
    return LoolaApi._fetchIt("post", url, data);
  }

  static get(url, data = {}) {
    return LoolaApi._fetchIt("get", url, data);
  }

  static delete(url, data = {}) {
    return LoolaApi._fetchIt("delete", url, data);
  }

  /**
   * Authentication
   */
  static signInOrRegister(userDetails) {
    console.log("signInOrRegister!!!", userDetails);

    return this.get(
      `/auth/sign-in-or-register?userEmail=${userDetails.email}&name=${userDetails.name}&profileImage=${userDetails.profileImage}`
    );
  }

  static signOut() {
    return this.get(`/auth/sign-out`);
  }

  /**
   * User Settings
   */
  static getUserSettings() {
    return this.get(`users-settings/get`);
  }

  static saveVideoLayoutToUserSettings(videoLayoutSettings) {
    return this.post(`users-settings/set-and-apply-video-canvas`, videoLayoutSettings);
  }

  static getUserSettingsLiveLimits() {
    return this.get(`rooms/get-user-live-limitations-for-last-month`);
  }

  /**
   * Polls Settings
   */
  static getPollsSettings() {
    return this.get(`live-polls/get-questions`);
  }

  static showPollQuestion(questionPayload) {
    return this.post(`live-polls/show-question`, questionPayload);
  }

  static hidePollQuestion(questionPayload) {
    return this.post(`live-polls/hide-question`, questionPayload);
  }

  /**
   * LIVE & BROADCAST EVENTS
   */
  static startBroadcastSession(broadcastConfig) {
    console.log("*** BROADCASTING - INSIDE ACTION");
    return this.post(`broadcast/start`, broadcastConfig);
  }

  static validateBroadcastSession() {
    console.log("*** BROADCASTING - INSIDE ACTION");
    return this.post(`broadcast/heartbeat`);
  }

  static endBroadcastSession(broadcastConfig) {
    console.log("*** BROADCASTING - INSIDE ACTION");
    return this.post(`broadcast/stop`, broadcastConfig);
  }

  static validateRTMPStream() {
    console.log("*** RTMP - INSIDE ACTION");
    return this.get(`rtmp/heartbeat`);
  }

  /**
   * Post Live Event
   */
  static getBroadcastSummary() {
    return this.get(`live-broadcast/summary`);
  }

  /**
   * Platform
   */

  static getConfiguredPlatforms() {
    return this.get("authenticate-platform/platforms-configured-by-user");
  }

  static platformToggle(data) {
    return this.post("authenticate-platform/platform-toggle", data);
  }

  static getLiveActiveFans() {
    return this.get("live-active-users/get");
  }

  static getLiveStats() {
    return this.get("live-stats/get");
  }

  static getLiveComments() {
    return this.get("live-comments/get");
  }
  static downloadRoomComments(roomId) {
    return this.get(`live-comments/download/${roomId}`);
  }

  static writeLiveComment(comment) {
    return this.post("live-comments/save", { message: comment });
  }

  static getRoomConfiguration() {
    return this.get("live-server-configuration/room-configuration");
  }

  static configurePlatform(platformDetails) {
    return this.post("authenticate-platform/configure", platformDetails);
  }

  static disconnectPlatform(platformDetails) {
    return this.delete(
      `authenticate-platform/disconnect-from-platform?platformType=${platformDetails.platformType}`
    );
  }

  static getPlatformAccounts(platformDetails) {
    return this.get(
      `platform-configuration-settings/accounts?platformType=${platformDetails.platformType}`
    );
  }

  static updatePlatformSettings(platformDetails) {
    return this.post(`platform-configuration-settings/update`, platformDetails);
  }

  static storeInstagramAuthToken(platformType, code, countryCode) {
    return this.get(
      `generic-oauth/set-auth-code?platformType=${platformType}&code=${code}&countryCode=${countryCode}`
    );
  }

  static getBroadcastsHistory() {
    const filterByDaysAgo = 360;
    return this.get(`broadcast-history/get?filterByDaysAgo=${filterByDaysAgo}`);
  }

  static getBroadcastInfoByRoomId(roomId) {
    return this.get(`broadcast-history/get-room-details?roomId=${roomId}`);
  }

  static shareOnIGTV(roomId) {
    return this.post("broadcast-history/share-on-igtv", roomId);
  }

  static deleteRoom(roomId) {
    return this.delete(`broadcast-history/delete-room/${roomId}`);
  }
}

export { LoolaApi };
