import "./StreamDestinationBox.css";

import React from "react";
import StreamDestinationItem from "./StreamDestinationItem";
import PropTypes from "prop-types";
import "../core/css/round-box.css";
import { Spinner } from "../core/Spinner";
import { Constants } from "../core/constants";
import BoxBlock from "../core/BoxBlock";
import config from "../Config";

class StreamDestinationBox extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.onLoad();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPlatformToggleFailed !== this.props.isPlatformToggleFailed) {
      if (nextProps.isPlatformToggleFailed) {
        console.log("error message on platform toggle: ", nextProps.platformToggleErrorMessage);
        this.props.showSystemMessage(
          "error",
          nextProps.platformToggleErrorMessage,
          `${config.helpurl}/connecting-your-channels`
        );
      }
    }
  }

  renderListOfStreamDestinations() {
    return this.props.configuredPlatforms.map((platform, key) => {
      const showSpinner =
        this.props.isFetchingPlatformToggle &&
        this.props.platformTypeBeingFetched === platform.platformType;

      const canToggle = !showSpinner && !this.props.isRunningGetPlatforms && !this.props.isLive;
      const platformUserPublicHandle = platform.getPlatformPublicHandle();

      return (
        <StreamDestinationItem
          key={key}
          isLive={this.props.isLive}
          platformType={platform.platformType}
          platformStatus={platform.status}
          platformUserName={platformUserPublicHandle}
          numberOfFans={this.getNumberOfFans(platform.platformType, this.props.platformStats)}
          togglePlatform={this.props.togglePlatform}
          showSpinner={showSpinner}
          canToggle={canToggle}
          showModal={this.props.showModal}
          sendAction={this.props.sendAction}
        />
      );
    });
  }

  getNumberOfFans(platformType, platformStats) {
    let numberOfFans = 0;
    if (platformStats) {
      const platformExists = platformType in platformStats;
      numberOfFans = platformExists ? platformStats[platformType].viewersCount : 0;
    }
    console.log("getNumberOfFans", numberOfFans);
    return numberOfFans;
  }

  render() {
    if (window.isMobile && this.props.isLive) return null;

    return (
      <BoxBlock title="Stream to" boxType={Constants.BOX_TYPES.STREAM_TO} canCollapse>
        <div className="StreamDestinationBox round-box">
          <div className="StreamDestinationBox_title">
            <Spinner
              isVisible={this.props.isRunningGetPlatforms}
              type={Constants.SPINNER_TYPES.DEFAULT}
              size={Constants.SPINNER_SIZES.XS}
            />
          </div>
          <div className="StreamDestinationBox_destinations">
            {this.renderListOfStreamDestinations()}
          </div>
        </div>
      </BoxBlock>
    );
  }
}

StreamDestinationBox.propTypes = {
  isLive: PropTypes.bool.isRequired,
  togglePlatform: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSystemMessage: PropTypes.func.isRequired,
  hideSystemMessage: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  configuredPlatforms: PropTypes.array.isRequired,
  platformStats: PropTypes.object.isRequired,
  isPlatformToggleFailed: PropTypes.bool,
  platformToggleErrorMessage: PropTypes.string,
  isFetchingPlatformToggle: PropTypes.bool,
  platformTypeBeingFetched: PropTypes.string,
};

export default StreamDestinationBox;
