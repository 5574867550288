import React, { Component } from "react";

export class StreamToIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1">
        <g id="streamto" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M12.0541301,21.9091084 C13.1812924,21.9125812 14.0955206,22.8340147 14.0908916,23.9614973 C14.0897343,24.507875 13.8756429,25.0206828 13.4879639,25.4061568 C13.1002849,25.7904733 12.5853082,26.0011528 12.0402431,25.9999953 C10.9107663,25.9953649 9.9965381,25.0739315 10.0000099,23.9464488 C10.0046389,22.8178085 10.9258105,21.9044781 12.0541301,21.9091084 Z M11.9527209,17.8225115 C15.3966242,17.83983 18.1905083,20.6400251 18.1817979,24.0636885 L18.1774426,25.1818182 L15.9268742,25.1742413 L15.9312294,24.0550292 C15.9432063,21.8653134 14.1608258,20.0739246 11.958165,20.0609357 L10.8181818,20.056606 L10.8268923,17.8181818 L11.9527209,17.8225115 Z M12.0318844,10.0046275 C19.7543876,10.0439617 26.0209136,16.3165989 25.9999475,23.9855978 L25.9952884,25.1818182 L23.5876845,25.1725631 L23.5923436,23.9774996 C23.6074858,17.6238803 18.4183829,12.4294611 12.0214014,12.3959114 L10.8181818,12.3912839 L10.8275001,10 L12.0318844,10.0046275 Z M10.8274305,13.7272727 L12.0228249,13.7318674 C17.6899654,13.7605842 22.286569,18.3644568 22.272696,23.9952412 L22.2680716,25.1818182 L19.8761267,25.1714801 L19.8807511,23.9849032 C19.892312,19.6624549 16.3627771,16.1279947 12.0135762,16.10617 L10.8181818,16.1015753 L10.8274305,13.7272727 Z"
            id="Combined-Shape"
            fill="#33DB6E"
          />
        </g>
      </svg>
    );
  }
}
