import React, { Component } from "react";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import OverlaysFrameContainer from "./OverlaysFrameContainer";

class OverlaysPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.OVERLAYS_PAGE);
  }

  render() {
    return <OverlaysFrameContainer isHidden={false} />;
  }
}

export default OverlaysPage;
