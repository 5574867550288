const ServerErrorMessages = {
  "2001": "Cannot log in to service",
  "2002": "Cannot log in to service: wrong username",
  "2003": "Cannot log in to service: wrong password",
  "2008": "Please use phone number to login (ex. +18008291040)",
  "2041":
    "Please open your app on your mobile device and confirm that you are trying to login, then retry",
  "2042":
    "Please open your app on your mobile device, and follow the instructions to access your account (reset password)",
  "2004": `Your TikTok account isn’t enabled for 3rd party streaming`,
  "2005":
    "Your YouTube channel isn’t yet enable for live streaming. Please follow the instruction to enable it.",
  "2006": "Open the configuration page and reconnect your social channel",
  // "2007": "captcha"
  "2081":
    "It seems that this account isn’t a valid account, please contact the specific social channel.",
  "2082": "It seems that the oauth token isn't mapped to a valid token",
  "2083": "It seems that the oauth token is invalid, Please re-configure your social channel",
  "2009": `Failed to 'Go Live', Please select at least one social channel`,
  "2110": `Failed to 'Go Live', Please verify your accounts and make sure they all enabled for live streaming`,
  "2111": `Failed to 'Go Live', Your YouTube account isn't enabled for live streaming`,
  "2112": `Failed to 'Go Live', Check your Instagram account`,
  "2113": `Failed to 'Go Live' on Tiktok, please try again`,
  "2114": `Failed to 'Go Live', Check your Twitch account`,
  "2115": `Failed to 'Go Live', Check your Trovo account`,
  "2116": `Failed to 'Go Live', Check your LiveMe account`,
  "2117": `Failed to 'Go Live', Check your 17 Live account`,
  "2118": `Failed to 'Go Live', Check your UpLive account`,
  "2120": `Failed to 'Go Live', Please check your audio and video sources`,
  "2130": "Live was ended unexpectedly, Click Go Live to start a new broadcast",
  "2150": "Your Loola account has been blocked, Please contact us",
  "2151": "You've exceeded your free plan limit.",
  "2152": "Please upgrade your plan to enable Custom RTMP destination",
  "2153":
    "Instagram temporary blocked you for live streaming due to detection of copyrighted music",
  "3000": "Failed sharing to IGTV, server error occured.",
  "3001": "Failed sharing to IGTV, already shared ?",
  "3002": "Failed sharing to IGTV, no broadcast found.",
  "3003": "Failed sharing to IGTV, please reconnect your Instagram account.",
  "4001": "Failed to delete video, server error occured.",
  "400": "User doesn't exist in the system",
  "502": "Network Error",
};

export { ServerErrorMessages };
