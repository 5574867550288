import React from "react";
import Button from "../../core/Button";
import PropTypes from "prop-types";
import "./ColorBoxItem.css";

import "../../core/css/round-box.css";
import "./VideoLayoutSettingsTab.css";
import ColorBoxItem from "./ColorBoxItem";
import TextField from "../../core/TextField";
import ColorPickerButton from "./ColorPickerButton";

const backgroundColorSelectors = [
  {
    id: 0, // blue
    name: "blue",
    value: "#03A9F4",
  },
  {
    id: 1, // green
    name: "green",
    value: "#4CAF50",
  },
  {
    id: 2, // red
    name: "red",
    value: "#FF5252",
  },
  {
    id: 3, // purple
    name: "purple",
    value: "#9C27B0",
  },
  {
    id: 4, // orange
    name: "orange",
    value: "#FF5722",
  },
  {
    id: 5, // cyan
    name: "cyan",
    value: "#009688",
  },
  {
    id: 6, // white
    name: "white",
    value: "#FFFFFF",
  },
  {
    id: 7, // black
    name: "black",
    value: "#000000",
  },
];

class VideoLayoutSettingsTab extends React.Component {
  constructor(props) {
    super(props);

    const nextBackgroundColorIndex = this.findColorIndex(props.backgroundColor);
    const nextHeadlineColorIndex = this.findColorIndex(props.headlineColor);

    this.state = {
      broadcastTitle: props.broadcastTitle,
      textOverlay: props.headline,
      backgroundColorIndex: nextBackgroundColorIndex, // background color
      headlineColorIndex: nextHeadlineColorIndex, // text overlay
      backgroundTooltipVisible: false,
      headlineTooltipVisible: false,
      saving: {
        isApply: true,
        isSaved: false,
      },
      isSaving: false,
    };
  }

  componentDidMount = () => {
    // this.updateStateUsingPropsData(this.props.broadcastTitle, this.props.headline, this.props.headlineColor, this.props.backgroundColor);
    this.props.loadUserSettings();

    document.body.addEventListener("click", e => {
      if (
        e.target.className === "VideoLayoutSettingsTab__backgroundColorContainerBox" ||
        e.target.className === "tooltip" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--blue" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--green" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--red" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--purple" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--orange" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--cyan" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--white" ||
        e.target.className === "ColorBoxItem ColorBoxItem__color--black" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--blue" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--green" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--red" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--purple" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--orange" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--cyan" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--white" ||
        e.target.className === "ColorPickerButton ColorBoxItem__color--black"
      ) {
        return null;
      } else if (
        e.target.className !== "tooltip" ||
        this.state.backgroundTooltipVisible ||
        this.state.headlineTooltipVisible
      ) {
        this.setState({
          backgroundTooltipVisible: false,
          headlineTooltipVisible: false,
        });
      }
    });
  };

  findColorIndex(colorValue) {
    const index = backgroundColorSelectors.findIndex(item => item.value === colorValue);
    if (index === -1) return 0;
    return index;
  }

  updateStateUsingPropsData(broadcastTitle, headline, headlineColor, backgroundColor) {
    const nextBackgroundColorIndex = this.findColorIndex(backgroundColor);
    const nextHeadlineColorIndex = this.findColorIndex(headlineColor);

    this.setState({
      broadcastTitle,
      textOverlay: headline,
      backgroundColorIndex: nextBackgroundColorIndex,
      headlineColorIndex: nextHeadlineColorIndex,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.broadcastTitle !== nextProps.broadcastTitle ||
      this.props.headline !== nextProps.headline ||
      this.props.backgroundColor !== nextProps.backgroundColor ||
      this.props.headlineColor !== nextProps.headlineColor
    ) {
      this.updateStateUsingPropsData(
        nextProps.broadcastTitle,
        nextProps.headline,
        nextProps.headlineColor,
        nextProps.backgroundColor
      );
    }

    if (this.props.isLoading === false && nextProps.isLoading && !this.state.isSaving) {
      this.setState({
        isSaving: true,
      });
    } else if (this.props.isLoading === true && !nextProps.isLoading && this.state.isSaving) {
      this.setState({
        isSaving: false,
        saving: {
          isSaved: true,
        },
      });
      this.props.loadUserSettings();
    }
  }

  handleSaveAndApply = () => {
    const videoLayout = {
      broadcastTitle: this.state.broadcastTitle,
      headline: this.state.textOverlay,
      headlineColor: backgroundColorSelectors[this.state.headlineColorIndex].value,
      backgroundColor: backgroundColorSelectors[this.state.backgroundColorIndex].value,
    };

    this.props.saveLayoutToUserSettings(videoLayout);
  };

  goToBackgroundColor = (id, name) => {
    console.log("selecting background color", id, name);
    this.setState({
      backgroundColorIndex: id,
      backgroundTooltipVisible: false,
    });
  };

  goToHeadlineColor = (id, name) => {
    console.log("selecting headline color", id, name);
    this.setState({
      headlineColorIndex: id,
      headlineTooltipVisible: false,
    });
  };

  handleChangeTextOverlay = e => {
    // this.props.headline = e.target.value;

    this.setState({
      textOverlay: e.target.value,
    });
  };

  handleChangeBroadcastTitle = e => {
    // this.props.broadcastTitle = e.target.value;

    this.setState({
      broadcastTitle: e.target.value,
    });
  };

  handleBgTooltipVisible = () => {
    this.setState({
      backgroundTooltipVisible: !this.state.backgroundTooltipVisible,
    });
  };

  handleHeadlineTooltipVisible = () => {
    this.setState({
      headlineTooltipVisible: !this.state.headlineTooltipVisible,
    });
  };

  render() {
    const shouldBeDisabled = !this.props.canEdit;
    const showLoadingState = this.state.isSaving && this.props.isLoading;

    return (
      <div className="VideoLayoutSettingsTab">
        <div className="VideoLayoutSettingsTab__topBox">
          <div>
            <div
              onKeyDown={e => {
                if (e.key === "Tab") {
                  e.preventDefault();
                  document.getElementsByClassName("TextField__inputFormInput")[1].focus();
                }
              }}
            >
              <TextField
                name="BroadcastTitle"
                label="Broadcast Title"
                wrapAddedClassName="VideoLayout"
                value={this.state.broadcastTitle}
                onChange={this.handleChangeBroadcastTitle}
              />
            </div>

            <div className="VideoLayoutSettingsTab__backgroundColorBox">
              <p className="VideoLayoutSettingsTab__backgroundTitle">Background:</p>
              <div className="colorPickerWrapper">
                <ColorPickerButton
                  currentColor={backgroundColorSelectors[this.state.backgroundColorIndex].name}
                  toggleTooltipVisible={this.handleBgTooltipVisible}
                />

                {this.state.backgroundTooltipVisible ? (
                  <div className="tooltip">
                    <div className="VideoLayoutSettingsTab__backgroundColorContainerBox">
                      {backgroundColorSelectors.map(color => (
                        <ColorBoxItem
                          key={`backgroundColor_${color.id}`}
                          label={color.name}
                          color={color.value}
                          goToColor={() => this.goToBackgroundColor(color.id, color.name)}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div style={{ marginLeft: "40px" }}>
            <TextField
              name="TextOverlay"
              label="Text Overlay"
              wrapAddedClassName="VideoLayout"
              value={this.state.textOverlay}
              onChange={this.handleChangeTextOverlay}
            />

            <div className="VideoLayoutSettingsTab__backgroundColorBox">
              <p className="VideoLayoutSettingsTab__backgroundTitle">Text Color:</p>

              <div className="colorPickerWrapper">
                <ColorPickerButton
                  currentColor={backgroundColorSelectors[this.state.headlineColorIndex].name}
                  toggleTooltipVisible={this.handleHeadlineTooltipVisible}
                />

                {this.state.headlineTooltipVisible ? (
                  <div className="tooltip">
                    <div className="VideoLayoutSettingsTab__backgroundColorContainerBox">
                      {backgroundColorSelectors.map(color => (
                        <ColorBoxItem
                          key={`headlineColor_${color.id}`}
                          label={color.name}
                          color={color.value}
                          goToColor={() => this.goToHeadlineColor(color.id, color.name)}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <Button
            onClick={this.handleSaveAndApply}
            className="btnApply"
            disabled={shouldBeDisabled}
            theme="save"
            showLoading={showLoadingState}
          >
            {!this.state.isSaving && this.state.saving.isApply && <span>Apply</span>}
            {!this.state.isSaving && this.state.saving.isSaved && <span>Saved &#10004;</span>}
            {showLoadingState && <span>Saving</span>}
          </Button>
        </div>
      </div>
    );
  }
}

VideoLayoutSettingsTab.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  broadcastTitle: PropTypes.string,
  headline: PropTypes.string,
  backgroundColor: PropTypes.string,
  headlineColor: PropTypes.string,
  loadUserSettings: PropTypes.func.isRequired,
  saveLayoutToUserSettings: PropTypes.func.isRequired,
};

export { VideoLayoutSettingsTab };
