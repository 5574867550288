import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class RequiredAuthenticationRoute extends React.Component {
  render() {
    const { component: Component, currentUser, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props => (currentUser ? <Component {...props} /> : <Redirect to="/" />)}
      />
    );
  }
}

function mapStateToProps(state) {
  const { currentUser } = state.authenticationData;

  return {
    currentUser,
  };
}

export default connect(mapStateToProps)(RequiredAuthenticationRoute);
