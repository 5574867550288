import "../core/css/round-box.css";
import "./LiveActionBar.css";

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ComingSoon from "../core/ComingSoon";

class LiveActionBarItem extends React.Component {
  handleSelection = () => {
    if (this.props.showComingSoon) return null;

    // else ... propagate event
    this.props.onSelectedItem(this.props.text);
  };

  renderContent() {
    if (this.props.showComingSoon) {
      const addedClassNameSuffix = "LiveActionBar";
      return (
        <ComingSoon addedClassNameSuffix={addedClassNameSuffix}>{this.props.children}</ComingSoon>
      );
    }
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }

  render() {
    const { classNameSuffix, elementName } = this.props;

    return (
      <div
        id={`${elementName}Action`}
        className="LiveActionBar__actionWrapper"
        onClick={this.handleSelection}
      >
        <div
          className={cx(
            "LiveActionBar__iconWrapper",
            "LiveActionBar__internalElement",
            `LiveActionBar__internalElement--${classNameSuffix}`
          )}
        >
          {this.renderContent()}
        </div>
        <div className="LiveActionBar__iconText LiveActionBar__internalElement">
          {this.props.text}
        </div>
      </div>
    );
  }
}

LiveActionBarItem.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  showComingSoon: PropTypes.bool,
  onSelectedItem: PropTypes.func,
};

export { LiveActionBarItem };
