import React from "react";
import PlatformCounter from "./PlatformCounter";
import "./PlatformsCountersList.css";
import PropTypes from "prop-types";
import AllChannelTypes from "../common/channelTypes";

class PlatformsCountersList extends React.Component {
  renderPlatformsCountersList() {
    return this.props.platforms.map((platformEntry, key) => (
      <PlatformCounter
        key={key}
        icon={AllChannelTypes.getChannelImageLogo(platformEntry.platformType)}
        views={platformEntry.views.toString()}
        totalFollowers={platformEntry.totalFollowers.toString()}
        newFollowers={platformEntry.newFollowers.toString()}
      />
    ));
  }

  render() {
    return (
      <div className="PlatformsCountersList  BroadcastSummary__inner-rounded-box">
        <div className="PlatformCounterList__titles">
          <div>Broadcast Views: </div>
          <div>Total Followers: </div>
        </div>
        <div className="PlatformCounterList__values">{this.renderPlatformsCountersList()}</div>
      </div>
    );
  }
}

PlatformsCountersList.propTypes = {
  platforms: PropTypes.array,
};

export default PlatformsCountersList;
