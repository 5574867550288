const BroadcastingState = {
  noBroadcast: "no_broadcast",
  initiatingBroadcast: "initiating_broadcast",
  broadcasting: "broadcasting",
  stoppingBroadcast: "stopping_broadcast",
};

// BroadcastingState --> ['no_broadcast', 'initiating_broadcast', 'broadcasting', 'stopping_broadcast']
// RoomState --> ['page_loading', 'preparing_platforms', 'ready_for_live']

export default BroadcastingState;
