import "./actions-styles.css";
import React, { Component } from "react";

class ScreenShareCamIcon extends Component {
  render() {
    return (
      <svg
        onClick={this.props.onClick}
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="50px"
        height="50px"
        viewBox="0 0 96 96"
        style={{
          enableBackground: "new 0 0 96 96",
          backgroundColor: this.props.enabled ? "#1ccd5a" : "",
        }}
      >
        <g id="XMLID_2_">
          <path
            id="XMLID_6_"
            className="st0"
            d="M66.3,61.8c2.5,0,4.6-2.1,4.6-4.6l0-22.9c0-2.5-2.1-4.6-4.6-4.6H29.7c-2.5,0-4.6,2-4.6,4.6v22.9
          c0,2.5,2,4.6,4.6,4.6h-9.2v4.6h55v-4.6H66.3z M50.3,53.7v-5c-6.4,0-10.6,1.9-13.8,6.2c1.3-6.1,4.8-12.2,13.8-13.5v-4.9l9.2,8.5
          L50.3,53.7z"
          />
        </g>
      </svg>
    );
  }
}

export default ScreenShareCamIcon;
