import React, { Component } from "react";

export class LiveChatEmptyStateIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="92px" height="92px" viewBox="0 0 92 92" version="1.1">
        <defs />
        <g id="viewersempty" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle id="Oval-2" fill="#F8F6F6" cx="46" cy="46" r="46" />
          <path
            d="M30.5890009,60.1464558 C30.3497315,60.8245181 30.0632822,61.4857132 29.733023,62.1266676 C28.8500852,63.5941159 27.724508,64.9063857 26.4068412,65.9993817 C25.8036127,66.5222656 25.2340841,67.0788839 24.6982554,67.6692366 C24.2028667,68.249469 24.2938564,68.522718 25.0588446,68.7015106 C26.9561499,69.1130708 28.924225,69.099577 30.8147903,68.6576558 C34.3970915,67.8682698 37.8715529,66.647083 41.1572947,65.0109625 C41.5313638,64.8186762 41.9593528,64.7545807 42.3704917,64.83217 C45.5787238,65.3752945 48.8644657,65.280838 52.0389978,64.5656679 C57.9331133,63.3039997 63.0521306,59.6708002 66.1963328,54.5229241 C68.6193568,50.6839445 69.5191446,46.0724461 68.7103466,41.6026324 C67.8678487,36.2860842 64.8752961,31.5497684 60.4403871,28.5035482 C56.4840168,25.5821454 51.6986287,24.0067469 46.7818108,24 C45.6124237,24.091083 44.4430366,24.091083 43.2736495,24.2698755 C38.2860618,24.8669752 33.6354733,27.0968219 30.0498022,30.6186977 C26.5686008,33.8976856 24.4353961,38.3607524 24.071437,43.1308027 C23.670408,47.6815791 24.9678548,52.2188617 27.711028,55.8689284 C28.523196,56.9045758 29.4229838,57.9402232 30.3194015,58.8847876 C30.6698807,59.2052648 30.7810904,59.7112815 30.5890009,60.1464558 Z"
            id="Fill-1"
            fill="#EBEBEB"
            opacity="0.70069067"
          />
        </g>
      </svg>
    );
  }
}
