/* eslint-disable eqeqeq */

const checkFacebookLoginState = onConnectedEvent => {
  console.log(`FB TEST PAGE - checkLoginState`);
  window.FB.getLoginStatus(response => {
    statusChangeCallback(response, onConnectedEvent);
  });
};

const statusChangeCallback = (response, onConnectedEvent) => {
  console.log(`FB TEST PAGE - status change callback ${response.status}`);
  if (response.status === "connected") {
    // Logged into your app and Facebook.
    // FacebookAuthUtil.testAPI();
    console.log("FacebookAuthUtil.statusChangeCallback: connected.");
    onConnectedEvent(response.status);
  } else if (response.status === "not_authorized") {
    // The person is logged into Facebook, but not your app.
    console.log("FacebookAuthUtil.statusChangeCallback: Please log into this app.");
    onConnectedEvent(response.status);
  } else {
    // The person is not logged into Facebook, so we're not sure if
    // they are logged into this app or not.
    console.log("FacebookAuthUtil.statusChangeCallback: Please log into facebook");
  }
};

export default checkFacebookLoginState;
