import React, { Component } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class DynamicPlansBox extends Component {
  onClick = (planName, buttonName, buttonType, isCurrentPlanSelected, e) => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.CHECKOUT_BUTTON_CLICKED, {
      plan: planName === "" ? "Free" : planName,
      button: buttonName,
    });

    if (isCurrentPlanSelected || buttonType.toLowerCase() === "soon") {
      e.preventDefault();
      return;
    }

    // else - we do change the page...
    const checkoutPageUrl = this.props.buildChargebeeHostedCheckoutPage(planName);
    window.location = checkoutPageUrl;
  };

  checkMiddleBoxTab = i => {
    if (i === 0) {
      return "navigation-box-tabs-first";
    }
    if (i === 1) {
      return "navigation-box-tabs-middle";
    }
  };

  printPriceLine = item => {
    if (item.pricePerMonth.toLowerCase() === "free")
      return <span className="pricing-box-price">{item.pricePerMonth}</span>;

    // else
    return [
      <span key="a" className="pricing-box-price">
        {item.pricePerMonth}
      </span>,
      <span key="b" className="pricing-box-text">
        / month
      </span>,
    ];
  };

  printPricingBox = item => {
    if (item.pricePerMonth.length > 0 || item.pricePerMonth.trim() !== "")
      return (
        <p className="pricing-box-title">
          {item.planDescription}
          <br />
          {this.printPriceLine(item)}
          <br />
          <span style={{ fontSize: "12px" }}>{item.main3}</span>
        </p>
      );

    // else -- empty
    return <p className="pricing-box-title">{item.planDescription}</p>;
  };

  isCurrentPlanSelected = planName => {
    const currentUserPlanType = this.props.currentUserPlanType ?? "";
    return planName.toLowerCase() === currentUserPlanType.toLowerCase();
  };

  // oldFormatButton = item => {
  //
  //   const isCurrentPlanSelected = this.isCurrentPlanSelected(item.planName);
  //   return (
  //     <a
  //       className={cx("pricing-box-checkout", isCurrentPlanSelected ? "disabled" : "")}
  //       href={
  //         item.buttonType.toLowerCase() === "subscribe"
  //           ? `#${item.planDescription.toLowerCase()}`
  //           : `${item.link}`
  //       }
  //       onClick={e =>
  //         this.onClick(item.planName, item.buttonText, item.buttonType, isCurrentPlanSelected, e)
  //       }
  //       data-cb-type={item.buttonType.toLowerCase() === "subscribe" ? "checkout" : undefined}
  //       data-cb-plan-id={item.planName}
  //     >
  //       {this.isCurrentPlanSelected(item.planName) ? "Current Plan" : item.buttonText}
  //     </a>
  //   );
  // }

  newFormatButton = item => {
    const isCurrentPlanSelected = this.isCurrentPlanSelected(item.planName);

    return (
      <a
        className={cx("pricing-box-checkout", isCurrentPlanSelected ? "disabled" : "")}
        href={
          item.buttonType.toLowerCase() === "subscribe"
            ? `#${item.planName.toLowerCase()}`
            : `${item.link}`
        }
        onClick={e =>
          this.onClick(item.planName, item.buttonText, item.buttonType, isCurrentPlanSelected, e)
        }
        data-cb-type={item.buttonType.toLowerCase() === "subscribe" ? "checkout" : undefined}
        data-cb-plan-id={item.planName}
      >
        {this.isCurrentPlanSelected(item.planName) ? "Current Plan" : item.buttonText}
      </a>
    );
  };

  renderButton = item => this.newFormatButton(item);

  render() {
    const { navigationValue } = this.props;

    return (
      <div className="box-navigation-wrapper">
        <div className="navigation-container-box-tabs">
          {navigationValue.map((item, i) => (
            <div className={cx("navigation-box-tabs", this.checkMiddleBoxTab(i))} key={i}>
              <div>
                {this.printPricingBox(item)}
                <p className="text-tabs">{item.text1}</p>
                <p className="text-tabs">{item.text2}</p>
                <p className="text-tabs">{item.text3}</p>
                <p className="text-tabs">{item.text4}</p>
                {item.text5.length > 0 ? <p className="text-tabs">{item.text5}</p> : null}

                {this.renderButton(item)}
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-text"> </div>
      </div>
    );
  }
}

DynamicPlansBox.propTypes = {
  buildChargebeeHostedCheckoutPage: PropTypes.func,
  showYearly: PropTypes.bool,
  navigationValue: PropTypes.array,
  currentUserPlanType: PropTypes.string,
};

export default DynamicPlansBox;
