export const ValidationRegExp = {
  EMPTY: /^$|\\s+/,
  RTMP_URL: /(rtmp|rtmps):\/\/(?:([^\s@]+?)[@])?([^\s:]+)(?:[:]([0-9]+))?(?:(\/[^\s?#]+)([?][^\s#]+)?)?([#]\S*)?/,
};

export const ComponentsNames = {
  RTMP_SERVER: "rtmpServer",
  STREAM_KEY: "streamKey",
  USER_NAME: "userName",
  PASSWORD: "password",
  GENERIC: "generic",
  SECURITY_CODE: "securityCode",
};
