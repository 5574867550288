import React from "react";
import PropTypes from "prop-types";
import SummaryCounter from "./SummaryCounter";
import "./DailyAchievement.css";
import LikeIcon from "../assets/icons/actions/SummaryModalHeart.js";
import { Constants } from "../core/constants";

class DailyAchievement extends React.Component {
  render() {
    const { value } = this.props;

    return (
      <div className="DailyAchievement">
        <div className="DailyAchievement_newRecord">New record!</div>
        <SummaryCounter value={value} title="Likes" addedClassName="daily-achievement">
          <LikeIcon color="red" size={Constants.ICON_SIZES.MEDIUM} />
        </SummaryCounter>
      </div>
    );
  }
}

DailyAchievement.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DailyAchievement;
