const IntercomHelpPages = {
  howToConnect: {
    youtube: "https://support.loola.tv/en/articles/1755887-how-to-connect-your-youtube-account",
    instagram: "https://support.loola.tv/en/articles/3015033-how-to-connect-your-instagram-account",
    tiktok: "https://support.loola.tv/en/articles/1755882-how-to-connect-your-tiktok-account",
    twitch: "https://support.loola.tv/en/articles/3475337-how-to-connect-your-twitch-account",
    facebook: "https://support.loola.tv/en/articles/3402381-how-to-connect-facebook-live",
    facebookrtmp: "https://support.loola.tv/en/articles/3402381-how-to-connect-facebook-live",
    liveme: "https://support.loola.tv/en/articles/1755876-how-to-connect-your-liveme-account",
    uplive: "https://support.loola.tv/en/articles/2370558-how-to-connect-your-uplive-account",
    seventeen: "https://support.loola.tv/en/articles/1952057-how-to-connect-your-seventeen-account",
    customrtmp: "https://support.loola.tv/en/articles/4480178-how-to-set-custom-rtmp-destination",
    twitter:
      "https://support.loola.tv/en/articles/4162295-how-to-change-re-connect-a-connected-account",
  },
  rtmpEncoderSettings: "https://support.loola.tv/en/articles/3284585-rtmp-encoder-settings",
};

export { IntercomHelpPages };
