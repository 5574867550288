import "./SystemMessage.css";
import React, { Component } from "react";

class SystemMessageHelpLink extends Component {
  render() {
    const { helpLink } = this.props;

    if (helpLink === undefined || helpLink === null || helpLink.length === 0) return null;

    return (
      <span>
        &nbsp;(click{" "}
        <a href={helpLink} target="_blank" rel="noopener noreferrer">
          here
        </a>{" "}
        for more details)
      </span>
    );
  }
}

export default SystemMessageHelpLink;
