import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AllChannelTypes from "../../../common/channelTypes";
import connectedIcon from "../../../assets/icons_new/connected.svg";

class SinglePlatformHeaderSection extends Component {
  renderPlatformConfigurationHeader(componentTitle, platformLogo, platformName, platformStatus) {
    return (
      <Fragment>
        <h1 className="SinglePlatformConfigurationPage__title">{componentTitle}</h1>
        <div className="SinglePlatformConfigurationPage__content">
          <div className="SinglePlatformConfigurationPage__platformImage-wrap">
            <img
              src={platformLogo}
              alt={platformName}
              className="SinglePlatformConfigurationPage__platformImage"
            />
            {platformStatus === "authenticated" && (
              <div className="SinglePlatformConfigurationPage__platformImage-connected">
                <img src={connectedIcon} alt="decorator" />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { platformType, platforms } = this.props;
    const platformLogo = AllChannelTypes.getChannelImageLogo(platformType);
    const platformName = AllChannelTypes.getChannelName(platformType);
    const componentTitle = `Configure Your ${platformName} Channel`;

    const platform = platforms.find(currPlatform => currPlatform.platformType === platformType);

    return this.renderPlatformConfigurationHeader(
      componentTitle,
      platformLogo,
      platformName,
      platform.status
    );
  }
}

SinglePlatformHeaderSection.propTypes = {
  platforms: PropTypes.array.isRequired,
  platformType: PropTypes.string,
};

export default SinglePlatformHeaderSection;
