import { combineReducers } from "redux";
import activeFansReducer from "../common/ActiveFans/active-fans-reducer";
import chatMessageReducer from "../common/ChatMessages/chat-messages-reducer";
import authenticationReducer from "../common/Authentication/authenticaion-reducer";
import { routerReducer } from "react-router-redux";
import liveVideoReducer from "../LiveVideo/live-video-reducer";
import modalReducer from "../Modals/modal-reducer";
import facebookReducer from "../Facebook/facebook-reducer";
import liveStatsReducer from "../Platforms/live-stats-reducer";
import platformsReducer from "../Platforms/platforms-reducer";
import platformSettingsReducer from "../Platforms/PlatformSettings/platform-settings-reducer";
import systemMessageReducer from "../core/system-message-reducer";
import runningActionsReducer from "./running_actions_reducer";
import postLiveBroadcastSummaryReducer from "../LiveVideo/post-live-broadcast-summary-reducer";
import userSettingsReducer from "../LiveVideoEditor/EditorTabs/user-settings-reducer";
import userSettingsLiveLimitsReducer from "../common/UserSettingsLiveLimits/user-settings-live-limits-reducer";
import pollsSettingsReducer from "../LiveVideoEditor/EditorTabs/polls-settings-reducer";
import broadcastHistoryReducer from "../BroadcastHistorySelection/broadcast-history-reducer";
import { janusReducers } from "react-redux-janus";

const [mcuReducer, videoRoomReducer] = janusReducers;

const allReducers = combineReducers({
  authenticationData: authenticationReducer,
  activeFansData: activeFansReducer,
  chatMessageData: chatMessageReducer,
  routing: routerReducer,
  liveVideoData: liveVideoReducer,
  platformsData: platformsReducer,
  platformSettingsData: platformSettingsReducer,
  modalData: modalReducer,
  facebookData: facebookReducer,
  broadcastHistoryData: broadcastHistoryReducer,
  liveStatsData: liveStatsReducer,
  postLiveBroadcastSummaryData: postLiveBroadcastSummaryReducer,
  showSystemMessageData: systemMessageReducer,
  runningActions: runningActionsReducer,
  userSettingsData: userSettingsReducer,
  userSettingsLiveLimitsData: userSettingsLiveLimitsReducer,
  pollsSettingsData: pollsSettingsReducer,
  ...mcuReducer,
  ...videoRoomReducer,
});

export default allReducers;
