import "./PollsSettingsTab.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import Questioner from "./Questioner";
import { Spinner } from "../../core/Spinner";
import { Constants } from "../../core/constants";

class PollsSettingsTab extends Component {
  componentDidMount = () => {
    this.props.loadPolls();
  };

  hideAllQuestion = () => {
    const { selectedQuestionId } = this.props;
    if (selectedQuestionId != null) this.props.hidePoll(selectedQuestionId);
  };

  renderPollsData() {
    const { selectedQuestionId } = this.props;
    return this.props.questionsList.map((questioner, index) => {
      const isSelected = questioner.questionId === selectedQuestionId;
      return (
        <Questioner
          key={index}
          questioner={questioner}
          isSelected={isSelected}
          index={index}
          showPoll={this.props.showPoll}
          hidePoll={this.props.hidePoll}
        />
      );
    });
  }

  renderEmptyState() {
    return (
      <div className="sad">
        <img
          src="https://cdn.shopify.com/s/files/1/1061/1924/products/Very_sad_emoji_icon_png_large.png?v=1480481019"
          alt="sad"
        />
        <p>No Q&amp;A activities recently.</p>
      </div>
    );
  }

  renderHideAllSection(hasQuestions) {
    const { selectedQuestionId } = this.props;
    if (!hasQuestions || selectedQuestionId == null) return null;

    // hasQuestions ==> you can hide all;
    return (
      <div className="hide-all-button" onClick={this.hideAllQuestion}>
        Hide
      </div>
    );
  }

  renderLoadingSection() {
    return (
      <div className="PollsSettingsTab">
        <div className="PollsSettingsTab-SpinnerWrapper">
          <Spinner
            type={Constants.SPINNER_TYPES.ACCORDION}
            isVisible
            size={Constants.SPINNER_SIZES.MEDIUM}
          />
          Loading...
        </div>
      </div>
    );
  }

  render() {
    const { isInstagramAuthenticated } = this.props;
    const hasQuestions = this.props.questionsList !== null && this.props.questionsList.length > 0;

    if (this.props.isLoadingQuestions) return this.renderLoadingSection();

    return (
      <div className="PollsSettingsTab-wrapper">
        <div className="PollsSettingsTab">
          {isInstagramAuthenticated ? (
            hasQuestions ? (
              this.renderPollsData()
            ) : (
              this.renderEmptyState()
            )
          ) : (
            <p className="sad">Please go live on Instagram first</p>
          )}
        </div>

        {this.renderHideAllSection(hasQuestions)}
      </div>
    );
  }
}

PollsSettingsTab.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  questionsList: PropTypes.array,
  selectedQuestionId: PropTypes.string,
  loadPolls: PropTypes.func.isRequired,
  showPoll: PropTypes.func.isRequired,
  hidePoll: PropTypes.func.isRequired,
  isLoadingQuestions: PropTypes.bool.isRequired,
  isInstagramAuthenticated: PropTypes.bool.isRequired,
};

export default PollsSettingsTab;
