class LiveStatsModel {
  constructor(liveStatsModel = []) {
    this.platformsStats = this.toJsonObject(liveStatsModel);

    const tempObj = LiveStatsModel.initTotals(this.platformsStats);
    this.sumViewers = tempObj.sumViewers;
    this.sumLikes = tempObj.sumLikes;
  }

  static initTotals(platformStatsObj) {
    let sumViewers = 0;
    let sumLikes = 0;

    if (platformStatsObj == null || Object.values(platformStatsObj).length === 0) {
      return { sumViewers, sumLikes };
    }

    sumViewers = Object.values(platformStatsObj)
      .map(a => a.viewersCount)
      .reduce((a, b) => a + b, 0);
    sumLikes = Object.values(platformStatsObj)
      .map(a => a.totalLikes)
      .reduce((a, b) => a + b, 0);

    return { sumViewers, sumLikes };
  }

  static mergePlatformStats(platformStatsA, platformStatsB) {
    const platformsStatsMergedObject = Object.assign({}, platformStatsA, platformStatsB);

    const liveStats = new LiveStatsModel();
    liveStats.platformsStats = platformsStatsMergedObject;

    const tempObj = LiveStatsModel.initTotals(liveStats.platformsStats);
    liveStats.sumViewers = tempObj.sumViewers;
    liveStats.sumLikes = tempObj.sumLikes;

    return liveStats;
  }

  static calculateLiveStats(platformStatsObject) {
    const liveStats = new LiveStatsModel();
    liveStats.platformsStats = platformStatsObject;

    if (liveStats.platformsStats == null || Object.values(liveStats.platformsStats).length === 0)
      liveStats.totalViews = 0;
    else liveStats.totalViews = Object.values(liveStats.platformsStats).reduce((a, b) => a + b);

    return liveStats;
  }

  toJsonObject(liveStatsModelArr) {
    const obj = {};
    liveStatsModelArr.forEach(item => {
      const platformType = item.platform;
      obj[platformType] = {
        viewersCount: Math.max(0, item.viewersCount),
        totalLikes: Math.max(0, item.totalLikes),
      };
    });
    return obj;
  }
}

export { LiveStatsModel };
