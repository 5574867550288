import "./WelcomePage.css";

import React, { Component } from "react";
import config from "../Config";

import { createStore } from "redux";
import reducer from "../reducers/index";
import SignInOrRegisterContainer from "../common/Authentication/SignInOrRegisterContainer";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

import logo from "../assets/images/welkomePageLogo.png";

console.log(`process-> node env == build type: ${process.env.NODE_ENV}`);
console.log(`config -> REACT APP CONFIG FILE: ${process.env.REACT_APP_CONFIG_FILE}`);
console.log(`config -> api url: ${config.apiurl}`);
console.log(`config -> google oAuth: ${config.googleOAuthClientID}`);

const store = createStore(reducer);
console.log("store->state", store.getState());

class WelcomePage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.STUDIO_CONNECT);
  }

  render() {
    return (
      <div className="WelcomePage">
        <div className="WelcomePage__welcomeMessage">
          <div className="WelcomePage__company">
            {/* Loola TV */}
            <img src={logo} alt="Loola TV" />
          </div>

          <div className="WelcomePage__firstSentence">live streaming platform in your browser</div>

          <div className="WelcomePage__secondSentence">Start by logging in to Loola</div>

          <div>
            <SignInOrRegisterContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomePage;
