import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../core/Button";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class UpgradeVersionButton extends Component {
  onUpgradeButtonClick = isExceeded => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.UPGRADE_VERSION_CLICKED, {
      isExceeded,
      isLive: this.props.isLive,
      isInTrialPeriod: this.props.isInTrialPeriod,
      trialTimeLeftInSeconds: this.props.trialTimeLeftInSeconds,
    });

    this.props.onButtonClicked();
  };

  render() {
    const { isExceeded } = this.props;

    return (
      <Button theme="save" onClick={() => this.onUpgradeButtonClick(isExceeded)}>
        Upgrade
      </Button>
    );
  }
}

UpgradeVersionButton.propTypes = {
  isExceeded: PropTypes.bool,
  isLive: PropTypes.bool,
  isInTrialPeriod: PropTypes.bool,
  trialTimeLeftInSeconds: PropTypes.number,
  trialExceededLimit: PropTypes.bool,
  onButtonClicked: PropTypes.func,
};

export default UpgradeVersionButton;
