import React, { Component } from "react";

class CarouselIndicatorImage extends Component {
  render() {
    return (
      <div
        className={
          this.props.index === this.props.activeIndex
            ? "carousel__indicator-image carousel__indicator-image--active"
            : "carousel__indicator-image"
        }
        onClick={this.props.goToSlide}
        // onMouseMove= {this.props.onClickMause}
        onMouseDown={this.props.goToSlide}
      >
        <div className="panel-img-wrap">
          <img className="panel-foto-img" src={`${this.props.slide.img}`} alt="" />
        </div>
        <p className="panel-info-img">{this.props.slide.body}</p>
      </div>
    );
  }
}

export default CarouselIndicatorImage;
