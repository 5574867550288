import "./UpgradeVersionIndicator.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import Scheduler from "../../core/Scheduler";
import { Constants } from "../../core/constants";
import UpgradeVersionLink from "./UpgradeVersionLink";

class UpgradeVersionIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counterDisplay: "15:00",
      timeLeftInSeconds: 15 * 60,
    };
  }

  componentDidMount() {
    this.props.sendAction(Constants.ACTION_TYPES.USER_SETTINGS_GET_LIVE_LIMIT);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.trialTimeLeftInSeconds && nextProps.trialTimeLeftInSeconds) {
      const { trialTimeLeftInSeconds } = nextProps;
      const timeLeftInSeconds = trialTimeLeftInSeconds;
      this.updateCountDownLiveTimer(timeLeftInSeconds);
    }
  }

  nextTick = () => {
    const duration = this.state.timeLeftInSeconds > 0 ? this.state.timeLeftInSeconds - 1 : 0;
    this.updateCountDownLiveTimer(duration);
  };

  updateCountDownLiveTimer(duration) {
    const timer = duration;
    const minutes = parseInt(timer / 60, 10);
    const seconds = parseInt(timer % 60, 10);

    const minutesString = minutes < 10 ? `0${minutes}` : minutes;
    const secondsString = seconds < 10 ? `0${seconds}` : seconds;

    const textContent = `${minutesString}:${secondsString}`;
    this.setState({ counterDisplay: textContent, timeLeftInSeconds: timer });
  }

  renderQuotaExceededCalloutMessage(isExceeded) {
    if (isExceeded) {
      return (
        <span className="UpgradeVersionIndicator__remainingMessage">
          {" "}
          You've exceeded your free plan quota for this month{" "}
        </span>
      );
    }

    // else
    return (
      <span>
        <span className="UpgradeVersionIndicator__remainingMessage">
          {" "}
          {this.state.counterDisplay}{" "}
        </span>{" "}
        minutes left this month{" "}
      </span>
    );
  }

  renderUpgradeVersionIndicator(isExceeded) {
    return (
      <div className="UpgradeVersionIndicator">
        {this.renderQuotaExceededCalloutMessage(isExceeded)}{" "}
        <UpgradeVersionLink isExceeded={isExceeded} {...this.props} />{" "}
      </div>
    );
  }

  render() {
    const { isInTrialPeriod, trialExceededLimit } = this.props;

    if (!isInTrialPeriod) return null;
    if (trialExceededLimit) return this.renderUpgradeVersionIndicator(true);

    return (
      <Scheduler interval={1000} isRunning={this.props.isLive} action={this.nextTick}>
        {this.renderUpgradeVersionIndicator(false)}
      </Scheduler>
    );
  }
}

UpgradeVersionIndicator.propTypes = {
  isInTrialPeriod: PropTypes.bool.isRequired,
  trialTimeLeftInSeconds: PropTypes.number.isRequired,
  trialExceededLimit: PropTypes.bool.isRequired,
  isLive: PropTypes.bool,
};

export default UpgradeVersionIndicator;
