import { Constants } from "../core/constants";

const initialState = {
  appId: "497118077478095",
  loginState: "unknown", // "connected" "not_authorized"
  currentFacebookProfile: "",
  instagramToken: "",
  instagramCookie: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.FACEBOOK_SET_LOGIN_STATE:
      return Object.assign({}, state, {
        loginState: action.payload.status,
      });

    case Constants.ACTION_TYPES.FACEBOOK_SET_ACCOUNT:
      return Object.assign({}, state, {
        currentFacebookProfile: action.payload.name,
      });

    case Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_COOKIE:
      return Object.assign({}, state, {
        instagramCookie: action.payload.cookie,
      });

    case Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_TOKEN:
      return Object.assign({}, state, {
        instagramToken: action.payload.token,
      });

    case Constants.ACTION_TYPES.FACEBOOK_CLEAR_INSTAGRAM_TOKEN:
    case Constants.ACTION_TYPES.PLATFORM_DISCONNECT:
      if (action.payload.platformType !== "instagram") return state;

      // else ...
      return Object.assign({}, state, {
        instagramToken: "",
      });

    default:
      return state;
  }
}
