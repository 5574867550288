import "./LiveVideo.css";
import "./LiveVideoInformationOverlay.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import VideoTotals from "../VideoRoom/LocalUser/VideoTotals";

class LiveVideoInformationOverlay extends Component {
  render() {
    return (
      <div className="LiveVideoInformationOverlay">
        <VideoTotals totalViewers={this.props.totalViewers} totalLikes={this.props.totalLikes} />
      </div>
    );
  }
}

LiveVideoInformationOverlay.propTypes = {
  isLive: PropTypes.bool,
  totalViewers: PropTypes.number,
  totalLikes: PropTypes.number,
};

export default LiveVideoInformationOverlay;
