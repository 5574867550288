import React, { Component } from "react";
import CarouselIndicator from "./CarouselIndicator";
import CarouselIndicatorImage from "./CarouselIndicatorImage";
import CarouselSlide from "./CarouselSlide";
import "./CarouselBox.css";
import stephanie from "../../../assets/images/stephanie-selector.png";
import olivia from "../../../assets/images/olivia-selector.png";
import rocky from "../../../assets/images/rocky-selector.png";
import anesha from "../../../assets/images/anesha-selector.png";

const selectors = [
  {
    id: 0,
    img: stephanie,
    body: "Stephanie Liu",
  },
  {
    id: 1,
    img: olivia,
    body: "Olivia Thai",
  },
  {
    id: 2,
    img: rocky,
    body: "Rocky G",
  },
  {
    id: 3,
    img: anesha,
    body: "Anesha Collins",
  },
];

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };
  }

  goToSlide = id => {
    this.setState({
      activeIndex: id,
    });
  };

  whellHandler = e => {
    if (this.state.isActiveScroll) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    this.setState({
      isActiveScroll: true,
    });

    setTimeout(() => {
      this.setState({
        isActiveScroll: false,
      });
    }, 300);

    const delta = e.deltaY;

    if (delta > 0) {
      if (this.state.activeIndex === 3) {
        return;
      }
      this.setState({
        activeIndex: this.state.activeIndex + 1,
      });

      e.preventDefault();
      e.stopPropagation();
    }

    if (delta < 0) {
      if (this.state.activeIndex === 0) {
        return;
      }
      this.setState({
        activeIndex: this.state.activeIndex - 1,
      });

      e.preventDefault();
      e.stopPropagation();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    return (
      <div className="container-slider">
        <ul className="panels">
          {this.props.slides.map(slide => (
            <CarouselSlide
              key={slide.id}
              index={slide.id}
              activeIndex={this.state.activeIndex}
              slide={slide}
            />
          ))}
        </ul>

        <div className="selectors-box">
          <p className="selectors-image-header">Testimonials</p>

          <ul className="selectors-image" onWheel={this.whellHandler}>
            {selectors.map(slide => (
              <CarouselIndicatorImage
                key={slide.id}
                index={slide.id}
                activeIndex={this.state.activeIndex}
                slide={slide}
                goToSlide={() => this.goToSlide(slide.id)}
              />
            ))}
          </ul>

          <ul className="selectors">
            {this.props.slides.map(slide => (
              <CarouselIndicator
                key={slide.id}
                index={slide.id}
                activeIndex={this.state.activeIndex}
                slide={slide}
                goToSlide={() => this.goToSlide(slide.id)}
              />
            ))}
          </ul>

          <p className="selector-count">
            {`${this.state.activeIndex + 1}/${this.props.slides.length}`}
          </p>
        </div>
      </div>
    );
  }
}

export default Carousel;
