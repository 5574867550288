import React, { Component } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

class CarouselSlide extends Component {
  render() {
    AOS.init();

    return (
      <div
        className={
          this.props.index === this.props.activeIndex ? "panel carousel__slide--active" : "panel"
        }
        data-aos="fade-left"
      >
        <div className="oval-box">
          <div className="oval-red" />
          <div className="oval-yellow" />
          <div className="oval-green" />
          <img src={`${this.props.slide.img}`} alt="" />
        </div>

        <div className="slider-text">
          <h2 className="panel-header">{this.props.slide.header}</h2>
          <p className="panel-info">{this.props.slide.body}</p>
          <p className="panel-info">{this.props.slide.content}</p>
          <p className="panel-info">{this.props.slide.footer}</p>
        </div>
      </div>
    );
  }
}

export default CarouselSlide;
