class ActiveFanModel {
  constructor(serverModel) {
    this.platform = serverModel.platform;
    this.name = serverModel.fullName;
    this.img = serverModel.imageUrl;
    this.fanPageUrl = serverModel.profilePage;
    this.followers = serverModel.totalFollowers;
    this.giftPoints = serverModel.totalGiftPoints;
    this.comments = serverModel.totalComments;
  }
}

export { ActiveFanModel };
