import "./LiveVideo.css";

import { VideoRoom } from "react-redux-janus";
import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import LocalUserContainer from "../VideoRoom/LocalUser/LocalUserContainer";
import LiveVideoInformationOverlay from "./LiveVideoInformationOverlay";
import { ConfiguredPlatformsModel } from "../models/PlatformModel";
import LiveVideoServerGridOverlay from "./LiveVideoServerGridOverlay";
import LiveVideoBroadcastSummary from "./LiveVideoBroadcastSummary";
import RTMPVideoPlayerLocalUser from "../VideoPlayer/RTMPVideoPlayerLocalUser";
import BroadcastingState from "../models/BroadcastingState";
import LiveVideoDynamicToggle from "./LiveVideoDynamicToggle";
import { Constants } from "../core/constants";

class LiveVideoComp extends Component {
  renderWebRTCVideoRoom(showInstagram, showWatermark) {
    const { audioinput, videoinput, resolution } = this.props.broadcastConfig;
    const mediaSample = {
      audio: {
        deviceId: audioinput,
      },
      video: {
        deviceId: videoinput,
        width: resolution?.value.width,
        height: resolution?.value.height,
      },
    };

    return (
      <React.Fragment>
        <VideoRoom
          janus={this.props.roomConfiguration.janus}
          publishers={this.props.roomConfiguration.publishers}
          user={this.props.roomConfiguration.user}
          room={this.props.roomConfiguration.room}
          // media={this.props.roomConfiguration.media}
          media={mediaSample}
          autoPublish
        >
          <LocalUserContainer />
        </VideoRoom>

        <LiveVideoServerGridOverlay showInstagram={showInstagram} showWatermark={showWatermark} />

        <LiveVideoInformationOverlay
          isLive={this.props.isLive}
          totalViewers={this.props.totalViewers}
          totalLikes={this.props.totalLikes}
        />
      </React.Fragment>
    );
  }

  renderRTMPVideoRoom(showInstagram, showWatermark) {
    const { rtmpConfiguration } = this.props;
    console.log("checking state: ", showInstagram, showWatermark);
    console.log("checking state & rtmp configuration: ", rtmpConfiguration);

    return (
      <React.Fragment>
        <RTMPVideoPlayerLocalUser
          isLive={this.props.isLive}
          rtmpUrl={rtmpConfiguration.rtmpServerUrl}
          hlsUrl={rtmpConfiguration.hlsFilePath}
          streamKey={rtmpConfiguration.streamKey}
          hasValidRTMPStream={rtmpConfiguration.hasValidRTMPStream}
          isValidResolution={rtmpConfiguration.isValidResolution}
          streamHealthData={rtmpConfiguration.streamHealthData}
          resolutionConstraints={rtmpConfiguration.resolutionConstraints}
          sampleRTMP={this.props.sampleRTMP}
          liveStatus={this.props.liveStatus}
          roomId={this.props.roomConfiguration.room.room}
        />
      </React.Fragment>
    );
  }

  renderVideoRoom(showInstagram, showWatermark) {
    const tabs = {
      [Constants.TABS.web]: this.renderWebRTCVideoRoom(showInstagram, showWatermark),
      [Constants.TABS.rtmp]: this.renderRTMPVideoRoom(showInstagram, showWatermark),
    };

    return tabs[this.props.broadcastConfig.activeTab];
  }

  render() {
    if (this.props.roomConfiguration.room === null || this.props.roomConfiguration.user === null)
      return "can't access your camera or get your user session details";

    const roundBoxClassName = window.isMobile ? "" : "round-box";

    const showInstagram = this.props.configuredPlatformsModel.isInstagramAuthenticated();

    const showWatermark = false;
    const { isBroadcastSummaryOpened, closeBroadcastSummary } = this.props;

    console.log(
      "configured -- platforms",
      this.props.configuredPlatformsModel,
      showInstagram,
      showWatermark
    );

    return (
      <div
        className={cx("LiveVideo", roundBoxClassName, isBroadcastSummaryOpened ? "blockPage" : "")}
      >
        {/* not to show if broadcastSummary is opened */}
        {!isBroadcastSummaryOpened && (
          <React.Fragment>
            {this.renderVideoRoom(showInstagram, showWatermark)}
            <LiveVideoDynamicToggle
              isLive={this.props.isLive}
              activeTab={this.props.broadcastConfig.activeTab}
              onTabSwitch={this.props.onTabSwitch}
            />
          </React.Fragment>
        )}
        {isBroadcastSummaryOpened && (
          <LiveVideoBroadcastSummary
            isBroadcastSummaryOpened={isBroadcastSummaryOpened}
            closeBroadcastSummary={closeBroadcastSummary}
          />
        )}
      </div>
    );
  }
}

LiveVideoComp.propTypes = {
  isLive: PropTypes.bool.isRequired,
  liveStatus: PropTypes.oneOf(Object.values(BroadcastingState)).isRequired,
  roomConfiguration: PropTypes.object,
  rtmpConfiguration: PropTypes.object,
  sampleRTMP: PropTypes.func,
  onTabSwitch: PropTypes.func,
  configuredPlatformsModel: PropTypes.instanceOf(ConfiguredPlatformsModel),
  totalViewers: PropTypes.number,
  totalLikes: PropTypes.number,
  isBroadcastSummaryOpened: PropTypes.bool,
  closeBroadcastSummary: PropTypes.func,
  broadcastConfig: PropTypes.object,
};

export default LiveVideoComp;
