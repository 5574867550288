import "./SystemMessage.css";
import { hideSystemMessage, showSystemMessage } from "./system-message-actions";

import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import ErrorMessage from "./SystemMessageError";
import { bindActionCreators } from "redux";
import SystemMessageHelpLink from "./SystemMessageHelpLink";

class SystemMessageComponent extends Component {
  render() {
    const {
      isVisible,
      messageText,
      messageType = "default",
      helpLink,
    } = this.props.showSystemMessageData;

    if (!isVisible) return null;

    // if error type, create specific error component
    let messageContent;
    if (messageType === "error") {
      const { hideSystemMessage: hideMessage } = this.props;
      messageContent = (
        <ErrorMessage
          messageText={messageText}
          helpLink={helpLink}
          hideSystemMessage={hideMessage}
        />
      );
    } else {
      messageContent = (
        <div className={cx("SystemMessage", `SystemMessage--${messageType}`)}>
          {messageText}
          <SystemMessageHelpLink helpLink={helpLink} />
        </div>
      );
    }

    return <React.Fragment>{messageContent}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    showSystemMessageData: state.showSystemMessageData,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hideSystemMessage, showSystemMessage }, dispatch);
}

const SystemMessage = connect(mapStateToProps, matchDispatchToProps)(SystemMessageComponent);

export { SystemMessage };
