import React, { Component } from "react";
import { connect } from "react-redux";
import { sendAction } from "../core/send-action";
import { Constants } from "../core/constants";
import { bindActionCreators } from "redux";
import BroadcastingState from "../models/BroadcastingState";

import RTMPVideoPlayer from "./RTMPVideoPlayer";

class RTMPVideoPlayerLocalUser extends Component {
  componentWillUnmount() {
    console.log("UNMOUNT - RTMPVideoPlayerLocalUser - START");
    const { roomId, liveStatus } = this.props;
    if (
      liveStatus === BroadcastingState.initiatingBroadcast ||
      liveStatus === BroadcastingState.broadcasting
    ) {
      this.endBroadcast(
        "feed.id",
        "feed.plugin.session.getSessionId()",
        "videoRoomLocal.id",
        roomId
      );
    }
  }

  // componentWillReceiveProps(nextProps, nextContext) {}

  componentDidUpdate(prevProps) {
    const { roomId, isLive, liveStatus } = this.props;

    // if (connected) {
    if (
      prevProps.isLive !== this.props.isLive &&
      liveStatus === BroadcastingState.initiatingBroadcast &&
      isLive &&
      this.props.isValidResolution
    ) {
      this.startBroadcast(
        "feed.id",
        "feed.plugin.session.getSessionId()",
        "videoRoomLocal.id",
        roomId
      );
    }

    if (liveStatus === BroadcastingState.stoppingBroadcast) {
      this.endBroadcast(
        "feed.id",
        "feed.plugin.session.getSessionId()",
        "videoRoomLocal.id",
        roomId
      );
    }
    // }
  }

  startBroadcast(feedId, sessionId, handleId, roomId) {
    console.log(`START BROADCAST: ${roomId}->${sessionId}->${handleId}->${feedId}`);
    const broadcastConfig = { type: "rtmp", sessionId, handleId, roomId, feedId };
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_START_BROADCAST, broadcastConfig);
  }

  endBroadcast(feedId, sessionId, handleId, roomId) {
    console.log(`END BROADCAST: ${roomId}->${sessionId}->${handleId}->${feedId}`);
    const broadcastConfig = { type: "rtmp", sessionId, handleId, roomId, feedId };
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_END_BROADCAST, broadcastConfig);
  }

  render() {
    return <RTMPVideoPlayer {...this.props} />;
  }
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(null, matchDispatchToProps)(RTMPVideoPlayerLocalUser);
