import { useEffect } from "react";

export const useEffectOnce = effect => {
  useEffect(effect, []);
};

export function getOffer({ videoDeviceId, audioDeviceId, width, height }) {
  return {
    audio: { deviceId: audioDeviceId },
    video: {
      deviceId: { exact: videoDeviceId },
      width,
      height,
      resizeMode: "crop-and-scale",
    },
    replaceVideo: !!videoDeviceId,
    replaceAudio: !!audioDeviceId,
  };
}

export function createOffer({ room, offer, onSuccess, onError }) {
  room.createOffer({
    media: offer,
    forced: true,
    success: jsep => {
      const publish = { request: "create", audio: true, video: true };
      room.send({
        message: publish,
        jsep,
      });
      if (onSuccess) {
        onSuccess(jsep);
      }
    },
    error: error => {
      console.log("switch-cam: plugin error", error);
      if (onError) {
        onError(error);
      }
    },
  });
}

export function createOfferWithStream({ room, stream, onSuccess, onError, replaceVideo = true }) {
  room.createOffer({
    stream,
    media: {
      replaceVideo,
      replaceAudio: true,
    },
    simulcast: false,
    simulcast2: false,
    success: jsep => {
      const message = { request: "create", audio: true, video: true };
      room.send({
        message,
        jsep,
      });

      if (onSuccess) {
        onSuccess(jsep);
      }
    },
    error: error => {
      console.error("something happened", error);
      if (onError) {
        onError(error);
      }
    },
  });
}

export function getMergedAudioTrack(firstTrack, secondTrack) {
  const localAudio = new MediaStream();
  localAudio.addTrack(firstTrack);
  const systemAudio = new MediaStream();
  systemAudio.addTrack(secondTrack);
  const audioContext = new AudioContext();
  const micAudio = audioContext.createMediaStreamSource(localAudio);
  const desktopAudio = audioContext.createMediaStreamSource(systemAudio);
  const dest = audioContext.createMediaStreamDestination();

  micAudio.connect(dest);
  desktopAudio.connect(dest);
  const finalAudioStream = dest.stream;

  return finalAudioStream.getAudioTracks()[0];
}
