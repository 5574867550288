import { Constants } from "../../core/constants";
import { UserSettingsLiveLimitsModel } from "../../models/UserSettingsLiveLimitsModel";

const initialState = {
  userSettingsLiveLimits: UserSettingsLiveLimitsModel.createEmptyModel(),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.USER_SETTINGS_GET_LIVE_LIMIT:
      const userSettingsLiveLimitsModel = UserSettingsLiveLimitsModel.createFromServerModel(
        action.payload
      );
      return Object.assign({}, state, { userSettingsLiveLimits: userSettingsLiveLimitsModel });

    default:
      return state;
  }
}
