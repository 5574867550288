import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { sendAction } from "../core/send-action";
import { connect } from "react-redux";
import OverlaysFrame from "./OverlaysFrame";
import PropTypes from "prop-types";
import { RoomConfigurationModel } from "../models/RoomConfigurationModel";

class OverlaysFrameContainer extends Component {
  render() {
    const { currentUser } = this.props;
    const { isHidden } = this.props;
    const loolaUserId = currentUser === null ? null : currentUser.email;

    const { roomConfiguration } = this.props;
    const roomId = RoomConfigurationModel.getRoomId(roomConfiguration);

    return <OverlaysFrame loolaUserId={loolaUserId} roomId={roomId} isHidden={isHidden} />;
  }
}

function mapStateToProps(state) {
  return {
    platforms: state.platformsData.configuredPlatformsModel,
    currentUser: state.authenticationData.currentUser,
    roomConfiguration: state.liveVideoData.roomConfiguration,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(OverlaysFrameContainer);

OverlaysFrameContainer.propTypes = {
  isHidden: PropTypes.bool.isRequired,
};
