import React, { Component } from "react";
import FacebookLoginButton from "./FacebookLoginButton";
import FacebookAuthUtil from "./facebook-auth-util";

class FacebookTestPage extends Component {
  constructor(props) {
    super(props);
    this.checkLoginState = this.checkLoginState.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener("FBObjectReady", this.initializeFacebookLogin);
  }

  componentWillUnmount() {
    window.document.removeEventListener("FBObjectReady", this.initializeFacebookLogin);
  }

  // componentDidMount() {
  //   // console.log("FB TEST PAGE - componentDidMount - start");
  //   // if (document.getElementById("facebook-jssdk")) {
  //   //   console.log("FB TEST PAGE - componentDidMount - inside if facebook-jssdk");
  //   //   return;
  //   // }
  //
  //   // this.setFBAsyncInit();
  //   // this.loadSdkAsynchronously();
  //
  //   // let rootElem = document.getElementById("fb-root");
  //   // if (!rootElem) {
  //   //   rootElem = document.createElement("div");
  //   //   rootElem.id = "fb-root";
  //   //   document.body.appendChild(rootElem);
  //   // }
  // }

  // // Here we run a very simple test of the Graph API after login is
  // // successful.  See statusChangeCallback() for when this call is made.
  // testAPI() {
  //   console.log("Welcome! Fetching your information.... ");
  //   window.FB.api("/me", response => {
  //     console.log(`Successful login for: ${response.name}`);
  //     document.getElementById("status").innerHTML = `Thanks for logging in, ${response.name}!`;
  //   });
  // }

  // This is called with the results from from FB.getLoginStatus().
  // statusChangeCallback = response => {
  //   console.log(`FB TEST PAGE - status change callback ${response.status}`);
  //   if (response.status === "connected") {
  //     // Logged into your app and Facebook.
  //     this.testAPI();
  //   } else if (response.status === "not_authorized") {
  //     // The person is logged into Facebook, but not your app.
  //     console.log("statusChangeCallback: Please log into this app.");
  //     // document.getElementById("status").innerHTML = "Please log into this app.";
  //   } else {
  //     // The person is not logged into Facebook, so we're not sure if
  //     // they are logged into this app or not.
  //     console.log("statusChangeCallback: Please log into facebook");
  //     // document.getElementById("status").innerHTML = "Please log into Facebook.";
  //   }
  // };

  /**
   * Init FB object and check Facebook Login status
   */
  initializeFacebookLogin = () => {
    this.FB = window.FB;
    this.checkLoginState();
  };

  checkLoginState = () => {
    FacebookAuthUtil.checkLoginState();
  };

  handleClick() {
    this.checkLoginState();
    // window.FB.login(this.checkLoginState(), {scope: 'public_profile,email'});
    // window.FB.login(this.checkLoginState(), { auth_type: scope: 'public_profile,email'});
  }

  render() {
    return (
      <div>
        <FacebookLoginButton />;
        <input type="button" onClick={this.handleClick} value="check status" />
      </div>
    );
  }
}

export default FacebookTestPage;
