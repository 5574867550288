import "./actions-styles.css";
import React, { Component } from "react";

export class EnhanceEffectIcon extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1">
        <title>Effects</title>
        <desc>Created with Sketch.</desc>
        <defs />
        <g
          id="Effects"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          fillOpacity="0.245442708"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polygon
            id="Page-1"
            stroke="#1CCD5A"
            fill="#1CCD5A"
            points="26.9195684 16 30.6243256 23.2814443 39 24.3663965 33.0411967 29.9439942 34.3310446 38 26.9205493 34.126249 19.5120157 38 20.9597842 29.9439942 15 24.3663965 23.2148112 23.2814443"
          />
        </g>
      </svg>
    );
  }
}
