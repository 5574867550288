import { API_CALL } from "../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../core/loola-api";
import { Constants } from "../core/constants";

const postLiveBroadcastActions = {
  [Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY,
        payload: LoolaApi.getBroadcastSummary(),
      },
    };
  },
};

export { postLiveBroadcastActions };
