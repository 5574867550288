import React, { Component } from "react";
import BoxBlock from "../../core/BoxBlock";
import { Constants } from "../../core/constants";
import DeleteIcon from "../../assets/icons/actions/DeleteIcon";
import BroadcastHistoryLiveEventEntry from "./BroadcastHistoryLiveEventEntry";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";
import { MODAL_TYPE_NOTIFICATION, MODAL_TYPE_CONFIRMATION } from "./../../Modals/ModalTypes";
import "./MyBroadcastsBox.css";

class MyBroadcastsBox extends Component {
  deleteBroadcast = roomId => {
    this.props.showModal(MODAL_TYPE_CONFIRMATION, {
      width: "300px",
      height: "300px",
      confirmText: "Confirm",
      rejectText: "Cancel",
      confirmationContent: this.deleteModalContent(),
      confirmationSubject: "Delete this video ?",
      onConfirm: isConfirmed => {
        if (isConfirmed) {
          this.props.deleteRoom(roomId);
          window.analytics.track(LoolaAnalyticsConstants.EVENTS.DELETE_HISTORY_CLICKED);
        }
      },
    });
  };

  deleteModalContent() {
    return (
      <div className="broadcast-delete-modal-content">
        <DeleteIcon additionalClassName="broadcast-delete-modal-icon" />
        <h3 className="broadcast-delete-modal-text">Delete this video ?</h3>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const { myBroadcastsList } = this.props;
    console.log(myBroadcastsList, "XXXXXXXXXXXX");

    if (
      prevProps.myBroadcastsList !== myBroadcastsList &&
      prevProps.myBroadcastsList.length - 1 === myBroadcastsList.length
    ) {
      this.props.showModal(MODAL_TYPE_NOTIFICATION, {
        title: `The video was deleted.`,
        width: "325px",
        actionText: "Close",
      });
    }
  }

  render() {
    return (
      <BoxBlock
        titleHider={this.props.titleHider}
        title="My Broadcasts"
        boxType={Constants.BOX_TYPES.MY_BROADCASTS}
        expandVertical
        // color="green"
        addedClassName="scrollable"
        isEmpty={this.props.myBroadcastsList.length === 0}
        emptyStateMessage="Empty state message"
      >
        {this.props.myBroadcastsList.map((element, i) => (
          <BroadcastHistoryLiveEventEntry
            key={i}
            roomId={element.roomId}
            title={element.title} // name
            startTime={element.startTime} // date
            numberOfViews={element.numberOfViews} // date
            numberOfChannels={element.numberOfChannels}
            isSelected={this.props.selectedBroadcastId === element.roomId} // this.props.currentBroadcast.title === element.title
            chooseCurrentBroadcast={this.props.fetchBroadcastById}
            videoLink={element.videoLink}
            videoPreviewImage={element.videoPreviewImage}
            deleteBroadcast={this.deleteBroadcast}
          />
        ))}
      </BoxBlock>
    );
  }
}

export default MyBroadcastsBox;
