/* eslint-disable eqeqeq */

class SessionUtil {
  static setSession(name, value) {
    sessionStorage.setItem(name, value);
  }

  static getSession(name) {
    return sessionStorage.getItem(name);
  }
}

export default SessionUtil;
