import React from "react";
import Title from "./Title.js";
import shaniPhoto from "../../assets/icons_new/about/team/shaniPhoto.jpeg";
import avitalPhoto from "../../assets/icons_new/about/team/avitalPhoto.png";
import sagiPhoto from "../../assets/icons_new/about/team/sagiPhoto.jpeg";
import dannyPhoto from "../../assets/icons_new/about/team/dannyPhoto.jpeg";
import gilPhoto from "../../assets/icons_new/about/team/gilPhoto.jpeg";
import yaronPhoto from "../../assets/icons_new/about/team/yaronPhoto.jpeg";
import wuPhoto from "../../assets/icons_new/about/team/wuPhoto.jpeg";
import phillippePhoto from "../../assets/icons_new/about/team/phillippePhoto.jpeg";
import nimrodPhoto from "../../assets/icons_new/about/team/nimrodPhoto.jpeg";
import icon1 from "../../assets/icons_new/about/people-1.svg";
import icon2 from "../../assets/icons_new/about/people-2.svg";
import icon3 from "../../assets/icons_new/about/people-3.svg";
import icon4 from "../../assets/icons_new/about/people-4.svg";
import icon5 from "../../assets/icons_new/about/people-5.svg";
import icon6 from "../../assets/icons_new/about/people-6.svg";
import icon7 from "../../assets/icons_new/about/people-7.svg";
import linkedImageSrc from "../../assets/icons_new/about/linkedin-sm.svg";
import "./Heart.css";

const leadershipTeam = [
  // Leadership
  {
    imgSrc: avitalPhoto,
    name: "Avital Yachin",
    role: "Co-Founder, CEO",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/avitalyachin/",
  },
  {
    imgSrc: shaniPhoto,
    name: "Shani Raba",
    role: "Co-Founder, CTO",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/rabashani/",
  },
  {
    imgSrc: sagiPhoto,
    name: "Sagi Braitner",
    role: "Co-Founder, VP Marketing",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/sagibraitner/",
  },
];

const advisorsAndInvestorsTeam = [
  // Advisors and Investors
  {
    imgSrc: nimrodPhoto,
    name: "Nimrod Cohen",
    role: "Managing Partner, TAU Ventures",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/nimrod-cohen-04b5485/",
  },
  {
    imgSrc: wuPhoto,
    name: "Arther Wu",
    role: "Head of Global Sales, Cheetah Mobile",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/arther-wu-b365358b",
  },
  {
    imgSrc: yaronPhoto,
    name: "Yaron Samid",
    role: "Founder, TechAviv",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/yaronsamid/",
  },
  {
    imgSrc: gilPhoto,
    name: "Gil Eyal",
    role: "Founder & CEO, HYPR",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/gileyal/",
  },
  {
    imgSrc: phillippePhoto,
    name: "Philippe Schwartz",
    role: "Partner, Square Peg Capital",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/philippe-schwartz/",
  },
  {
    imgSrc: dannyPhoto,
    name: "Danny Hadar",
    role: "Managing Director, Fosun",
    socialImageSrc: linkedImageSrc,
    socialLink: "https://www.linkedin.com/in/danny-hadar-8a62b01/",
  },
];

const drawTeamMembers = array =>
  array.map((member, i) => (
    <div className="about__heart-member" key={i}>
      <div className="about__heart-member-foto-wrap">
        <img src={member.imgSrc} className="about__heart-member-foto" alt="member-foto" />
      </div>
      <div className="about__heart-member-info">
        <span className="about__heart-member-name">{member.name}</span>
        <span className="about__heart-member-role">{member.role}</span>
      </div>
      {/* <div className="about__heart-member-social"> */}
      <div>
        <a href={member.socialLink} target="_blank" rel="noopener noreferrer">
          <img
            className="about__heart-member-social-img"
            src={member.socialImageSrc}
            alt="social-icon"
          />
        </a>
      </div>
    </div>
  ));

const Heart = ({ show, title }) => (
  <div className="about__heart">
    {aboutDecorations()}
    {show ? aboutContent(title) : null}
  </div>
);

const aboutDecorations = () => (
  <div className="about__heart-icons-wrap">
    <div className="about__heart-icons about__container">
      <img src={icon1} alt="decorator" className="about__heart-icon-img" />
      <img src={icon2} alt="decorator" className="about__heart-icon-img" />
      <img src={icon3} alt="decorator" className="about__heart-icon-img" />
      <img src={icon4} alt="decorator" className="about__heart-icon-img" />
      <img src={icon5} alt="decorator" className="about__heart-icon-img" />
      <img src={icon6} alt="decorator" className="about__heart-icon-img" />
      <img src={icon7} alt="decorator" className="about__heart-icon-img" />
    </div>
  </div>
);

const aboutContent = title => (
  <div className="about__container">
    <Title {...title} />
    <div className="about__heart-title-leadership">
      <span className="about__heart-title-txt">Leadership</span>
    </div>
    <div className="about__heart-members">{drawTeamMembers(leadershipTeam)}</div>
    <div className="about__heart-title-investors">
      <span className="about__heart-title-txt">Investors and Advisors</span>
    </div>
    <div className="about__heart-members">{drawTeamMembers(advisorsAndInvestorsTeam)}</div>
  </div>
);

export default Heart;
