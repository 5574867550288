import React, { Component } from "react";
import "./MyBroadcastsBox.css";
import "./DeleteLink.css";
import DeleteIcon from "../../assets/icons/actions/DeleteIcon";

class DeleteLink extends Component {
  render() {
    return (
      <button
        className="myBroadcastsList-delete-button DeleteLink left"
        onClick={this.props.onClick}
        data-tip="Delete broadcast from history"
      >
        <DeleteIcon size="medium" />
      </button>
    );
  }
}
export default DeleteLink;
