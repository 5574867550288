import "./LoolaBrowserDetectionMessage.css";

import React, { Component } from "react";
import { LoolaPermanentMessage } from "./LoolaPermanentMessage";
import PropTypes from "prop-types";

class LoolaBrowserDetectionMessage extends Component {
  render() {
    return (
      <LoolaPermanentMessage
        extraClassName="LoolaBrowserDetectionMessage"
        handleLinkClicked={this.props.onDismissClicked}
      >
        <div>
          <div>
            Unsupported browser detected please use either{" "}
            <p className="LoolaBrowserDetectionMessage__messageSection--emphasized">
              Google Chrome
            </p>{" "}
            or <p className="LoolaBrowserDetectionMessage__messageSection--emphasized">Opera</p>.
          </div>
          <p>Loola uses WebRTC technology which has limited support in your current browser.</p>
        </div>
      </LoolaPermanentMessage>
    );
  }
}

LoolaBrowserDetectionMessage.propTypes = {
  onDismissClicked: PropTypes.func,
};

export { LoolaBrowserDetectionMessage };
