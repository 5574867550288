import React from "react";
import {
  PricingPagePath,
  AboutPagePath,
  PublicLandingPagePath,
  StudioConnectPagePath,
} from "../../../PageConstants";
import { NavHashLink as NavLink } from "react-router-hash-link";

class HeaderNavigationBox extends React.Component {
  render() {
    return (
      <ul className={this.props.className}>
        <li>
          <NavLink target="_self" rel="noopener noreferrer" to={StudioConnectPagePath}>
            Studio
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`${PublicLandingPagePath}#preview-container-box`}
            scroll={el => el.scrollIntoView({ behavior: "smooth", block: "start" })}
          >
            Features
          </NavLink>
        </li>
        <li>
          <NavLink
            target="_self"
            rel="noopener noreferrer"
            to={PricingPagePath}
            activeStyle={{
              color: "#f62682",
            }}
          >
            Pricing
          </NavLink>
        </li>
        <li>
          <NavLink
            target="_self"
            rel="noopener noreferrer"
            to={AboutPagePath}
            activeStyle={{
              color: "#f62682",
            }}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://support.loola.tv/">
            FAQ
          </a>
        </li>
      </ul>
    );
  }
}

export default HeaderNavigationBox;
