import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "../reducers";
import logger from "redux-logger";
import promise from "redux-promise";
import thunk from "redux-thunk";
import { loolaApiHandler } from "../redux-middlewares/loola-api-handler";

const enhancers = [];
const middleware = [loolaApiHandler, promise, thunk, logger];

if (process.env.NODE_ENV === "development") {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  allReducers,
  /* preloadedState, */
  composedEnhancers
);

export { store };
