import React, { Component } from "react";
import Button from "../core/Button";

class ConnectButton extends Component {
  render() {
    const { status } = this.props;

    if (status === "authorized") {
      return <div className="Button--save pseudo-button">Connected</div>;
    }
    return (
      <Button theme="save" onClick={this.props.onClick}>
        Connect
      </Button>
    );
  }
}

export default ConnectButton;
