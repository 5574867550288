import React, { Component } from "react";
import PropTypes from "prop-types";
import { LiveActionBarItem } from "../LiveVideoEditor/LiveActionBarItem";
import videojs from "video.js";
import "./VideoHLSWrapper.css";

export default class VideoHLSWrapper extends Component {
  player = {};
  playerId = "my-player";
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: true,
      intervalId: null,
      closeTimeOutId: null,
    };
  }

  componentWillUnmount() {
    console.log("UNMOUNT - VideoHLSWrapper - START", this.player);
    if (this.player) {
      console.log("UNMOUNT - VideoHLSWrapper - inside dispose", this.player);
      this.player.dispose();
    }
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  componentDidMount() {
    // this.scriptLoaded();
    // const player = videojs('my-player');
    this.loadPlayerProperly();

    const intervalId = setInterval(this.healthCheck, 1000);
    this.setState({ intervalId });
  }

  reset = () => {
    console.log("Calling player.reset");
    this.player.src({
      src: this.props.src,
      type: "application/x-mpegURL",
    });
  };

  onPlayerError = (plr, err) => {
    // this.setState({ isLoaded: false });
    // this.player.pause();
    console.log("errror", plr, err);
  };

  onPlayerEnded = () => {
    // this.setState({ isLoaded: false });
    console.log("Awww...over so soon?!");
  };

  healthCheck = () => {
    if (!this.player?.isReady_) return;

    const error = this.player.error();

    if (error) {
      // we can handle error state;
    }

    const readyState = this.player.readyState();

    const isStuck = readyState === 0 && this.player.remainingTime() !== Infinity;
    const noMoreData = this.player.remainingTime() <= 0;

    if (isStuck || noMoreData) {
      this.reset();
    }

    if ([0, 1, 2].includes(readyState)) {
      if (!this.state.closeTimeOutId) {
        const closeTimeOutId = setTimeout(this.props.onStreamEnd, 30000);
        this.setState({ closeTimeOutId });
      }
    } else if (readyState === 4 && this.state.closeTimeOutId) {
      clearTimeout(this.state.closeTimeOutId);
      this.setState({ closeTimeOutId: null });
    }
  };

  loadPlayerProperly = () => {
    // const videoPlayerSelector = "#playerId";
    // const playerElement = document.querySelector(videoPlayerSelector);
    // if (playerElement) {
    //   playerElement.addEventListener("error", event => {
    //     console.log("Video Player Error: listener: ", event);
    //   });
    // }

    try {
      // const playerOptions = {errorDisplay: false};
      this.player = videojs(this.playerId, {}, function onPlayerReady() {
        videojs.log("Your player is ready!");
        // In this context, `this` is the player that was created by Video.js.
        const playPromise = this.play();
        if (playPromise !== undefined) {
          videojs.log("Your player - playPromise", playPromise);
          playPromise
            .then(() => videojs.log("Your player is played - inside promise!"))
            .catch(err => videojs.log.error(err));
        }
        videojs.log("Your player is being played (async)!");
      });
      // How about an event listener?
      videojs.log("YourPlayer is adding events", this.player);
      this.player.on("error", this.onPlayerError);
      this.player.on("ended", this.onPlayerEnded);

      this.player.src({
        src: this.props.src,
        type: "application/x-mpegURL",
      });
    } catch (e) {
      videojs.log.error("cannot load videojs", e);
    }
  };

  // <video-js autoplay muted id='my-video'
  //           className='video-js vjs-default-skin vjs-big-play-centered vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v7 vjs-user-active'
  //           controls width='640' height='480' data-setup='{"techOrder":["html5"], "fluid": true}'>
  renderHLSViewer = () => (
    <video
      id={this.playerId}
      className="VideoJsWrapper-size video-js vjs-default-skin vjs-big-play-centered vjs-paused vjs-controls-enabled vjs-touch-enabled vjs-workinghover vjs-v7 vjs-user-active"
      controls
      muted
      autoPlay
      preload="auto"
      data-setup='{"techOrder":["html5"], "errorDisplay": false}'
    />
  );

  renderFlashViewer = () => (
    <div id="VideoHLSWrapper" className="VideoHLSWrapper">
      Flash is not supported anymore...
    </div>
  );

  handleRefresh = () => {
    this.loadPlayerProperly();
  };

  renderStillLoadingMessage = () => {
    if (this.state.isLoaded) return;

    // else
    return (
      <div className="VideoHLSWrapper__message">
        Still Loading? <br />
        Make sure your 3rd party app (e.g. ManyCam, OBS, etc.) is running and streaming to the right
        RTMP url. <br />
        Hit refresh when ready.... <br />
        <input type="button" onClick={this.handleRefresh} value="refresh" />
      </div>
    );
  };

  render() {
    console.log(`is loaded: ${this.state.isLoaded}`);
    return (
      <div id="VideoHLSWrapper" className="VideoHLSWrapper">
        {this.renderHLSViewer()}
      </div>
    );
  }
}

VideoHLSWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  isHLS: PropTypes.bool.isRequired,
};

export { LiveActionBarItem };
