import { withRouter } from "react-router";
import {
  getGoogleAuthenticatedUser,
  MoveToMainVideoRoomPage,
  getUserGeoIP,
} from "./action-sign-in";
import { SignInStatusEnum } from "./SignInStatusEnum";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleSignInBox from "./GoogleSignInBox";
import { sendAction } from "../../core/send-action";
import { Constants } from "../../core/constants";

function getSignInStatus(authenticationState) {
  console.log("getSignInStatus", authenticationState);
  if (authenticationState.currentUser) return SignInStatusEnum.signedIn;
  else if (authenticationState.isGoogleAuthenticated) return SignInStatusEnum.authenticated;
  return SignInStatusEnum.anonymous;
}

function mapStateToProps(state) {
  console.log("SignInOrRegisterContainer - mapStateToProps!!!", state.authenticationData);
  const authenticationState = state.authenticationData;
  const currentState = getSignInStatus(authenticationState);
  return {
    signInStatus: currentState,
    authenticatedEmail: authenticationState.authenticatedEmail,
    authenticatedUser: authenticationState.authenticatedUser,
    geoLocationCountryCode: authenticationState.geoLocationCountryCode,
    signedInUser: authenticationState.currentUser,
    hasError: authenticationState.hasError,
    isRunningUserSignInOrRegister: state.runningActions[
      Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER
    ].isRunning(),
    isErrorUserSignInOrRegister: state.runningActions[
      Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER
    ].isError(),
    isDoneUserSignInOrRegister: state.runningActions[
      Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER
    ].isDone(),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
      getGoogleAuthenticatedUser,
      MoveToMainVideoRoomPage,
      getUserGeoIP,
    },
    dispatch
  );
}

// promote from component to container
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleSignInBox));
