import { Constants } from "../core/constants";
import { LoolaApi } from "../core/loola-api";
import { API_CALL } from "../redux-middlewares/loola-api-handler";

const broadcastHistoryActions = {
  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_CURRENT_BROADCAST]: obj => {
    return {
      type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_CURRENT_BROADCAST,
      payload: obj,
    };
  },

  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_ALL]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_ALL,
        payload: LoolaApi.getBroadcastsHistory(),
      },
    };
  },

  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_BY_ROOM]: roomId => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_BY_ROOM,
        payload: LoolaApi.getBroadcastInfoByRoomId(roomId),
      },
    };
  },

  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM]: roomId => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM,
        payload: LoolaApi.downloadRoomComments(roomId),
      },
    };
  },

  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_SHARE_ON_IGTV]: roomId => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_SHARE_ON_IGTV,
        payload: LoolaApi.shareOnIGTV(roomId),
      },
    };
  },

  [Constants.ACTION_TYPES.BROADCASTS_HISTORY_DELETE_ROOM]: roomId => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.BROADCASTS_HISTORY_DELETE_ROOM,
        payload: LoolaApi.deleteRoom(roomId),
      },
    };
  },
};

export { broadcastHistoryActions };
