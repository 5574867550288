const LoolaAnalyticsConstants = {
  PAGES: {
    LANDING_PAGE: "landing-page",
    ABOUT_US_PAGE: "about-us-page",
    PRIVACY_PAGE: "privacy-policy-page",
    TERMS_PAGE: "terms-of-service-page",
    PRICING_PAGE: "pricing-page",
    STUDIO_CONNECT: "studio-connect",
    MAIN_VIDEO_ROOM: "main-video-room",
    CONFIGURATION_PAGE: "configuration-page",
    THANK_YOU_PAGE: "thank-you-page",
    BROADCAST_ANALYTICS_PAGE: "broadcast-analytics-page",
    OVERLAYS_PAGE: "overlays-page",
  },

  EVENTS: {
    USER_PLATFORM_CONFIGURATION_STARTED: "User Platform Configuration Started",
    USER_PLATFORM_CONFIGURATION_FAILED: "User Platform Configuration Failed",
    USER_PLATFORM_CONFIGURATION_COMPLETED: "User Platform Configuration Completed",
    USER_PLATFORM_CONFIGURATION_DISCONNECTED: "User Platform Configuration Disconnected",

    CHROME_EXTENSION_VALIDATION: "CHROME EXTENSION VALIDATION",
    CHROME_EXTENSION_GET_TOKEN_STARTED: "Chrome Extension Get Token Started",
    CHROME_EXTENSION_GET_TOKEN_FAILED: "Chrome Extension Get Token Failed",
    CHROME_EXTENSION_GET_TOKEN_COMPLETED: "Chrome Extension Get Token Completed",

    USER_GET_STARTED_BUTTON_CLICKED: "User Get Started Button Clicked",
    USER_LOGIN_STARTED: "User Login Started",
    USER_LOGIN_SUCCESS: "User Login Success",
    USER_SIGNED_OUT: "User Signed Out",

    GO_LIVE_CLICKED: "Go Live Clicked",
    BROADCAST_STARTED: "Broadcast Started",
    BROADCAST_FAILED: "Broadcast Failed",
    BROADCAST_COMPLETED: "Broadcast Completed",
    BROADCAST_SUMMARY_VIEWED: "Broadcast Summary Viewed",

    LIVE_ACTION_BAR_SAVE_VIDEO_LAYOUT: "Live Action Bar - Video Layout Saved",
    LIVE_ACTION_BAR_SAVE_Q_AND_A: "Live Action Bar - QnA Saved",

    CHECKOUT_BUTTON_CLICKED: "Checkout Button Clicked",
    CHECKOUT_LOADED: "Checkout Opened",
    CHECKOUT_FAILED: "Checkout Failed",
    CHECKOUT_SUCCESS: "Checkout Success",

    UPGRADE_VERSION_CLICKED: "Upgrade Version Clicked",

    DOWNLOAD_COMMENTS_CLICKED: "Download Comments Clicked",
    DOWNLOAD_BROADCAST_VIDEO_CLICKED: "Download Broadcast Video Clicked",
    SHARE_ON_IGTV_CLICKED: "Share on IGTV Clicked",
    DELETE_HISTORY_CLICKED: "Delete History Clicked",
    BROADCASTS_HISTORY_SELECTED: "Broadcasts History Selected",
  },
};

export { LoolaAnalyticsConstants };
