import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../Modals/Modal";
import SinglePlatformSettingsPageContainer from "./SinglePlatformSettingsPageContainer";

class PlatformSettingsModal extends Component {
  render() {
    return (
      <Modal onClose={this.props.onClose} width="360px">
        <SinglePlatformSettingsPageContainer {...this.props} />
      </Modal>
    );
  }
}

PlatformSettingsModal.propTypes = {
  onClose: PropTypes.func,
};

export default PlatformSettingsModal;
