import { createSelector } from "reselect";

const videoRoomFeedsSelector = state => state.videoRoom.feeds;
const videoRoomErrorsSelector = state => state.videoRoom.error;
const videoRoomAudioSelector = state => state.videoRoom.audioDisabled;
const connectedSelector = state => state.mcu.connected;
// const janusConfigSelector = state => state.janusConfig;
const roomConfigurationSelector = state => state.liveVideoData.roomConfiguration;
const goLiveSelector = state => state.liveVideoData.goLive;
const liveStatusSelector = state => state.liveVideoData.liveStatus;

export function getFeed(feeds) {
  const result = feeds.filter(feed => !feed.remote);
  return result[0] ? result[0] : null;
}

function getVideoAndAudioErrors(videoRoomErrors, audioDisabled) {
  console.log("LocalUser -- getVideoAndAudioErrors", videoRoomErrors, audioDisabled);

  // ignoring specific errors
  if (!videoRoomErrors && !audioDisabled) return null;
  if (videoRoomErrors && videoRoomErrors.type === "ROOM_ICE_ERROR") return null;

  // const roomLocalFeedErrorType = "ROOM_LOCAL_FEED_ERROR";
  const notAllowedErrorType = "NotAllowedError";
  let sourceType = "other";
  let errorType = notAllowedErrorType;
  if (videoRoomErrors) {
    sourceType = "video";

    if (videoRoomErrors && videoRoomErrors.message) {
      errorType =
        typeof videoRoomErrors.message === "object"
          ? videoRoomErrors.message.name
          : videoRoomErrors.message;
    }
  }

  if (audioDisabled) {
    sourceType = "audio";
  }

  const errorMessage = {
    sourceType,
    message: `Permission denied, please enable your ${sourceType}`,
    errorType,
  };

  // in this case it is either detailed error description (from if) or absolutely null - so no errors at all...
  return errorMessage;
}

export default createSelector(
  videoRoomFeedsSelector,
  videoRoomErrorsSelector,
  videoRoomAudioSelector,
  connectedSelector,
  // janusConfigSelector,
  roomConfigurationSelector,
  goLiveSelector,
  liveStatusSelector,
  (
    videoRoomFeeds,
    videoRoomVideoErrors,
    videoRoomAudioDisabled,
    connected,
    // janusConfig,
    roomConfiguration,
    goLive,
    liveStatus
  ) => {
    const feed = getFeed(videoRoomFeeds);
    const videoRoomLocal = feed ? feed.plugin : null;
    // const stream = feed ? feed.stream : null;
    const videoRoomErrors = getVideoAndAudioErrors(videoRoomVideoErrors, videoRoomAudioDisabled);

    const roomId = roomConfiguration
      ? roomConfiguration.room
        ? roomConfiguration.room.room
        : null
      : null;
    // const roomId = janusConfig ? (janusConfig.room ? janusConfig.room.room : null) : null;

    return {
      feed,
      videoRoomErrors,
      videoRoomLocal,
      connected,
      roomId,
      goLive,
      liveStatus,
    };
  }
);
