import "../core/css/round-box.css";
import "./LiveActionBar.css";

import React from "react";

import { MusicPlayerIcon } from "../assets/icons/actions/MusicPlayerIcon";
import { GiftIcon } from "../assets/icons/actions/GiftIcon";
import { LayoutIcon } from "../assets/icons/actions/LayoutIcon";
import { EnhanceEffectIcon } from "../assets/icons/actions/EnhanceEffectIcon";
import { BackgroundIcon } from "../assets/icons/actions/BackgroundIcon";
import { PollsIcon } from "../assets/icons/actions/PollsIcon";

import VideoLayoutSettingsTabContainer from "./EditorTabs/VideoLayoutSettingsTabContainer";
import PollsSettingsTabContainer from "./EditorTabs/PollsSettingsTabContainer";

import { TabNavigation } from "./Tabs/TabNavigation";
import BoxBlock from "../core/BoxBlock";
import { Constants } from "../core/constants";
import { LiveActionBarItem } from "./LiveActionBarItem";

const RenderNavigationItem = props => (
  <LiveActionBarItem
    key={props.id}
    text={props.text}
    elementName={props.text.toLowerCase()}
    classNameSuffix={props.text.toLowerCase()}
    onSelectedItem={props.onTabPaneSelected}
    showComingSoon={props.showComingSoon}
  >
    {props.menuIcon()}
  </LiveActionBarItem>
);

class LiveActionBar extends React.Component {
  actionBars = [
    {
      id: "music",
      text: "Music",
      tabTitle: "Music",
      menuIcon: () => <MusicPlayerIcon />,
      showComingSoon: true,
      render: () => <div>Tab 1 Content</div>,
    },
    {
      id: "giveaways",
      text: "Giveaways",
      tabTitle: "Giveaways",
      menuIcon: () => <GiftIcon />,
      showComingSoon: true,
      render: () => <div>Tab 1 Content</div>,
    },
    {
      id: "layout",
      text: "Layout",
      tabTitle: "Layout",
      menuIcon: () => <LayoutIcon />,
      showComingSoon: false,
      render: () => <VideoLayoutSettingsTabContainer />,
    },
    {
      id: "effects",
      text: "Effects",
      tabTitle: "Effects",
      menuIcon: () => <EnhanceEffectIcon />,
      showComingSoon: true,
      render: () => <div>Tab 1 Content</div>,
    },
    {
      id: "background",
      text: "Background",
      tabTitle: "Background",
      menuIcon: () => <BackgroundIcon />,
      showComingSoon: true,
      render: () => <div>Tab 1 Content</div>,
    },
    {
      id: "polls",
      text: "Q&A",
      tabTitle: "Viewers Q&A",
      menuIcon: () => <PollsIcon />,
      showComingSoon: false,
      render: () => <PollsSettingsTabContainer />,
    },
  ];

  render() {
    return (
      <BoxBlock title="Live Action Bar" boxType={Constants.BOX_TYPES.ACTION_BAR} hideTitle>
        <div className="round-box LiveActionBar">
          <TabNavigation
            panes={this.actionBars}
            renderNavigationItem={item => <RenderNavigationItem {...item} />}
          />
        </div>
      </BoxBlock>
    );
  }
}

export default LiveActionBar;
