import React from "react";
import qs from "query-string";
import { Redirect } from "react-router-dom";
import { setAuthToken } from "./platform-auth-token-util";

class PeriscopeAuthLandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "started",
    };
  }

  // TODO: Code Review with some FED Expert, this one was written based on this - https://davidwalsh.name/window-postmessage
  componentDidMount() {
    const { search } = this.props.location;
    const queryString = qs.parse(search);
    const { code } = queryString;
    console.log("code", code);

    setAuthToken("periscope", code)
      .then(data => {
        console.log("set auth token finished successfully", data);
        this.setState({ status: "success" });
      })
      .catch(err => {
        this.setState({ status: "failed" });
        console.log("crap, something went wrong", err);
      });
  }

  render() {
    // const urlParams = new URLSearchParams(window.location.search);
    // const hasCodeParam = urlParams.has("code"); // true or false
    // const codeParam = urlParams.get("code"); // twitch token
    // console.log(hasCodeParam);
    // console.log(codeParam);

    // 9130 - missing auth token - if calling and user isn't register!!!
    if (this.state.status === "success") return <Redirect to="/configure-platforms" />;

    return <div>Validating & Authenticating your Periscope Account...</div>;
  }
}

export default PeriscopeAuthLandingPage;
