import "./GoogleSignInBox.css";

import React from "react";
import PropTypes from "prop-types";
import config from "../../Config/index";
import GoogleButton from "../../core/GoogleButton";
import { AuthStatusEnum } from "./AuthStatusEnum";
import { SignInStatusEnum } from "./SignInStatusEnum";
import { Constants } from "../../core/constants";
import { Spinner } from "../../core/Spinner";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class GoogleSignInBox extends React.Component {
  // Communication between two tabs
  // TODO: Code Review with some FED Expert, this one was written based on this - https://davidwalsh.name/window-postmessage
  gotApprovalMessage(message) {
    if (message && message.type === "user-verified") {
      console.log("GOOGLE AUTH PAGE => GOT MESSAGE", message);
      clearInterval(this.state.messageInterval);
      if (message.status === AuthStatusEnum.success) {
        this.props.getGoogleAuthenticatedUser();
      }
    }
  }

  onClick = () => {
    console.log("onclick", this.state.authUrl);
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_LOGIN_STARTED);
    const newWindow = window.open(this.state.authUrl, "_blank");

    // periodical message sender
    const intervalId = setInterval(() => {
      const message = "get-auth-status";
      const timeOfPost = new Date().getTime();
      console.log(`sending message: ${message}  ${timeOfPost}`);
      newWindow.postMessage(message, "*"); // send the message and target URI
    }, 2000);

    // listen to holla back
    window.addEventListener(
      "message",
      event => {
        // if(event.origin !== 'http://scriptandstyle.com') return;
        // console.log("received response:  ", event.data);
        this.gotApprovalMessage(event.data);
      },
      false
    );

    this.setState({ messageInterval: intervalId });
  };

  componentDidMount() {
    this.props.getUserGeoIP();
    // TODO: extract to action-creator
    this.fetchAuthenticationUrl();
  }

  fetchAuthenticationUrl() {
    fetch(`${config.apiurl}/google-auth/get-auth-url`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(data => data.json())
      .then(response => {
        console.log("GOOGLE Authentication URL", response);
        this.setState({ authUrl: response.url });
      })
      .catch(err => {
        console.log("ERROR OCCURRED, probably server is down", err);
      });
  }

  componentDidUpdate(prevProps) {
    console.log("GoogleSignInBox - componentDidUpdate!!!", prevProps, this.props);

    if (
      this.props.signInStatus === SignInStatusEnum.authenticated &&
      !this.props.isRunningUserSignInOrRegister &&
      !this.props.isDoneUserSignInOrRegister
    ) {
      const userDetails = {
        email: this.props.authenticatedUser.email,
        name: this.props.authenticatedUser.name,
        profileImage: this.props.authenticatedUser.profileImage,
        geoLocationCountryCode: this.props.geoLocationCountryCode,
      };

      console.log("GoogleSignInBox - componentDidUpdate - CALLING: USER_SIGN_IN_OR_REGISTER!!!");
      this.props.sendAction(Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER, userDetails);
    }
  }

  renderServerBasedGoogleConnectButton() {
    return (
      <div>
        <div className="GoogleSignInBox__buttonArea">
          <GoogleButton onClick={this.onClick} />
        </div>
        <div className="GoogleSignInBox__googleMessage">
          By signing up with Google I agree to the YouTube&nbsp;
          <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">
            terms of service
          </a>
        </div>
      </div>
    );
  }

  render() {
    const failedToSignInOrRegister = this.props.hasError || this.props.isErrorUserSignInOrRegister;

    return (
      <div className="GoogleSignInBox">
        {this.renderServerBasedGoogleConnectButton()}
        <Spinner
          isVisible={this.props.isRunningUserSignInOrRegister}
          type={Constants.SPINNER_TYPES.DEFAULT}
          size={Constants.SPINNER_SIZES.SMALL}
        />
        {failedToSignInOrRegister && (
          <div id="sign-in-message" className="GoogleSignInBox__errorMessage">
            Sorry! There was an error loading the items
          </div>
        )}
      </div>
    );
  }
}

GoogleSignInBox.propTypes = {
  signInStatus: PropTypes.oneOf([
    SignInStatusEnum.anonymous,
    SignInStatusEnum.authenticated,
    SignInStatusEnum.signedIn,
  ]),
  authenticatedEmail: PropTypes.string,
  authenticatedUser: PropTypes.object,
  geoLocationCountryCode: PropTypes.string,
  signedInUser: PropTypes.object,
  hasError: PropTypes.bool.isRequired,
  isRunningUserSignInOrRegister: PropTypes.bool.isRequired,
  isErrorUserSignInOrRegister: PropTypes.bool.isRequired,
  isDoneUserSignInOrRegister: PropTypes.bool.isRequired,
};

GoogleSignInBox.defaultProps = {
  signInStatus: SignInStatusEnum.anonymous,
  signedInUser: null,
  hasError: false,
  isRunningUserSignInOrRegister: false,
  isErrorUserSignInOrRegister: false,
  isDoneUserSignInOrRegister: false,
};

export default GoogleSignInBox;
