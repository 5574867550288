import { API_CALL } from "../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../core/loola-api";
import { Constants } from "../core/constants";

export const getLiveStats = () => {
  return {
    [API_CALL]: {
      type: Constants.ACTION_TYPES.LIVE_GET_STATS,
      payload: LoolaApi.getLiveStats(),
    },
  };
};
