import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { MainVideoRoomPagePath } from "../PageConstants";

class ForceRedirectWhenLoggedInRoute extends React.Component {
  render() {
    const { component: Component, currentUser, ...rest } = this.props;

    console.log("ForceRedirectWhenLoggedInRoute - currentUser", this.props.currentUser);
    return (
      <Route
        {...rest}
        render={props =>
          currentUser ? <Redirect to={MainVideoRoomPagePath} /> : <Component {...props} />
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const { currentUser } = state.authenticationData;

  return {
    currentUser,
  };
}

export default connect(mapStateToProps)(ForceRedirectWhenLoggedInRoute);
