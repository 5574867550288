import "./ChatMessage.css";

import React from "react";
import PropTypes from "prop-types";

class ChatGiftMessage extends React.PureComponent {
  renderGiftIcon() {
    if (this.props.type === "gift") {
      return (
        <img
          className="ChatMessage__giftImage"
          src={this.props.giftImage}
          alt={this.props.message}
        />
      );
    }
  }

  render() {
    console.log("ChatGiftMessage -- render called");
    return (
      <div className="ChatMessage__messageSection ChatMessage__messageSection--gift">
        <span>just sent {this.props.message}</span>
        <div className="ChatMessage__giftItem">{this.renderGiftIcon()}</div>
      </div>
    );
  }
}

ChatGiftMessage.propTypes = {
  message: PropTypes.string.isRequired,
  giftImage: PropTypes.string,
  type: PropTypes.string,
};

export default ChatGiftMessage;
