import "./LocalUser.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { sendAction } from "../../core/send-action";
import { saveBroadcastConfig } from "../../LiveVideo/live-broadcast-actions";
import { Constants } from "../../core/constants";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import cx from "classnames";
import { Janus } from "react-redux-janus";
import BroadcastSettings from "./BroadcastSettings/BroadcastSettings";

import BroadcastingState from "../../models/BroadcastingState";
import { Spinner } from "../../core/Spinner";

import { WebRTCInputEnum } from "./constants";
import { TUTORIAL_URL } from "../video-room-constants";

class LocalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cam: true,
      hasDevicesEnumerated: false,
      inputDevices: [],
      hasDeviceConfigurationError: false,
      deviceConfigurationError: null,
    };
  }

  getStream(feed) {
    if (feed && feed.stream) {
      return feed.stream;
    }
    return null;
  }

  componentWillUnmount() {
    console.log("UNMOUNT - LocalUser - START");
    const { feed, videoRoomLocal, roomId, liveStatus } = this.props;
    if (
      liveStatus === BroadcastingState.initiatingBroadcast ||
      liveStatus === BroadcastingState.broadcasting
    ) {
      this.endBroadcast(feed.id, feed.plugin.session.getSessionId(), videoRoomLocal.id, roomId);
    }
    if (videoRoomLocal) {
      console.log("UNMOUNT - LocalUser - VIDEO ROOM LOCAL");

      videoRoomLocal.session.destroy();
      videoRoomLocal.ondetached();
      videoRoomLocal.detach();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { feed } = nextProps;
    if (feed) console.log("BITRATE", feed.plugin.getBitrate());
  }

  componentDidMount() {
    if (this.state.inputDevices && this.state.inputDevices.length === 0) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          this.validateStoredInputs(devices);
          this.setState({
            inputDevices: devices,
            hasDevicesEnumerated: true,
          });
        })
        .catch(err => console.log("errrrrrr", err));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      feed,
      videoRoomErrors,
      videoRoomLocal,
      connected,
      roomId,
      goLive,
      liveStatus,
    } = this.props;
    if (videoRoomErrors) {
      console.log(
        "Video Room - Sources Error - no permissions for Camera or Audio ",
        videoRoomErrors
      );
    }

    if (feed && feed.stream && !videoRoomErrors) {
      // if (prevProps.feed !== feed) {
      const video = ReactDOM.findDOMNode(this).querySelector("video");
      // check if video null ???
      // Janus.attachMediaStream(video, feed.stream);
      if (video != null) {
        console.log("VIDEO STREAM WAS ATTACHED", feed.stream);
        Janus.attachMediaStream(video, feed.stream);
      }
      // }

      console.log(`did update --> local-user feed: ${feed}`);
      console.log(`did update --> local-user videoRoomLocal: ${videoRoomLocal}`);
      console.log(`did update --> local-user connected: ${connected}`);
      console.log(`did update --> local-user roomId: ${roomId}`);
      console.log(`did update --> local-user goLive: ${goLive}`);
      console.log(`did update --> local-user liveStatus: ${liveStatus}`);

      console.log("did update", prevProps, this.props);

      if (connected) {
        if (
          prevProps.goLive !== this.props.goLive &&
          liveStatus === BroadcastingState.initiatingBroadcast &&
          goLive
        ) {
          this.startBroadcast(
            feed.id,
            feed.plugin.session.getSessionId(),
            videoRoomLocal.id,
            roomId
          );
        }

        if (liveStatus === BroadcastingState.stoppingBroadcast) {
          this.endBroadcast(feed.id, feed.plugin.session.getSessionId(), videoRoomLocal.id, roomId);
        }
      }
    }
  }

  validateStoredInputs(devices) {
    const audioinput = devices.find(
      device =>
        device.kind === WebRTCInputEnum.audioInput && device.deviceId === this.props.audioinput
    )
      ? this.props.audioinput
      : null;
    const videoinput = devices.find(
      device =>
        device.kind === WebRTCInputEnum.videoInput && device.deviceId === this.props.videoinput
    )
      ? this.props.videoinput
      : null;

    this.props.saveBroadcastConfig({ audioinput, videoinput });
  }

  startBroadcast(feedId, sessionId, handleId, roomId) {
    console.log(`START BROADCAST: ${roomId}->${sessionId}->${handleId}->${feedId}`);
    const broadcastConfig = { sessionId, handleId, roomId, feedId };
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_START_BROADCAST, broadcastConfig);
  }

  endBroadcast(feedId, sessionId, handleId, roomId) {
    console.log(`END BROADCAST: ${roomId}->${sessionId}->${handleId}->${feedId}`);
    const broadcastConfig = { sessionId, handleId, roomId, feedId };
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_END_BROADCAST, broadcastConfig);
  }

  notifyOnSuccessfulChange = () => {
    this.setState({
      hasDeviceConfigurationError: false,
      deviceConfigurationError: null,
    });
  };

  showSourceSelectionErrorNotification = (sourceType, extraData) => {
    this.setState({
      hasDeviceConfigurationError: true,
      deviceConfigurationError: { sourceType, extraData },
    });
  };

  tryAgain = () => {
    window.location.reload();
  };

  renderErrorMessageUI(sourceType, errorMessage, showHelpLink) {
    const { feed, videoRoomLocal } = this.props;
    const { inputDevices } = this.state;
    const stream = this.getStream(feed);
    const videoInputs = inputDevices.filter(device => device.kind === WebRTCInputEnum.videoInput);
    const audioInputs = inputDevices.filter(device => device.kind === WebRTCInputEnum.audioInput);

    return (
      <div className="LocalUser-wrap">
        <div className="LocalUser">
          <div className="LocalUser__errorMessageWrapper">
            <BroadcastSettings
              audioInputs={audioInputs}
              videoInputs={videoInputs}
              videoRoomLocal={videoRoomLocal}
              stream={stream}
              notifySourceSelectionError={this.showSourceSelectionErrorNotification}
              notifyOnSuccessfulChange={this.notifyOnSuccessfulChange}
            />
            <span className={`LocalUser__errorMessageText errorMessage--${sourceType}`}>
              {errorMessage}
            </span>

            {showHelpLink && (
              <video
                className="LocalUser__errorMessageTutorialVideo"
                muted
                playsInline
                autoPlay
                loop
                src={TUTORIAL_URL}
              />
              // <a
              //   href="https://support.google.com/chrome/answer/2693767?hl=en"
              //   target="_blank"
              //   rel="noopener noreferrer"
              //   className="LocalUser__errorMessageLink"
              // >
              //   Here’s how to enable it
              // </a>
            )}

            <div className="LocalUser__errorMessageButton-wrap">
              <button className="LocalUser__errorMessageButton" onClick={this.tryAgain}>
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderErrorMessage(sourceType, extraData, defaultMessage = "") {
    console.log("LocalUser -- renderErrorMessage", sourceType, extraData);

    let errorMessage = defaultMessage;
    let hasAccessTutorial;
    switch (sourceType) {
      case "video":
      case "audio":
        if (extraData === "overconstrained") {
          errorMessage = "Invalid Resolution";
          return this.renderErrorMessageUI(sourceType, errorMessage, false);
        }

        if (sourceType === "audio") {
          if (extraData === "emptyVideoOutputs") {
            errorMessage = "Seems you don't have an audio device.";
          } else {
            errorMessage = "Please allow access to your audio device.";
            hasAccessTutorial = true;
          }
        } else if (sourceType === "video") {
          if (extraData === "NotAllowedError") {
            errorMessage = "Please allow access to your camera.";
            hasAccessTutorial = true;
          } else if (extraData === "emptyVideoOutputs") {
            errorMessage = "Looks like you don't have camera.";
          } else if (extraData === "NotReadableError") {
            errorMessage = `Looks like your camera is already used by another program
              If you are using a streaming software like OBS, please switch to RTMP mode`;
          }
        }

        return this.renderErrorMessageUI(sourceType, errorMessage, hasAccessTutorial);
      case "other":
      default:
        errorMessage = "Seems you don’t have a camera or audio.";
        return this.renderErrorMessageUI(sourceType, errorMessage, true);
    }
  }

  renderVideoObject(stream) {
    const regularOrientation = "LocalUser__video--regularOrientation";
    const flipOrientation = "LocalUser__video--flipOrientation";
    const orientation =
      this.props.isVideoFlipped || this.props.isScreenSharing
        ? flipOrientation
        : regularOrientation;
    return (
      <div className="LocalUser__videoWrapper">
        {stream ? (
          <video
            className={cx("LocalUser__video", orientation)}
            muted
            autoPlay
            playsInline
            data-disabled={!stream.active}
          />
        ) : (
          <div className="LocalUser__spinnerWrapper">
            <Spinner isVisible size="large" />
          </div>
        )}
        {!this.state.cam ? <div /> : null}
      </div>
    );
  }

  render() {
    const { feed, videoRoomErrors, videoRoomLocal } = this.props;
    const { inputDevices, hasDevicesEnumerated } = this.state;

    const stream = this.getStream(feed);

    const videoInputs = inputDevices.filter(device => device.kind === WebRTCInputEnum.videoInput);
    const audioInputs = inputDevices.filter(device => device.kind === WebRTCInputEnum.audioInput);

    if (this.state.hasDeviceConfigurationError) {
      const { sourceType, extraData } = this.state.deviceConfigurationError;
      return this.renderErrorMessage(sourceType, extraData);
    }

    if (videoRoomErrors) {
      return this.renderErrorMessage(
        videoRoomErrors.sourceType,
        videoRoomErrors.errorType,
        videoRoomErrors.message
      );
    }

    if (!videoInputs.length && hasDevicesEnumerated) {
      return this.renderErrorMessage("video", "emptyVideoOutputs");
    }

    if (!audioInputs.length && hasDevicesEnumerated) {
      return this.renderErrorMessage("audio", "emptyVideoOutputs");
    }

    return (
      <div className="LocalUser">
        {this.renderVideoObject(stream)}
        {stream && stream.getVideoTracks().length > 0 && (
          <BroadcastSettings
            audioInputs={audioInputs}
            videoInputs={videoInputs}
            videoRoomLocal={videoRoomLocal}
            stream={stream}
            notifySourceSelectionError={this.showSourceSelectionErrorNotification}
            notifyOnSuccessfulChange={this.notifyOnSuccessfulChange}
          />
        )}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction, saveBroadcastConfig }, dispatch);
}

function mapStateToProps(state) {
  const { audioinput, videoinput, isVideoFlipped } = state.liveVideoData.broadcastConfig;
  const { isScreenSharing } = state.liveVideoData;
  return {
    audioinput,
    videoinput,
    isVideoFlipped,
    isScreenSharing,
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(LocalUser);
