import { bindActionCreators } from "redux";
import React from "react";

import { sendAction } from "../../core/send-action";
import { connect } from "react-redux";
import "../../core/css/round-box.css";
import ChatViewerBox from "./ChatViewerBox";
import { Constants } from "../../core/constants";

class ChatViewerBoxContainer extends React.Component {
  getComments = () => {
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_GET_COMMENTS);
  };

  writeNewComment = comment => {
    this.props.sendAction(Constants.ACTION_TYPES.LIVE_WRITE_COMMENT, comment);
  };

  render() {
    const { isMobile, isBroadcastSummaryOpened } = this.props;
    console.log("ChatViewerBoxContainer render called");

    if (isBroadcastSummaryOpened && isMobile) {
      return null;
    }

    return (
      <ChatViewerBox
        interval={2000}
        isRunning={this.props.isBroadcasting}
        action={this.getComments}
        writeComment={this.writeNewComment}
        messages={this.props.comments}
        overlay={this.props.overlay}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    comments: state.chatMessageData.comments,
    errorMessage: state.chatMessageData.errorMessage,
    isBroadcasting: state.liveVideoData.isBroadcasting,
    isBroadcastSummaryOpened: state.postLiveBroadcastSummaryData.isOpened,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ChatViewerBoxContainer);
