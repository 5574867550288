import { loadState } from "../../store/localStorage";
import { Constants } from "../../core/constants";
import { CurrentUserModel } from "../../models/CurrentUserModel";

const persistedState = loadState();

const emptyItem = {
  currentUser: null,
  hasError: false,
  isGoogleAuthenticated: false,
  authenticatedEmail: null,
  authenticatedUser: null,
  geoIP: null,
  geoLocationCountryCode: null,
};

let initialState = emptyItem;
if (persistedState != null) {
  initialState = persistedState.authenticationData;
  if (initialState.currentUser)
    initialState.currentUser = Object.assign(new CurrentUserModel(), initialState.currentUser);
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER:
      console.log("authentication-reducer-USER_SIGN_IN_OR_REGISTER!!!", action.payload);
      const currentUserModel = new CurrentUserModel(action.payload);

      return Object.assign({}, state, {
        currentUser: currentUserModel,
        isGoogleAuthenticated: true,
        hasError: false,
      });

    // this is an important state that tracks error on "room-configuration" which is the first event on the page
    // that might fail due to cookies issues and inconsistent state data
    case Constants.ACTION_TYPES.LIVE_GET_ROOM_CONFIGURATION:
      if (action.error && action.error.code === 400) {
        return Object.assign({}, state, {
          currentUser: null,
          hasError: true,
        });
      }
      return state;

    case "SIGN_IN_FAILED":
      return Object.assign({}, state, {
        currentUser: null,
        hasError: action.hasError,
      });

    case Constants.ACTION_TYPES.USER_SIGN_OUT:
      return Object.assign({}, state, {
        currentUser: null,
        authenticatedEmail: null,
        isGoogleAuthenticated: false,
        hasError: false,
      });

    case "GOOGLE_USER_IS_AUTHENTICATED":
      return Object.assign({}, state, {
        currentUser: null,
        authenticatedEmail: action.payload.email,
        isGoogleAuthenticated: true,
        authenticatedUser: action.payload,
        hasError: false,
      });

    case Constants.ACTION_TYPES.SESSION_STORE_GEOLOCATION:
      return Object.assign({}, state, {
        geoIP: action.payload.geoIP,
        geoLocationCountryCode: action.payload.geoLocation,
      });

    default:
      return state;
  }
}
