import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCoffee,
  faCog,
  faCircle,
  faCamera,
  faCheck,
  faComments,
  faDownload,
  faEye,
  faArrowAltCircleUp,
  faArrowCircleUp,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";

import { faTelegram } from "@fortawesome/free-brands-svg-icons";

library.add(
  faCoffee,
  faCog,
  faCircle,
  faCamera,
  faCheck,
  faComments,
  faDownload,
  faEye,
  faArrowAltCircleUp,
  faArrowCircleUp,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faTelegram
);
