import "./SampleVideoPlayerPage.css";

import React, { Component } from "react";
import ReactHLS from "react-hls";
import VideoJsWrapper from "./VideoJsWrapper";
import TextField from "../core/TextField";
import Button from "../core/Button";

export default class SampleVideoPlayerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userEmail: "",
      loadRTMP: false,
    };
  }

  handleUserEmail = evt => {
    this.setState({ userEmail: evt.target.value });
  };

  handleLoadRTMP = () => {
    this.setState({ loadRTMP: true });
  };

  renderRTMPViewers = () => {
    if (!this.state.loadRTMP) {
      return (
        <div className="SampleVideoPlayerPage__videos">
          Please fill user email in order to be able to see his RTMP{" "}
        </div>
      );
    }

    // else
    // const streamKey = this.state.userEmail;
    const streamKey = "2gA7zX-Z2mSzSa";
    // const videoRTMPSource = `rtmp://52.207.44.73:1935/pull&${streamKey}_raw`;
    const videoRTMPSource = `rtmp://rtmp-dev.loola.tv/pull&${streamKey}`;
    // const videoRTMPSource = `rtmp://52.207.44.73:1935/pull&2gA7zX-Z2mSzSa`;
    return (
      <div className="SampleVideoPlayerPage__videos">
        <div className="SampleVideoPlayerPage__box">
          <ReactHLS url="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" />
        </div>

        <div className="SampleVideoPlayerPage__box">
          <VideoJsWrapper src={videoRTMPSource} />
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="SampleVideoPlayerPage">
        <div className="SampleVideoPlayerPage__title">put your video player here...</div>

        <div className="SampleVideoPlayerPage__inputSection">
          <div>
            <TextField
              value={this.state.userEmail}
              onChange={this.handleUserEmail}
              label="User Loola Email"
              wrapAddedClassName="SinglePlatform"
              name="userEmail"
            />
          </div>
          <Button id="loadRTMP" onClick={this.handleLoadRTMP} theme="save">
            Load RTMP
          </Button>
        </div>

        {this.renderRTMPViewers()}
      </div>
    );
  }
}
