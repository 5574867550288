import React, { Component } from "react";
import cx from "classnames";
import HeaderNavigationBox from "./HeaderNavigationBox";
import logoWhite from "../../../assets/icons/loola_logo_white.svg";
import logoBlack from "../../../assets/icons/loola-logo-symbol.svg";
import { PublicLandingPagePath } from "../../../PageConstants";
import "./LoolaPublicPageHeader.css";
import PropTypes from "prop-types";

class LoolaPublicPageHeader extends Component {
  chooseColor() {
    if (this.props.textColor === "white") return "white";
    return "black";
  }

  getLogo = () => {
    switch (this.props.logoColor) {
      case "white":
        return logoWhite;
      case "black":
      default:
        return logoBlack;
    }
  };

  render() {
    return (
      <header>
        <a target="_self" rel="noopener noreferrer" href={PublicLandingPagePath}>
          <img className="logo" src={this.getLogo()} alt="" />
        </a>

        <HeaderNavigationBox
          className={cx(
            "LandingPage__headerItems",
            `LandingPage__headerItems--${this.chooseColor()}`
          )}
        />

        <div className="burgers">
          <input className="burger-check" id="burger-check" type="checkbox" />
          <label htmlFor="burger-check" className="burger" />
          <nav id="navigation1" className="navigation">
            <HeaderNavigationBox />
          </nav>
        </div>
      </header>
    );
  }
}

LoolaPublicPageHeader.propTypes = {
  textColor: PropTypes.oneOf(["black", "white"]).isRequired,
  logoColor: PropTypes.oneOf(["black", "white"]).isRequired,
};

export default LoolaPublicPageHeader;
