import { Constants } from "../core/constants";
import BroadcastingState from "../models/BroadcastingState";
import { RTMPSettingsModel } from "../models/RTMPSettingsModel";
import config from "../Config";
import { ServerErrorMessages } from "../core/server-error-messages";
import {
  loadBroadcastConfigFromStorage,
  saveBroadcastConfigToStorage,
} from "../store/broadcastLocalStorage";

const rtmpEmptyState = {
  hasValidRTMPStream: false,
  isValidResolution: true,
  serverUrl: "",
  rtmpServerUrl: "",
  hlsFilePath: "",
  streamKey: "",
};

const initialState = {
  liveStatus: BroadcastingState.noBroadcast,
  broadcastConfig: loadBroadcastConfigFromStorage(),
  rtmpConfiguration: rtmpEmptyState,
  hasValidRTMPStream: false,
  goLive: false,
  isScreenSharing: false,
  isBroadcasting: false,
  hasError: false,
  error: null,
  errorMessage: null,
  roomConfiguration: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.LIVE_GET_ROOM_CONFIGURATION:
      if (action.error) {
        return Object.assign({}, state, {
          hasError: true,
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      console.log(
        "NEW ROOM CONFIGURATION --> SHOULD HAPPEN ONCE PER LIVE",
        action.payload.room,
        action.payload.user
      );
      const tempRoomConfiguration = {
        room: action.payload.room,
        user: action.payload.user,
        media: action.payload.media,
        publishers: 6,
        // debug: true,
        janus: {
          // sdpSemantics: "plan-b",
          server: config.janusapi,
          iceServers: action.payload.iceServers,
          iceTransportPolicy: action.payload.iceTransportPolicy,
        },
      };

      return Object.assign({}, state, {
        roomConfiguration: tempRoomConfiguration,
      });

    case Constants.ACTION_TYPES.LIVE_GO_LIVE:
      return Object.assign({}, state, {
        liveStatus: BroadcastingState.initiatingBroadcast,
        goLive: true,
        hasError: false,
      });

    case Constants.ACTION_TYPES.LIVE_START_BROADCAST:
      console.log("*** BROADCASTING - IN REDUCER *** LIVE_START_BROADCAST", action);
      if (action.error) {
        // return failed-broadcast
        return Object.assign({}, state, {
          liveStatus: BroadcastingState.noBroadcast,
          isBroadcasting: false,
          goLive: false,
          hasError: true,
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      return Object.assign({}, state, {
        liveStatus: BroadcastingState.broadcasting,
        isBroadcasting: true,
        hasError: false,
      });

    case Constants.ACTION_TYPES.LIVE_END_LIVE:
      return Object.assign({}, state, {
        liveStatus: BroadcastingState.stoppingBroadcast,
        goLive: false,
      });

    case Constants.ACTION_TYPES.LIVE_END_BROADCAST:
      console.log("*** BROADCASTING - IN REDUCER *** LIVE_END_BROADCAST");
      return Object.assign({}, state, {
        liveStatus: BroadcastingState.noBroadcast,
        isBroadcasting: false,
      });

    case Constants.ACTION_TYPES.LIVE_HEARTBEAT_BROADCAST:
      console.log("*** BROADCASTING - IN REDUCER *** LIVE_HEARTBEAT_BROADCAST");
      if (action.payload.isLive === false) {
        // Error while Live or closed in-strange-way
        console.log("*** BROADCASTING - IN REDUCER *** LIVE_HEARTBEAT_BROADCAST -- DEAD");
        const errorCode = "2130";
        const errorMessageForHeartbeat = ServerErrorMessages[errorCode];
        return Object.assign({}, state, {
          hasError: true,
          errorMessage: errorMessageForHeartbeat,
          error: { code: errorCode, message: errorMessageForHeartbeat },
          liveStatus: BroadcastingState.stoppingBroadcast,
          goLive: false,
        });
      }

      console.log("*** BROADCASTING - IN REDUCER *** LIVE_HEARTBEAT_BROADCAST -- STILL LIVE");
      return state;

    case Constants.ACTION_TYPES.LIVE_TAB_SWITCH:
      saveBroadcastConfigToStorage({ activeTab: action.payload });
      return Object.assign({}, state, {
        broadcastConfig: {
          ...state.broadcastConfig,
          activeTab: action.payload,
        },
      });

    case Constants.ACTION_TYPES.RTMP_LOAD:
    case Constants.ACTION_TYPES.RTMP_HEARTBEAT:
      const rtmpSettingsModel = RTMPSettingsModel.map(action.payload);
      // const isServerHasValidRTMPSession = action.payload.hasRTMPValidSession || false;
      console.log("*** RTMP HEARTBEAT - IN REDUCER *** ", rtmpSettingsModel, action.payload);
      saveBroadcastConfigToStorage({ activeTab: Constants.TABS.rtmp });

      return Object.assign({}, state, {
        broadcastConfig: {
          ...state.broadcastConfig,
          activeTab: Constants.TABS.rtmp,
        },
        rtmpConfiguration: { ...rtmpSettingsModel },
      });
    case Constants.ACTION_TYPES.LIVE_SAVE_BROADCAST_CONFIG:
      return Object.assign({}, state, {
        broadcastConfig: {
          ...state.broadcastConfig,
          ...action.payload,
        },
      });
    case Constants.ACTION_TYPES.LIVE_CHANGE_SCREEN_SHARE_STATUS: {
      return Object.assign({}, state, {
        isScreenSharing: action.payload.status,
      });
    }
    default:
      return state;
  }
}
