import React from "react";
import qs from "query-string";
import config from "../../Config";
import { LoolaApi } from "../../core/loola-api";
import { AuthStatusEnum } from "./AuthStatusEnum";

let authStatus;

class GoogleAuthLandingPage extends React.Component {
  // TODO: Code Review with some FED Expert, this one was written based on this - https://davidwalsh.name/window-postmessage
  componentDidMount() {
    // respond to events
    authStatus = AuthStatusEnum.started;
    window.addEventListener(
      "message",
      event => {
        // if(event.origin !== 'https://davidwalsh.name') return;
        if (window.trackJs) {
          window.trackJs.console.log(`message received ${event.data}`, event);
        }

        console.log(
          `message received:  ${event.data}`,
          event,
          event.origin,
          event.source,
          authStatus
        );

        if (authStatus === AuthStatusEnum.success || authStatus === AuthStatusEnum.failed) {
          if (event.data === "get-auth-status") {
            const eventMessage = {
              type: "user-verified",
              status: authStatus,
            };
            // event.source.postMessage("user-verified", "*");
            event.source.postMessage(eventMessage, "*");
            window.close();
          }
          // if (event.data === "close")
        }
      },
      false
    );

    const { search } = this.props.location;
    const queryString = qs.parse(search);
    const { code } = queryString;
    console.log("code", code);
    this.setAuthToken(code)
      .then(() => {
        console.log("set auth token finished successfully");
        authStatus = AuthStatusEnum.success;
      })
      .catch(() => {
        console.log("crap, something went wrong");
        authStatus = AuthStatusEnum.failed;
      });
  }

  setAuthToken(code) {
    return fetch(`${config.apiurl}/google-auth/set-auth-code?code=${code}`, {
      method: "GET",
      mode: "cors",
      credentials: LoolaApi.apiCredentials(),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(data => data.json())
      .then(response => console.log("post set-auth-code", response));
  }

  render() {
    return <div>Validating & Authenticating your Google Account...</div>;
  }
}

export default GoogleAuthLandingPage;
