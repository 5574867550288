import { API_CALL } from "../../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../../core/loola-api";
import { Constants } from "../../core/constants";

const pollsSettingsActions = {
  [Constants.ACTION_TYPES.POLLS_GET_QUESTIONS]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.POLLS_GET_QUESTIONS,
        payload: LoolaApi.getPollsSettings(),
      },
    };
  },

  [Constants.ACTION_TYPES.POLLS_SHOW_QUESTION]: questionPayload => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.POLLS_SHOW_QUESTION,
        payload: LoolaApi.showPollQuestion(questionPayload),
      },
    };
  },

  [Constants.ACTION_TYPES.POLLS_HIDE_QUESTION]: questionPayload => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.POLLS_HIDE_QUESTION,
        payload: LoolaApi.hidePollQuestion(questionPayload),
      },
    };
  },
};

export { pollsSettingsActions };
