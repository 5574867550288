import "./PresenceIndicator.css";

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import UserBox from "../common/UserBox";
import LiveButton from "./LiveButton";
import { ConfigurePlatformsPagePath } from "../PageConstants";
import { Link } from "react-router-dom";
import BroadcastingState from "../models/BroadcastingState";

class PresenceIndicator extends Component {
  isUserSignedIn() {
    console.log("isUserSignedIn", this.props);
    // this is actually means --> return this.props.currentUser ? true : false;
    console.log(
      "IS USER SIGNED IN -",
      !this.props.isLoading && !this.props.hasError && !!this.props.currentUser
    );
    return !this.props.isLoading && !this.props.hasError && !!this.props.currentUser;
  }

  renderAnonymousIndicator() {
    return [
      <ul key="anonymous-indicator">
        <li
          key="1"
          className="HeaderUserProfile LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text"
        >
          {/* TODO: Add tomorrow ;) */}
          {/* <span className="LoolaAppHeader__headerItemText">Sign In</span> */}
        </li>
      </ul>,
    ];
  }

  renderLiveButton() {
    if (window.location.href.includes("main-video-room")) {
      return (
        <li>
          <LiveButton
            hasAtLeastOnePlatformAuthenticated={this.props.hasAtLeastOnePlatformAuthenticated}
            isRTMP={this.props.isRTMP}
            hasValidRTMPStream={this.props.hasValidRTMPStream}
            isReadyForLive={this.props.isReadyForLive}
            isLive={this.props.isLive}
            liveStatus={this.props.liveStatus}
            openBroadcastSummaryModal={this.props.openBroadcastSummaryModal}
            hasBroadcastSummaryDetails={this.props.hasBroadcastSummaryDetails}
            isLiveBroadcastSessionEnded={this.props.isLiveBroadcastSessionEnded}
            sendAction={this.props.sendAction}
          />
        </li>
      );
    }

    return null;
  }

  renderSignedInIndicator() {
    const { currentUser } = this.props;
    return (
      <ul key="signed-in-indicator" className="PresenceIndicator">
        <li className="PresenceIndicator__item LoolaAppHeader__headerItemText--Text">
          <UserBox
            name={currentUser.name}
            imageSrc={currentUser.profileImage}
            showImageOnly={window.isMobile}
          />
        </li>
        <li className="PresenceIndicator__item LoolaAppHeader__headerItemText--Text">
          <Link to={ConfigurePlatformsPagePath}>
            <FontAwesomeIcon icon={["fa", "cog"]} name="cog" className="PresenceIndicator__cog" />
          </Link>
        </li>
        {this.renderLiveButton()}
      </ul>
    );
  }

  render() {
    console.log("Presence ... ", this.props.currentUser);
    return this.isUserSignedIn() ? this.renderSignedInIndicator() : this.renderAnonymousIndicator();
  }
}

PresenceIndicator.propTypes = {
  currentUser: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  hasAtLeastOnePlatformAuthenticated: PropTypes.bool.isRequired,
  isRTMP: PropTypes.bool.isRequired,
  hasValidRTMPStream: PropTypes.bool.isRequired,
  isReadyForLive: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
  liveStatus: PropTypes.oneOf(Object.values(BroadcastingState)).isRequired,
  moveToSignInOrRegisterPage: PropTypes.func,
  hasBroadcastSummaryDetails: PropTypes.bool,
  isLiveBroadcastSessionEnded: PropTypes.bool,
  openBroadcastSummaryModal: PropTypes.func,
  sendAction: PropTypes.func,
};

export default PresenceIndicator;
