import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ActiveFansBox from "./ActiveFansBox";
import { Constants } from "../../core/constants";
import { sendAction } from "../../core/send-action";

class ActiveFansBoxContainer extends React.Component {
  render() {
    const getActiveFansAction = () =>
      this.props.sendAction(Constants.ACTION_TYPES.LIVE_GET_ACTIVE_FANS);
    return (
      <ActiveFansBox
        interval={10000}
        isRunning={this.props.isBroadcasting}
        action={getActiveFansAction}
        fans={this.props.fans}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    fans: state.activeFansData.fans,
    // errorMessage: state.activeFansData.errorMessage,
    isBroadcasting: state.liveVideoData.isBroadcasting,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(ActiveFansBoxContainer);
