import { Constants } from "../core/constants";
import {
  BroadcastSummaryCountersModel,
  PlatformCountersModel,
  SummaryPageModel,
} from "../models/SummaryPageModel";
import { OPEN_BROADCAST_SUMMARY, CLOSE_BROADCAST_SUMMARY } from "../Modals/ActionTypes";

const initialState = {
  broadcastSummaryCounters: new BroadcastSummaryCountersModel(),
  dailyAchievement: "",
  platformsCounters: [],
  isOpened: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_BROADCAST_SUMMARY: {
      return Object.assign({}, state, {
        isOpened: true,
      });
    }

    case CLOSE_BROADCAST_SUMMARY: {
      return Object.assign({}, state, {
        isOpened: false,
      });
    }

    case Constants.ACTION_TYPES.POST_LIVE_GET_SUMMARY:
      const broadcastSummaryCountersModel = new BroadcastSummaryCountersModel(
        action.payload.views,
        action.payload.maxViewers,
        action.payload.newFollowers,
        action.payload.likes,
        action.payload.duration
      );
      let platformsCountersModel = [];
      if (action.payload.platforms) {
        platformsCountersModel = action.payload.platforms.map(
          platformItem => new PlatformCountersModel(platformItem)
        );
      }
      const summaryPageModel = new SummaryPageModel(
        broadcastSummaryCountersModel,
        platformsCountersModel
      );

      const newState = Object.assign({}, state, {
        broadcastSummaryCounters: broadcastSummaryCountersModel,
        dailyAchievement: summaryPageModel.dailyAchievement,
        platformsCounters: platformsCountersModel,
      });
      return newState;
    default:
      return state;
  }
}
