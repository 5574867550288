import React, { Component } from "react";
import "./ColorBoxItem.css";
import PropTypes from "prop-types";
import cx from "classnames";

class ColorBoxItem extends Component {
  render() {
    // const selectionClassName = this.props.isSelected ? "ColorBoxItem--active" : "";

    return (
      <button
        className={cx(
          "ColorBoxItem",
          `ColorBoxItem__color--${this.props.label}`
          // selectionClassName
        )}
        onClick={this.props.goToColor}
      />
    );
  }
}

ColorBoxItem.propTypes = {
  goToColor: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  // isSelected: PropTypes.bool.isRequired,
};

export default ColorBoxItem;
