import React, { Component } from "react";
import chatImg from "../../../assets/images/chat-2.png";
import questionImg from "../../../assets/images/question.png";
import triangleImg from "../../../assets/images/triangle.png";
import { AboutPagePath } from "../../../PageConstants";

const navigationValue = [
  {
    img: triangleImg,
    text: "The Mind, The Soul & The Heart",
    button: "About Us",
    link: AboutPagePath,
  },
  {
    img: questionImg,
    text: "Any Questions?",
    button: "FAQ",
    link: "https://support.loola.tv/",
  },
  {
    img: chatImg,
    text: "We Love Feedback!",
    button: "Contact Us",
    link: "mailto:sales@loola.tv",
  },
];

class FooterNavigationBox extends Component {
  render() {
    return (
      <div className="navigation-container-box">
        {navigationValue.map((item, i) => (
          <div className="navigation-box" key={i}>
            <div className="navigation-img">
              <img src={item.img} alt="" />
            </div>
            <div>
              <p className="navigation-title">{item.text}</p>

              <a
                target={i === 0 || i === 2 ? "_self" : "_blank"}
                className="navigation-link"
                rel="noreferrer"
                href={item.link}
              >
                {item.button}
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default FooterNavigationBox;
