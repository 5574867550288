import React, { Component } from "react";
import PropTypes from "prop-types";
import config from "../Config";

class OverlaysFrame extends Component {
  render() {
    const { loolaUserId, roomId, isHidden } = this.props;

    const displayValue = isHidden ? "none" : "block";
    const enableAnalytics = !isHidden;
    const baseUrl = config.hostname;
    const { overlaysAppUrl } = config;

    return (
      <iframe
        allow="clipboard-write"
        title="overlays-iframe"
        style={{
          border: "0px",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          display: displayValue,
        }}
        height="100%"
        width="100%"
        src={`${overlaysAppUrl}/?loolaUserId=${loolaUserId}&roomId=${roomId}&enableAnalytics=${enableAnalytics}&baseUrl=${baseUrl}`}
      />
    );
  }
}

export default OverlaysFrame;

OverlaysFrame.propTypes = {
  loolaUserId: PropTypes.string,
  roomId: PropTypes.number,
  loolaUserPlanType: PropTypes.string,
  isHidden: PropTypes.bool,
};
