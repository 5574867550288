import React from "react";

export const BroadcastSettingsMenuItem = props => (
  <div
    onClick={props.onClick}
    className={`LocalUser__broadcastSettings-menu_item ${
      props.selected ? "LocalUser__broadcastSettings-menu_item--selected" : ""
    } ${props.disabled ? "LocalUser__broadcastSettings-menu_item--disabled" : ""}`}
  >
    {props.label}
  </div>
);
