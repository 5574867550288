import React from "react";
import { connect } from "react-redux";
import { LoolaBrowserDetectionMessage } from "./LoolaBrowserDetectionMessage";
import LoolaCountryAndJSDetectionMessage from "./LoolaCountryAndJSDetectionMessage";
import SessionUtil from "../core/session-util";

import {
  BrowserDetectionCookieName,
  LocationDetectionCookieName,
} from "../core/cookie-names-constants";

class LoolaPermanentMessages extends React.Component {
  handleDismissClicked = cookieName => {
    SessionUtil.setSession(cookieName, "dismissed");
  };

  checkIfCookieDismissedAlready = cookieName => {
    const sessionValue = SessionUtil.getSession(cookieName);
    return sessionValue === "dismissed";
  };

  checkIfBrowserSupported = () => {
    const browser = window.getBrowser();
    return window.isSupported(browser);
  };

  // checkIfJSIsEnabled = () => true;

  checkIfCountryCodeIsEnabled = () => {
    if (this.props.geoLocationCountryCode === null) return false;
    return true;
  };

  render() {
    if (window.isMobile) return null;

    if (
      !this.checkIfBrowserSupported() &&
      !this.checkIfCookieDismissedAlready(BrowserDetectionCookieName)
    )
      return (
        <LoolaBrowserDetectionMessage
          onDismissClicked={() => this.handleDismissClicked(BrowserDetectionCookieName)}
        />
      );

    if (
      !this.checkIfCountryCodeIsEnabled() &&
      !this.checkIfCookieDismissedAlready(LocationDetectionCookieName)
    )
      return (
        <LoolaCountryAndJSDetectionMessage
          onDismissClicked={() => this.handleDismissClicked(LocationDetectionCookieName)}
        />
      );

    return null;
  }
}

function mapStateToProps(state) {
  return {
    geoLocationCountryCode: state.authenticationData.geoLocationCountryCode,
  };
}

export default connect(mapStateToProps, null)(LoolaPermanentMessages);
