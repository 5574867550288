import React, { Component } from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";

class BroadcastPreview extends Component {
  renderVideoHtmlSection(broadcastInfo) {
    if (broadcastInfo && broadcastInfo.videoLink) {
      return (
        <Video
          key={broadcastInfo.videoLink}
          autoPlay
          loop
          muted
          controls={["PlayPause", "Seek", "Volume", "Fullscreen"]}
        >
          <source src={broadcastInfo.videoLink} type='video/webm; codecs="vp8"' />
        </Video>
      );
    }

    return (
      <div className="broadcast-missing-preview-text">No video available for this broadcast</div>
    );
  }
  render() {
    const { currentBroadcast } = this.props;
    const { broadcastInfo } = currentBroadcast;

    return (
      <React.Fragment>
        <div className="broadcast-preview-title">broadcast preview</div>
        <div className="broadcast-preview-image-wrapper">
          <div className="video-wrapper">{this.renderVideoHtmlSection(broadcastInfo)}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default BroadcastPreview;
