import { Constants } from "../core/constants";
import { LoolaApi } from "../core/loola-api";
import { API_CALL } from "../redux-middlewares/loola-api-handler";

const platformsActions = {
  [Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS]: userDetails => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS,
        payload: LoolaApi.getConfiguredPlatforms(userDetails),
      },
    };
  },
  [Constants.ACTION_TYPES.PLATFORM_TOGGLE]: platformDetails => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_TOGGLE,
        payload: LoolaApi.platformToggle(platformDetails),
      },
    };
  },
  [Constants.ACTION_TYPES.PLATFORM_IS_FETCHING_PLATFORM_TOGGLE]: platformDetails => {
    return {
      type: Constants.ACTION_TYPES.PLATFORM_IS_FETCHING_PLATFORM_TOGGLE,
      payload: platformDetails,
    };
  },

  [Constants.ACTION_TYPES.PLATFORM_CONFIGURE]: platformDetails => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_CONFIGURE,
        payload: LoolaApi.configurePlatform(platformDetails),
      },
    };
  },

  [Constants.ACTION_TYPES.PLATFORM_DISCONNECT]: platformDetails => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_DISCONNECT,
        payload: LoolaApi.disconnectPlatform(platformDetails),
      },
    };
  },

  [Constants.ACTION_TYPES.RESET_PLATFORM_ERROR]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.RESET_PLATFORM_ERROR,
      },
    };
  },
};

export { platformsActions };
