import "./LiveVideoDynamicToggle.css";

import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Constants } from "../core/constants";

class LiveVideoDynamicToggle extends Component {
  onTabSwitch = tab => {
    this.props.onTabSwitch(tab);
  };

  render() {
    if (window.isMobile) return null;
    if (this.props.isLive) return null;

    const webCamIsActive = this.props.activeTab === Constants.TABS.web ? "active-strip-button" : "";
    const rtmpIsActive = this.props.activeTab === Constants.TABS.rtmp ? "active-strip-button" : "";

    // currently this is going to be rendered only on PC (/web) - as on mobile we are trying to be very lean and clean
    return (
      <div className="LiveVideoDynamicToggle">
        <button
          disabled={!!webCamIsActive}
          onClick={() => this.onTabSwitch(Constants.TABS.web)}
          className={cx("strip-button", "strip-button-0", webCamIsActive)}
        >
          Webcam
        </button>
        <button
          disabled={!!rtmpIsActive}
          onClick={() => this.onTabSwitch(Constants.TABS.rtmp)}
          className={cx("strip-button", "strip-button-1", rtmpIsActive)}
        >
          RTMP
        </button>
      </div>
    );
  }
}

LiveVideoDynamicToggle.propTypes = {
  isLive: PropTypes.bool,
  activeTab: PropTypes.string,
  onTabSwitch: PropTypes.func,
};

export default LiveVideoDynamicToggle;
