import React, { Component } from "react";
import BroadcastHistorySelectionContainer from "./BroadcastHistorySelectionContainer";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

class BroadcastHistoryPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.BROADCAST_ANALYTICS_PAGE);
  }

  render() {
    return <BroadcastHistorySelectionContainer />;
  }
}

export default BroadcastHistoryPage;
