import React, { Component } from "react";
import "./InvalidResolutionErrorBox.css";
import { PricingPagePath } from "../PageConstants";
import PropTypes from "prop-types";

export default class InvalidResolutionErrorBox extends Component {
  convertToKbps = mb => {
    const kbps = mb * 1024;
    return kbps.toFixed(0);
  };

  formatConstraintMessage = () => {
    // return `The free plan is limited to: 1280x720 @ 30 FPS, 2500Kbs`;
    const { plan, width, height, frameRate, kbps } = this.props.resolutionConstraints;
    return `The ${plan} plan is limited to: ${width} x ${height} @ ${frameRate} FPS, ${kbps}Kbs`;
  };

  formatActualResolutionMessage = () => {
    const { height, width, bitRate, frameRate } = this.props.streamHealthData;
    return `${width} x ${height} @ ${frameRate} FPS, ${this.convertToKbps(bitRate)}Kbps`;
  };

  render() {
    return (
      <div className="invalid-resolution-box__wrapper">
        <div className="invalid-resolution-box__error-content">
          <div className="invalid-resolution-box__error">
            <span className="invalid-resolution-box__error-message">
              High resolution/bitrate detected
            </span>
            <span className="invalid-resolution-box__error-message--important">
              {this.formatActualResolutionMessage()}
            </span>
          </div>

          <div className="invalid-resolution-box__error__reason">
            <span className="invalid-resolution-box__reason-text">
              {this.formatConstraintMessage()}
            </span>
          </div>
          <p className="invalid-resolution-box__error__guide">
            Please fix your settings and refresh the page
          </p>
          <a
            href={PricingPagePath}
            target="_blank"
            rel="noopener noreferrer"
            className="invalid-resolution-box__upgrade-plan"
          >
            Upgrade Plan
          </a>
        </div>
      </div>
    );
  }
}

const streamShape = {
  plan: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  bitRate: PropTypes.number.isRequired,
  kbps: PropTypes.number,
  frameRate: PropTypes.number.isRequired,
};

InvalidResolutionErrorBox.propTypes = {
  streamHealthData: PropTypes.objectOf(PropTypes.shape(streamShape)),
  resolutionConstraints: PropTypes.objectOf(PropTypes.shape(streamShape)),
};
