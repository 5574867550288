import "./PlatformIcon.css";

import React from "react";
import PropTypes from "prop-types";
import AllChannelTypes from "../common/channelTypes";

import FacebookLogo from "../assets/icons/channels/facebook.svg";
import InstagramLogo from "../assets/icons/channels/instagram.svg";
import CustomRTMPLogo from "../assets/icons/channels/custom-rtmp.svg";
import TwitchLogo from "../assets/icons/channels/twitch.svg";
import TwitterLogo from "../assets/icons/channels/twitter.svg";
import TrovoLogo from "../assets/icons/channels/trovo.svg";
// import PeriscopeLogo from "../assets/icons/channels/periscope.svg";
import SnapchatLogo from "../assets/icons/channels/snapchat.svg";
import YoutubeLogo from "../assets/icons/channels/youtube.svg";
import SeventeenLogo from "../assets/icons/channels/seventeen.svg";
import TikTokLogo from "../assets/icons/channels/tiktok.svg";
import LivemeLogo from "../assets/icons/channels/liveme.svg";
import UpLiveLogo from "../assets/icons/channels/uplive.svg";

class PlatformIcon extends React.PureComponent {
  render() {
    console.log("PlatformIcon render called");
    return this.renderChannel();
  }

  renderChannel() {
    // TODO: Change to grouping with multiple icons
    // <span className="fa-stack fa-lg">
    //    <i className="fa fa-circle fa-stack-2x"></i>
    //    <i className="fa fa-flag fa-stack-1x fa-inverse"></i>
    // </span>

    switch (this.props.channel) {
      case "youtube":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={YoutubeLogo}
            title="youtube"
            alt="youtube"
            name="youtube-icon"
          />
        );
      case "instagram":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={InstagramLogo}
            title="instagram"
            alt="instagram"
            name="instagram-icon"
          />
        );
      case "tiktok":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={TikTokLogo}
            title="tiktok"
            alt="tiktok"
            name="tiktok-icon"
          />
        );
      case "twitch":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={TwitchLogo}
            title="twitch"
            alt="twitch"
            name="twitch-icon"
          />
        );
      case "trovo":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={TrovoLogo}
            title="trovo"
            alt="trovo"
            name="trovo-icon"
          />
        );
      case "periscope":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={TwitterLogo}
            title="periscope"
            alt="periscope"
            name="periscope-icon"
          />
        );
      case "twitter":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={TwitterLogo}
            title="twitter"
            alt="twitter"
            name="twitter-icon"
          />
        );
      case "liveme":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={LivemeLogo}
            title="liveme"
            alt="liveme"
            name="liveme-icon"
          />
        );
      case "uplive":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={UpLiveLogo}
            title="uplive"
            alt="uplive"
            name="uplive-icon"
          />
        );
      case "seventeen":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={SeventeenLogo}
            title="seventeen"
            alt="seventeen"
            name="seventeen-icon"
          />
        );
      case "facebook":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={FacebookLogo}
            title="facebook"
            alt="facebook"
            name="facebook-icon"
          />
        );
      case "facebookrtmp":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={FacebookLogo}
            title="facebook"
            alt="facebook rtmp"
            name="facebook-rtmp-icon"
          />
        );
      case "customrtmp":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={CustomRTMPLogo}
            title="custom"
            alt="custom"
            name="custom-rtmp-icon"
          />
        );
      case "snapchat":
        return (
          <img
            className="PlatformIcon__channelStyle"
            src={SnapchatLogo}
            title="snapchat"
            alt="snapchat"
            name="snapchat-icon"
          />
        );
      default:
    }
  }
}

PlatformIcon.propTypes = {
  channel: PropTypes.oneOf(AllChannelTypes.channelTypesNames),
};

export default PlatformIcon;
