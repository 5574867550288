import React, { Component } from "react";
import PropTypes from "prop-types";
import PresenceIndicator from "./PresenceIndicator";
import LoolaLogo from "../assets/icons/loola-logo-symbol.svg";
import { Constants } from "../core/constants";
import "./LoolaAppHeader.css";
import {
  StudioConnectPagePath,
  MainVideoRoomPagePath,
  OverlaysPagePath,
  BroadcastHistoryPagePath,
  PricingPagePath,
} from "../PageConstants";
import { Link, Prompt } from "react-router-dom";
import { clearAuthState } from "../core/clear-auth-state";
import BroadcastingState from "../models/BroadcastingState";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import UpgradeVersionContainer from "../common/UpgradeVersion/UpgradeVersionContainer";
import { UpgradeVersionComponentType } from "../common/UpgradeVersion/UpgradeVersionComponentType";

class LoolaAppHeader extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isSuccessUserSignOut && nextProps.isSuccessUserSignOut) {
      // TODO: change this after moving from cookie based to headers concept
      clearAuthState();
      window.location.href = "/";
    }

    if (!this.props.hasError && nextProps.hasError) {
      this.props.sendAction(Constants.ACTION_TYPES.USER_SIGN_OUT);
    }

    if (this.props.isSuccessfulSignIn || nextProps.isSuccessfulSignIn) {
      window.analytics.identify(nextProps.currentUser.email, {
        name: nextProps.currentUser.name,
        email: nextProps.currentUser.email,
        planType: nextProps.currentUser.subscriptionPlan,
      });

      window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_LOGIN_SUCCESS);
    }
  }

  renderUpgradeVersionIndicator() {
    return (
      <UpgradeVersionContainer
        componentType={UpgradeVersionComponentType.indicator}
        isLive={this.props.isLive}
      />
    );
  }

  render() {
    return (
      <div className="LoolaAppHeader">
        <Prompt
          when={this.props.isLive}
          message={() =>
            "Are you sure you want to leave this page? Changes you made may not be saved."
          }
        />

        <ul className="LoolaAppHeader__headerItems" role="navigation">
          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemsItem--Logo">
            <Link to={StudioConnectPagePath} className="LoolaAppHeader__Link">
              <img
                src={LoolaLogo}
                alt="Loola TV"
                name="LoolaLogo"
                className="LoolaAppHeader__LoolaLogo"
              />
            </Link>
          </li>

          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text">
            <Link to={MainVideoRoomPagePath} className="LoolaAppHeader__Link">
              <span className="LoolaAppHeader__headerItemText">Studio</span>
            </Link>
          </li>

          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text">
            <Link to={OverlaysPagePath} className="LoolaAppHeader__Link">
              <span className="LoolaAppHeader__headerItemText">Overlays</span>
              <span className="LoolaAppHeader__headerItemText--NewIndication">NEW</span>
            </Link>
          </li>

          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text">
            <Link to={BroadcastHistoryPagePath} className="LoolaAppHeader__Link">
              <span className="LoolaAppHeader__headerItemText">History</span>
            </Link>
          </li>

          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text">
            <a
              href="https://support.loola.tv/"
              target="_blank"
              rel="noopener noreferrer"
              className="LoolaAppHeader__Link"
            >
              <span className="LoolaAppHeader__headerItemText">FAQ</span>
            </a>
          </li>

          <li className="LoolaAppHeader__headerItemsItem LoolaAppHeader__headerItemText--Text">
            <a
              href={PricingPagePath}
              target="_blank"
              rel="noopener noreferrer"
              className="LoolaAppHeader__Link"
            >
              <span className="LoolaAppHeader__headerItemText">Pricing</span>
            </a>
          </li>
        </ul>

        {this.renderUpgradeVersionIndicator()}

        <PresenceIndicator {...this.props} />
      </div>
    );
  }
}

LoolaAppHeader.propTypes = {
  currentUser: PropTypes.object,
  userSettingsLiveLimits: PropTypes.object,
  hasError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasAtLeastOnePlatformAuthenticated: PropTypes.bool.isRequired,
  isRTMP: PropTypes.bool.isRequired,
  hasValidRTMPStream: PropTypes.bool.isRequired,
  isReadyForLive: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
  liveStatus: PropTypes.oneOf(Object.values(BroadcastingState)).isRequired,
  isSuccessfulSignIn: PropTypes.bool.isRequired,
  hasBroadcastSummaryDetails: PropTypes.bool,
  isLiveBroadcastSessionEnded: PropTypes.bool,
  moveToSignInOrRegisterPage: PropTypes.func,
  openBroadcastSummaryModal: PropTypes.func,
  sendAction: PropTypes.func,
};

export default LoolaAppHeader;
