import { connect } from "react-redux";
import { sendAction } from "../core/send-action";
import BroadcastSummaryVideoOverlay from "./BroadcastSummaryVideoOverlay";
import { bindActionCreators } from "redux";
import { BroadcastHistoryPagePath, PricingPagePath } from "../PageConstants";
import { Constants } from "../core/constants";

function mapStateToProps(state) {
  const authenticationState = state.authenticationData;
  const { currentUser } = authenticationState;

  const {
    broadcastSummaryCounters,
    dailyAchievement,
    platformsCounters,
  } = state.postLiveBroadcastSummaryData;

  const isUserPaying = currentUser.isUser() && currentUser.isPaying();
  const isRTMP = state.liveVideoData.broadcastConfig.activeTab === Constants.TABS.rtmp;

  return {
    liveMetadata: {
      isRTMP,
    },
    userMetadata: {
      isUserPaying,
    },
    dailyAchievement: {
      value: dailyAchievement,
    },
    summaryCounters: {
      views: broadcastSummaryCounters.views,
      viewers: broadcastSummaryCounters.viewers,
      followers: broadcastSummaryCounters.newFollowers,
      likes: broadcastSummaryCounters.likes,
      duration: broadcastSummaryCounters.duration,
    },
    platformsCounters,
    commands: {
      onDownloadVideo: () => {
        window.location.href = BroadcastHistoryPagePath;
      },
      onDetailedStat: () => {
        window.location.href = BroadcastHistoryPagePath;
      },
      closeBroadcastSummary: () => {
        window.location.reload();
      },
      onBroadcastAgain: () => {
        window.location.reload();
      },
      onUpgrade: () => {
        window.location.href = PricingPagePath;
      },
    },
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(BroadcastSummaryVideoOverlay);
