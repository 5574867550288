const config = (module.exports = {});

config.env = "development";
config.hostname = "localhost";
config.helpurl = "https://support.loola.tv";
config.apiurl = "http://localhost:4200";
config.overlaysAppUrl = `https://overlays-dev.loola.tv`;

config.janusapi = "wss://ws-dev.loola.tv/janus-api";
config.turnserver = "turn.loola.tv:80";
config.janususer = "janususer";
config.januspass = "januspwd";

// `[{urls: "stun:${config.turnserver}"},{urls: "turn:${config.turnserver}?transport=tcp", username: "${config.janususer}", credential: "${config.januspwd}"}`

// mongo database
// config.mongo = {};
// config.mongo.uri = process.env.MONGO_URI || 'localhost';
// config.mongo.db = 'example_dev';
