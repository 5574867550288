import "./PricingPage.css";

import React, { Component } from "react";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";
import { YearlyPricingPlans, MonthlyPricingPlans } from "./pricing-plans-constants";
import DynamicPlansBox from "./DynamicPlansBox";
import LoolaPublicPageHeader from "../LandingPage/components/LoolaPublicPageHeader";
import LandingPageFooter from "../LandingPage/LandingPageFooter";
import CookieUtil from "../../core/cookie-util";
import { FirstPromoterTrackingIDCookieName } from "../../core/cookie-names-constants";

import Switch from "./ToggleSwitch";
import PropTypes from "prop-types";

class PricingPage extends Component {
  state = {
    yearlyPlansBoxVisible: true,
    monthPlansBoxVisible: false,
  };

  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.PRICING_PAGE);
  }

  buildQueryParamsForChargebeeHostedCheckoutPage = () => {
    const { loolaUserId } = this.props;
    const firstPromoterValue = CookieUtil.getCookie(FirstPromoterTrackingIDCookieName);

    const query = [];
    if (firstPromoterValue) {
      const customerParam = `customer[cf_tid]=${firstPromoterValue}`;
      query.push(customerParam);
    }
    if (loolaUserId) {
      const userIdParam = `subscription[cf_loola_userid]=${loolaUserId}`;
      query.push(userIdParam);
    }
    return query;
  };

  buildChargebeeHostedCheckoutPage = planName => {
    // build Query Url based on Plan Name
    const queryUrl = `https://loola.chargebee.com/hosted_pages/plans/${planName}`;

    // build Query Params based on the relevant cases - e.g. first promoter and user existance
    const query = this.buildQueryParamsForChargebeeHostedCheckoutPage();
    const queryParams = query.length ? `?${query.join("&")}` : "";

    // return between url and params
    return queryUrl + queryParams;
  };

  selectYearlyPlanBox = () => {
    this.setState({
      yearlyPlansBoxVisible: true,
      monthPlansBoxVisible: false,
    });
  };

  selectMonthlyPlanBox = () => {
    this.setState({
      yearlyPlansBoxVisible: false,
      monthPlansBoxVisible: true,
    });
  };

  togglePlanBox = () => {
    if (this.state.yearlyPlansBoxVisible) {
      this.selectMonthlyPlanBox();
    } else {
      this.selectYearlyPlanBox();
    }
  };

  render() {
    const { loolaUserId, loolaUserPlanType } = this.props;
    console.log("PricingPage: User Current Plan Type", loolaUserId, loolaUserPlanType);

    return (
      <div className="PricingPage">
        <LoolaPublicPageHeader textColor="black" logoColor="black" />

        <div className="box-wrapper">
          <div className="PricingPage__welcomeMessage">
            <div className="PricingPage__firstSentence">Choose your pricing plan</div>
          </div>

          <div className="switch">
            <span
              onClick={this.selectYearlyPlanBox}
              style={this.state.monthPlansBoxVisible ? { color: "black" } : null}
            >
              Billed Monthly{" "}
            </span>
            <Switch isOn={this.state.yearlyPlansBoxVisible} handleToggle={this.togglePlanBox} />
            <span
              onClick={this.selectMonthlyPlanBox}
              style={this.state.yearlyPlansBoxVisible ? { color: "black" } : null}
            >
              {" "}
              Billed Annually{" "}
            </span>
            <span
              onClick={this.selectMonthlyPlanBox}
              style={
                this.state.yearlyPlansBoxVisible ? { color: "red" /* , fontWeight: "600" */ } : null
              }
            >
              (20% off)
            </span>
          </div>

          {this.state.yearlyPlansBoxVisible ? (
            <div className="tab-content">
              <DynamicPlansBox
                key="yearlyPlanBoxForTabContent"
                showYearly
                navigationValue={YearlyPricingPlans}
                buildChargebeeHostedCheckoutPage={this.buildChargebeeHostedCheckoutPage}
                currentUserPlanType={loolaUserPlanType}
              />
            </div>
          ) : (
            <div style={{ display: "none" }}>
              <DynamicPlansBox
                key="yearlyPlanBoxForNone"
                showYearly
                navigationValue={YearlyPricingPlans}
                buildChargebeeHostedCheckoutPage={this.buildChargebeeHostedCheckoutPage}
                currentUserPlanType={loolaUserPlanType}
              />
            </div>
          )}

          {this.state.monthPlansBoxVisible ? (
            <div className="tab-content">
              <DynamicPlansBox
                key="monthlyPlanBoxForTabContent"
                navigationValue={MonthlyPricingPlans}
                buildChargebeeHostedCheckoutPage={this.buildChargebeeHostedCheckoutPage}
                currentUserPlanType={loolaUserPlanType}
              />
            </div>
          ) : (
            <div style={{ display: "none" }}>
              <DynamicPlansBox
                key="monthlyPlanBoxForNone"
                navigationValue={MonthlyPricingPlans}
                buildChargebeeHostedCheckoutPage={this.buildChargebeeHostedCheckoutPage}
                currentUserPlanType={loolaUserPlanType}
              />
            </div>
          )}
        </div>

        <LandingPageFooter />
      </div>
    );
  }
}

PricingPage.propTypes = {
  loolaUserId: PropTypes.string,
  loolaUserPlanType: PropTypes.string,
};

export default PricingPage;
