import "./core/css/reset.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import RequiredAuthenticationRoute from "./route-types/RequiredAuthenticationRoute";
import ForceRedirectWhenLoggedInRoute from "./route-types/ForceRedirectWhenLoggedInRoute";
import WelcomePage from "./WelcomePage/WelcomePage";
import Layout from "./App/Layout";
import MainVideoRoomPage from "./VideoRoom/MainVideoRoomPage";
import BasicExample from "./BasicExample/BasicExample";
import ConfigurePlatformsPage from "./Platforms/ConfigurePlatformsPage";
import GoogleAuthLandingPage from "./common/Authentication/GoogleAuthLandingPage";
import InstagramAuthLandingPage from "./common/Authentication/PlatformAuthPages/InstagramAuthLandingPage";
import TwitchAuthLandingPage from "./common/Authentication/PlatformAuthPages/TwitchAuthLandingPage";
import TrovoAuthLandingPage from "./common/Authentication/PlatformAuthPages/TrovoAuthLandingPage";
import FacebookAuthLandingPage from "./common/Authentication/PlatformAuthPages/FacebookAuthLandingPage";
import PeriscopeAuthLandingPage from "./common/Authentication/PlatformAuthPages/PeriscopeAuthLandingPage";
import TwitterAuthLandingPage from "./common/Authentication/PlatformAuthPages/TwitterAuthLandingPage";
import YoutubeAuthLandingPage from "./common/Authentication/PlatformAuthPages/YoutubeAuthLandingPage";
import ModalTesterPage from "./Modals/ModalTesterPage";
import AboutPage from "./PublicWebsite/AboutPage/AboutPage";
import PricingPageContainer from "./PublicWebsite/PricingPage/PricingPageContainer";
import PrivacyPage from "./PublicWebsite/PrivacyPage/PrivacyPage";
import TermsPage from "./PublicWebsite/TermsPage/TermsPage";
import SubscriptionCompletedPage from "./PublicWebsite/PricingPage/SubscriptionCompletedPage";
import BroadcastHistoryPage from "./BroadcastHistorySelection/BroadcastHistoryPage";
import OverlaysPage from "./Overlays/OverlaysPage";
import LandingPage from "./PublicWebsite/LandingPage/LandingPage";
import { store } from "./store/application-store";
import { saveState } from "./store/localStorage";
import throttle from "lodash/throttle";
import FacebookTestPage from "./Facebook/FacebookTestPage";
import SampleVideoPlayerPage from "./VideoPlayer/SampleVideoPlayerPage";

import "./fontawesome.js";

import {
  StudioConnectPagePath,
  ConfigurePlatformsPagePath,
  InstagramAuthPagePath,
  TwitchAuthPagePath,
  TrovoAuthPagePath,
  PeriscopeAuthPagePath,
  TwitterAuthPagePath,
  YoutubeAuthPagePath,
  FacebookAuthPagePath,
  GoogleAuthPagePath,
  MainVideoRoomPagePath,
  TestModalPagePath,
  AboutPagePath,
  PricingPagePath,
  PrivacyPagePath,
  TermsPagePath,
  SubscriptionCompletedPagePath,
  BroadcastHistoryPagePath,
  OverlaysPagePath,
  PublicLandingPagePath,
  FacebookTestPath,
  SampleVideoPlayerPagePath,
} from "./PageConstants";

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState(state);
  }),
  1000
);

const publicPages = [
  PublicLandingPagePath,
  AboutPagePath,
  PricingPagePath,
  PrivacyPagePath,
  TermsPagePath,
  StudioConnectPagePath,
  SubscriptionCompletedPagePath,
  SampleVideoPlayerPagePath,
];

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Layout avoidLoolaAppHeader={publicPages}>
        <Switch>
          <ForceRedirectWhenLoggedInRoute
            exact
            path={StudioConnectPagePath}
            component={WelcomePage}
          />
          <Route exact path="/basic-example" component={BasicExample} />
          <RequiredAuthenticationRoute
            exact
            path={MainVideoRoomPagePath}
            component={MainVideoRoomPage}
          />
          <Route exact path={ConfigurePlatformsPagePath} component={ConfigurePlatformsPage} />
          <Route path={InstagramAuthPagePath} component={InstagramAuthLandingPage} />
          <Route path={TwitchAuthPagePath} component={TwitchAuthLandingPage} />
          <Route path={TrovoAuthPagePath} component={TrovoAuthLandingPage} />
          <Route path={FacebookAuthPagePath} component={FacebookAuthLandingPage} />
          <Route path={PeriscopeAuthPagePath} component={PeriscopeAuthLandingPage} />
          <Route path={TwitterAuthPagePath} component={TwitterAuthLandingPage} />
          <Route path={YoutubeAuthPagePath} component={YoutubeAuthLandingPage} />
          <Route path={GoogleAuthPagePath} component={GoogleAuthLandingPage} />
          <Route path={TestModalPagePath} component={ModalTesterPage} />
          <Route path={AboutPagePath} component={AboutPage} />
          <Route path={PricingPagePath} component={PricingPageContainer} />
          <Route path={PrivacyPagePath} component={PrivacyPage} />
          <Route path={TermsPagePath} component={TermsPage} />
          <Route path={SubscriptionCompletedPagePath} component={SubscriptionCompletedPage} />
          <Route path={BroadcastHistoryPagePath} component={BroadcastHistoryPage} />
          <Route path={OverlaysPagePath} component={OverlaysPage} />
          <Route path={FacebookTestPath} component={FacebookTestPage} />
          <Route path={SampleVideoPlayerPagePath} component={SampleVideoPlayerPage} />
          <Route path={PublicLandingPagePath} component={LandingPage} />
        </Switch>
      </Layout>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
