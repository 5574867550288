import "../../core/css/round-box.css";

import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./TabContent.css";

class TabContent extends React.Component {
  handleBackButton = () => {
    this.props.onBackEvent();
  };

  renderBackButton() {
    return (
      <div className="TabContent__backAction" onClick={this.handleBackButton}>
        <span>&lt;</span>
      </div>
    );
  }

  renderContent(withButton) {
    const buttonContextClassName = withButton ? "withBackButton" : "withoutBackButton";
    return (
      <div className={cx(`TabContent__content--${buttonContextClassName}`)}>
        {this.props.children}
      </div>
    );
  }

  renderSpecificContentWithBackButton() {
    return (
      <div className="TabContent">
        <div className="TabContent__title">{this.props.tabTitle}</div>
        <div className="TabContent__body">
          {this.renderBackButton()}
          {this.renderContent(true)}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.showBackButton) return this.renderSpecificContentWithBackButton();
    return <div className="TabContent">{this.renderContent(false)}</div>;
  }
}

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
  showBackButton: PropTypes.bool,
  tabTitle: PropTypes.string,
  onBackEvent: PropTypes.func,
};

export { TabContent };
