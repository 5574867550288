import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AllChannelTypes from "../common/channelTypes";
import { Constants } from "../core/constants";
import Spinner from "./Spinner";
import TextField from "../core/TextField";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import ConnectButton from "./ConnectButton";
import Button from "../core/Button";
import config from "../Config";
import { connect } from "react-redux";
import connectedIcon from "../assets/icons_new/connected.svg";
import "./SinglePlatformConfigurationPage.css";
import SinglePlatformHeaderSection from "./SinglePlatformSections/Sections/SinglePlatformHeaderSection";
import FacebookLoginButton from "../Facebook/FacebookLoginButton";
import TwitterLoginButton from "../Twitter/TwitterLoginButton";
import InstagramLoginForm from "../Facebook/InstagramLoginForm";
import { IntercomHelpPages } from "../core/intercom-help-pages";
import { ComponentsNames, ValidationRegExp } from "./platform-constants";

class SinglePlatformConfigurationPage extends Component {
  constructor(props) {
    super(props);

    const currentPlatform = props.platforms.find(p => p.platformType === props.platformType);
    const username = currentPlatform.username != null ? currentPlatform.username : "";
    const { rtmpServer, streamKey } = currentPlatform.rtmpConfigurationData;
    const platformAuthType = AllChannelTypes.getPlatformAuthType(props.platformType);

    this.state = {
      platformUserName: username,
      platformUserPassword: "",
      platformRTMPServer: rtmpServer,
      platformStreamKey: streamKey,
      showPassword: false,
      captchaImageValue: "",
      securityCode: "",
      currentPhase: "USER_PASSWORD", // USER_PASSWORD, 2FA, CAPTCHA, IT_WAS_ME, SUCCESS
      isTouched: false,
      platformAuthType,
    };
  }

  __getOAuthPlatformCTA() {
    const platformName = AllChannelTypes.getChannelName(this.props.platformType);
    return `${platformName} Connect`;
  }
  __getOAuthPlatformUrl() {
    // facebook state field
    const max = 9000000;
    const min = 1000000;
    const state = Math.random() * (max - min) + min;

    // instagram e2e field
    const timeInMs = Date.now();
    const instagramExtraData = `{"init":${timeInMs}}`;

    // https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=profile%20email%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&prompt=consent%20select_account&response_type=code&client_id=586692893620-rc0kgtkmrqmovgie6h8nu59u4us042gt.apps.googleusercontent.com&redirect_uri=http://localhost:9099/auth/youtube
    const youtubeConfig = config.youtubeUrl;

    if (this.props.platformType === "instagram")
      return `${config.instagramUrl}&e2e=${instagramExtraData}`;
    if (this.props.platformType === "facebook") return `${config.facebookUrl}&state=${state}`;
    if (this.props.platformType === "twitch") return config.twitchUrl;
    if (this.props.platformType === "twitter")
      return "https://twitter.com/oauth/authorize?oauth_token=jc9w5gAAAAABOMJvAAABfALgFik";
    if (this.props.platformType === "trovo") return config.trovoUrl;
    if (this.props.platformType === "periscope") return config.periscopeUrl;
    if (this.props.platformType === "youtube") return youtubeConfig;

    // this should never happen
    return "";
  }
  __isPlatformTypeRequiresOAuth() {
    return this.state.platformAuthType === "OAUTH";
  }
  __isPlatformTypeRequiresRTMP() {
    return this.state.platformAuthType === "RTMP";
  }

  __changeCurrentPhase(phaseName) {
    this.setState(prev => {
      const newState = Object.assign({}, prev);
      newState.currentPhase = phaseName;
      return newState;
    });
  }

  changePhaseToInitial() {
    this.__changeCurrentPhase("USER_PASSWORD");
  }

  changePhaseTo2FA() {
    this.__changeCurrentPhase("2FA");
  }

  changePhaseToItWasMe() {
    this.__changeCurrentPhase("IT_WAS_ME");
  }

  changePhaseToCaptcha() {
    this.__changeCurrentPhase("CAPTCHA");
  }

  changePhaseToSuccess() {
    this.__changeCurrentPhase("SUCCESS");
  }

  // set exact phase
  __setExactPhase(nextProps) {
    if (!this.props.requireCaptchaVerification && nextProps.requireCaptchaVerification) {
      this.changePhaseToCaptcha();
    }

    if (!this.props.requireMobileDeviceValidation && nextProps.requireMobileDeviceValidation) {
      this.changePhaseToItWasMe();
    }

    if (!this.props.requireTwoFactorAuthCode && nextProps.requireTwoFactorAuthCode) {
      this.changePhaseTo2FA();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(
      "SinglePlatformConfigurationPage - receive props",
      this.state.currentPhase,
      this.props,
      nextProps
    );

    if (nextProps.isSuccessPlatformDisconnect) {
      this.changePhaseToInitial();
      this.props.onClose();
    }

    this.__setExactPhase(nextProps);

    if (!this.props.isSuccessPlatformConfigure && nextProps.isSuccessPlatformConfigure) {
      this.changePhaseToSuccess();
      window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_PLATFORM_CONFIGURATION_COMPLETED, {
        platform: nextProps.platformType,
      });
    }

    if (!this.props.isErrorPlatformConfigure && nextProps.isErrorPlatformConfigure) {
      window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_PLATFORM_CONFIGURATION_FAILED, {
        platform: nextProps.platformType,
        reason: nextProps.errorMessage,
      });
    }
  }

  __sendReporting = platformDetails => {
    if (this.props.requireCaptchaVerification) {
      platformDetails = Object.assign({}, platformDetails, {
        captchaImageValue: this.state.captchaImageValue,
      });
      // this.changePhaseToCaptcha();
    }

    if (this.props.requireMobileDeviceValidation) {
      // platformDetails = Object.assign({}, platformDetails, {
      //   captchaImageValue: this.state.captchaImageValue,
      // });
      // this.changePhaseToItWasMe();
    }

    if (this.props.requireTwoFactorAuthCode) {
      platformDetails = Object.assign({}, platformDetails, {
        twoFactorAuthCode: this.props.requireTwoFactorAuthCode,
        securityCode: this.state.securityCode,
      });
      // this.changePhaseTo2FA();
    }

    window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_PLATFORM_CONFIGURATION_STARTED, {
      platform: platformDetails.platformType,
      step: this.state.currentPhase, // this.props.requireTwoFactorAuthCode ? "2FA" : "USER_PASSWORD",
    });

    return platformDetails;
  };

  __doTheSave = (platformDetails, onlyReporting) => {
    if (onlyReporting) {
      platformDetails = this.__sendReporting(platformDetails);
      return;
    }

    // else
    platformDetails = this.__sendReporting(platformDetails);
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_CONFIGURE, platformDetails);
  };

  handleSaveWithOAuth = onlyReporting => {
    const platformDetails = {
      platformType: this.props.platformType,
      oauthCode: this.props.facebookMetadata.instagramToken,
    };

    // on OAuth we usually just doing reporting, beside specific flows (like: 2FA and ThisWasMe ... where we should update server as well)
    this.__doTheSave(platformDetails, onlyReporting);
  };

  handleSaveWithRTMP = onlyReporting => {
    const serverErrors = this.__detectIsError(ComponentsNames.RTMP_SERVER, true);
    const streamKeyErrors = this.__detectIsError(ComponentsNames.STREAM_KEY, true);

    if (serverErrors.showError || streamKeyErrors.showError) return;

    const platformDetails = {
      platformType: this.props.platformType,
      platformRTMPServer: this.state.platformRTMPServer,
      platformStreamKey: this.state.platformStreamKey,
    };

    // on RTMP we usually just doing reporting, beside specific flows (like: 2FA and ThisWasMe ... where we should update server as well)
    this.__doTheSave(platformDetails, onlyReporting);
  };

  handleSaveWithUserPassword = () => {
    const platformDetails = {
      platformType: this.props.platformType,
      username: this.state.platformUserName,
      password: this.state.platformUserPassword,
    };

    // on USER PASS Flow we always save and do reporting ...
    this.__doTheSave(platformDetails, false);
  };

  handleDisconnectAccount = () => {
    window.analytics.track(
      LoolaAnalyticsConstants.EVENTS.USER_PLATFORM_CONFIGURATION_DISCONNECTED,
      {
        platform: this.props.platformType,
      }
    );

    // run (specific platform) disconnect event
    // ??

    // initiate the disconnect server call
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_DISCONNECT, {
      platformType: this.props.platformType,
    });
  };

  handleUserName = evt => {
    this.setState({ platformUserName: evt.target.value });
  };

  handleRTMPServer = evt => {
    this.setState({ platformRTMPServer: evt.target.value });
  };

  handleStreamKey = evt => {
    this.setState({ platformStreamKey: evt.target.value });
  };

  handleCaptchaCode = evt => {
    this.setState({ captchaImageValue: evt.target.value.replace(/\s/gi, "") });
  };

  handleSecurityCode = evt => {
    this.setState({ securityCode: evt.target.value.replace(/\s/gi, "") });
  };

  handlePassword = evt => {
    this.setState({ platformUserPassword: evt.target.value });
  };

  togglePassword = () => {
    this.setState(prev => {
      return {
        ...prev,
        showPassword: !prev.showPassword,
      };
    });
  };

  __initError(errorMessage, additionalInfoLink) {
    return {
      showError: true,
      errorMessage,
      additionalInfoLink,
    };
  }

  __detectIsError = (componentName, forceTouched = false) => {
    const { error } = this.props;
    const { platformType, currentUser } = this.props;
    const { isTouched, platformStreamKey, platformRTMPServer } = this.state;

    const noErrorToShow = {
      showError: false,
      errorMessage: "",
    };

    if (!error) {
      if (isTouched || forceTouched) {
        switch (componentName) {
          case ComponentsNames.STREAM_KEY: {
            if (ValidationRegExp.EMPTY.test(platformStreamKey)) {
              return this.__initError("Field required");
            }
            if (currentUser.rtmpKey === platformStreamKey) {
              return this.__initError("Can't use your own Loola RTMP key");
            }
            return noErrorToShow;
          }
          case ComponentsNames.RTMP_SERVER: {
            // facebook doesn't require the RTMP_SERVER, so we validate only for the customRTMP
            if (platformType === AllChannelTypes.channelTypes.customrtmp.key) {
              if (ValidationRegExp.EMPTY.test(platformRTMPServer)) {
                return this.__initError("Field required");
              }
              if (!ValidationRegExp.RTMP_URL.test(platformRTMPServer)) {
                return this.__initError("URL must start with rtmp:// or rtmps://");
              }
            }
            return noErrorToShow;
          }
          default:
            return noErrorToShow;
        }
      }
      return noErrorToShow;
    }

    const errorCode = error.code;

    switch (errorCode) {
      case 2001:
        if (
          componentName === ComponentsNames.STREAM_KEY ||
          componentName === ComponentsNames.GENERIC ||
          componentName === ComponentsNames.PASSWORD
        ) {
          return this.__initError("Cannot log in to service");
        }
        return noErrorToShow;

      case 2002: {
        if (componentName === ComponentsNames.USER_NAME) {
          return this.__initError("Incorrect Username");
        }
        return noErrorToShow;
      }

      case 2003: {
        if (componentName === ComponentsNames.PASSWORD) {
          return this.__initError("Incorrect Password");
        }
        return noErrorToShow;
      }

      case 2006: {
        const { friendlyMessage } = error.rawError;
        if (componentName === ComponentsNames.SECURITY_CODE && friendlyMessage) {
          return this.__initError(friendlyMessage);
        }
        return noErrorToShow;
      }

      case 2008: {
        const { friendlyMessage } = error.rawError;
        if (componentName === ComponentsNames.USER_NAME && friendlyMessage) {
          return this.__initError(friendlyMessage);
        }
        return noErrorToShow;
      }

      case 2081: {
        if (componentName === ComponentsNames.USER_NAME) {
          return this.__initError(
            `It seems that this account isn’t a valid account at ${platformType}`
          );
        }
        return noErrorToShow;
      }

      case 2082: {
        if (
          componentName === ComponentsNames.GENERIC &&
          platformType === AllChannelTypes.channelTypes.instagram.key
        ) {
          return this.__initError(
            `The Facebook account you use isn't linked to an Instagram account.`,
            "https://support.loola.tv/faq/general/linking-instagram-and-facebook-accounts"
          );
        }
        return noErrorToShow;
      }

      default: {
        return noErrorToShow;
      }
    }
  };

  renderRTMPServerURL() {
    const error = this.__detectIsError(ComponentsNames.RTMP_SERVER);
    const rtmpServerTitle = "RTMP URL:";

    return (
      <TextField
        value={this.state.platformRTMPServer}
        onChange={this.handleRTMPServer}
        label={rtmpServerTitle}
        wrapAddedClassName="SinglePlatform"
        name={ComponentsNames.RTMP_SERVER}
        shouldShowError={error.showError}
        errorMessage={error.errorMessage}
      />
    );
  }

  renderStreamKey() {
    const error = this.__detectIsError(ComponentsNames.STREAM_KEY);
    const streamKeyTitle = "Stream Key:";

    return (
      <TextField
        value={this.state.platformStreamKey}
        onChange={this.handleStreamKey}
        label={streamKeyTitle}
        wrapAddedClassName="SinglePlatform"
        name={ComponentsNames.STREAM_KEY}
        shouldShowError={error.showError}
        errorMessage={error.errorMessage}
      />
    );
  }

  renderUserNameField() {
    const error = this.__detectIsError(ComponentsNames.USER_NAME);
    const listOfPhoneEnabledPlatforms = ["liveme", "uplive", "tiktok"];
    const { platformType } = this.props;
    // const { currentUser } = this.props;
    // const isLiveInUSA = currentUser.isLiveInUS();
    const isPlatformEnablePhoneAndEmailUsers = listOfPhoneEnabledPlatforms.includes(platformType);

    const userNameTitle = isPlatformEnablePhoneAndEmailUsers
      ? "Username / Email / Phone"
      : "Username";

    return (
      <TextField
        value={this.state.platformUserName}
        onChange={this.handleUserName}
        label={userNameTitle}
        wrapAddedClassName="SinglePlatform"
        name={ComponentsNames.USER_NAME}
        shouldShowError={error.showError}
        errorMessage={error.errorMessage}
      />
    );
  }

  renderPassword(type) {
    const error = this.__detectIsError(ComponentsNames.PASSWORD);

    return (
      <span className="passwordWrapper">
        <TextField
          value={this.state.platformUserPassword}
          onChange={this.handlePassword}
          label="Password"
          name={ComponentsNames.PASSWORD}
          type={type}
          wrapAddedClassName="SinglePlatform"
          shouldShowError={error.showError}
          errorMessage={error.errorMessage}
        />
        <span
          className="SinglePlatformConfigurationPage__inputFormInputEyeIcon"
          onClick={this.togglePassword}
        />
      </span>
    );
  }

  renderPasswordField() {
    if (this.state.showPassword) {
      return this.renderPassword("text", "eye-slash");
    }

    return this.renderPassword(ComponentsNames.PASSWORD, "eye");
  }

  renderHelpMessage() {
    if (this.__isPlatformTypeRequiresOAuth()) return null;

    const { platformType } = this.props;
    const helpLink = IntercomHelpPages.howToConnect[platformType];

    // TODO: change to functions instead of hardcoded strings (is facebook, is custom ... );
    const helpMessage =
      platformType === "facebookrtmp"
        ? "Where is my facebook stream key?"
        : platformType === "customrtmp"
        ? "How can I set username/password?"
        : "Forgot password?";

    if (this.state.currentPhase === "USER_PASSWORD" && platformType) {
      return (
        <div className="SinglePlatformConfigurationPage__HelpLink">
          <a href={helpLink} target="_blank" rel="noopener noreferrer">
            {helpMessage}
          </a>
        </div>
      );
    }

    return "";
  }

  renderPlatformConfigurationHeader(componentTitle, platformLogo, platformName, platformStatus) {
    return (
      <Fragment>
        <h1 className="SinglePlatformConfigurationPage__title">{componentTitle}</h1>
        <div className="SinglePlatformConfigurationPage__content">
          <div className="SinglePlatformConfigurationPage__platformImage-wrap">
            <img
              src={platformLogo}
              alt={platformName}
              className="SinglePlatformConfigurationPage__platformImage"
            />
            {platformStatus === "authenticated" && (
              <div className="SinglePlatformConfigurationPage__platformImage-connected">
                <img src={connectedIcon} alt="decorator" />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderSaveButton(platform, showSaveButton, triggerSaveEvent) {
    if (this.props.error && this.props.error.code === 2042) return null;
    if (!showSaveButton) return null;

    return (
      <div className="SinglePlatformConfigurationPage__actionButtons">
        <div className="SinglePlatformConfigurationPage__actionButtonsSaveButtonWrapper">
          <ConnectButton
            status={platform.status}
            onClick={() => {
              triggerSaveEvent();
            }}
          />
        </div>
      </div>
    );
  }

  renderDisconnectButton(platform) {
    if (platform.status !== "not-configured")
      return (
        <Button
          additionalClassName="SinglePlatformConfigurationPage__actionFooterDisconnectLink"
          onClick={this.handleDisconnectAccount}
        >
          {" "}
          Disconnect
        </Button>
      );
    return null;
  }

  // return callback
  selectOnSaveEventCallback() {
    const onlyReporting = false;

    if (this.__isPlatformTypeRequiresOAuth()) {
      return () => this.handleSaveWithOAuth(onlyReporting);
    } else if (this.__isPlatformTypeRequiresRTMP()) {
      return () => this.handleSaveWithRTMP(onlyReporting);
    } else {
      return () => this.handleSaveWithUserPassword();
    }
  }

  renderPlatformConfigurationFooter(platform, showSaveButton) {
    const onSaveEvent = () => {
      if (!this.state.isTouched) {
        this.setState({ isTouched: true });
      }
      this.selectOnSaveEventCallback()();
    };

    return (
      <Fragment>
        <div className="SinglePlatformConfigurationPage__actionFooter">
          {this.renderHelpMessage()}
          {this.renderSaveButton(platform, showSaveButton, onSaveEvent)}
          {this.renderDisconnectButton(platform)}
        </div>
        <div className="SinglePlatformConfigurationPage__secure-wrap">
          <span className="SinglePlatformConfigurationPage__secure">This connection is Secure</span>
        </div>
      </Fragment>
    );
  }

  renderPlatformConfigurationForm() {
    if (this.props.requireTwoFactorAuthCode) {
      return this.render2FASection();
    }

    if (this.props.requireCaptchaVerification) {
      return this.renderCaptchaSection();
    }

    if (this.props.requireMobileDeviceValidation) {
      return this.renderItWasMeValidationSection();
    }

    // We are on USER_PASSWORD PHASE
    if (this.__isPlatformTypeRequiresOAuth()) {
      return this.renderOAuthActionsSection();
    }

    if (this.__isPlatformTypeRequiresRTMP()) {
      return this.renderRTMPActionsSection();
    }
    // on regular form - we show the configuration...
    return this.renderUserPasswordSection();
  }

  componentWillUnmount = () => {
    this.changePhaseToInitial();
    this.props.resetPlatformError();
  };

  renderCaptchaSection() {
    const base64Format = "data:image/gif;base64";
    const base64Value = this.props.requireCaptchaVerification;
    const imageSrc = `${base64Format},${base64Value}`;
    // const errorText = this.props.errorMessage;

    return (
      <div className="SinglePlatformConfigurationPage__inputForm">
        <img src={imageSrc} alt="captcha" />
        <TextField
          value={this.state.captchaImageValue}
          onChange={this.handleCaptchaCode}
          label="Captcha Value:"
          name="captcha"
          wrapAddedClassName="SinglePlatform"
        />
      </div>
    );
  }

  renderItWasMeValidationSection() {
    const channelName = AllChannelTypes.getChannelName(this.props.platformType);

    if (
      this.props.requireMobileDeviceValidation &&
      this.props.error &&
      this.props.error.code === 2042
    ) {
      return (
        <div className="SinglePlatformConfigurationPage__textualMessage">
          <span>It seems that this account isn’t a valid account at {channelName}</span>
          <span> Please use different account. </span>
        </div>
      );
    }

    const thisWasMeHelpLink = "https://support.loola.tv/troubleshooting/this-was-me-isnt-working";
    return (
      <div className="SinglePlatformConfigurationPage__textualMessage">
        <span>Please open {channelName} on your mobile device and select</span>
        <span> "This Was Me" </span>
        <span>
          <a href={thisWasMeHelpLink} target="_blank" rel="noopener noreferrer">
            More info
          </a>
        </span>
      </div>
    );
  }

  render2FASection() {
    const error = this.__detectIsError(ComponentsNames.SECURITY_CODE);

    return (
      <div className="SinglePlatformConfigurationPage__inputForm">
        <TextField
          value={this.state.securityCode}
          onChange={this.handleSecurityCode}
          label="Security Code:"
          name={ComponentsNames.SECURITY_CODE}
          wrapAddedClassName="SinglePlatform"
          shouldShowError={error.showError}
          errorMessage={error.errorMessage}
        />
      </div>
    );
  }

  renderOAuthActionsSection() {
    const error = this.__detectIsError(ComponentsNames.GENERIC);
    console.log("renderOAuthActionsSection: ", error);
    if (this.props.platformType === AllChannelTypes.channelTypes.instagram.key && error.showError) {
      return (
        <div>
          <div className="SinglePlatformConfigurationPage__errorMessage">{error.errorMessage}</div>
          <div>
            <a href={error.additionalInfoLink} target="_blank" rel="noopener noreferrer">
              More info
            </a>
          </div>
        </div>
      );
    }

    // else - no error, render the other stuff...
    let userNameSection = "";
    if (this.state.platformUserName) {
      userNameSection = <div>Connected with {this.state.platformUserName}</div>;
      return (
        <div className="SinglePlatformConfigurationPage">
          <div className="SinglePlatformConfigurationPage__screen--oauthForm">
            <div>{userNameSection}</div>
          </div>
        </div>
      );
    }

    // else ...
    const platformOAuthUrl = this.__getOAuthPlatformUrl();
    const platformCTAMessage = this.__getOAuthPlatformCTA();
    const onlyReporting = true;
    if (this.props.platformType === AllChannelTypes.channelTypes.instagram.key) {
      return (
        <div className="SinglePlatformConfigurationPage">
          <div className="SinglePlatformConfigurationPage__screen--oauthForm">
            <div>
              <InstagramLoginForm
                platformType={this.props.platformType}
                countryCode={this.props.geoLocationCountryCode}
                currentFacebookProfile={this.props.facebookMetadata.currentFacebookProfile}
                token={this.props.facebookMetadata.instagramToken}
                cookie={this.props.facebookMetadata.instagramCookie}
                loginState={this.props.facebookMetadata.loginState}
                onSaveEvent={() => this.handleSaveWithOAuth(onlyReporting)}
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.props.platformType === AllChannelTypes.channelTypes.facebook.key) {
      return (
        <div className="SinglePlatformConfigurationPage">
          <div className="SinglePlatformConfigurationPage__screen--oauthForm">
            <FacebookLoginButton />
          </div>
        </div>
      );
    }

    if (this.props.platformType === AllChannelTypes.channelTypes.twitter.key) {
      return (
        <div className="SinglePlatformConfigurationPage">
          <div className="SinglePlatformConfigurationPage__screen--oauthForm">
            <TwitterLoginButton />
          </div>
        </div>
      );
    }

    // other platforms ...
    return (
      <div className="SinglePlatformConfigurationPage">
        <div className="SinglePlatformConfigurationPage__screen--oauthForm">
          <div>
            <a href={platformOAuthUrl}>{platformCTAMessage}</a>
          </div>
        </div>
      </div>
    );
  }

  renderRTMPActionsSection() {
    if (this.props.platformType === AllChannelTypes.channelTypes.facebookrtmp.key) {
      return (
        <div className="SinglePlatformConfigurationPage__inputForm">
          <div>{this.renderStreamKey()}</div>
        </div>
      );
    }

    // else
    return (
      <div className="SinglePlatformConfigurationPage__inputForm">
        <div>{this.renderRTMPServerURL()}</div>
        <div>{this.renderStreamKey()}</div>
      </div>
    );
  }

  renderUserPasswordSection() {
    return (
      <div className="SinglePlatformConfigurationPage__inputForm">
        <div>{this.renderUserNameField()}</div>
        <div>{this.renderPasswordField()}</div>
      </div>
    );
  }

  renderPreventActionsScreen() {
    return (
      <div className="SinglePlatformConfigurationPage">
        <div className="SinglePlatformConfigurationPage__screen--allSet">You're all set!</div>
      </div>
    );
  }

  renderSuccessScreen() {
    return (
      <div className="SinglePlatformConfigurationPage" onClick={this.props.onClose}>
        <div className="SinglePlatformConfigurationPage__screen--connected">
          <img
            src={connectedIcon}
            alt="decorator"
            className="SinglePlatformConfigurationPage__connected-icon"
          />
          <div className="SinglePlatformConfigurationPage__connected-text">
            <span>Successfully</span>
            <span>connected</span>
          </div>
        </div>
      </div>
    );
  }

  renderLoadingSpinnerScreen() {
    return (
      <div className="SinglePlatformConfigurationPage">
        <Spinner />
        <div>Patience, this may take up to 30 seconds</div>
      </div>
    );
  }

  render() {
    const { platformType, platforms } = this.props;
    const platform = platforms.find(currPlatform => currPlatform.platformType === platformType);

    if (this.props.isRunningPlatformConfigure || this.props.isRunningPlatformDisconnect) {
      return this.renderLoadingSpinnerScreen();
    } else if (this.state.currentPhase === "SUCCESS") {
      return this.renderSuccessScreen();
      // } else if (platform.platformType === "youtube") {
      //   return this.renderPreventActionsScreen();
    }

    if (this.__isPlatformTypeRequiresOAuth()) {
      const withSaveButton = this.state.currentPhase !== "USER_PASSWORD";
      return (
        <div className="SinglePlatformConfigurationPage">
          <SinglePlatformHeaderSection platforms={platforms} platformType={platformType} />
          {this.renderPlatformConfigurationForm()}
          {this.renderPlatformConfigurationFooter(platform, withSaveButton)}
        </div>
      );
    }

    // return <SinglePlatform.UserPasswordScreen Type="UserPassword" />
    // return <SinglePlatform.OAuthScreen Type="UserPassword" platformType={this.props.platformType}/>
    // return <SinglePlatform.OAuthScreen platformType={this.props.platformType} />

    // else ... the rest ... (USER_PASS and RTMP)
    return (
      <div className="SinglePlatformConfigurationPage">
        <SinglePlatformHeaderSection platforms={platforms} platformType={platformType} />
        {this.renderPlatformConfigurationForm()}
        {this.renderPlatformConfigurationFooter(platform, true)}
      </div>
    );
  }
}

SinglePlatformConfigurationPage.propTypes = {
  platformType: PropTypes.string,
  requireTwoFactorAuthCode: PropTypes.string,
  requireCaptchaVerification: PropTypes.object,
  requireMobileDeviceValidation: PropTypes.object,
  isSuccessPlatformConfigure: PropTypes.bool,
  isErrorPlatformConfigure: PropTypes.bool,
  error: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default connect(state => {
  return {
    platforms: state.platformsData.platforms,
    is2Auth: state.platformsData,
    geoLocationCountryCode: state.authenticationData.geoLocationCountryCode,
    facebookMetadata: state.facebookData,
    currentUser: state.authenticationData.currentUser,
  };
}, null)(SinglePlatformConfigurationPage);
