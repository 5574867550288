import React from "react";
import PropTypes from "prop-types";

class Scheduler extends React.Component {
  _timer = null;
  _startTime = null;

  componentWillUnmount() {
    this.stopInterval();
  }

  componentDidMount() {
    console.log("Scheduler: Did Mount");
    if (this.props.isRunning) {
      this.startInterval();
    }
  }

  componentDidUpdate() {
    console.log("Scheduler: Did Update");
    // if (this.props.isRunning) {
    //   this.startInterval();
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("Scheduler: Will Receive Props");
    if (this.props.isRunning !== nextProps.isRunning) {
      if (nextProps.isRunning) {
        this.startInterval();
      } else {
        this.stopInterval();
      }
    }
  }

  isSessionTimedOut = () => {
    if (this.props && this.props.sessionTimeout) {
      const currentTime = Date.now();
      const runTime = currentTime - this._startTime;

      console.log("Scheduler: isSessionTimedOut", runTime, this.props.sessionTimeout);
      // timed out!
      if (runTime >= this.props.sessionTimeout) return true;
    }

    // no need to trigger timeout events
    return false;
  };

  sessionTimedOutEvent = () => {
    if (
      this.props &&
      this.props.onSessionTimeout &&
      typeof this.props.onSessionTimeout === "function"
    )
      this.props.onSessionTimeout();

    this.stopInterval();
  };

  callActionSafely = () => {
    try {
      if (this.isSessionTimedOut()) this.sessionTimedOutEvent();
      if (this.props && typeof this.props.action === "function") this.props.action();
      else console.log("error while calling this.prop.action");
    } catch (e) {
      console.log("error in Scheduler - safeAction", e);
    }
  };

  startInterval = () => {
    if (this._timer == null) {
      this._timer = setInterval(this.callActionSafely, this.props.interval);
      this._startTime = Date.now();
    }
  };

  stopInterval = () => {
    console.log("Scheduler: stopInterval");
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = null;
    }
  };

  render() {
    return React.Children.only(this.props.children);
  }
}

Scheduler.propTypes = {
  interval: PropTypes.number.isRequired,
  isRunning: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  sessionTimeout: PropTypes.number,
  onSessionTimeout: PropTypes.func,
};

export default Scheduler;
