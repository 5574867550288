import React from "react";
import "../core/css/round-box.css";
import StreamDestinationBox from "./StreamDestinationBox";
import Scheduler from "../core/Scheduler";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLiveStats } from "./get-live-stats";
import { sendAction } from "../core/send-action";
import { showSystemMessage, hideSystemMessage } from "../core/system-message-actions";
import { showModal } from "../Modals/action-modal";
import { Constants } from "../core/constants";
import BroadcastingState from "../models/BroadcastingState";

class StreamDestinationBoxContainer extends React.Component {
  handleTogglePlatform = platformDetails => {
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_TOGGLE, platformDetails);
  };

  handleOnLoad = () => {
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS);
  };

  render() {
    const { isBroadcastOpened, isMobile } = this.props;
    const duringLive = this.props.liveStatus !== BroadcastingState.noBroadcast;

    if (isMobile && isBroadcastOpened) {
      return null;
    }

    return (
      <Scheduler interval={5000} isRunning={this.props.goLive} action={this.props.triggerStats}>
        <StreamDestinationBox
          isLive={duringLive}
          isRunningGetPlatforms={this.props.isRunningGetPlatforms}
          isPlatformToggleFailed={this.props.isPlatformToggleFailed}
          platformToggleErrorMessage={this.props.platformToggleErrorMessage}
          onLoad={this.handleOnLoad}
          configuredPlatforms={this.props.configuredPlatforms}
          platformStats={this.props.liveStats.platformsStats}
          isFetchingPlatformToggle={this.props.isRunningPlatformToggle}
          platformTypeBeingFetched={this.props.platformTypeBeingFetched}
          showModal={this.props.showModal}
          showSystemMessage={this.props.showSystemMessage}
          hideSystemMessage={this.props.hideSystemMessage}
          togglePlatform={platformDetails => {
            this.handleTogglePlatform(platformDetails);
          }}
          sendAction={this.props.sendAction}
        />
      </Scheduler>
    );
  }
}

function mapStateToProps(state) {
  console.log("map to state in STREAM DESTINATION BOX", state.platformsData.platforms);

  return {
    currentUser: state.authenticationData.currentUser,
    hasError: state.authenticationData.hasError,
    isLoading: state.authenticationData.isLoading,
    configuredPlatforms: state.platformsData.platforms,
    platformTypeBeingFetched: state.platformsData.platformTypeBeingFetched,
    goLive: state.liveVideoData.goLive,
    liveStatus: state.liveVideoData.liveStatus,
    liveStats: state.liveStatsData.liveStats,
    isPlatformToggleFailed: state.runningActions[Constants.ACTION_TYPES.PLATFORM_TOGGLE].isError(),
    platformToggleErrorMessage: state.platformsData.errorMessage,
    isRunningPlatformToggle: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_TOGGLE
    ].isRunning(),
    isRunningGetPlatforms: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_GET_PLATFORMS
    ].isRunning(),
    isBroadcastOpened: state.postLiveBroadcastSummaryData.isOpened,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
      triggerStats: getLiveStats,
      showSystemMessage,
      hideSystemMessage,
      showModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(StreamDestinationBoxContainer);
