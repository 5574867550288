import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { BroadcastSettingsMenu } from "./BroadcastSettingsMenu";
import { BroadcastSettingsMenuItem } from "./BroadcastSettingsMenuItem";

export const BroadcastSettingsItem = ({ icon: Icon, ...props }) => {
  const [open, setOpen] = useState(false);
  const handleClick = e => {
    e.preventDefault();
    setOpen(prev => !prev);
  };

  const handleClickOutside = () => {
    setOpen(false);
  };

  return (
    <div className={`LocalUser__broadcastSettings-item `}>
      <div className="LocalUser__broadcastSettings-actions">
        <Icon enabled={props.enabled} onClick={props.onClick} rotated={props.rotated} />
        {props.menuItems && (
          <Popover
            isOpen={open}
            positions={["top", "left"]}
            align="end"
            padding={10}
            reposition
            content={
              <BroadcastSettingsMenu>
                {props.menuItems.map(({ items, isSelected, onChange, isDisabled }, index) => {
                  const isLast = index === props.menuItems.length - 1;
                  return (
                    <React.Fragment key={index}>
                      {items.map((item, elIndex) => (
                        <BroadcastSettingsMenuItem
                          key={elIndex}
                          label={item.label}
                          selected={isSelected(item)}
                          onClick={() => onChange(item)}
                          disabled={isDisabled}
                        />
                      ))}
                      {!isLast && <div className="LocalUser__broadcastSettings-menu_divider" />}
                    </React.Fragment>
                  );
                })}
              </BroadcastSettingsMenu>
            }
            onClickOutside={handleClickOutside}
          >
            <div className="LocalUser__broadcastSettings-icon" onClick={handleClick}>
              <FontAwesomeIcon size="xs" icon={open ? faChevronDown : faChevronUp} />
            </div>
          </Popover>
        )}
      </div>
      <span className="LocalUser__broadcastSettings-text">{props.label}</span>
    </div>
  );
};
