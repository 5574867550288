import { Constants } from "../../core/constants";
import { ActiveFanModel } from "../../models/ActiveFanModel";

const initialState = {
  fans: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.LIVE_GET_ACTIVE_FANS:
      console.log("GET_FANS - REDUCER", action.payload);
      if (action.payload == null || !Array.isArray(action.payload)) return state;

      const fansListServerResponse = action.payload.slice(0); // clone
      const newFans = fansListServerResponse.map(serverModel => new ActiveFanModel(serverModel));

      console.log("GET_FANS - REDUCER, newFans", newFans);
      return Object.assign({}, state, { fans: newFans });
    default:
      return state;
  }
}
