import { Constants } from "../../core/constants";
import { LoolaApi } from "../../core/loola-api";
import { API_CALL } from "../../redux-middlewares/loola-api-handler";

const activeFansActions = {
  [Constants.ACTION_TYPES.LIVE_GET_ACTIVE_FANS]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_GET_ACTIVE_FANS,
        payload: LoolaApi.getLiveActiveFans(),
      },
    };
  },
};

export { activeFansActions };
