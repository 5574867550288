import React, { Component } from "react";
import PropTypes from "prop-types";
import { EyeIcon } from "../../assets/icons/actions/EyeIcon";
import { Constants } from "../../core/constants";
import "./LocalUserIcon.css";
import "./VideoTotals.css";
import { LikeIcon } from "../../assets/icons/actions/LikeIcon";
import PrettyCounter from "../../core/PrettyCounter";

class VideoTotals extends Component {
  render() {
    const { totalViewers, totalLikes } = this.props;
    return (
      <div className="LocalUserInformationIcon">
        <div className="VideoTotals__iconWrapper">
          <EyeIcon size={Constants.ICON_SIZES.MEDIUM} color="white" />
          <div className="VideoTotals__value">
            <PrettyCounter value={totalViewers} />
          </div>
        </div>
        <div className="VideoTotals__iconWrapper">
          <LikeIcon size={Constants.ICON_SIZES.MEDIUM} color="white" />
          <div className="VideoTotals__value">
            <PrettyCounter value={totalLikes} />
          </div>
        </div>
      </div>
    );
  }
}
VideoTotals.propTypes = {
  totalViewers: PropTypes.number,
  totalLikes: PropTypes.number,
};

export default VideoTotals;
