import { WebRTCInputEnum } from "../constants";

export const capture = "screen"; // "window"
export const screenShareOptionsPreset = {
  label: "Screen Share",
  kind: WebRTCInputEnum.screenInput,
  video: capture,
  audio: true,
};

export const resolutionOptionsPreset = [
  {
    id: 0,
    label: "640x480 (4:3)",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 640 },
    height: { exact: 480 },
  },
  {
    id: 1,
    label: "852x480 (16:9)",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 852 },
    height: { exact: 480 },
  },
  {
    id: 2,
    label: "1280x720 (16:9)",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 1280 },
    height: { exact: 720 },
  },
  {
    id: 3,
    label: "480x720 (2:3) - Portrait",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 480 },
    height: { exact: 720 },
  },
  {
    id: 4,
    label: "608x1080 (9:16) - Portrait",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 608 },
    height: { exact: 1080 },
  },
  {
    id: 5,
    label: "720x1280 (9:16) - Portrait",
    kind: WebRTCInputEnum.resolutionInput,
    width: { exact: 720 },
    height: { exact: 1280 },
  },
];
