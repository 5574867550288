class BroadcastHistoryLiveEventDetails {
  constructor(serverModel) {
    const { broadcastInfo } = serverModel;
    this.email = broadcastInfo.email;
    this.roomId = broadcastInfo.roomId;
    this.broadcastInfo = broadcastInfo;
    this.liveEventPlatformsTotals = serverModel.liveEventPlatformsTotals;
    this.liveEventPlatformCounters = serverModel.liveEventPlatformCounters;
    this.empty = false;
    this.IGTVShareStatus = null;
  }
}

export { BroadcastHistoryLiveEventDetails };
