class RoomConfigurationModel {
  static isValidRoomConfiguration(roomConfiguration) {
    if (roomConfiguration === null) return false;

    if (Object.keys(roomConfiguration).length === 0 && roomConfiguration.constructor === Object)
      return false;

    // so we have roomConfiguration
    return true;
  }

  static getRoomId(roomConfiguration) {
    if (RoomConfigurationModel.isValidRoomConfiguration(roomConfiguration))
      return roomConfiguration.room.room;

    return null;
  }
}

export { RoomConfigurationModel };
