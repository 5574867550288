import { Constants } from "../core/constants";
import { LiveStatsModel } from "../models/LiveStatsModel";

const initialState = {
  liveStats: new LiveStatsModel(),
  totalViewers: 0,
  totalLikes: 0,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.LIVE_GET_STATS:
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
        });
      }

      const liveStats = new LiveStatsModel(action.payload);

      const mergedLiveStats = LiveStatsModel.mergePlatformStats(
        state.liveStats.platformsStats,
        liveStats.platformsStats
      );

      return Object.assign({}, state, {
        liveStats: mergedLiveStats,
        totalViewers: mergedLiveStats.sumViewers,
        totalLikes: mergedLiveStats.sumLikes,
      });

    default:
      return state;
  }
}
