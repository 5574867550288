import { Constants } from "../core/constants";

export function loadBroadcastConfigFromStorage() {
  const newConfig = localStorage.getItem("broadcastConfig");

  if (newConfig) {
    try {
      return JSON.parse(newConfig);
    } catch (e) {
      return {
        isAudioMuted: false,
        isVideoMuted: false,
        isVideoFlipped: false,
      };
    }
  } else {
    // TODO remove it after month or so. 11.10.2022
    const oldConfig = loadOldBroadcastValues();
    clearOldBroadcastValues();
    return {
      ...oldConfig,
      isAudioMuted: false,
      isVideoMuted: false,
      isVideoFlipped: false,
    };
  }
}

export function saveBroadcastConfigToStorage(config) {
  const currentConfig = loadBroadcastConfigFromStorage();
  if (currentConfig) {
    localStorage.setItem("broadcastConfig", JSON.stringify({ ...currentConfig, ...config }));
  } else {
    localStorage.setItem("broadcastConfig", JSON.stringify(config));
  }
}

function clearOldBroadcastValues() {
  const items = [
    "audioinput",
    "videoinput",
    "resolution",
    "BROADCAST_CONFIG_KEY",
    "SHOULD_SHOW_RTMP",
  ];

  items.forEach(item => {
    localStorage.removeItem(item);
  });
}

function loadOldBroadcastValues() {
  return {
    audioinput: localStorage.getItem("audioinput"),
    videoinput: localStorage.getItem("videoinput"),
    resolution: localStorage.getItem("resolution"),
    activeTab: localStorage.getItem("SHOULD_SHOW_RTMP") ? Constants.TABS.rtmp : Constants.TABS.web,
  };
}
