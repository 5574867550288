/* eslint-disable no-bitwise */
import React, { Component } from "react";
import PropTypes from "prop-types";
import ActiveFan from "./ActiveFan";

export default class ActiveFansList extends Component {
  getHash = input => {
    let hash = 0;
    const len = input.length;
    for (let i = 0; i < len; i += 1) {
      hash = (hash << 5) - hash + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return hash;
  };

  renderFans() {
    if (this.props.fans) {
      return this.props.fans.map(fan => {
        const metadata = `${fan.rank}_${fan.giftPoints}_${fan.comments}_${fan.followers}`;
        const keyName = `${fan.platform}_${fan.name}_${metadata}`;
        const key = this.getHash(keyName);

        console.log("render - ActiveFansList - Internal Item", fan, keyName, metadata, key);

        return (
          <ActiveFan
            platform={fan.platform}
            name={fan.name}
            img={fan.img}
            fanPageUrl={fan.fanPageUrl}
            followers={fan.followers}
            giftPoints={fan.giftPoints}
            comments={fan.comments}
            key={key}
          />
        );
      });
    }
    return "";
  }

  render() {
    console.log("render - ActiveFansList - Length", this.props.fans.length);

    return <div>{this.renderFans()}</div>;
  }
}

ActiveFansList.propTypes = {
  fans: PropTypes.array,
};
