import { connect } from "react-redux";
import SinglePlatformConfigurationPage from "./SinglePlatformConfigurationPage";
import { sendAction } from "../core/send-action";
import { bindActionCreators } from "redux";
import { Constants } from "../core/constants";

function mapStateToProps(state) {
  if (state.modalData === null || state.modalData.platformType === null) return state.modalData;

  return {
    platforms: state.platformsData.platforms,
    error: state.platformsData.error,
    errorMessage: state.platformsData.errorMessage,
    requireTwoFactorAuthCode: state.platformsData.requireTwoFactorAuthCode,
    requireCaptchaVerification: state.platformsData.requireCaptchaVerification,
    requireMobileDeviceValidation: state.platformsData.requireMobileDeviceValidation,
    isSuccessPlatformConfigure: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_CONFIGURE
    ].isSuccess(),
    isErrorPlatformConfigure: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_CONFIGURE
    ].isError(),
    isRunningPlatformConfigure: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_CONFIGURE
    ].isRunning(),
    isSuccessPlatformDisconnect: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_DISCONNECT
    ].isSuccess(),
    isErrorPlatformDisconnect: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_DISCONNECT
    ].isError(),
    isRunningPlatformDisconnect: state.runningActions[
      Constants.ACTION_TYPES.PLATFORM_DISCONNECT
    ].isRunning(),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePlatformConfigurationPage);
