import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import "./ShareOnIGTVButton.css";
import IGTVIcon from "../assets/icons/actions/IGTVIcon";

class ShareOnIGTVButton extends React.Component {
  constructor(props) {
    super(props);
    this.actionText = "Share to IGTV";
    this.successText = "Shared";
    this.loadingText = "Sharing";
  }

  render() {
    const { isShared, disabled, additionalClassName, showLoading } = this.props;

    return (
      <Button
        onClick={this.handleClick.bind(this)}
        additionalClassName={`ShareOnIGTVButton ${additionalClassName} ${
          showLoading ? "loading" : ""
        } ${isShared ? "success" : ""}`}
        disabled={disabled}
        showLoading={showLoading}
      >
        <span className="ShareOnIGTVButton__action-text">
          {showLoading ? this.loadingText : isShared ? this.successText : this.actionText}
        </span>
        {!showLoading ? (
          isShared ? (
            <span>&#10004;</span>
          ) : (
            <IGTVIcon additionalClassName="ShareOnIGTVButton__icon" size="small" />
          )
        ) : null}
      </Button>
    );
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}

ShareOnIGTVButton.propTypes = {
  onClick: PropTypes.func,
  additionalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isShared: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
};

export default ShareOnIGTVButton;
