import React from "react";
import Title from "./Title";
import Text from "./Text";
import topRight from "../../assets/icons_new/about/mind-top-right.svg";
import middleLeft from "../../assets/icons_new/about/mind-middle-left.svg";
import middleRight from "../../assets/icons_new/about/mind-middle-right.svg";
import bottomMiddle from "../../assets/icons_new/about/mind-bottom-middle.svg";
import "./Mind.css";

const Mind = ({ title, text }) => (
  <div className="about__mind">
    <div className="about__container">
      <Title {...title} />
      <div className="about__text_mind">
        <Text {...text} />
      </div>
      <img src={topRight} alt="decorator" className="about__mind-icon top-right" />
      <img src={middleLeft} alt="decorator" className="about__mind-icon middle-left" />
      <img src={middleRight} alt="decorator" className="about__mind-icon middle-right" />
      <img src={bottomMiddle} alt="decorator" className="about__mind-icon bottom-middle" />
    </div>
  </div>
);

export default Mind;
