import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PricingPage from "./PricingPage";
import { sendAction } from "../../core/send-action";

class PricingPageContainer extends React.Component {
  render() {
    const { currentUser } = this.props;
    const loolaUserId = currentUser === null ? null : currentUser.email;
    const loolaPricingPlanType = currentUser === null ? null : currentUser.subscriptionPlan;
    return <PricingPage loolaUserId={loolaUserId} loolaUserPlanType={loolaPricingPlanType} />;
  }
}

function mapStateToProps(state) {
  const authenticationState = state.authenticationData;
  return {
    currentUser: authenticationState.currentUser,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PricingPageContainer);
