import "../../core/css/round-box.css";
import "./ChatViewerBox.css";

import React from "react";
import ChatMessageList from "./ChatMessageList";
import BoxBlock from "../../core/BoxBlock";
import { Constants } from "../../core/constants";
import Scheduler from "../../core/Scheduler";

import ChatCommentInputBox from "./ChatCommentInputBox";

export default class ChatViewerBox extends React.Component {
  // relevant only on Web (big screens) -- here for example we have the ability to write comments back to our users.
  renderChatAsBox = () => (
    <BoxBlock
      title="Live Chat"
      expandVertical
      boxType={Constants.BOX_TYPES.CHAT}
      color="blue"
      addedClassName="hasScroll"
      isEmpty={this.props.messages === null || this.props.messages.length === 0}
      emptyStateMessage="Read viewers messages during your live video"
    >
      {this.props.isRunning && <ChatCommentInputBox writeComment={this.props.writeComment} />}

      <ChatMessageList overlay={false} messages={this.props.messages} />
    </BoxBlock>
  );

  // relevant only in Mobile -- here for example we won't have the ability to write comments to our users.
  renderChatAsOverlay = () => {
    const reverseOrder = this.props.messages.slice().reverse();
    return <ChatMessageList overlay={this.props.overlay} messages={reverseOrder} />;
  };

  render = () => {
    console.log("ChatViewerBox render called");

    return (
      <Scheduler
        interval={this.props.interval}
        isRunning={this.props.isRunning}
        action={this.props.action}
      >
        {this.props.overlay ? this.renderChatAsOverlay() : this.renderChatAsBox()}
      </Scheduler>
    );
  };
}
