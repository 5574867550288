import React from "react";
import PropTypes from "prop-types";
import AllChannelTypes from "../common/channelTypes";
import { MODAL_TYPE_CONFIGURATION, MODAL_TYPE_PLATFORM_SETTINGS } from "../Modals/ModalTypes";
import { showModal } from "../Modals/action-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cx from "classnames";
import "./PlatformItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SettingsSupported = ["facebook"];
class PlatformItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  showPlatformConfigurationSettings = e => {
    e.stopPropagation();
    const { channelType } = this.props;

    this.props.showModal(MODAL_TYPE_PLATFORM_SETTINGS, {
      platformType: channelType,
      platform: this.props.platform,
      getPlatformConfig: this.props.getPlatformConfig,
      width: "300px",
    });
  };

  showConfigurationPage = () => {
    const { channelType } = this.props;

    this.props.showModal(MODAL_TYPE_CONFIGURATION, {
      platformType: channelType,
      platform: this.props.platform,
      getPlatformConfig: this.props.getPlatformConfig,
      width: "300px",
    });
  };

  setHover = () => this.setState({ isHovered: true });
  cancelHover = () => this.setState({ isHovered: false });

  renderHoverButton = () => {
    if (!SettingsSupported.includes(this.props.channelType)) return null;
    if (!this.state.isHovered) return null;

    return (
      <div onClick={this.showPlatformConfigurationSettings.bind(this)}>
        <FontAwesomeIcon name="cog" className="PlatformItem__platformItemCog" />
      </div>
    );
  };

  render() {
    const { channelType, isConfigured } = this.props;

    const channelLogo = AllChannelTypes.getChannelImageLogo(channelType);
    const channelName = AllChannelTypes.getChannelName(channelType);
    // const linkToSingleChannelPage = `/configure-single-platform/${channelType}`;
    // var currentImageStyle = this.props.channelStatus === 'on' ? style.imageStyle : {...style.imageStyle, ...style.channelOff}

    return (
      <div
        className={cx("PlatformItem", isConfigured ? "active" : "")}
        data-streaming-platform-id="1"
        onClick={this.showConfigurationPage.bind(this)}
      >
        <div
          className="PlatformItem__platformItemImage-wrap"
          onMouseEnter={this.setHover}
          onMouseLeave={this.cancelHover}
        >
          <div className="PlatformItem__platformItemImage-inner">
            {this.renderHoverButton()}
            <img className="PlatformItem__platformItemImage" src={channelLogo} alt={channelName} />
          </div>
        </div>
        <span className="PlatformItem__platformItemText">{channelName}</span>
      </div>
    );
  }
}

PlatformItem.propTypes = {
  channelType: PropTypes.string.isRequired, // todo: change to oneof
  isConfigured: PropTypes.bool,
};

function matchDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ showModal }, dispatch),
  };
}

export default connect(null, matchDispatchToProps)(PlatformItem);
