import "./Button.css";

import cx from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "./Spinner";
import { Constants } from "./constants";

class Button extends React.Component {
  render() {
    const { theme = "default" } = this.props;
    const { disabled } = this.props;
    const { additionalClassName = "" } = this.props;
    const { showLoading } = this.props;

    if (showLoading) {
      return (
        <button
          onClick={this.handleClick.bind(this)}
          disabled={disabled}
          className={cx("Button", `Button--${theme}`, additionalClassName)}
        >
          <div className="Button__contentWithShowLoading">
            <span>{this.props.children}</span>
            <Spinner
              isVisible
              type={Constants.SPINNER_TYPES.DEFAULT}
              size={Constants.SPINNER_SIZES.SMALL}
            />
          </div>
        </button>
      );
    }

    return (
      <button
        onClick={this.handleClick.bind(this)}
        disabled={disabled}
        className={cx("Button", `Button--${theme}`, additionalClassName)}
      >
        {this.props.children}
      </button>
    );
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  additionalClassName: PropTypes.string,
  showLoading: PropTypes.bool,
};

export default Button;
