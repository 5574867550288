import React, { Component } from "react";
import spinner from "../assets/icons_new/SinglePlatformSpinner.svg";

class Spinner extends Component {
  render() {
    return (
      <div className="SinglePlatform__Spinner-wrap">
        <img
          src={spinner}
          width={50}
          height={50}
          className="SinglePlatform__Spinner"
          alt="spinner"
        />
      </div>
    );
  }
}

export default Spinner;
