class UserSettingsModel {
  constructor() {
    this.broadcastTitle = "";
    this.showCanvas = false;
    this.videoCanvas = {
      headline: "",
      headlineColor: "white",
      backgroundColor: "black",
    };
    // this.chatPreference = null;
    // this.defaultVideoResolution = serverModel.defaultVideoResolution;
  }

  static map(serverModel) {
    const response = {
      broadcastTitle: serverModel.broadcastTitle,
      showCanvas: serverModel.showCanvas,
      videoCanvas: serverModel.videoCanvas,
      chatPreference: serverModel.chatPreference,
      defaultVideoResolution: serverModel.defaultVideoResolution,
    };
    return response;
  }
}

export { UserSettingsModel };
