import React from "react";
import Title from "./Title";
import Text from "./Text";
import bottomCloudLeft from "../../assets/icons_new/about/soul-bottom-cloud-left.svg";
import bottomCloudMiddle from "../../assets/icons_new/about/soul-bottom-cloud-middle.svg";
import bottomCloudRight from "../../assets/icons_new/about/soul-bottom-cloud-right.svg";
import "./Soul.css";

const Soul = ({ title, text }) => (
  <div className="about__soul">
    <div className="about__container">
      <Title {...title} />
      <div className="about__text_soul">
        <Text {...text} />
      </div>
    </div>
    <img src={bottomCloudLeft} alt="decorator" className="about__soul-bottom-cloud middle" />
    <img src={bottomCloudMiddle} alt="decorator" className="about__soul-bottom-cloud left" />
    <img src={bottomCloudRight} alt="decorator" className="about__soul-bottom-cloud right" />
  </div>
);

export default Soul;
