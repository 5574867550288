import React, { Component } from "react";
import { connect } from "react-redux";
import BroadcastHistorySelectionPage from "./BroadcastHistorySelectionPage";
import { Constants } from "../core/constants";
import { sendAction } from "../core/send-action";
import { bindActionCreators } from "redux";
import { showSystemMessage } from "../core/system-message-actions";
import { showModal } from "./../Modals/action-modal";

class BroadcastHistorySelectionContainer extends Component {
  UNSAFE_componentWillMount() {
    // window.analytics.page(LoolaAnalyticsConstants.PAGES.CONFIGURATION_PAGE);
    this.props.sendAction(Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_ALL);
  }

  getHistoryByRoomById = roomId =>
    this.props.sendAction(Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_BY_ROOM, roomId);

  getCommentsByRoomId = roomId =>
    this.props.sendAction(Constants.ACTION_TYPES.BROADCASTS_HISTORY_GET_COMMENTS_BY_ROOM, roomId);

  shareBroadcastOnIGTV = roomId =>
    this.props.sendAction(Constants.ACTION_TYPES.BROADCASTS_HISTORY_SHARE_ON_IGTV, roomId);

  deleteRoom = roomId =>
    this.props.sendAction(Constants.ACTION_TYPES.BROADCASTS_HISTORY_DELETE_ROOM, roomId);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error && this.props.error !== nextProps.error) {
      if (nextProps.error.message) {
        this.props.showSystemMessage("error", nextProps.error.message);
      }
    }
  }

  render() {
    return (
      <BroadcastHistorySelectionPage
        fetchBroadcastById={this.getHistoryByRoomById}
        getCommentsByRoomId={this.getCommentsByRoomId}
        shareBroadcastOnIGTV={this.shareBroadcastOnIGTV}
        deleteRoom={this.deleteRoom}
        showModal={this.props.showModal}
        broadcastsList={this.props.broadcastsList}
        selectedBroadcast={this.props.selectedBroadcast}
        isSharingToIGTV={this.props.runningActions[
          Constants.ACTION_TYPES.BROADCASTS_HISTORY_SHARE_ON_IGTV
        ].isRunning()}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedBroadcast: state.broadcastHistoryData.selectedBroadcast,
    broadcastsList: state.broadcastHistoryData.allBroadcasts,
    error: state.broadcastHistoryData.error,
    runningActions: state.runningActions,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendAction,
      showSystemMessage,
      showModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastHistorySelectionContainer);
