import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { sendAction } from "../core/send-action";
import { Constants } from "../core/constants";
import PropTypes from "prop-types";

class FacebookStateContainer extends React.Component {
  componentDidMount() {
    console.log(`FacebookStateContainer - component did mount`);
    window.document.addEventListener("FBObjectReady", this.initializeFacebookLogin);
    if (this.props.forceInitialize) {
      this.initializeFacebookLogin();
    }
  }

  componentWillUnmount() {
    console.log(`FacebookStateContainer - component will unmount`);
    window.document.removeEventListener("FBObjectReady", this.initializeFacebookLogin);
  }

  /**
   * Init FB object and check Facebook Login status
   */
  initializeFacebookLogin = () => {
    this.FB = window.FB;
    this.checkFacebookLoginState(this.updateLoginState);
  };

  checkFacebookLoginState = onConnectedEvent => {
    console.log(`FacebookStateContainer - checkLoginState`);
    window.FB.getLoginStatus(response => {
      this.statusChangeCallback(response, onConnectedEvent);
    });
  };

  statusChangeCallback = (response, onConnectedEvent) => {
    console.log(`FacebookStateContainer - status change callback ${response.status}`);
    if (response.status === "connected") {
      // Logged into your app and Facebook.
      // FacebookAuthUtil.testAPI();
      console.log("FacebookStateContainer - FacebookAuthUtil.statusChangeCallback: connected.");
      onConnectedEvent(response.status);
    } else if (response.status === "not_authorized") {
      // The person is logged into Facebook, but not your app.
      console.log(
        "FacebookStateContainer - FacebookAuthUtil.statusChangeCallback: Please log into this app."
      );
      onConnectedEvent(response.status);
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
      console.log(
        "FacebookStateContainer - FacebookAuthUtil.statusChangeCallback: Please log into facebook"
      );
    }
  };

  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  updateLoginState = loginState => {
    window.FB.api("/me", response => {
      console.log(
        `FacebookStateContainer - Successful login - state: ${loginState}, for user: ${response.name}`
      );
      this.props.sendAction(Constants.ACTION_TYPES.FACEBOOK_SET_LOGIN_STATE, loginState);
      if (response.name != null) {
        this.props.sendAction(Constants.ACTION_TYPES.FACEBOOK_SET_ACCOUNT, response.name);
      }
    });
  };

  render() {
    return null;
  }
}

FacebookStateContainer.propTypes = {
  forceInitialize: PropTypes.bool,
};

function mapStateToProps(state) {
  const data = state.facebookData;
  return {
    loginState: data.loginState,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(FacebookStateContainer);
