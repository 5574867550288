import { Constants } from "../../core/constants";
import { LoolaApi } from "../../core/loola-api";
import { API_CALL } from "../../redux-middlewares/loola-api-handler";

const platformSettingsActions = {
  [Constants.ACTION_TYPES.PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS]: platformDetails => {
    console.log("action platform settings", platformDetails);
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS,
        payload: LoolaApi.getPlatformAccounts(platformDetails),
      },
    };
  },

  [Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS]: platformDetails => {
    console.log("action platform settings", platformDetails);
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS,
        payload: LoolaApi.updatePlatformSettings(platformDetails),
      },
    };
  },
};

export { platformSettingsActions };
