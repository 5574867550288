import "./ChatMessage.css";

import React from "react";
import PropTypes from "prop-types";
import UserBox from "../UserBox";
import { Constants } from "../../core/constants";
import AllChannelTypes from "../../common/channelTypes";
import ChatGiftMessage from "./ChatGiftMessage";

class ChatMessage extends React.PureComponent {
  renderMessageByType() {
    switch (this.props.type) {
      case "gift":
        return (
          <ChatGiftMessage
            type={this.props.type}
            message={this.props.message}
            giftImage={this.props.giftImage}
          />
        );
      case "follow":
        return (
          <div className="ChatMessage__messageSection ChatMessage__messageSection--follow">
            is now following you
          </div>
        );
      case "join":
        return (
          <div className="ChatMessage__messageSection ChatMessage__messageSection--join">
            just joined
          </div>
        );
      case "leave":
        return;

      default:
        // otherwise, just printing the type == "comment" => shows: text - message
        return (
          <div className="ChatMessage__messageSection ChatMessage__messageSection--text">
            {this.props.message}
          </div>
        );
    }
  }

  render() {
    console.log("RENDER CHAT: ITEM", this.props.type);

    // we are not showing "leave" events.
    if (this.props.type === "leave") return "";

    return (
      <div className="ChatMessage">
        <UserBox
          imageSrc={this.props.userImage}
          name={this.props.userName}
          channel={this.props.userChannel}
          showImageOnly
          imageSize={Constants.ICON_SIZES.LARGE}
        />

        <div className="ChatMessage__dataItem" data-timestamp={this.props.timestamp}>
          <UserBox
            imageSrc={this.props.userImage}
            name={this.props.userName}
            channel={this.props.userChannel}
            textWeight="bold"
            showNameAndChannelOnly
          />

          {this.renderMessageByType()}
        </div>
      </div>
    );
  }
}

ChatMessage.propTypes = {
  userName: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  userChannel: PropTypes.oneOf(AllChannelTypes.channelTypesNames),
  message: PropTypes.string.isRequired,
  giftImage: PropTypes.string,
  type: PropTypes.string,
};

export default ChatMessage;
