import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./ComingSoon.css";

class ComingSoon extends Component {
  state = {
    isDisplayed: false,
    isFading: false,
  };

  clickHandler = () => {
    this.setState({
      isDisplayed: true,
      isFading: false,
    });
  };

  fadeLabel = () => {
    this.setState(prev => {
      return {
        isDisplayed: false,
        isFading: !!prev.isDisplayed,
      };
    });
  };

  render() {
    const { children, text = "Coming Soon", addedClassNameSuffix } = this.props;
    const { isDisplayed, isFading } = this.state;

    return (
      <div className="ComingSoon-wrap" onClick={this.clickHandler} onMouseLeave={this.fadeLabel}>
        <div
          className={cx(
            "ComingSoon",
            isDisplayed ? "active" : "",
            addedClassNameSuffix ? `ComingSoon--${addedClassNameSuffix}` : "",
            isFading ? "fading" : ""
          )}
        >
          {text}
        </div>
        <div className="ComingSoon__content">{children}</div>
      </div>
    );
  }
}

ComingSoon.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  text: PropTypes.string,
};

export default ComingSoon;
