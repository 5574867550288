import "./Spinner.css";
import { Constants } from "./constants";

import React from "react";
import PropTypes from "prop-types";

class Spinner extends React.Component {
  renderDefaultSpinner(size) {
    return <div className={`Spinner Spinner--default Spinner--default--${size}`} />;
  }

  renderAccordionSpinner(size) {
    return (
      <div className={`Spinner Spinner--accordion Spinner--accordion--${size}`}>
        <div
          className={`Spinner--accordionDiv Spinner--accordionDiv--${size} Spinner--accordionRect1`}
        />
        <div
          className={`Spinner--accordionDiv Spinner--accordionDiv--${size} Spinner--accordionRect2`}
        />
        <div
          className={`Spinner--accordionDiv Spinner--accordionDiv--${size} Spinner--accordionRect3`}
        />
        <div
          className={`Spinner--accordionDiv Spinner--accordionDiv--${size} Spinner--accordionRect4`}
        />
        <div
          className={`Spinner--accordionDiv Spinner--accordionDiv--${size} Spinner--accordionRect5`}
        />
      </div>
    );
  }

  render() {
    if (!this.props.isVisible) return null;

    const {
      type = Constants.SPINNER_TYPES.DEFAULT,
      size = Constants.SPINNER_SIZES.SMALL,
    } = this.props;

    switch (type) {
      case Constants.SPINNER_TYPES.DEFAULT:
        return this.renderDefaultSpinner(size);
      case Constants.SPINNER_TYPES.ACCORDION:
        return this.renderAccordionSpinner(size);
      default:
        return this.renderDefaultSpinner();
    }
  }
}

Spinner.propTypes = {
  isVisible: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(Constants.SPINNER_TYPES)),
  size: PropTypes.oneOf(Object.values(Constants.SPINNER_SIZES)),
};

export { Spinner };
