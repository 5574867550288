import React, { Component } from "react";
import LoolaAppHeaderContainer from "../LoolaAppHeader/LoolaAppHeaderContainer";
import { sendAction } from "../core/send-action";
import ModalRoot from "../Modals/ModalRoot";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { SystemMessage } from "../core/SystemMessage";
import { LandscapeLayout } from "./LandscapeLayout";
import LoolaPermanentMessages from "./LoolaPermanentMessages";

class Layout extends Component {
  shouldNotShowLoolaAppHeader() {
    const notShowLoolaAppHeader =
      this.props.avoidLoolaAppHeader.indexOf(this.props.location.pathname) > -1;
    return notShowLoolaAppHeader;
  }

  renderLoolaAppHeader() {
    if (this.shouldNotShowLoolaAppHeader()) return null;

    return <LoolaAppHeaderContainer showSystemMessage={this.handleShowSystemMessage} />;
  }

  renderBrowserDetectionMessage() {
    if (this.shouldNotShowLoolaAppHeader()) return null;

    return <LoolaPermanentMessages />;
  }

  render() {
    const mainContentDivClassName = this.shouldNotShowLoolaAppHeader()
      ? "without-nav-container"
      : "with-nav-container";

    return (
      <div className="full-height">
        <LandscapeLayout />
        <SystemMessage />
        {this.renderLoolaAppHeader()}
        <div className={mainContentDivClassName}>{this.props.children}</div>
        {this.renderBrowserDetectionMessage()}
        {/* <LoolaAppFooter /> */}
        <ModalRoot />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(Layout));
