import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import "./SinglePlatformSettingsPage.css";
import { Constants } from "../../core/constants";
import ButtonWithStatus from "../../core/ButtonWithStatus";
// import Button from "../../core/Button";

class SinglePlatformSettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destinationSubject: "",
      destinationId: "",
    };
  }

  setStateForSelectors(destinationSubject, destinationId) {
    this.setState({ destinationSubject });
    this.setState({ destinationId });
  }

  UNSAFE_componentWillMount() {
    // Loading Room Configuration
    console.log("SinglePlatformSettingsPage - componentWillMount", this.props);
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS, {
      platformType: this.props.platformType,
    });
  }

  componentDidMount() {
    this.setStateForSelectors(this.props.selectedAccountType, this.props.selectedAccountId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isSuccessPlatformUpdateSettings) {
      // this.changePhaseToInitial();
      this.props.onClose();
    }

    console.log("SinglePlatformSettingsPage - receive props", this.props, nextProps);

    if (nextProps.selectedAccountId !== this.props.selectedAccountId) {
      this.setStateForSelectors(nextProps.selectedAccountType, nextProps.selectedAccountId);
    }
  }

  handleSave = () => {
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS, {
      platformType: this.props.platformType,
      selectedAccount: this.state.destinationId,
    });
  };

  onDestinationSubjectSelectionChange = event => {
    this.setStateForSelectors(event.target.value, "");
  };

  onDestinationIdSelectionChange = event => {
    this.setStateForSelectors(this.state.destinationSubject, event.target.value);
  };

  renderDestinationSubjects = () => (
    <select
      id="destinationSubjectSelection"
      name="destinationSubjectSelection"
      onChange={this.onDestinationSubjectSelectionChange}
      value={this.state.destinationSubject}
    >
      <option value="profile">Share on Your Timeline</option>
      <option value="page">Share on a Page you Manage</option>
      <option value="group">Share in a Group</option>
    </select>
  );

  renderPossibleItems = () => {
    if (this.state.destinationSubject === "" || this.state.destinationSubject === "profile") {
      return null;
    }

    const accountOptions = this.props.platformAccounts.filter(
      item => item.type === this.state.destinationSubject
    );

    if (accountOptions == null || accountOptions.length === 0) {
      return <div> No {this.state.destinationSubject} to choose from </div>;
    }

    const optionsComponent = accountOptions.map(item => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ));
    return (
      <div>
        <select
          id="destinationSelection"
          name="destinationSelection"
          onChange={this.onDestinationIdSelectionChange}
          value={this.state.destinationId}
        >
          <option value="" disabled hidden>
            Please Choose...
          </option>
          {optionsComponent}
        </select>
      </div>
    );
  };

  render() {
    // const { platformType, platforms } = this.props;
    // const platform = platforms.find(currPlatform => currPlatform.platformType === platformType);

    console.log("render platform settings", this.props);
    return (
      <div className="SinglePlatformSettingsPage">
        <div className="SinglePlatformSettingsPage__title">Update Your Platform Settings</div>
        <div className="SinglePlatformSettingsPage__section">
          Choose where to post your live broadcast:
        </div>
        <div className="SinglePlatformSettingsPage__section">
          {this.renderDestinationSubjects()}
        </div>
        <div className="SinglePlatformSettingsPage__section">{this.renderPossibleItems()}</div>
        <div className="SinglePlatformSettingsPage__section">
          <ButtonWithStatus
            disabled={this.props.isPageLoading}
            showLoading={this.props.isRunningPlatformUpdateSettings}
            onClick={() => {
              this.handleSave();
            }}
          />
        </div>
      </div>
    );
  }
}

SinglePlatformSettingsPage.propTypes = {
  platformType: PropTypes.string,
  platformAccounts: PropTypes.array,
  isPageLoading: PropTypes.bool,
  isSuccessPlatformUpdateSettings: PropTypes.bool,
  isErrorPlatformUpdateSettings: PropTypes.bool,
  isRunningPlatformUpdateSettings: PropTypes.bool,
};

export default connect(state => {
  return {
    platforms: state.platformsData.platforms,
    platformSettingsData: state.platformSettingsData.accounts,
    is2Auth: state.platformsData,
  };
}, null)(SinglePlatformSettingsPage);
