import { Constants } from "../core/constants";
import { LoolaApi } from "../core/loola-api";

const facebookActions = {
  [Constants.ACTION_TYPES.FACEBOOK_SET_LOGIN_STATE]: loginState => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_SET_LOGIN_STATE,
      payload: { status: loginState },
    };
  },

  [Constants.ACTION_TYPES.FACEBOOK_SET_ACCOUNT]: facebookName => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_SET_ACCOUNT,
      payload: { name: facebookName },
    };
  },

  [Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_COOKIE]: instagramCookie => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_COOKIE,
      payload: { cookie: instagramCookie },
    };
  },

  [Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_TOKEN]: instagramToken => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_TOKEN,
      payload: { token: instagramToken },
    };
  },

  [Constants.ACTION_TYPES.FACEBOOK_CLEAR_INSTAGRAM_TOKEN]: () => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_CLEAR_INSTAGRAM_TOKEN,
      payload: { token: "" },
    };
  },

  [Constants.ACTION_TYPES.FACEBOOK_STORE_INSTAGRAM_TOKEN]: data => {
    return {
      type: Constants.ACTION_TYPES.FACEBOOK_STORE_INSTAGRAM_TOKEN,
      payload: LoolaApi.storeInstagramAuthToken(data.platformType, data.token, data.countryCode),
    };
  },
};

export { facebookActions };
