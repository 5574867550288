import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MyBroadcastsBox.css";
import PropTypes from "prop-types";
import "./VideoLink.css";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class VideoLink extends Component {
  onClick = e => {
    e.stopPropagation();

    // update analytics
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.DOWNLOAD_BROADCAST_VIDEO_CLICKED);

    window.open(this.props.filePath, "_blank");
  };

  render() {
    if (this.props.filePath === undefined || this.props.filePath === null) return null;

    return (
      <div
        className="VideoLink left"
        onClick={this.onClick}
        data-tip={`Download ${this.props.type} video`}
      >
        <FontAwesomeIcon icon="download" name="download" className="fa fa-download" />
      </div>
    );
  }
}

VideoLink.propTypes = {
  filePath: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default VideoLink;
