import React, { Component } from "react";
import "./BroadcastSummaryClose.css";

class BroadcastSummaryClose extends Component {
  render() {
    const { closeBroadcastSummary } = this.props;

    return (
      <div className="BroadcastSummaryClose">
        <div className="BroadcastSummaryClose-button" onClick={closeBroadcastSummary}>
          +
        </div>
      </div>
    );
  }
}

export default BroadcastSummaryClose;
