import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import cx from "classnames";

class Questioner extends React.Component {
  handleChooseQuestion = () => {
    const { questioner } = this.props;

    if (this.props.isSelected) this.props.hidePoll(questioner.questionId);
    else this.props.showPoll(questioner.questionId);
  };

  render() {
    const { questioner, isSelected } = this.props;

    return (
      <div
        className={cx("question-block-wrapper", isSelected && "question-focused")}
        onClick={this.handleChooseQuestion}
      >
        <img src={questioner.avatar} alt={questioner.nickName} className="question-avatar" />

        <div className="question-block">
          <p className={cx("question-name", isSelected && "question-text-focused")}>
            {questioner.nickName}
          </p>

          <p className={cx("question", isSelected && "question-text-focused")}>
            {questioner.question}
          </p>

          <div className={cx("question-hider", isSelected && "question-text-focused")}>
            <FontAwesomeIcon icon="eye" name="toggle" className="fas fa-eye" />
          </div>

          {!isSelected && <div className="question-line" />}
        </div>
      </div>
    );
  }
}

Questioner.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  questioner: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  showPoll: PropTypes.func.isRequired,
  hidePoll: PropTypes.func.isRequired,
};

export default Questioner;
