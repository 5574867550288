import React from "react";
import Modal from "./Modal";
import "./ConfirmationModal.css";

const ConfirmationModal = ({
  title,
  onConfirm,
  confirmText,
  rejectText,
  hideModal,
  confirmationContent,
  height,
  width,
}) => {
  const handleConfirm = isConfirmed => () => {
    hideModal();
    onConfirm(isConfirmed);
  };
  const onClose = () => {
    hideModal();
  };

  return (
    <Modal width={width} height={height} title={title} onClose={onClose}>
      <div className="confirmation-modal__wrapper">
        {confirmationContent}
        <div className="confirmation-modal__action-container">
          <button
            className="Button Button--delete confirmation-modal__action--yes"
            onClick={handleConfirm(true)}
          >
            {confirmText}
          </button>
          <button
            className="Button Button--cancel confirmation-modal__action--no"
            onClick={handleConfirm(false)}
          >
            {rejectText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
