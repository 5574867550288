import { Constants } from "../../core/constants";

const initialState = {
  comments: [],
  error: null,
  errorMessage: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.LIVE_GET_COMMENTS:
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      if (action.payload == null || !Array.isArray(action.payload)) return state;

      const comments = state.comments.slice(0); // clone

      // Reversing the comments array before initializing
      // const addedComments = action.payload.reverse().slice(0); // clone
      const addedComments = action.payload.slice(0); // clone
      const newComments = comments.concat(addedComments);

      // we are showing only 150 comments to the host
      const limit = 150;
      let removeCount = 0;
      if (newComments.length > limit) {
        removeCount = newComments.length - limit;
      }

      if (removeCount > 0) {
        // we cut the first (oldest) messages (as the list is concatenated and the new messages will appear at the end)
        // later in the UI we will reverse the array to show the last messages (latest) first (on top)
        const editedComments = newComments.slice(removeCount);
        console.log(
          "RENDER CHAT: REDUCER: AFTER SPLICE -- before & after",
          newComments.length,
          editedComments.length
        );

        const gifts = editedComments.filter(item => item.type === "gift");
        console.log("gifts count --", gifts.length);

        return Object.assign({}, state, {
          comments: editedComments,
          error: null,
          errorMessage: "",
        });
      }

      // else
      return Object.assign({}, state, {
        comments: newComments,
        error: null,
        errorMessage: "",
      });

    case Constants.ACTION_TYPES.LIVE_WRITE_COMMENT:
    default:
      return state;
  }
}
