import "./LandscapeLayout.css";

import React, { Component } from "react";

class LandscapeLayout extends Component {
  render() {
    if (!window.isMobile) return null;

    return <div className="LandscapeLayout">Please use portrait orientation</div>;
  }
}

export { LandscapeLayout };
