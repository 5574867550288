import React, { Component } from "react";
import Button from "../core/Button";
import PropTypes from "prop-types";

class ButtonWithStatus extends Component {
  renderText() {
    const { showSuccess } = this.props;
    const { showLoading } = this.props;

    if (showLoading) {
      return <span>Apply</span>;
    }

    if (showSuccess) {
      return <span>Saved &#10004;</span>;
    }

    return <span>Save</span>;
  }

  render() {
    return (
      <Button
        onClick={this.props.onClick}
        className="btnApply"
        disabled={this.props.disabled}
        theme="save"
        showLoading={this.props.showLoading}
      >
        {this.renderText()}
      </Button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  additionalClassName: PropTypes.string,
  showLoading: PropTypes.bool,
  showSuccess: PropTypes.bool,
};

export default ButtonWithStatus;
