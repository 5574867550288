import FacebookLogo from "../assets/icons/channels/facebook.svg";
import CustomRTMPLogo from "../assets/icons/channels/custom-rtmp.svg";
import TwitterLogo from "../assets/icons/channels/twitter.svg";
import TwitchLogo from "../assets/icons/channels/twitch.svg";
import TrovoLogo from "../assets/icons/channels/trovo.svg";
import SnapchatLogo from "../assets/icons/channels/snapchat.svg";
import TikTokLogo from "../assets/icons/channels/tiktok.svg";
import YoutubeLogo from "../assets/icons/channels/youtube-new.svg";
import InstagramLogo from "../assets/icons/channels/instagram.svg";
// import PeriscopeLogo from "../assets/icons/channels/periscope.svg";
// import LivemeLogo from "../assets/icons/channels/liveme.svg";
// import UpLiveLogo from "../assets/icons/channels/uplive.svg";
// import SeventeenLogo from "../assets/icons/channels/seventeen-new.svg";

const channelTypes = {
  youtube: { key: "youtube", name: "YouTube", logo: YoutubeLogo },
  instagram: { key: "instagram", name: "Instagram", logo: InstagramLogo },
  tiktok: { key: "tiktok", name: "TikTok", logo: TikTokLogo },
  twitter: { key: "twitter", name: "Twitter", logo: TwitterLogo },
  trovo: { key: "trovo", name: "Trovo", logo: TrovoLogo },
  periscope: { key: "periscope", name: "Twitter", logo: TwitterLogo },
  twitch: { key: "twitch", name: "Twitch", logo: TwitchLogo },
  facebook: { key: "facebook", name: "Facebook", logo: FacebookLogo },
  facebookrtmp: { key: "facebookrtmp", name: "Facebook", logo: FacebookLogo },
  customrtmp: { key: "customrtmp", name: "Custom", logo: CustomRTMPLogo },
  snapchat: { key: "snapchat", name: "Snapchat", logo: SnapchatLogo },
  // liveme: { key:"liveme", name: "LiveMe", logo: LivemeLogo },
  // uplive: { key:"uplive", name: "UpLive", logo: UpLiveLogo },
  // seventeen: { key:"seventeen", name: "17 Live", logo: SeventeenLogo },
};

const channelTypesNames = Object.keys(channelTypes).map(index => index);

const getChannelName = key => channelTypes[key].name;

const getChannelImageLogo = key => {
  if (channelTypes[key]) {
    const obj = channelTypes[key];
    return obj.logo;
  }
  throw new Error("value not exist");
};

const oAuthPlatformTypes = [
  "youtube",
  "facebook",
  "twitch",
  "twitter",
  "trovo",
  "periscope",
  "instagram",
];
const rtmpPlatformTypes = ["customrtmp", "facebookrtmp"];

const getPlatformAuthType = platformType => {
  if (oAuthPlatformTypes.includes(platformType)) return "OAUTH";
  else if (rtmpPlatformTypes.includes(platformType)) return "RTMP";
  else return "PASSWORD";
};

export default {
  channelTypes,
  channelTypesNames,
  getChannelName,
  getChannelImageLogo,
  getPlatformAuthType,
};
