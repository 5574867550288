import React from "react";

function PrettyCounter({ value, mode, additionalNumber }) {
  return (
    <React.Fragment>
      {prettifyNumber(value)} {additionalNumber && `(${prettifyNumber(additionalNumber)})`}
    </React.Fragment>
  );

  function prettifyNumber(v) {
    let val = parseInt(v, 0);
    val = val > 0 ? val : 0;

    // return if the value doesn't larger than 10000
    if (v.length < 5) {
      return val;
    }

    switch (mode) {
      case "regular":
        return fillNumberWithSpaces(val);

      case "shortcut":
        return abbrNum(val, 0);

      default:
        return fillNumberWithSpaces(val);
    }

    // 1000 will be transformed to 1 000, 1000000 -> 1 000 000 and so on
    function fillNumberWithSpaces() {
      return (+val).toLocaleString().replace(/,/gi, " ");
    }

    // got function from existing component
    function abbrNum(number, decPlaces) {
      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = 10 ** decPlaces; // Math.Pow(10, decPlaces)

      // Enumerate number abbreviations
      const abbrev = ["k", "m", "b", "t"];

      // Go through the array backwards, so we do the largest first
      for (let i = abbrev.length - 1; i >= 0; i -= 1) {
        // Convert array index to "1000", "1000000", etc
        const size = 10 ** ((i + 1) * 3); // Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
          // Here, we multiply by decPlaces, round, and then divide by decPlaces.
          // This gives us nice rounding to a particular decimal place.
          number = Math.round((number * decPlaces) / size) / decPlaces;

          // Add the letter for the abbreviation
          number += abbrev[i];

          // We are done... stop
          break;
        }
      }

      return number;
    }
  }
}

export default PrettyCounter;
