import React from "react";
import { Redirect } from "react-router-dom";
import qs from "query-string";
import { setFacebookAuthToken } from "./platform-auth-token-util";

class FacebookAuthLandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "started",
    };
  }

  // TODO: Code Review with some FED Expert, this one was written based on this - https://davidwalsh.name/window-postmessage
  componentDidMount() {
    const { search } = this.props.location;
    const queryString = qs.parse(search);
    const { code, state } = queryString;

    console.log("facebook response querystring breakdown", code, state, queryString);
    setFacebookAuthToken(code, state)
      .then(data => {
        console.log("set auth token finished successfully", data);
        this.setState({ status: "success" });
      })
      .catch(err => {
        console.log("crap, something went wrong", err);
        this.setState({ status: "failed" });
      });
  }

  render() {
    if (this.state.status === "success") return <Redirect to="/configure-platforms" />;

    return <div>Validating & Authenticating your Facebook Account...</div>;
  }
}

export default FacebookAuthLandingPage;
