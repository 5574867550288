import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import PrettyCounter from "../core/PrettyCounter";
import "./SummaryCounter.css";

class SummaryCounter extends React.Component {
  render() {
    const { title, value, addedClassName } = this.props;
    const csname = addedClassName ? `SummaryCounter--${addedClassName}` : "";

    return (
      <section className={cx("SummaryCounter", csname)}>
        <div className="SummaryCounter__title">{title}</div>
        <div className="SummaryCounter__children">{this.props.children}</div>
        <div className="SummaryCounter__value">
          <PrettyCounter value={value} />
        </div>
      </section>
    );
  }
}

SummaryCounter.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SummaryCounter;
