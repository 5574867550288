import React, { Component } from "react";
import config from "../Config";

class TwitterLoginButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      authUrl: "",
    };
  }
  componentDidMount() {
    // TODO: extract to action-creator
    this.fetchAuthenticationUrl();
  }

  fetchAuthenticationUrl() {
    fetch(`${config.apiurl}/twitter-auth/start-twitter-auth`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(data => data.json())
      .then(response => {
        console.log("TWITTER Authentication URL", response);
        this.setState({
          authUrl: response.redirectUrl,
          loaded: true,
        });
      })
      .catch(err => {
        console.log("ERROR OCCURRED, probably server is down", err);
      });
  }

  render() {
    const platformOAuthUrl = this.state.authUrl;

    if (!this.state.loaded) return <div> loading... </div>;

    return (
      <div>
        <a href={platformOAuthUrl}>Twitter Connect</a>
      </div>
    );
  }
}

export default TwitterLoginButton;
