import { PlatformStatuses } from "../core/server_enums";

class PlatformModel {
  constructor(platformModel = {}) {
    this.platformType = platformModel.platformType || null;
    this.status = platformModel.status || PlatformStatuses.NOT_CONFIGURED;
    this.username = platformModel.username || null;
    this.publicPlatformHandle = platformModel.publicPlatformHandle || null;
    this.authType = platformModel.authType || null;
    this.rtmpConfigurationData = new RTMPConfigurationModel(
      platformModel.rtmpConfigurationData || {}
    );
  }

  getPlatformPublicHandle() {
    return this.publicPlatformHandle ? this.publicPlatformHandle : this.username;
  }

  isConfiguredOrAuthenticated() {
    return this.status !== PlatformStatuses.NOT_CONFIGURED;
  }
}

class RTMPConfigurationModel {
  constructor(configurationModel = {}) {
    this.rtmpServer = configurationModel.rtmpServer || "";
    this.streamKey = configurationModel.streamKey || "";
  }

  static isValidRTMPServerPath(rtmpServerUrl) {
    const returnValue = {
      isValid: false,
      errorMessages: [],
    };

    if (rtmpServerUrl == null) {
      returnValue.errorMessages.push("Please provide a valid url");
    }

    const startWithRTMP = rtmpServerUrl.match("^(rtmp://|rtmps://)");
    if (startWithRTMP === null || startWithRTMP.length === 0) {
      returnValue.errorMessages.push("The url must starts with either rtmp:// or rtmps://");
    }

    if (returnValue.errorMessages.length === 0) {
      returnValue.isValid = true;
    }

    return returnValue;
  }

  getFullRTMPPath() {
    const urlPathArr = [this.rtmpServer, this.streamKey];
    const url = this.rtmpServer.endsWith("/") ? urlPathArr.join("/") : urlPathArr.join("");

    return url;
  }
}

class ConfiguredPlatformsModel {
  constructor(platformModelArr = []) {
    this.platformModelArr = platformModelArr;
  }

  hasAtLeastOneAuthenticated() {
    if (this.platformModelArr.length === 0) return false;
    let atLeastOneAuthenticated = false;
    this.platformModelArr.forEach(platformModel => {
      if (platformModel.status === PlatformStatuses.AUTHENTICATED) atLeastOneAuthenticated = true;
    });
    return atLeastOneAuthenticated;
  }

  isInstagramAuthenticated() {
    return this.isAuthenticated("instagram");
  }

  isAuthenticated(platformType) {
    const instagramIndex = this.platformModelArr.findIndex(
      platform => platform.platformType === platformType
    );

    if (instagramIndex === -1) return false;
    return this.platformModelArr[instagramIndex].status === PlatformStatuses.AUTHENTICATED;
  }
}

export { PlatformModel, ConfiguredPlatformsModel };
