import React, { Component } from "react";

class UserMic extends Component {
  render() {
    const { enabled, onClick } = this.props;

    return (
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="49"
        viewBox="0 0 50 49"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M32.1 24.48c0 3.842-3.178 6.958-7.1 6.958-3.92 0-7.099-3.116-7.099-6.958h-2.276c.065 4.544 3.456 8.384 8.036 9.098v4.476h2.678v-4.477c4.58-.715 7.971-4.554 8.036-9.099H32.1v.002zm-7.099 4.074c2.218.002 4.019-1.757 4.021-3.93a5.22 5.22 0 0 0-.002-.144v-8.147c.08-2.175-1.654-4.001-3.873-4.08-2.22-.08-4.083 1.62-4.164 3.795-.004.095-.004.19 0 .285v8.147c-.078 2.172 1.655 3.996 3.872 4.071.048.001.097.003.146.003z"
          />
          {!enabled && <path fill="#FFF" d="M14.34 6.51L39.906 38.08l-2.38 1.928L11.96 8.437z" />}
        </g>
      </svg>
    );
  }
}

export default UserMic;
