import { API_CALL } from "../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../core/loola-api";
import { Constants } from "../core/constants";

const goLiveActions = {
  [Constants.ACTION_TYPES.LIVE_GET_ROOM_CONFIGURATION]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_GET_ROOM_CONFIGURATION,
        payload: LoolaApi.getRoomConfiguration(),
      },
    };
  },

  [Constants.ACTION_TYPES.LIVE_GO_LIVE]: () => {
    return {
      type: Constants.ACTION_TYPES.LIVE_GO_LIVE,
      payload: "live",
    };
  },

  [Constants.ACTION_TYPES.LIVE_END_LIVE]: () => {
    return {
      type: Constants.ACTION_TYPES.LIVE_END_LIVE,
      payload: "end_live",
    };
  },
};

export { goLiveActions };
