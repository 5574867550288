import "./SubscriptionCompletedPage.css";

import React, { Component } from "react";

import LoolaPublicPageHeader from "../LandingPage/components/LoolaPublicPageHeader";
import LandingPageFooter from "../LandingPage/LandingPageFooter";

import { StudioConnectPagePath } from "../../PageConstants";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class SubscriptionCompletedPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.THANK_YOU_PAGE);
  }

  render() {
    return (
      <div className="SubscriptionCompletedPage">
        <LoolaPublicPageHeader textColor="black" logoColor="black" />

        <div className="thank_you">
          <h3>Thanks for your subscription!</h3>

          <p>
            You will get an email from us soon.
            <br />
          </p>

          <p>
            For any questions, please contact us at{" "}
            <a href="mailto:support@loola.tv">support@loola.tv</a>
          </p>

          <a href={StudioConnectPagePath} className="getStartedThankYou">
            Get Started
          </a>
        </div>

        <LandingPageFooter />
      </div>
    );
  }
}

export default SubscriptionCompletedPage;
