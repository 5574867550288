import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Constants } from "../../core/constants";
import { sendAction } from "../../core/send-action";
import PollsSettingsTab from "./PollsSettingsTab";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class PollsSettingsTabContainer extends React.Component {
  handleShowPoll = questionId => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.LIVE_ACTION_BAR_SAVE_Q_AND_A, questionId);

    const questionPayload = { questionId };
    this.props.sendAction(Constants.ACTION_TYPES.POLLS_SHOW_QUESTION, questionPayload);
  };

  handleHidePoll = questionId => {
    const questionPayload = { questionId };
    this.props.sendAction(Constants.ACTION_TYPES.POLLS_HIDE_QUESTION, questionPayload);
  };

  handleLoadPolls = () => {
    this.props.sendAction(Constants.ACTION_TYPES.POLLS_GET_QUESTIONS);
  };

  render() {
    return (
      <PollsSettingsTab
        canEdit
        questionsList={this.props.allQuestions}
        selectedQuestionId={this.props.selectedQuestionId}
        loadPolls={this.handleLoadPolls}
        showPoll={this.handleShowPoll}
        hidePoll={this.handleHidePoll}
        isLoadingQuestions={this.props.isLoadingQuestions}
        isInstagramAuthenticated={this.props.isInstagramAuthenticated}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoaded: state.userSettingsData.isLoaded,
    allQuestions: state.pollsSettingsData.allQuestions,
    selectedQuestionId: state.pollsSettingsData.selectedQuestionId,
    isBroadcasting: state.liveVideoData.isBroadcasting,
    isLoadingQuestions: state.runningActions[
      Constants.ACTION_TYPES.POLLS_GET_QUESTIONS
    ].isRunning(),
    isInstagramAuthenticated: state.platformsData.configuredPlatformsModel.isInstagramAuthenticated(),
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(PollsSettingsTabContainer);
