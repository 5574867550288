import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MirrorFlipCamIcon from "../../../assets/icons/actions/MirrorFlipCamIcon";
import ScreenShareCamIcon from "../../../assets/icons/actions/ScreenShareCamIcon";
import UserCamIcon from "../../../assets/icons/actions/UserCamIcon";
import UserMicIcon from "../../../assets/icons/actions/UserMicIcon";

import { BroadcastSettingsItem } from "./BroadcastSettingsItem";
import "./BroadcastSettings.css";
import { resolutionOptionsPreset } from "./contants";
import { WebRTCInputEnum } from "../constants";
import {
  createOffer,
  getOffer,
  useEffectOnce,
  createOfferWithStream,
  getMergedAudioTrack,
} from "./utils";
import {
  saveBroadcastConfig,
  muteAudio,
  muteVideo,
  unmuteAudio,
  unmuteVideo,
  flipVideo,
  startScreenShare,
  stopScreenShare,
} from "../../../LiveVideo/live-broadcast-actions";

const BroadcastSettings = props => {
  const {
    videoinput,
    audioinput,
    resolutioninput,
    isAudioMuted,
    isVideoMuted,
    isVideoFlipped,
  } = props.broadcastConfig;

  const [localStream, setLocalStream] = useState(null);
  const [desktopAudioTrack, setDesktopAudioTrack] = useState(null);

  const selectedVideoInputId = useMemo(() => videoinput || props.videoInputs[0]?.deviceId, [
    videoinput,
    props.videoInputs,
  ]);
  const selectedAudioInputId = useMemo(() => audioinput || props.audioInputs[0]?.deviceId, [
    audioinput,
    props.audioInputs,
  ]);
  const selectedResolutionId = useMemo(() => resolutioninput || 0, [resolutioninput]);

  // This should fire only after first render with initial values from store, that's why deps array is empty
  useEffectOnce(() => {
    if (isAudioMuted) props.muteAudio();
    if (isVideoMuted) props.muteVideo();
    if (isVideoFlipped) props.flipVideo();
  });

  const handleSuccessInputChange = (inputType, mediaDeviceId) => {
    props.saveBroadcastConfig({ [inputType]: mediaDeviceId });
    // TODO there is a bug, that not any source change should clear any error
    props.notifyOnSuccessfulChange();
  };

  const handleSuccessStartScreenShare = () => {
    props.startScreenShare();
  };

  const handleScreenShareEnded = () => {
    handleVideoChange({ deviceId: selectedVideoInputId });
  };

  const handleStopScreenShare = () => {
    props.stopScreenShare();
    setLocalStream(null);
    if (desktopAudioTrack) {
      desktopAudioTrack.stop();
    }
    setDesktopAudioTrack(null);
    handleAudioChange({ deviceId: selectedAudioInputId, forceReplace: true });
  };

  const handleResolutionChange = value => {
    const { width, height } = value;
    const offer = getOffer({ videoDeviceId: selectedVideoInputId, width, height });
    createOffer({
      room: props.videoRoomLocal,
      offer,
      onSuccess: () => handleSuccessInputChange(WebRTCInputEnum.resolutionInput, value.id),
    });
  };

  const handleVideoChange = value => {
    const { width, height } = resolutionOptionsPreset[selectedResolutionId];
    const offer = getOffer({ videoDeviceId: value.deviceId, width, height });
    createOffer({
      room: props.videoRoomLocal,
      offer,
      onSuccess: () => {
        handleSuccessInputChange(WebRTCInputEnum.videoInput, value.deviceId);
        handleStopScreenShare();
        if (isVideoMuted) {
          props.muteVideo();
        }
      },
      onError: () => props.notifySourceSelectionError("video", "overconstrained"),
    });
  };

  const handleAudioChange = value => {
    if (localStream && !value.forceReplace) {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: {
            deviceId: value.deviceId,
            ...stereoAudioSupport,
          },
        })
        .then(audioStream => {
          localStream.removeTrack(localStream.getAudioTracks()[0]);
          const micAudioTrack = audioStream.getAudioTracks()[0];
          if (desktopAudioTrack) {
            const mergedAudioTrack = getMergedAudioTrack(desktopAudioTrack, micAudioTrack);
            localStream.addTrack(mergedAudioTrack);
          } else {
            localStream.addTrack(micAudioTrack);
          }

          createOfferWithStream({
            room: props.videoRoomLocal,
            stream: localStream,
            replaceVideo: false,
            onSuccess: () => {
              handleSuccessInputChange(WebRTCInputEnum.audioInput, value.deviceId);
              if (isAudioMuted) {
                props.muteAudio();
              }
            },
          });
        });
    } else {
      const offer = getOffer({ audioDeviceId: value.deviceId });
      createOffer({
        room: props.videoRoomLocal,
        offer,
        onSuccess: () => {
          handleSuccessInputChange(WebRTCInputEnum.audioInput, value.deviceId);
          if (isAudioMuted) {
            props.muteAudio();
          }
        },
      });
    }
  };

  const stereoAudioSupport = {
    channelCount: 2,
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false,
  };
  const audioSuppressLocalPlayback = { suppressLocalAudioPlayback: true };

  const handleScreenShareClick = () => {
    if (props.isScreenSharing) {
      handleVideoChange({ deviceId: selectedVideoInputId });
    } else {
      const shareScreenAudio = Object.assign({}, audioSuppressLocalPlayback, stereoAudioSupport);
      navigator.mediaDevices
        .getDisplayMedia({
          video: { frameRate: 30 },
          audio: shareScreenAudio,
        })
        .then(stream => {
          const videoTrack = stream.getVideoTracks()[0];
          const deskAudioTrack = stream.getAudioTracks()[0];
          videoTrack.contentHint = "motion";
          videoTrack.onended = handleScreenShareEnded;
          navigator.mediaDevices
            .getUserMedia({
              audio: {
                deviceId: selectedAudioInputId,
                ...stereoAudioSupport,
              },
              video: false,
            })
            .then(audioStream => {
              const micAudioTrack = audioStream.getAudioTracks()[0];
              if (deskAudioTrack) {
                stream.removeTrack(deskAudioTrack);
                const mergedAudioTrack = getMergedAudioTrack(deskAudioTrack, micAudioTrack);
                stream.addTrack(mergedAudioTrack);
              } else {
                stream.addTrack(micAudioTrack);
              }

              createOfferWithStream({
                room: props.videoRoomLocal,
                stream,
                onSuccess: () => {
                  handleSuccessStartScreenShare();
                  setDesktopAudioTrack(deskAudioTrack);
                  setLocalStream(stream);
                },
                onError: () => handleVideoChange({ deviceId: selectedVideoInputId }),
              });
            });
        });
    }
  };

  const handleAudioClick = () => {
    if (isAudioMuted) {
      props.unmuteAudio();
    } else {
      props.muteAudio();
    }
  };

  const handleVideoClick = () => {
    if (isVideoMuted) {
      props.unmuteVideo();
    } else {
      props.muteVideo();
    }
  };

  const handleFlipClick = () => {
    props.flipVideo();
  };

  return (
    <div className="LocalUser__broadcastSettings">
      <BroadcastSettingsItem
        icon={MirrorFlipCamIcon}
        label="Flip"
        onClick={handleFlipClick}
        rotated={!isVideoFlipped}
      />
      <BroadcastSettingsItem
        label="Audio"
        icon={UserMicIcon}
        onClick={handleAudioClick}
        menuItems={[
          {
            items: props.audioInputs,
            isSelected: item => item.deviceId === selectedAudioInputId,
            onChange: handleAudioChange,
          },
        ]}
        enabled={!isAudioMuted}
      />
      <BroadcastSettingsItem
        label="Video"
        icon={UserCamIcon}
        onClick={handleVideoClick}
        menuItems={[
          {
            items: resolutionOptionsPreset,
            isSelected: value => value.id === selectedResolutionId,
            isDisabled: props.isScreenSharing,
            onChange: handleResolutionChange,
          },
          {
            items: props.videoInputs,
            isSelected: value => value.deviceId === selectedVideoInputId && !props.isScreenSharing,
            onChange: handleVideoChange,
          },
        ]}
        enabled={!isVideoMuted}
      />
      <BroadcastSettingsItem
        icon={ScreenShareCamIcon}
        onClick={handleScreenShareClick}
        label={props.isScreenSharing ? "Stop share" : "Share screen"}
        enabled={props.isScreenSharing}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { broadcastConfig, isScreenSharing } = state.liveVideoData;
  return {
    broadcastConfig,
    isScreenSharing,
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveBroadcastConfig,
      muteAudio,
      unmuteAudio,
      muteVideo,
      unmuteVideo,
      flipVideo,
      startScreenShare,
      stopScreenShare,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastSettings);
