import "./MainVideoRoomPage.css";

import React, { Component } from "react";
import MobileVideoRoomView from "./MobileVideoRoomView";
import WebVideoRoomView from "./WebVideoRoomView";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

export default class MainVideoRoomPage extends Component {
  componentDidMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.MAIN_VIDEO_ROOM);
  }

  // This Page is being used mostly as a router to the relevant View (either web or mobile)
  // on Web the Video Room will appear a bit different with the chat on the right, on mobile the chat will appear as overly, etc.
  render() {
    return window.isMobile ? <MobileVideoRoomView /> : <WebVideoRoomView />;
  }
}
