import "./BroadcastHistorySelectionPage.css";
import React, { Component } from "react";
import MyBroadcastsBox from "../common/MyBroadcasts/MyBroadcastsBox";
import BoxBlock from "../core/BoxBlock";
import arrowLeft from "../assets/images/arrow-left.png";
// import { myBroadcastsList } from "./myBroadcastsList";
import BroadcastInfo from "./BroadcastInfo";
import BroadcastPreview from "./BroadcastPreview";
import PropTypes from "prop-types";

class BroadcastHistorySelectionPage extends Component {
  isBroadcastListEmpty = () => {
    if (this.props.broadcastsList === null || this.props.broadcastsList.length === 0) return true;
    return false;
  };

  isSingleBroadcastSelected = () => {
    if (this.props.selectedBroadcast.empty) return false;
    return true;
  };

  renderRightPane(selectedBroadcast, getCommentsByRoomId, shareBroadcastOnIGTV, isSharingToIGTV) {
    const isBroadcastListEmpty = this.isBroadcastListEmpty();
    const isSingleBroadcastSelected = this.isSingleBroadcastSelected();

    if (isBroadcastListEmpty) {
      return (
        <React.Fragment>
          <div className="BroadcastHistorySelectionPage-content">
            <BoxBlock
              borderHide={selectedBroadcast.recently}
              title="my_broadcasts"
              boxType="my_broadcasts"
            >
              <div className="BroadcastHistorySelectionPage-recently-phrase-2">
                <img
                  src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/samsung/161/white-frowning-face_2639.png"
                  alt="frowning face"
                  style={{
                    width: "30px",
                  }}
                />
                <br />
                There has been no broadcast activities recently!
              </div>
            </BoxBlock>
          </div>
        </React.Fragment>
      );
    }

    if (isSingleBroadcastSelected) {
      return (
        <div className="BroadcastHistorySelectionPage-content">
          <BoxBlock title="my_broadcasts" boxType="my_broadcasts">
            <BroadcastInfo
              getCommentsByRoomId={getCommentsByRoomId}
              shareBroadcastOnIGTV={shareBroadcastOnIGTV}
              currentBroadcast={selectedBroadcast}
              isSharingToIGTV={isSharingToIGTV}
            />
          </BoxBlock>

          <BoxBlock
            addedClassName="broadcast-preview"
            title="my_broadcasts"
            boxType="my_broadcasts"
          >
            <BroadcastPreview currentBroadcast={selectedBroadcast} />
          </BoxBlock>
        </div>
      );
    }

    // else ... show message to user to select one item ...
    return (
      <div className="BroadcastHistorySelectionPage-content phrase-1-wrapper">
        <BoxBlock title="my_broadcasts" boxType="my_broadcasts">
          <div className="select-broadcast-height-wrapper">
            <div className="BroadcastHistorySelectionPage-phrase-1">
              To view broadcast history, Select a Broadcast from the{" "}
              <span className="word-left">left</span> panel...
              <br />
              <img src={arrowLeft} alt="arrow-left" className="arrow-left" />
            </div>
          </div>
        </BoxBlock>
      </div>
    );
  }

  renderLeftPane(broadcastsList, selectedBroadcast, fetchBroadcastById, deleteRoom, showModal) {
    return (
      <div className="BroadcastHistorySelectionPage__sidePanel">
        <MyBroadcastsBox
          myBroadcastsList={broadcastsList}
          fetchBroadcastById={fetchBroadcastById}
          selectedBroadcastId={selectedBroadcast.roomId}
          deleteRoom={deleteRoom}
          showModal={showModal}
          titleHider={selectedBroadcast.recently === false}
        />
      </div>
    );
  }

  render() {
    const {
      selectedBroadcast,
      broadcastsList,
      fetchBroadcastById,
      getCommentsByRoomId,
      shareBroadcastOnIGTV,
      isSharingToIGTV,
      deleteRoom,
      showModal,
    } = this.props;

    return (
      <div className="BroadcastHistorySelectionPage">
        {this.renderLeftPane(
          broadcastsList,
          selectedBroadcast,
          fetchBroadcastById,
          deleteRoom,
          showModal
        )}
        {this.renderRightPane(
          selectedBroadcast,
          getCommentsByRoomId,
          shareBroadcastOnIGTV,
          isSharingToIGTV
        )}
      </div>
    );
  }
}

BroadcastHistorySelectionPage.propTypes = {
  broadcastsList: PropTypes.array,
  selectedBroadcast: PropTypes.object,
  fetchBroadcastById: PropTypes.func,
  getCommentsByRoomId: PropTypes.func,
  shareBroadcastOnIGTV: PropTypes.func,
  deleteRoom: PropTypes.func,
  showModal: PropTypes.func,
  isSharingToIGTV: PropTypes.bool,
};

export default BroadcastHistorySelectionPage;
