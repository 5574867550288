import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PricingPagePath } from "../../PageConstants";
import { LoolaAnalyticsConstants } from "../../core/loola-analytics-constants";

class UpgradeVersionLink extends Component {
  onUpgradeLinkClick = isExceeded => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.UPGRADE_VERSION_CLICKED, {
      isExceeded,
      isLive: this.props.isLive,
      isInTrialPeriod: this.props.isInTrialPeriod,
      trialTimeLeftInSeconds: this.props.trialTimeLeftInSeconds,
    });
  };

  render() {
    const { isExceeded } = this.props;

    return (
      <a
        href={PricingPagePath}
        onClick={() => this.onUpgradeLinkClick(isExceeded)}
        target="_self"
        rel="noopener noreferrer"
      >
        <span className="UpgradeVersionIndicator__upgradeMessage">
          (Upgrade Now <FontAwesomeIcon icon="arrow-circle-up" name="arrow-circle-up" />)
        </span>
      </a>
    );
  }
}

UpgradeVersionLink.propTypes = {
  isExceeded: PropTypes.bool,
  isLive: PropTypes.bool,
  isInTrialPeriod: PropTypes.bool,
  trialTimeLeftInSeconds: PropTypes.number,
  trialExceededLimit: PropTypes.bool,
};

export default UpgradeVersionLink;
