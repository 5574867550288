import React, { Component } from "react";
import { numberWithCommas } from "../core/functions";
import moment from "moment";
import PlatformIcon from "../common/PlatformIcon";
import { downloadData } from "../core/utils";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import DownloadButton from "../core/DownloadButton";
import ShareOnIGTVButton from "../core/ShareOnIGTVButton";

class BroadcastInfo extends Component {
  getComments = roomId => {
    this.props.getCommentsByRoomId(roomId);
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.DOWNLOAD_COMMENTS_CLICKED);
  };

  shareOnIGTV = roomId => {
    this.props.shareBroadcastOnIGTV({ roomId });
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.SHARE_ON_IGTV_CLICKED);
  };

  convertDateToTime(tempTime) {
    return new Date(tempTime).toTimeString().split(" ")[0];
  }

  formatCommentsAsDownloadableText = comments => {
    let formattedComments = "";
    Array.from(comments).forEach(comment => {
      formattedComments += `${this.convertDateToTime(comment.timeStamp)} > ${comment.userName} @ ${
        comment.channel
      } : ${comment.comment}\n`;
    });
    return `data:text/plain;charset=utf-8,${encodeURIComponent(formattedComments)}`;
  };

  componentDidUpdate(prevProps) {
    const { broadcastInfo } = this.props.currentBroadcast;
    const { comments, roomId } = broadcastInfo;
    if (
      prevProps.currentBroadcast.broadcastInfo.comments !== comments &&
      comments &&
      comments.length > 0
    ) {
      downloadData(comments, `${roomId}_comments.txt`, this.formatCommentsAsDownloadableText);
    }
  }
  renderActions(currentBroadcast, broadcastInfo, isSharingToIGTV) {
    const { liveEventPlatformsTotals, liveEventPlatformCounters } = currentBroadcast;
    return (
      <>
        {liveEventPlatformsTotals.some(
          liveEventPlatformsTotal => liveEventPlatformsTotal.platform === "instagram"
        ) ? (
          <ShareOnIGTVButton
            showLoading={isSharingToIGTV}
            additionalClassName="broadcast-share-on-IGTV"
            isShared={currentBroadcast.IGTVShareStatus}
            onClick={() => {
              this.shareOnIGTV(broadcastInfo.roomId);
            }}
          />
        ) : null}
        <DownloadButton
          disabled={!(liveEventPlatformCounters.sumOfComments > 0)}
          actionText="Download Chat"
          onClick={() => {
            this.getComments(broadcastInfo.roomId);
          }}
        />
      </>
    );
  }

  render() {
    const { currentBroadcast, isSharingToIGTV } = this.props;
    const { broadcastInfo } = currentBroadcast;
    const { liveEventPlatformsTotals, liveEventPlatformCounters } = currentBroadcast;
    const broadcastTitle = broadcastInfo.title ? broadcastInfo.title : "[No Name]";

    return (
      <React.Fragment>
        <div className="separator-line" />

        <div className="full-statistics">
          <div className="full-statistics-div">
            <div className="broadcast-name-wrapper">
              <div className="broadcast-summary-heading">
                <h4 className="broadcast-summary-title">Summary</h4>
                <div className="broadcast-action-container">
                  {this.renderActions(currentBroadcast, broadcastInfo, isSharingToIGTV)}
                </div>
              </div>
              Broadcast Title: <span className="broadcast-name">{broadcastTitle}</span>
            </div>

            <div className="summary-info">
              <div className="fiftyPercent" style={{ marginRight: "40px" }}>
                <div>
                  <p>
                    Total views:
                    <span className="spanP">
                      {numberWithCommas(liveEventPlatformCounters.sumOfViews, 0)}
                    </span>
                  </p>
                  <p>
                    Max Concurrent Viewers:
                    <span className="spanP">
                      {numberWithCommas(liveEventPlatformCounters.sumOfViewers, 0)}
                    </span>
                  </p>
                  <p>
                    Total Likes:
                    <span className="spanP">
                      {numberWithCommas(liveEventPlatformCounters.sumOfLikes, 0)}
                    </span>
                  </p>
                  <p>
                    Total comments:
                    <br />
                    <span className="spanP">
                      {numberWithCommas(liveEventPlatformCounters.sumOfComments, 0)}
                    </span>
                  </p>
                  <p>
                    New Followers:
                    <span className="spanP">
                      {numberWithCommas(liveEventPlatformCounters.newFollowers, 0)}
                    </span>
                  </p>
                </div>
              </div>

              <div className="fiftyPercent">
                <div style={{ marginLeft: 10, marginRight: 15 }}>
                  <p>
                    Date:{" "}
                    <span className="spanP">
                      {moment(broadcastInfo.startTime).format("MMM Do YYYY, h:mm a")}
                    </span>
                  </p>
                  <p>
                    Duration: <span className="spanP">{broadcastInfo.lengthInSeconds}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="full-statistics-div">
            <h4 style={{ flex: "1 0" }}>Views per platform</h4>

            <div className="full-statistics-div-platforms">
              {liveEventPlatformsTotals.map((platform, i) => (
                <div className="full-statistics-div-platform" key={i}>
                  <div className="full-statistics-div-platform-image-wrapper">
                    <PlatformIcon channel={platform.platform} />
                  </div>

                  <p style={{ fontWeight: 600 }}>{`${platform.platform}`}</p>

                  <p>{numberWithCommas(platform.totalViews)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BroadcastInfo;
