import React, { Component } from "react";
import PropTypes from "prop-types";
import "./CopyToClipboardComponent.css";
import TextField from "../core/TextField";

const FakeButton = ({ text, onMouseOut }) => (
  <div className="clip-button" onMouseOut={onMouseOut} onBlur={onMouseOut}>
    {text}
  </div>
);

// const Pre = ({ setRef, text }) => <pre ref={setRef}>{text}</pre>;

// const Pre = ({ setRef, text, title }) => (
//   <input ref={setRef} value={text} className="pre--input" type="text" readOnly />
// );

const Pre = ({ setRef, text, title }) => (
  <TextField
    ref={setRef}
    value={text}
    // onChange={this.handleUserName}
    label={title}
    name={title.replace(/\s/g, "")}
    shouldShowError={false}
  />
);

class CopyToClipboardComponent extends Component {
  handleDidCopy() {
    console.log("Copied content");
    this.setState({
      pop: "clip-pop",
    });
  }

  handleMouseOut() {
    if (this.state && this.state.pop) {
      this.setState({
        pop: null,
      });
    }
  }

  handleCopy2 = e => {
    // find target element
    // const t = e.target;
    // let c = t.dataset.copytarget;
    // let inp = (c ? document.querySelector(c) : null);

    const inp = e.target;
    // is element selectable?
    if (inp && inp.select) {
      // select text
      inp.select();

      try {
        // copy text
        document.execCommand("copy");
        this.handleDidCopy();
        inp.blur();
      } catch (err) {
        console.log("please press Ctrl/Cmd+C to copy");
      }
    }
  };

  handleCopy() {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNode(this.sample);
    selection.empty();
    selection.addRange(range);

    const execCommandResult = document.execCommand("copy");
    if (execCommandResult) {
      this.handleDidCopy();
    } else {
      console.log("Copy failed");
    }
  }
  render() {
    const { pop } = this.state || {};
    const classNames = `CopyToClipboardComponent ${pop || ""}`;

    return (
      <div
        className={classNames}
        onClick={e => this.handleCopy2(e)}
        onMouseOut={() => this.handleMouseOut()}
        onBlur={() => this.handleMouseOut()}
      >
        <Pre
          setRef={r => {
            this.sample = r;
          }}
          title={this.props.title}
          text={this.props.text}
        />
        <FakeButton text="Copy" />
      </div>
    );
  }
}

CopyToClipboardComponent.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { CopyToClipboardComponent };
