import React, { Component } from "react";
import BroadcastSummaryContainer from "../Analytics/BroadcastSummaryContainer";

class LiveVideoBroadcastSummary extends Component {
  render() {
    const { isBroadcastSummaryOpened, closeBroadcastSummary } = this.props;

    if (isBroadcastSummaryOpened) {
      return <BroadcastSummaryContainer closeBroadcastSummary={closeBroadcastSummary} />;
    }
    return null;
  }
}

export default LiveVideoBroadcastSummary;
