import React from "react";
import "./index.css";

const Switch = props => {
  const classNames = ["switch switch--graphite-small"];
  return (
    <div className={classNames} onClick={props.handleToggle}>
      <ToggleButton isOn={props.isOn} />
    </div>
  );
};

const ToggleButton = props => {
  const classNames = [
    "switch-toggle",
    props.isOn ? "switch-toggle--on" : "switch-toggle--off",
  ].join(" ");
  return <div className={classNames} />;
};

export default Switch;
