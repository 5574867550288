import React, { Component } from "react";
import "./IGTVIcon.css";

class IGTVIcon extends Component {
  render() {
    const { size, additionalClassName } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`IGTV-icon--${size} ${additionalClassName}`}
        viewBox="0 0 108.56 122.88"
        fill="#ffffff"
      >
        <path d="M30.47 16.05h12.47l-6.35-7.16A5.349 5.349 0 1144.6 1.8l9.11 10.27 9.1-10.27a5.349 5.349 0 118.01 7.09l-6.35 7.16h13.62c8.4 0 16.04 3.43 21.57 8.96 5.49 5.49 8.9 13.05 8.9 21.35v46.21c0 8.3-3.41 15.86-8.9 21.35-5.53 5.53-13.16 8.96-21.57 8.96H30.47c-8.4 0-16.04-3.43-21.57-8.96C3.41 108.43 0 100.87 0 92.57V46.36c0-8.3 3.41-15.86 8.9-21.35 5.53-5.53 13.17-8.96 21.57-8.96zm-2.59 59.13a5.352 5.352 0 01-2.68-10.36L54 57.37c2.86-.74 5.78.98 6.52 3.84.14.53.19 1.07.17 1.59l-.32 6.38 19.17-5.52a5.35 5.35 0 016.61 3.67 5.35 5.35 0 01-3.67 6.61l-26.02 7.5c-.64.23-1.34.34-2.06.31-2.95-.14-5.23-2.65-5.1-5.6l.33-6.58-21.75 5.61zm50.21-48.7H30.47c-5.4 0-10.31 2.22-13.88 5.78-3.6 3.6-5.84 8.59-5.84 14.1v46.21c0 5.51 2.24 10.5 5.84 14.1 3.57 3.57 8.48 5.78 13.88 5.78h47.61c5.4 0 10.31-2.22 13.88-5.78 3.6-3.6 5.84-8.59 5.84-14.1V46.36c0-5.51-2.24-10.5-5.84-14.1-3.56-3.57-8.47-5.78-13.87-5.78z" />
      </svg>
    );
  }
}

export default IGTVIcon;
