import { Constants } from "../../core/constants";
import { UserSettingsModel } from "../../models/UserSettingsModel";

const initialState = {
  overlayCanvasUrl: null,
  videoOverlay: null,
  userSettings: null, // videoResolution, showLogo
  isSaved: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.ACTION_TYPES.USER_SETTINGS_SAVE_VIDEO_LAYOUT:
      return state; // Object.assign({}, state, { fans: newFans });

    case Constants.ACTION_TYPES.USER_SETTINGS:
      const userSettingsServerResponse = action.payload; // clone
      const userSettings = UserSettingsModel.map(userSettingsServerResponse);

      console.log("USER SETTINGS -- GET", userSettings);
      return Object.assign({}, state, { userSettings, isSaved: true });
    default:
      return state;
  }
}
