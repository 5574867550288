import { Constants } from "../../core/constants";

const initialState = {
  platform: "",
  accounts: [],
  selectedAccountId: "",
};

export default function(state = initialState, action) {
  console.log("reducer platform settings", action.type);
  switch (action.type) {
    case Constants.ACTION_TYPES.PLATFORM_SETTINGS_GET_PLATFORM_ACCOUNTS:
      console.log("reducer platform settings");
      if (action.error) {
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.error.message,
        });
      }

      const { pages, groups, selectedAccountId } = action.payload;
      const accounts = groups.concat(pages);

      console.log("reducer platform settings", accounts, action.payload);
      return Object.assign({}, state, {
        pages,
        groups,
        accounts,
        selectedAccountId,
      });

    case Constants.ACTION_TYPES.PLATFORM_SETTINGS_UPDATE_PLATFORM_SETTINGS:
      return Object.assign({}, state, { selectedAccountId: action.payload.selectedAccountId });

    default:
      return state;
  }
}
