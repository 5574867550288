import { isFSA } from "flux-standard-action";
import { ServerErrorMessages } from "../core/server-error-messages";
import { Constants } from "../core/constants";
import { isPromise } from "../core/utils";

const API_CALL = "API_CALL";

const updateActionWithError = (
  action,
  errorCode,
  errorMessage,
  rawErrorMessage = null,
  rawServerError = null
) =>
  Object.assign({}, action, {
    payload: {},
    error: {
      code: errorCode,
      message: errorMessage,
      rawMessage: rawErrorMessage,
      rawError: rawServerError,
    },
  });

const loolaApiHandler = __store => next => action => {
  if (action === "undefined") {
    return;
  }

  const callApiAction = action[API_CALL];
  if (typeof callApiAction === "undefined") {
    return next(action);
  }

  let _action = action[API_CALL];

  if (!isFSA(_action)) {
    return isPromise(_action) ? _action.then(next) : next(_action);
  }

  if (isPromise(_action.payload)) {
    _action.payload
      .then(response => {
        if (!response.ok) {
          // non-applicative error - 404, 500 etc..
          return next(
            updateActionWithError(
              _action,
              response.status,
              ServerErrorMessages[response.status],
              response.statusText
            )
          );
        }
        response.json().then(apiResponse => {
          // Status - return payload
          // must be "==" to support 200 and "200"
          console.log(apiResponse);
          // eslint-disable-next-line
          if (apiResponse.status == Constants.API_STATUS_CODES.OK) {
            return next(Object.assign({}, _action, { payload: apiResponse.data }));
          }
          // must be "==" to support 401 and "401" for example
          // eslint-disable-next-line eqeqeq
          if (apiResponse.status == Constants.API_STATUS_CODES.UNAUTHORIZED) {
            _action = {
              type: Constants.ACTION_TYPES.UNAUTHORIZED,
              payload: { source_action_type: _action.type },
            };
            return next(_action);
          }
          // Applicative error
          let errorMessage = ServerErrorMessages[apiResponse.status];
          if (!errorMessage) {
            errorMessage = Constants.DEFAULT_SERVER_APPLICATIVE_ERROR_MESSAGE;
            console.error(`Server error: ${apiResponse.errorMessage} in action:`, _action);
          }
          return next(
            updateActionWithError(
              _action,
              apiResponse.status,
              errorMessage,
              apiResponse.errorMessage,
              apiResponse.rawServerError
            )
          );
        });
      })
      .catch(error => {
        console.error(error);
        return next(
          updateActionWithError(
            _action,
            Constants.API_STATUS_CODES.SERVER_ERROR,
            Constants.DEFAULT_500_SERVER_ERROR_MESSAGE
          )
        );
      });
  } else {
    return next(_action);
  }
};

export { loolaApiHandler, API_CALL };
