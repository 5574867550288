import { Constants } from "../../core/constants";
import { API_CALL } from "../../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../../core/loola-api";

const authActions = {
  [Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER]: userDetails => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.USER_SIGN_IN_OR_REGISTER,
        payload: LoolaApi.signInOrRegister(userDetails),
      },
    };
  },

  [Constants.ACTION_TYPES.USER_SIGN_OUT]: () => {
    // TODO: change this after moving from cookie based to headers concept
    // clearAuthState();
    // window.location.href = "/";
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.USER_SIGN_OUT,
        payload: LoolaApi.signOut(),
      },
    };
  },
};

export { authActions };
