import "./MainVideoRoomPage.css";

import React, { Component } from "react";
import LiveActionBar from "../LiveVideoEditor/LiveActionBar";
import ActiveFansBoxContainer from "../common/ActiveFans/ActiveFansBoxContainer";
import StreamDestinationBoxContainer from "../Platforms/StreamDestinationBoxContainer";
import LiveVideoContainer from "../LiveVideo/LiveVideoContainer";
import ChatViewerBoxContainer from "../common/ChatMessages/ChatViewerBoxContainer";
import FacebookStateContainer from "../Facebook/FacebookStateContainer";
import OverlaysFrameContainer from "../Overlays/OverlaysFrameContainer";

export default class MainVideoRoomPage extends Component {
  render() {
    console.log("MainVideoRoomPage render called");
    return (
      <div className="MainVideoRoomPage WebVideoRoomView">
        <FacebookStateContainer />
        <div className="MainVideoRoomPage__sidePanel MainVideoRoomPage__rightRailContainer">
          <div className="MainVideoRoomPage__rightRailContainerChatViewerBoxContainer">
            <ChatViewerBoxContainer />
          </div>
        </div>
        <div className="MainVideoRoomPage__videoRailContainer">
          <LiveVideoContainer />
          <LiveActionBar />
        </div>
        <div className="MainVideoRoomPage__sidePanel  MainVideoRoomPage__leftRailContainer">
          <StreamDestinationBoxContainer />
          <ActiveFansBoxContainer />
          <OverlaysFrameContainer isHidden />
        </div>
      </div>
    );
  }
}
