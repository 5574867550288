import React, { Component } from "react";
import PlatformItem from "./PlatformItem";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { sendAction } from "../core/send-action";
import { withRouter } from "react-router";
import { Constants } from "../core/constants";

import "./ConfigurePlatformsPage.css";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";
import { platformsActions } from "./platforms-actions";
import FacebookStateContainer from "../Facebook/FacebookStateContainer";

const getPlatformConfig = platformsActions.PLATFORM_GET_PLATFORMS;

class ConfigurePlatformsPage extends Component {
  UNSAFE_componentWillMount() {
    window.analytics.page(LoolaAnalyticsConstants.PAGES.CONFIGURATION_PAGE);
    this.props.getPlatformConfig();
  }

  handleSignOut = () => {
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.USER_SIGNED_OUT);
    this.props.sendAction(Constants.ACTION_TYPES.USER_SIGN_OUT);
  };

  renderListOfPlatforms() {
    const { platforms } = this.props;
    const { platformModelArr } = platforms;

    return platformModelArr.map(platformModel => {
      const { platformType } = platformModel;
      const isConfigured = platformModel.isConfiguredOrAuthenticated();

      return (
        <PlatformItem
          channelType={platformType}
          isConfigured={isConfigured}
          key={platformType}
          platform={platformModel}
          getPlatformConfig={this.props.getPlatformConfig}
        />
      );
    });
  }

  renderUserIdSection() {
    if (this.props.currentUser === null || this.props.currentUser === undefined) return null;

    // else ...
    return (
      <div className="ConfigurePlatformsPage__divItemStyle ConfigurePlatformsPage__Identification">
        <div className="ConfigurePlatformsPage__Identification--subject"> User ID: </div>
        <div>{this.props.currentUser.email}</div>
      </div>
    );
  }

  renderUserSubscriptionPlanSection() {
    const { currentUser } = this.props;
    if (currentUser === null || currentUser === undefined) return null;
    if (
      currentUser.subscriptionPlan === null ||
      currentUser.subscriptionPlan === undefined ||
      currentUser.subscriptionPlan === "" ||
      currentUser.subscriptionPlan.toLowerCase() === "free"
    )
      return null;

    // else ...
    return (
      <div className="ConfigurePlatformsPage__divItemStyle ConfigurePlatformsPage__Identification">
        <div className="ConfigurePlatformsPage__Identification--subject"> Subscription Plan: </div>
        <span>{this.props.currentUser.subscriptionPlan}</span> &nbsp;
      </div>
    );
  }

  render() {
    return (
      <section className="ConfigurePlatformsPage">
        <h1 className="ConfigurePlatformsPage__title"> Click to Configure New Channels </h1>
        <FacebookStateContainer />
        <div className="ConfigurePlatformsPage__divItemStyle">
          <div className="ConfigurePlatformsPage__platformsContainer">
            {this.renderListOfPlatforms()}
          </div>
        </div>
        <div className="ConfigurePlatformsPage__divItemStyle">
          {this.renderUserIdSection()}
          {this.renderUserSubscriptionPlanSection()}
        </div>
        <div className="ConfigurePlatformsPage__logout">
          <button className="ConfigurePlatformsPage__logout-button" onClick={this.handleSignOut}>
            Log Out
          </button>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    platforms: state.platformsData.configuredPlatformsModel,
    currentUser: state.authenticationData.currentUser,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ sendAction, getPlatformConfig }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(ConfigurePlatformsPage));
