import React, { Component } from "react";
import PropTypes from "prop-types";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, .65)",
  },
  content: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10000,
    overflow: "auto",
    textAlign: "center",
    overflowScrolling: "touch",
    padding: "4px",
    cursor: "pointer",
    after: {
      verticalAlign: "middle",
      display: "inline-block",
      height: "100%",
      marginLeft: "-.05em",
      content: "",
    },
  },
  dialog: {
    position: "relative",
    outline: 0,
    width: "100%",
    background: "white",
    display: "inline-block",
    verticalAlign: "middle",
    boxSizing: "border-box",
    maxWidth: "650px",
    cursor: "default",
    boxShadow: "0px 23px 10px -17px rgba(0,0,0,0.5)",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: "12px",
  },
  header: {
    position: "relative",
    padding: "16px 8px 8px 8px",
  },
  body: {
    paddingBottom: "16px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  close: {
    cursor: "pointer",
    position: "absolute",
    width: "30px",
    height: "30px",
    top: 0,
    right: 0,
    color: "#000",
    fontSize: "20px",
  },
};

export default class Modal extends Component {
  listenKeyboard = event => {
    if (event.key === "Escape" || event.keyCode === 27) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener("keydown", this.listenKeyboard, true);
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener("keydown", this.listenKeyboard, true);
    }
  }

  get title() {
    const { title } = this.props;

    return title ? (
      <div className="modal__title">
        <h1>{title}</h1>
      </div>
    ) : null;
  }

  get close() {
    const { onClose } = this.props;

    return onClose ? (
      <div className="modal__close" style={styles.close} onClick={onClose}>
        x
      </div>
    ) : null;
  }

  onOverlayClick = () => {
    this.props.onClose();
  };

  onDialogClick = event => {
    event.stopPropagation();
  };

  render() {
    const { children, width = "100%", height = "auto" } = this.props;

    const dialogStyle = {
      ...styles.dialog,
      width,
      height,
    };

    return (
      <div className="modal">
        <div style={styles.overlay} />
        <div style={styles.content} onClick={this.onOverlayClick}>
          <div style={dialogStyle} onClick={this.onDialogClick}>
            <div style={styles.header}>
              {this.title}
              {this.close}
            </div>
            <div style={styles.body}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
};
