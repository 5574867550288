import { API_CALL } from "../../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../../core/loola-api";
import { Constants } from "../../core/constants";

const userSettingsLiveLimitsActions = {
  [Constants.ACTION_TYPES.USER_SETTINGS_GET_LIVE_LIMIT]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.USER_SETTINGS_GET_LIVE_LIMIT,
        payload: LoolaApi.getUserSettingsLiveLimits(),
      },
    };
  },
};

export { userSettingsLiveLimitsActions };
