import React, { Component } from "react";
import "./InstagramLoginForm.css";
import { connect } from "react-redux";
import Scheduler from "../core/Scheduler";
import { bindActionCreators } from "redux";
import { sendAction } from "../core/send-action";
import PropTypes from "prop-types";
import { Constants } from "../core/constants";
import { ConfigurePlatformsPagePath } from "../PageConstants";

import InstagramLoginButton from "./InstagramLoginButton";
import ChromeExtensionUtil from "../core/chrome-extension-util";
import FacebookStateContainer from "./FacebookStateContainer";
import { LoolaAnalyticsConstants } from "../core/loola-analytics-constants";

class InstagramLoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasExtensionInstalled: false,
      isLoginStarted: false,
    };
  }

  checkExtensionIsInstalled = () => {
    ChromeExtensionUtil.checkExtensionIsInstalled(isInstalled => {
      this.setState({ hasExtensionInstalled: isInstalled });

      window.analytics.track(LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_VALIDATION, {
        hasExtensionInstalled: isInstalled,
        platform: this.props.platformType,
      });
    });
  };

  cleanToken = () => {
    ChromeExtensionUtil.sendMessage("CLEAN_TOKEN", reply => {
      this.props.sendAction(Constants.ACTION_TYPES.FACEBOOK_CLEAR_INSTAGRAM_TOKEN);
      console.log("InstagramLoginForm: CLEAN Token -> ", reply);
    });
  };

  getInstagramCookiesFromExtension = () => {
    ChromeExtensionUtil.sendMessage("GET_INSTAGRAM_COOKIES", reply => {
      if (reply == null || reply === "" || reply === "null") {
        // window.analytics.track(
        //   LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_GET_TOKEN_FAILED, {platform: this.props.platformType});
        console.log("InstagramLoginForm: GET Token -> reply is NULL", reply);
        return;
      }
      // else...
      console.log("InstagramLoginForm: GET Token -> ", reply.mid, reply.ds_user_id, reply);

      // else - in case no nulls or errors - we will send the token to our servers
      this.setState({ isLoginStarted: false });
      this.props.sendAction(Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_COOKIE, reply);
      window.analytics.track(LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_GET_TOKEN_COMPLETED, {
        platform: this.props.platformType,
      });
    });
  };

  getTokenDetailsFromExtension = () => {
    ChromeExtensionUtil.sendMessage("GET_TOKEN", reply => {
      console.log("InstagramLoginForm: GET Token -> ", reply);

      if (reply == null || reply === "" || reply === "null") {
        // window.analytics.track(
        //   LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_GET_TOKEN_FAILED, {platform: this.props.platformType});
        return;
      }

      // else - in case no nulls or errors - we will send the token to our servers
      this.setState({ isLoginStarted: false });
      this.props.sendAction(Constants.ACTION_TYPES.FACEBOOK_SET_INSTAGRAM_TOKEN, reply);
      window.analytics.track(LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_GET_TOKEN_COMPLETED, {
        platform: this.props.platformType,
      });
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("InstagramLoginForm: component will receive props");
    // if (this.props.token !== nextProps.token && nextProps.token !== "") {
    //   console.log(
    //     "InstagramLoginForm: component will receive props -> new token: ",
    //     nextProps.token
    //   );
    //   this.callSetAuthToken(nextProps.token, nextProps.countryCode);
    // }

    if (this.props.cookie !== nextProps.cookie && nextProps.cookie !== "") {
      console.log(
        "InstagramLoginForm: component will receive props -> new cookie: ",
        nextProps.cookie
      );
      // this.callSetAuthToken(nextProps.token, nextProps.countryCode);
      this.callSetAuthTokenByCookie(nextProps.cookie, nextProps.countryCode);
    }
  }

  UNSAFE_componentWillMount() {
    console.log("InstagramLoginForm: here we should check state... ");
    this.checkExtensionIsInstalled();
    this.cleanToken();
  }
  componentWillUnmount() {
    console.log("InstagramLoginForm: unmount and cleaning token... ");
    this.cleanToken();
  }

  callSetAuthTokenByCookie(cookie, countryCode) {
    this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_CONFIGURE, {
      platformType: this.props.platformType,
      oauthCode: cookie,
      countryCode,
    });
  }

  // callSetAuthToken(token, countryCode) {
  //   this.props.sendAction(Constants.ACTION_TYPES.PLATFORM_CONFIGURE, {
  //     platformType: this.props.platformType,
  //     oauthCode: token,
  //     countryCode,
  //   });
  // }

  renderHasExtensionInstalled() {
    return (
      <div>
        Please Download our{" "}
        <a
          href="https://chrome.google.com/webstore/detail/loola/cegddajdfabllncgnkadccjdkffbnolk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chrome Extension
        </a>{" "}
        first.
      </div>
    );
  }

  renderNoFacebookUserIsConnected() {
    return (
      <div>
        <div>
          Please log into{" "}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>{" "}
          first.
        </div>
        <div>
          Next, please click <a href={ConfigurePlatformsPagePath}>here</a>
        </div>
      </div>
    );
  }

  handleSave = () => {
    this.setState({ isLoginStarted: true });
    window.analytics.track(LoolaAnalyticsConstants.EVENTS.CHROME_EXTENSION_GET_TOKEN_STARTED, {
      platform: this.props.platformType,
    });
    // this.props.onSaveEvent();
  };

  renderScheduledInstagramLoginButton() {
    // else
    const { isLoginStarted } = this.state;
    // const noTokenYet = this.props.token === "";
    const noCookieYet = this.props.cookie === "";
    console.log(
      `InstagramLoginForm: noTokenYet = ${noCookieYet}, isLoginStarted = ${isLoginStarted}, scheduler is running = ${isLoginStarted}`
    );
    return (
      <Scheduler
        action={this.getInstagramCookiesFromExtension}
        interval={2000}
        isRunning={isLoginStarted}
      >
        <InstagramLoginButton
          onClickEvent={this.handleSave}
          currentFacebookProfile={this.props.currentFacebookProfile}
        />
      </Scheduler>
    );
  }
  renderInstagramLoginStates() {
    if (!this.state.hasExtensionInstalled) {
      return this.renderHasExtensionInstalled();
    }

    // else
    return this.renderScheduledInstagramLoginButton();
  }

  render() {
    return (
      <div className="InstagramLoginForm">
        <FacebookStateContainer forceInitialize />
        {this.renderInstagramLoginStates()}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAction,
    },
    dispatch
  );
}

InstagramLoginButton.propTypes = {
  platformType: PropTypes.string,
  currentFacebookProfile: PropTypes.string,
  loginState: PropTypes.string, // "unknown" "connected" "not_authorized"
  token: PropTypes.string,
  cookie: PropTypes.object,
  countryCode: PropTypes.string,
  isConnectedToInstagram: PropTypes.bool,
  onSaveEvent: PropTypes.func,
};

export default connect(null, matchDispatchToProps)(InstagramLoginForm);
