import { API_CALL } from "../redux-middlewares/loola-api-handler";
import { LoolaApi } from "../core/loola-api";
import { Constants } from "../core/constants";

const liveStreamActions = {
  [Constants.ACTION_TYPES.LIVE_START_BROADCAST]: broadcastConfig => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_START_BROADCAST,
        payload: LoolaApi.startBroadcastSession(broadcastConfig),
      },
    };
  },
  [Constants.ACTION_TYPES.LIVE_HEARTBEAT_BROADCAST]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_HEARTBEAT_BROADCAST,
        payload: LoolaApi.validateBroadcastSession(),
      },
    };
  },
  [Constants.ACTION_TYPES.LIVE_END_BROADCAST]: broadcastConfig => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_END_BROADCAST,
        payload: LoolaApi.endBroadcastSession(broadcastConfig),
      },
    };
  },

  [Constants.ACTION_TYPES.LIVE_TAB_SWITCH]: tab => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.LIVE_TAB_SWITCH,
        payload: tab,
      },
    };
  },

  [Constants.ACTION_TYPES.RTMP_LOAD]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.RTMP_LOAD,
        payload: LoolaApi.validateRTMPStream(),
      },
    };
  },

  [Constants.ACTION_TYPES.RTMP_HEARTBEAT]: () => {
    return {
      [API_CALL]: {
        type: Constants.ACTION_TYPES.RTMP_HEARTBEAT,
        payload: LoolaApi.validateRTMPStream(),
      },
    };
  },
};

export { liveStreamActions };
